///////////////////////////////
// Imports
///////////////////////////////

import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_ClassCalculatedFields_Document } from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import { DatabaseRef_DataBucket_CalculatedField_Document } from 'rfbp_aux/services/database_endpoints/clients/architecture/data_buckets'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
  TsType_FormInputType,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_FormDialogObject } from 'rfbp_core/services/context'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Variables
///////////////////////////////

const mappingTypeOptions: TsInterface_UnspecifiedObject = {
  hard_code: {
    key: 'hard_code',
    value: rLIB('Hard Code'),
    icon: 'rectangle-code',
    disabled: false,
  },
  user_entered_value: {
    key: 'user_entered_value',
    value: rLIB('User Entered Value'),
    icon: 'message-pen',
    disabled: false,
  },
  lookup_table_value: {
    key: 'lookup_table_value',
    value: rLIB('Lookup Table Value'),
    icon: 'magnifying-glass-arrow-right',
  },
}

///////////////////////////////
// Exports
///////////////////////////////

export const tableColumns_CalculatedFieldVariables: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('Variable Name'), 'name'),
  variable_mapping_type: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Variable Mapping Type')
      },
      header_sort_by: null,
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        // Value
        let dropdownValue = ''
        if (
          tableAdditionalData != null &&
          tableAdditionalData.calculatedField != null &&
          tableAdditionalData.calculatedField.variable_mapping != null &&
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData.calculatedField.variable_mapping[rowData.key as string] != null &&
          tableAdditionalData.calculatedField.variable_mapping[rowData.key as string].variable_mapping_type != null
        ) {
          dropdownValue = tableAdditionalData.calculatedField.variable_mapping[rowData.key as string].variable_mapping_type
        }
        // Cell JSX
        let cellJSX = (
          <Box
            className="tw-py-2"
            // sx={cellSX}
          >
            <FormControl
              className="bp_thin_select_input bp_thin_select_multiple_input"
              sx={{ minWidth: '130px', width: 'calc(100%)' }}
            >
              <InputLabel>{rLIB('Variable Mapping Type')}</InputLabel>
              <Select
                autoWidth={true}
                label={rLIB('Variable Mapping Type')}
                onChange={(event, value) => {
                  if (rowData.key != null) {
                    if (
                      tableAdditionalData != null &&
                      tableAdditionalData.itemKey != null &&
                      tableAdditionalData.calculatedField != null &&
                      tableAdditionalData.calculatedField.key != null &&
                      rowData != null &&
                      rowData.key != null &&
                      event.target.value != null
                    ) {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        variable_mapping: {
                          [rowData.key as string]: {
                            variable_mapping_type: event.target.value,
                          },
                        },
                      }
                      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          if (tableAdditionalData.table_data_source === 'class') {
                            DatabaseSetMergeDocument(
                              DatabaseRef_ClassCalculatedFields_Document(
                                res_GCK.clientKey,
                                tableAdditionalData.itemKey,
                                tableAdditionalData.calculatedField.key,
                              ),
                              updateObject,
                            )
                              .then((res_DSMD) => {
                                // Nothing
                              })
                              .catch((rej_DSMD) => {
                                console.error(rej_DSMD)
                              })
                          } else if (tableAdditionalData.table_data_source === 'data_bucket') {
                            DatabaseSetMergeDocument(
                              DatabaseRef_DataBucket_CalculatedField_Document(
                                res_GCK.clientKey,
                                tableAdditionalData.itemKey,
                                tableAdditionalData.calculatedField.key,
                              ),
                              updateObject,
                            )
                              .then((res_DSMD) => {
                                // Nothing
                              })
                              .catch((rej_DSMD) => {
                                console.error(rej_DSMD)
                              })
                          }
                        })
                        .catch((rej_GCK) => {
                          console.error(rej_GCK)
                        })
                    }
                  }
                }}
                value={dropdownValue}
              >
                {objectToArray(mappingTypeOptions).map((option: TsInterface_UnspecifiedObject) => (
                  <MenuItem
                    key={option['key']}
                    value={option['key']}
                    disabled={option['disabled']}
                  >
                    <Box
                      className="tw-inline-block"
                      sx={{ marginRight: '8px', color: themeVariables.error_main }}
                    >
                      <Icon icon={option['icon']} />
                    </Box>
                    {option['value']}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )
        return cellJSX
      },
    },
  },
  variable_mapping_value: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Variable Mapping Value')
      },
      header_sort_by: null,
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        // Determine Cell Type
        let variableMappingType = ''
        let userEnteredFieldKey = ''
        let lookupTableKey = ''
        let dateQueryKey = ''
        let primaryLookupInputKey = ''
        let secondaryLookupInputKey = ''
        let hardCodeValue: string | JSX.Element = ''
        let variableMappingObject: TsInterface_UnspecifiedObject = {}
        let selectedLookupTable = null
        if (
          tableAdditionalData != null &&
          tableAdditionalData.calculatedField != null &&
          tableAdditionalData.calculatedField.variable_mapping != null &&
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData.calculatedField.variable_mapping[rowData.key as string] != null &&
          tableAdditionalData.calculatedField.variable_mapping[rowData.key as string].variable_mapping_type != null
        ) {
          variableMappingObject = tableAdditionalData.calculatedField.variable_mapping[rowData.key as string]
          variableMappingType = tableAdditionalData.calculatedField.variable_mapping[rowData.key as string].variable_mapping_type
          hardCodeValue = getProp(tableAdditionalData.calculatedField.variable_mapping[rowData.key as string], 'hardcoded_value', '').toString()
          userEnteredFieldKey = getProp(tableAdditionalData.calculatedField.variable_mapping[rowData.key as string], 'associated_user_entered_field_key', '')
          lookupTableKey = getProp(tableAdditionalData.calculatedField.variable_mapping[rowData.key as string], 'associated_lookup_table_key', '')
          dateQueryKey = getProp(tableAdditionalData.calculatedField.variable_mapping[rowData.key as string], 'associated_lookup_table_date_key', '')
          primaryLookupInputKey = getProp(
            tableAdditionalData.calculatedField.variable_mapping[rowData.key as string],
            'associated_lookup_table_primary_input_key',
            '',
          )
          secondaryLookupInputKey = getProp(
            tableAdditionalData.calculatedField.variable_mapping[rowData.key as string],
            'associated_lookup_table_secondary_input_key',
            '',
          )
        }
        // Generate List of User Entered Field Options
        let userEnteredFieldOptions: TsInterface_UnspecifiedObject[] = []
        if (tableAdditionalData != null && tableAdditionalData.us_selectedClassFields != null) {
          for (let loopClassFieldKey in tableAdditionalData.us_selectedClassFields) {
            let loopClassField = tableAdditionalData.us_selectedClassFields[loopClassFieldKey]
            if (
              loopClassField != null &&
              loopClassField.key != null &&
              loopClassField.name != null &&
              loopClassField.status === 'active' &&
              loopClassField.form_type === 'checkboxes'
            ) {
              for (let loopOptionKey in loopClassField.options) {
                let loopOption = loopClassField.options[loopOptionKey]
                userEnteredFieldOptions.push({
                  key: loopClassField.key + '.' + loopOptionKey,
                  value: loopClassField.name + ' - ' + loopOption.value,
                  disabled: false,
                })
              }
            } else if (loopClassField != null && loopClassField.key != null && loopClassField.name != null && loopClassField.status === 'active') {
              userEnteredFieldOptions.push({
                key: loopClassField.key,
                value: loopClassField.name,
                disabled: false,
              })
            }
          }
        }
        let userEnterValueSelectJSX = (
          <FormControl
            className="bp_thin_select_input bp_thin_select_multiple_input"
            sx={{ minWidth: '130px', width: 'calc(100%)' }}
          >
            <InputLabel>{rLIB('User Entered Value')}</InputLabel>
            <Select
              autoWidth={true}
              label={rLIB('User Entered Value')}
              onChange={(event, value) => {
                if (rowData.key != null) {
                  if (
                    tableAdditionalData != null &&
                    tableAdditionalData.itemKey != null &&
                    tableAdditionalData.calculatedField != null &&
                    tableAdditionalData.calculatedField.key != null &&
                    rowData != null &&
                    rowData.key != null &&
                    event.target.value != null
                  ) {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      variable_mapping: {
                        [rowData.key as string]: {
                          associated_user_entered_field_key: event.target.value,
                        },
                      },
                    }
                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        if (tableAdditionalData.table_data_source === 'class') {
                          DatabaseSetMergeDocument(
                            DatabaseRef_ClassCalculatedFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, tableAdditionalData.calculatedField.key),
                            updateObject,
                          )
                            .then((res_DSMD) => {
                              // Nothing
                            })
                            .catch((rej_DSMD) => {
                              console.error(rej_DSMD)
                            })
                        } else if (tableAdditionalData.table_data_source === 'data_bucket') {
                          DatabaseSetMergeDocument(
                            DatabaseRef_DataBucket_CalculatedField_Document(
                              res_GCK.clientKey,
                              tableAdditionalData.itemKey,
                              tableAdditionalData.calculatedField.key,
                            ),
                            updateObject,
                          )
                            .then((res_DSMD) => {
                              // Nothing
                            })
                            .catch((rej_DSMD) => {
                              console.error(rej_DSMD)
                            })
                        }
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                      })
                  }
                }
              }}
              value={userEnteredFieldKey}
            >
              {objectToArray(userEnteredFieldOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                  disabled={option['disabled']}
                >
                  {option['value']}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
        if (
          lookupTableKey != '' &&
          tableAdditionalData != null &&
          tableAdditionalData.us_activeLookupTables != null &&
          tableAdditionalData.us_activeLookupTables[lookupTableKey] != null
        ) {
          selectedLookupTable = tableAdditionalData.us_activeLookupTables[lookupTableKey]
        }
        // Switch on Variable Mapping Type
        switch (variableMappingType) {
          case 'hard_code':
            // Inputs to hard code
            // eslint-disable-next-line no-case-declarations
            let inputFormType: TsType_FormInputType = 'text_basic'
            // eslint-disable-next-line no-case-declarations
            let inputDataType: 'string' | 'number' | 'boolean' = 'string'
            switch (getProp(rowData, 'data_type', null)) {
              case 'string':
                inputFormType = 'text_basic'
                inputDataType = 'string'
                break
              case 'number':
                inputFormType = 'text_number'
                inputDataType = 'number'
                break
              case 'boolean':
                inputFormType = 'boolean_switch'
                inputDataType = 'boolean'
                break
              default:
                cellJSX = <></>
            }
            if (hardCodeValue === '') {
              hardCodeValue = <Box className="tw-italic tw-opacity-30 tw-inline-block">{rLIB('Missing')}</Box>
            }
            // eslint-disable-next-line no-case-declarations
            let propValueJSX = <>{hardCodeValue}</>
            // eslint-disable-next-line no-case-declarations
            let editIconJSX = <></>
            editIconJSX = (
              <Box
                sx={{
                  'color': themeVariables.success_light,
                  '&:hover': {
                    color: themeVariables.success_main,
                  },
                }}
                className="tw-inline-block tw-ml-2 tw-cursor-pointer"
                onClick={() => {
                  const FormDialogObject: TsInterface_FormDialogObject = {
                    form: {
                      formAdditionalData: {},
                      formData: variableMappingObject,
                      formInputs: {
                        hardcoded_value: {
                          key: 'hardcoded_value',
                          label: (
                            <>
                              {rLIB('Hardcoded Value')} - {rowData.name}
                            </>
                          ),
                          input_type: inputFormType,
                          required: true,
                          data_type: inputDataType,
                        },
                      },
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: {},
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          if (rowData.key != null) {
                            if (
                              tableAdditionalData != null &&
                              tableAdditionalData.itemKey != null &&
                              tableAdditionalData.calculatedField != null &&
                              tableAdditionalData.calculatedField.key != null &&
                              rowData != null &&
                              rowData.key != null &&
                              formSubmittedData != null &&
                              formSubmittedData['hardcoded_value'] != null
                            ) {
                              let updateObject: TsInterface_UnspecifiedObject = {
                                variable_mapping: {
                                  [rowData.key as string]: {
                                    hardcoded_value: formSubmittedData['hardcoded_value'],
                                  },
                                },
                              }
                              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                                .then((res_GCK) => {
                                  if (tableAdditionalData.table_data_source === 'class') {
                                    DatabaseSetMergeDocument(
                                      DatabaseRef_ClassCalculatedFields_Document(
                                        res_GCK.clientKey,
                                        tableAdditionalData.itemKey,
                                        tableAdditionalData.calculatedField.key,
                                      ),
                                      updateObject,
                                    )
                                      .then((res_DSMD) => {
                                        resolve(res_DSMD)
                                      })
                                      .catch((rej_DSMD) => {
                                        console.error(rej_DSMD)
                                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                        reject(rej_DSMD)
                                      })
                                  } else if (tableAdditionalData.table_data_source === 'data_bucket') {
                                    DatabaseSetMergeDocument(
                                      DatabaseRef_DataBucket_CalculatedField_Document(
                                        res_GCK.clientKey,
                                        tableAdditionalData.itemKey,
                                        tableAdditionalData.calculatedField.key,
                                      ),
                                      updateObject,
                                    )
                                      .then((res_DSMD) => {
                                        resolve(res_DSMD)
                                      })
                                      .catch((rej_DSMD) => {
                                        console.error(rej_DSMD)
                                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                        reject(rej_DSMD)
                                      })
                                  }
                                })
                                .catch((rej_GCK) => {
                                  console.error(rej_GCK)
                                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                  reject(rej_GCK)
                                })
                            }
                          }
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'success',
                      formDialogHeaderText: (
                        <>
                          {rLIB('Edit Hard Coded Value')} - {rowData.name}
                        </>
                      ),
                      formDialogIcon: <Icon icon="pen-to-square" />,
                    },
                  }
                  tableHooks.uc_setUserInterface_FormDialogDisplay({
                    display: true,
                    form: FormDialogObject,
                  })
                }}
              >
                <Icon icon="pen-to-square" />
              </Box>
            )
            // Hard Code Cell JSX
            cellJSX = (
              <Box>
                {propValueJSX}
                {editIconJSX}
              </Box>
            )
            break
          case 'user_entered_value':
            cellJSX = <Box className="tw-py-2">{userEnterValueSelectJSX}</Box>
            break
          case 'lookup_table_value':
            // Lookup Table Input
            // eslint-disable-next-line no-case-declarations
            let lookupTableOptions: TsInterface_UnspecifiedObject[] = []
            if (tableAdditionalData != null && tableAdditionalData.us_activeLookupTables != null) {
              for (let loopLookupTableKey in tableAdditionalData.us_activeLookupTables) {
                let loopLookupTable = tableAdditionalData.us_activeLookupTables[loopLookupTableKey]
                if (loopLookupTable != null && loopLookupTable.key != null && loopLookupTable.name != null && loopLookupTable.status === 'active') {
                  lookupTableOptions.push({
                    key: loopLookupTable.key,
                    value: loopLookupTable.name,
                  })
                }
              }
            }
            // eslint-disable-next-line no-case-declarations
            let dateRangeOptions: TsInterface_UnspecifiedObject[] = [
              {
                key: 'timestamp_primary_query',
                value: rLIB('Primary Query Date'),
              },
            ]
            // eslint-disable-next-line no-case-declarations
            let lookupTableInputJSX = (
              <FormControl
                className="bp_thin_select_input bp_thin_select_multiple_input tw-mb-3 tw-mt-3"
                sx={{ minWidth: '130px', width: 'calc(100%)' }}
              >
                <InputLabel>{rLIB('Lookup Table')}</InputLabel>
                <Select
                  autoWidth={true}
                  label={rLIB('Lookup Table')}
                  onChange={(event, value) => {
                    if (rowData.key != null) {
                      if (
                        tableAdditionalData != null &&
                        tableAdditionalData.itemKey != null &&
                        tableAdditionalData.calculatedField != null &&
                        tableAdditionalData.calculatedField.key != null &&
                        rowData != null &&
                        rowData.key != null &&
                        event.target.value != null
                      ) {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          variable_mapping: {
                            [rowData.key as string]: {
                              associated_lookup_table_key: event.target.value,
                            },
                          },
                        }
                        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            if (tableAdditionalData.table_data_source === 'class') {
                              DatabaseSetMergeDocument(
                                DatabaseRef_ClassCalculatedFields_Document(
                                  res_GCK.clientKey,
                                  tableAdditionalData.itemKey,
                                  tableAdditionalData.calculatedField.key,
                                ),
                                updateObject,
                              )
                                .then((res_DSMD) => {
                                  // Nothing
                                })
                                .catch((rej_DSMD) => {
                                  console.error(rej_DSMD)
                                })
                            } else if (tableAdditionalData.table_data_source === 'data_bucket') {
                              DatabaseSetMergeDocument(
                                DatabaseRef_DataBucket_CalculatedField_Document(
                                  res_GCK.clientKey,
                                  tableAdditionalData.itemKey,
                                  tableAdditionalData.calculatedField.key,
                                ),
                                updateObject,
                              )
                                .then((res_DSMD) => {
                                  // Nothing
                                })
                                .catch((rej_DSMD) => {
                                  console.error(rej_DSMD)
                                })
                            }
                          })
                          .catch((rej_GCK) => {
                            console.error(rej_GCK)
                          })
                      }
                    }
                  }}
                  value={lookupTableKey}
                >
                  {objectToArray(lookupTableOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
                    <MenuItem
                      key={index}
                      value={option['key']}
                      disabled={option['disabled']}
                    >
                      {option['value']}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
            // Date Input
            // eslint-disable-next-line no-case-declarations
            let dateInputJSX = (
              <FormControl
                className="bp_thin_select_input bp_thin_select_multiple_input tw-mb-3"
                sx={{ minWidth: '130px', width: 'calc(100%)' }}
              >
                <InputLabel>{rLIB('Date for Lookup')}</InputLabel>
                <Select
                  autoWidth={true}
                  label={rLIB('Date for Lookup')}
                  onChange={(event, value) => {
                    if (rowData.key != null) {
                      if (
                        tableAdditionalData != null &&
                        tableAdditionalData.itemKey != null &&
                        tableAdditionalData.calculatedField != null &&
                        tableAdditionalData.calculatedField.key != null &&
                        rowData != null &&
                        rowData.key != null &&
                        event.target.value != null
                      ) {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          variable_mapping: {
                            [rowData.key as string]: {
                              associated_lookup_table_date_key: event.target.value,
                            },
                          },
                        }
                        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            if (tableAdditionalData.table_data_source === 'class') {
                              DatabaseSetMergeDocument(
                                DatabaseRef_ClassCalculatedFields_Document(
                                  res_GCK.clientKey,
                                  tableAdditionalData.itemKey,
                                  tableAdditionalData.calculatedField.key,
                                ),
                                updateObject,
                              )
                                .then((res_DSMD) => {
                                  // Nothing
                                })
                                .catch((rej_DSMD) => {
                                  console.error(rej_DSMD)
                                })
                            } else if (tableAdditionalData.table_data_source === 'data_bucket') {
                              DatabaseSetMergeDocument(
                                DatabaseRef_DataBucket_CalculatedField_Document(
                                  res_GCK.clientKey,
                                  tableAdditionalData.itemKey,
                                  tableAdditionalData.calculatedField.key,
                                ),
                                updateObject,
                              )
                                .then((res_DSMD) => {
                                  // Nothing
                                })
                                .catch((rej_DSMD) => {
                                  console.error(rej_DSMD)
                                })
                            }
                          })
                          .catch((rej_GCK) => {
                            console.error(rej_GCK)
                          })
                      }
                    }
                  }}
                  value={dateQueryKey}
                >
                  {objectToArray(dateRangeOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
                    <MenuItem
                      key={index}
                      value={option['key']}
                      disabled={option['disabled']}
                    >
                      {option['value']}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
            // Lookup 1 Input
            // eslint-disable-next-line no-case-declarations
            let primaryLookupInputJSX = <></>
            if (
              selectedLookupTable != null &&
              (selectedLookupTable.table_type === 'identical_lookup' ||
                selectedLookupTable.table_type === 'matrix_lookup' ||
                selectedLookupTable.table_type === 'range_lookup')
            ) {
              primaryLookupInputJSX = (
                <FormControl
                  className="bp_thin_select_input bp_thin_select_multiple_input tw-mb-3"
                  sx={{ minWidth: '130px', width: 'calc(100%)' }}
                >
                  <InputLabel>{rLIB('Primary Lookup Input')}</InputLabel>
                  <Select
                    autoWidth={true}
                    label={rLIB('Primary Lookup Input')}
                    onChange={(event, value) => {
                      if (rowData.key != null) {
                        if (
                          tableAdditionalData != null &&
                          tableAdditionalData.itemKey != null &&
                          tableAdditionalData.calculatedField != null &&
                          tableAdditionalData.calculatedField.key != null &&
                          rowData != null &&
                          rowData.key != null &&
                          event.target.value != null
                        ) {
                          let updateObject: TsInterface_UnspecifiedObject = {
                            variable_mapping: {
                              [rowData.key as string]: {
                                associated_lookup_table_primary_input_key: event.target.value,
                              },
                            },
                          }
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              if (tableAdditionalData.table_data_source === 'class') {
                                DatabaseSetMergeDocument(
                                  DatabaseRef_ClassCalculatedFields_Document(
                                    res_GCK.clientKey,
                                    tableAdditionalData.itemKey,
                                    tableAdditionalData.calculatedField.key,
                                  ),
                                  updateObject,
                                )
                                  .then((res_DSMD) => {
                                    // Nothing
                                  })
                                  .catch((rej_DSMD) => {
                                    console.error(rej_DSMD)
                                  })
                              } else if (tableAdditionalData.table_data_source === 'data_bucket') {
                                DatabaseSetMergeDocument(
                                  DatabaseRef_DataBucket_CalculatedField_Document(
                                    res_GCK.clientKey,
                                    tableAdditionalData.itemKey,
                                    tableAdditionalData.calculatedField.key,
                                  ),
                                  updateObject,
                                )
                                  .then((res_DSMD) => {
                                    // Nothing
                                  })
                                  .catch((rej_DSMD) => {
                                    console.error(rej_DSMD)
                                  })
                              }
                            })
                            .catch((rej_GCK) => {
                              console.error(rej_GCK)
                            })
                        }
                      }
                    }}
                    value={primaryLookupInputKey}
                  >
                    {objectToArray(userEnteredFieldOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
                      <MenuItem
                        key={index}
                        value={option['key']}
                        disabled={option['disabled']}
                      >
                        {option['value']}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )
            }
            // Lookup 2 Input - if matrix
            // eslint-disable-next-line no-case-declarations
            let secondaryLookupInputJSX = <></>
            if (selectedLookupTable != null && selectedLookupTable.table_type === 'matrix_lookup') {
              secondaryLookupInputJSX = (
                <FormControl
                  className="bp_thin_select_input bp_thin_select_multiple_input tw-mb-3"
                  sx={{ minWidth: '130px', width: 'calc(100%)' }}
                >
                  <InputLabel>{rLIB('Secondary Lookup Input')}</InputLabel>
                  <Select
                    autoWidth={true}
                    label={rLIB('Secondary Lookup Input')}
                    onChange={(event, value) => {
                      if (rowData.key != null) {
                        if (
                          tableAdditionalData != null &&
                          tableAdditionalData.itemKey != null &&
                          tableAdditionalData.calculatedField != null &&
                          tableAdditionalData.calculatedField.key != null &&
                          rowData != null &&
                          rowData.key != null &&
                          event.target.value != null
                        ) {
                          let updateObject: TsInterface_UnspecifiedObject = {
                            variable_mapping: {
                              [rowData.key as string]: {
                                associated_lookup_table_secondary_input_key: event.target.value,
                              },
                            },
                          }
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              if (tableAdditionalData.table_data_source === 'class') {
                                DatabaseSetMergeDocument(
                                  DatabaseRef_ClassCalculatedFields_Document(
                                    res_GCK.clientKey,
                                    tableAdditionalData.itemKey,
                                    tableAdditionalData.calculatedField.key,
                                  ),
                                  updateObject,
                                )
                                  .then((res_DSMD) => {
                                    // Nothing
                                  })
                                  .catch((rej_DSMD) => {
                                    console.error(rej_DSMD)
                                  })
                              } else if (tableAdditionalData.table_data_source === 'data_bucket') {
                                DatabaseSetMergeDocument(
                                  DatabaseRef_DataBucket_CalculatedField_Document(
                                    res_GCK.clientKey,
                                    tableAdditionalData.itemKey,
                                    tableAdditionalData.calculatedField.key,
                                  ),
                                  updateObject,
                                )
                                  .then((res_DSMD) => {
                                    // Nothing
                                  })
                                  .catch((rej_DSMD) => {
                                    console.error(rej_DSMD)
                                  })
                              }
                            })
                            .catch((rej_GCK) => {
                              console.error(rej_GCK)
                            })
                        }
                      }
                    }}
                    value={secondaryLookupInputKey}
                  >
                    {objectToArray(userEnteredFieldOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
                      <MenuItem
                        key={index}
                        value={option['key']}
                        disabled={option['disabled']}
                      >
                        {option['value']}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )
            }
            // TODO: Lookup Table

            cellJSX = (
              <Box className="">
                {lookupTableInputJSX}
                {dateInputJSX}
                {primaryLookupInputJSX}
                {secondaryLookupInputJSX}
              </Box>
            )
            break
          default:
            cellJSX = (
              <Box className="">
                {/* <Json data={rowData} /> */}
                {/* <Json data={tableAdditionalData.calculatedField} /> */}
              </Box>
            )
        }
        return cellJSX
      },
    },
  },
}

export const tableSettings_CalculatedFieldVariables: TsInterface_TableSettings = {
  size: 'small',
  sortable: true,
  sort_property_default: 'name',
  paginated: false,
  pagination_rows_per_page_default: 20,
  pagination_rows_per_page_options: [10, 20, 50],
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'inactive',
        conditions: [],
      },
    },
  ],
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

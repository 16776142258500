//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			1) Defines all valid URLs and which containers they are linked to
			2) Specifies the global theme/style of the application
			3) Creates live database listeners for user and client information
			4) Generates application permissions based on user and client information
			5) Triggers a rerender when global user, client user, or permissions are updated
			6) Makes user/client info and other global state variables available via context
			7) Provides the physical location where error messages are rendered

			ADDING A NEW PAGE
				1) Create file in containers folder
				2) Import page into "App.tsx" (this file)
				3) Add Route into the Router in "App.tsx" at the bottom (this file)
				4) Add Page into the "ApplicationPages" variable of "applicationStructure.tsx"
				5) IF the page is a root level page, add it to ApplicationNavPages (and ApplicationNavSections if it is in a new section) in "applicationStructure.tsx"

		TODO:
			[ ] Typescript - 1 instance of @ts-expect-error - combined contexts
			[ ] Typescript - 4 instances of any

	*/

///////////////////////////////
// Imports
///////////////////////////////

import 'app/styles/app.css'
import 'rfbp_aux/styles/main.css'
// import {
// 	Container as AssociationsListPage
// } from 'app/pages/database/associations/associations_list'
// import {
// 	Container as AssociationsViewPage
// } from 'app/pages/database/associations/associations_view'
import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { Container as APIDocumentationIndexPage } from 'app/pages/api_docs/api_docs_index'
import { Container as ClassesListPage } from 'app/pages/architecture_classes/classes_list'
import { Container as ClassesViewPage } from 'app/pages/architecture_classes/classes_view'
import { Container as DataBucketsListPage } from 'app/pages/architecture_data_buckets/data_buckets_list'
import { Container as DataBucketsViewPage } from 'app/pages/architecture_data_buckets/data_bucket_view'
import { Container as TasksIndexPage } from 'app/pages/data_tasks/tasks_index'
import { Container as TaskTemplateViewPage } from 'app/pages/data_tasks/task_template_view'
import { Container as AllDataListPage } from 'app/pages/data_transactional/data_list'
import { Container as DirectoryIndexPage } from 'app/pages/directory/directory_index'
import { Container as ReportsListPage } from 'app/pages/export_reports/reports_list'
import { Container as ReportTemplatesViewPage } from 'app/pages/export_reports/report_templates_view'
import { Container as ScheduledEmailsListPage } from 'app/pages/export_scheduled_emails/scheduled_emails_list'
import { Container as HomePage } from 'app/pages/home/home'
import { Container as UserSettingsPage } from 'app/pages/home/user_settings'
import { Container as UserManagementIndexPage } from 'app/pages/owner/user_management'
import { Container as FormulasIndexPage } from 'app/pages/refine_formulas/formulas_index'
import { Container as FormulasViewPage } from 'app/pages/refine_formulas/formulas_view'
import { Container as LookupTablesIndexPage } from 'app/pages/refine_lookup_tables/lookup_tables_index'
import { Container as LookupTablesViewPage } from 'app/pages/refine_lookup_tables/lookup_tables_view'
import { Container as DataAnalysisListPage } from 'app/pages/review_analyses/data_analysis_list'
import { Container as DataAnalysisTemplateViewPage } from 'app/pages/review_analyses/data_analysis_template_view'
import { Container as DataAnalysisViewPage } from 'app/pages/review_analyses/data_analysis_view'
import { Container as ReconciliationAutoViewPage } from 'app/pages/review_reconciliation/reconciliation_automatic_view'
import { Container as ReconciliationImpromptuViewPage } from 'app/pages/review_reconciliation/reconciliation_impromptu_view'
import { Container as ReconciliationListPage } from 'app/pages/review_reconciliation/reconciliation_list'
import { Container as UnauthenticatedLoginPage } from 'app/pages/unauthenticated/login'
import { Container as UnauthenticatedPrivacyPolicyPage } from 'app/pages/unauthenticated/privacy_policy'
import { Container as UnauthenticatedFormPage } from 'app/pages/unauthenticated/qr_code_form'
import { Container as UnauthenticatedTermsOfServicePage } from 'app/pages/unauthenticated/terms_of_service'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useEffect, useReducer, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { DefaultAppTheme } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Container as SuperClientPermissionsManagementPage } from 'rfbp_aux/pages/super/client_permissions_management'
import { Container as SuperTestLabPage } from 'rfbp_aux/pages/super/test_lab'
import { DatabaseRef_ClientPermissions_Document, DatabaseRef_GlobalUser_Document } from 'rfbp_aux/services/database_endpoints/database_endpoints'
import { AlertDialog, ConfirmDialog, CustomDialog, ErrorDialog, FormDialog, PromptDialog, SnackbarDisplay } from 'rfbp_core/components/dialog'
import { ScrollToTop } from 'rfbp_core/components/utilities/scroll_to_top'
import {
  CombinedAppContext,
  RootData_Default_AuthenticatedUserState,
  RootData_Default_ClientKeyState,
  RootData_Default_ClientPermissionsState,
  RootData_Default_ClientUserState,
  RootData_Default_GlobalUserState,
  RootData_Default_UserPermissionsState,
  TsInterface_RootData_AuthenticatedUser,
  TsInterface_RootData_ClientPermissions,
  TsInterface_RootData_ClientUser,
  TsInterface_RootData_GlobalUser,
  TsInterface_RootData_UserPermissions,
  TsInterface_UserInterface_AlertDialogDisplay,
  TsInterface_UserInterface_ConfirmDialogDisplay,
  TsInterface_UserInterface_ConfirmDialogInternalState,
  TsInterface_UserInterface_CustomDialogDisplay,
  TsInterface_UserInterface_ErrorDialogDisplay,
  TsInterface_UserInterface_FormDialogDisplay,
  TsInterface_UserInterface_FormDialogInternalState,
  TsInterface_UserInterface_NavBadges,
  TsInterface_UserInterface_PromptDialogDisplay,
  TsInterface_UserInterface_PromptDialogInternalState,
  TsInterface_UserInterface_SnackbarDisplay,
  TsType_RootData_ClientKey,
  TsType_UserInterface_LoadingBarDisplay,
  TsType_UserInterface_NavBarDisplay,
  UserInterface_Default_AlertDialogDisplayState,
  UserInterface_Default_ConfirmDialogDisplayState,
  UserInterface_Default_ConfirmDialogInternalState,
  UserInterface_Default_CustomDialogDisplayState,
  UserInterface_Default_ErrorDialogDisplayState,
  UserInterface_Default_FormDialogDisplayState,
  UserInterface_Default_FormDialogInternalState,
  UserInterface_Default_LoadingBarDisplayState,
  UserInterface_Default_NavBarDisplayState,
  UserInterface_Default_PromptDialogDisplayState,
  UserInterface_Default_PromptDialogInternalState,
  UserInterface_Default_SnackbarDisplayState,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ClientPermissionsLiveData {
  [propKey: string]: any
  // TODO
}

interface TsInterface_ClientUserLiveData {
  [propKey: string]: any
  // TODO
}

interface TsInterface_GlobalUserLiveData {
  [propKey: string]: any
  // TODO
}

type TsType_DismissFunction = (obj?: any) => void

///////////////////////////////
// Variables
///////////////////////////////

// Nav Badges
let defaultNavBadgeCounts = {
  counts: {},
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

function App() {
  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [uc_AppData_UrlHistory, uc_setAppData_UrlHistory] = useState<string[]>([])
  const [uc_RootData_AuthenticatedUser, uc_setRootData_AuthenticatedUser] =
    useState<TsInterface_RootData_AuthenticatedUser>(RootData_Default_AuthenticatedUserState)
  const [uc_RootData_ClientKey, uc_setRootData_ClientKey] = useState<TsType_RootData_ClientKey>(RootData_Default_ClientKeyState)
  const [uc_RootData_ClientPermissions, uc_setRootData_ClientPermissions] =
    useState<TsInterface_RootData_ClientPermissions>(RootData_Default_ClientPermissionsState)
  const [uc_RootData_ClientUser, uc_setRootData_ClientUser] = useState<TsInterface_RootData_ClientUser>(RootData_Default_ClientUserState)
  const [uc_RootData_GlobalUser, uc_setRootData_GlobalUser] = useState<TsInterface_RootData_GlobalUser>(RootData_Default_GlobalUserState)
  const [uc_RootData_UserPermissions, uc_setRootData_UserPermissions] = useState<TsInterface_RootData_UserPermissions>(RootData_Default_UserPermissionsState)
  const [uc_UserInterface_AlertDialogDisplay, uc_setUserInterface_AlertDialogDisplay] = useState<TsInterface_UserInterface_AlertDialogDisplay>(
    UserInterface_Default_AlertDialogDisplayState,
  )
  const [uc_UserInterface_ConfirmDialogAction, uc_setUserInterface_ConfirmDialogAction] = useState<TsInterface_UserInterface_ConfirmDialogInternalState>(
    UserInterface_Default_ConfirmDialogInternalState,
  )
  const [uc_UserInterface_ConfirmDialogDisplay, uc_setUserInterface_ConfirmDialogDisplay] = useState<TsInterface_UserInterface_ConfirmDialogDisplay>(
    UserInterface_Default_ConfirmDialogDisplayState,
  )
  const [uc_UserInterface_CustomDialogDisplay, uc_setUserInterface_CustomDialogDisplay] = useState<TsInterface_UserInterface_CustomDialogDisplay>(
    UserInterface_Default_CustomDialogDisplayState,
  )
  const [uc_UserInterface_ErrorDialogDisplay, uc_setUserInterface_ErrorDialogDisplay] = useState<TsInterface_UserInterface_ErrorDialogDisplay>(
    UserInterface_Default_ErrorDialogDisplayState,
  )
  const [uc_UserInterface_FormDialogAction, uc_setUserInterface_FormDialogAction] = useState<TsInterface_UserInterface_FormDialogInternalState>(
    UserInterface_Default_FormDialogInternalState,
  )
  const [uc_UserInterface_FormDialogDisplay, uc_setUserInterface_FormDialogDisplay] = useState<TsInterface_UserInterface_FormDialogDisplay>(
    UserInterface_Default_FormDialogDisplayState,
  )
  const [uc_UserInterface_LoadingBarDisplay, uc_setUserInterface_LoadingBarDisplay] = useState<TsType_UserInterface_LoadingBarDisplay>(
    UserInterface_Default_LoadingBarDisplayState,
  )
  const [uc_UserInterface_NavBadges, uc_setUserInterface_NavBadges] = useState<TsInterface_UserInterface_NavBadges>(defaultNavBadgeCounts)
  const [uc_UserInterface_NavBarDisplay, uc_setUserInterface_NavBarDisplay] =
    useState<TsType_UserInterface_NavBarDisplay>(UserInterface_Default_NavBarDisplayState)
  const [uc_UserInterface_PromptDialogAction, uc_setUserInterface_PromptDialogAction] = useState<TsInterface_UserInterface_PromptDialogInternalState>(
    UserInterface_Default_PromptDialogInternalState,
  )
  const [uc_UserInterface_PromptDialogDisplay, uc_setUserInterface_PromptDialogDisplay] = useState<TsInterface_UserInterface_PromptDialogDisplay>(
    UserInterface_Default_PromptDialogDisplayState,
  )
  const [uc_UserInterface_SnackbarDisplay, uc_setUserInterface_SnackbarDisplay] = useState<TsInterface_UserInterface_SnackbarDisplay>(
    UserInterface_Default_SnackbarDisplayState,
  )
  const [uc_rootData_AuthenticatedUserKey, uc_setRootData_AuthenticatedUserKey] = useState<string | null>(null)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    // Create listeners to user and client database locations and generate application permissions
    // Create variables for database unsubscribers
    let databaseClientPermissionsUnsubscribe: TsType_DismissFunction
    let databaseClientUserUnsubscribe: TsType_DismissFunction
    let databaseGlobalUserUnsubscribe: TsType_DismissFunction
    let databaseUnassignedProjectsUnsubscribe: TsType_DismissFunction
    let databaseUnreadNotificationsUnsubscribe: TsType_DismissFunction
    let databaseUnreadMessagesUnsubscribe: TsType_DismissFunction
    let databaseOpenTasksUnsubscribe: TsType_DismissFunction
    let databaseUnreadRemindersUnsubscribe: TsType_DismissFunction
    if (uc_rootData_AuthenticatedUserKey != null) {
      // Set user authentication to global state
      uc_setRootData_AuthenticatedUser({ loggedIn: true, uid: uc_rootData_AuthenticatedUserKey })

      // Callback function to set client permissions to global state
      const clientPermissionsLiveDataUpdate = (clientPermissionsLiveData: TsInterface_ClientPermissionsLiveData) => {
        uc_setRootData_ClientPermissions(clientPermissionsLiveData as TsInterface_RootData_ClientPermissions)
      }

      // Callback function to set client user to global state
      const clientUserLiveDataUpdate = (clientUserLiveData: TsInterface_ClientUserLiveData) => {
        uc_setRootData_ClientUser(clientUserLiveData as TsInterface_RootData_ClientUser)
      }

      // Callback function to set global client to global state and subsequently load client level info
      const globalUserLiveDataUpdate = (globalUserLiveData: TsInterface_GlobalUserLiveData) => {
        if (globalUserLiveData != null && globalUserLiveData.client_key != null) {
          // CUSTOM FOR THIS APPLICATION
          let copyOfGlobalUserLiveData = JSON.parse(JSON.stringify(globalUserLiveData))
          if (
            copyOfGlobalUserLiveData != null &&
            copyOfGlobalUserLiveData.client_key != null &&
            copyOfGlobalUserLiveData != null &&
            copyOfGlobalUserLiveData['client_access'] != null &&
            copyOfGlobalUserLiveData['client_access'][copyOfGlobalUserLiveData.client_key] != null &&
            copyOfGlobalUserLiveData['client_access'][copyOfGlobalUserLiveData.client_key] != null
          ) {
            copyOfGlobalUserLiveData['user_role'] = copyOfGlobalUserLiveData['client_access'][copyOfGlobalUserLiveData.client_key]
          }
          // END CUSTOM FOR THIS APPLICATION

          // Set Global User and Client Key
          // uc_setRootData_GlobalUser(globalUserLiveData as TsInterface_RootData_GlobalUser)
          uc_setRootData_GlobalUser(copyOfGlobalUserLiveData as TsInterface_RootData_GlobalUser)
          if (globalUserLiveData != null && globalUserLiveData.client_key != null) {
            uc_setRootData_ClientKey(globalUserLiveData.client_key)
          }
          // Create Database Listener for Client User
          if (globalUserLiveData != null && globalUserLiveData.client_key != null && uc_rootData_AuthenticatedUserKey != null) {
            databaseClientUserUnsubscribe = DatabaseGetLiveDocument(DatabaseRef_GlobalUser_Document(uc_rootData_AuthenticatedUserKey), clientUserLiveDataUpdate)
          }
          // Create Database Listener for Client Permissions
          if (globalUserLiveData != null && globalUserLiveData.client_key != null) {
            databaseClientPermissionsUnsubscribe = DatabaseGetLiveDocument(
              DatabaseRef_ClientPermissions_Document(globalUserLiveData.client_key),
              clientPermissionsLiveDataUpdate,
            )
          }
        }
      }
      // Create Database Listener for Global User
      databaseGlobalUserUnsubscribe = DatabaseGetLiveDocument(DatabaseRef_GlobalUser_Document(uc_rootData_AuthenticatedUserKey), globalUserLiveDataUpdate)
    } else {
      // If the user is not authenticated then wipe all user and client related data in global state
      uc_setRootData_AuthenticatedUser(RootData_Default_AuthenticatedUserState)
      uc_setRootData_ClientPermissions(RootData_Default_ClientPermissionsState)
      uc_setRootData_ClientUser(RootData_Default_ClientUserState)
      uc_setRootData_GlobalUser(RootData_Default_GlobalUserState)
    }
    return () => {
      if (typeof databaseClientPermissionsUnsubscribe === 'function') {
        databaseClientPermissionsUnsubscribe()
      }
      if (typeof databaseClientUserUnsubscribe === 'function') {
        databaseClientUserUnsubscribe()
      }
      if (typeof databaseGlobalUserUnsubscribe === 'function') {
        databaseGlobalUserUnsubscribe()
      }
      if (typeof databaseUnassignedProjectsUnsubscribe === 'function') {
        databaseUnassignedProjectsUnsubscribe()
      }
      if (typeof databaseUnreadNotificationsUnsubscribe === 'function') {
        databaseUnreadNotificationsUnsubscribe()
      }
      if (typeof databaseUnreadMessagesUnsubscribe === 'function') {
        databaseUnreadMessagesUnsubscribe()
      }
      if (typeof databaseOpenTasksUnsubscribe === 'function') {
        databaseOpenTasksUnsubscribe()
      }
      if (typeof databaseUnreadRemindersUnsubscribe === 'function') {
        databaseUnreadRemindersUnsubscribe()
      }
    }
  }, [ur_forceRerender, uc_rootData_AuthenticatedUserKey])

  useEffect(() => {
    let authenticatedUnsubscribe: TsType_DismissFunction
    // Verify if user is authenticated
    authenticatedUnsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (user && user.uid != null) {
        uc_setRootData_AuthenticatedUserKey(user.uid)
      }
    })
    return () => {
      // Close database listeners on component unmount - I don't think this gets used in production (just on hot reload during development)
      if (typeof authenticatedUnsubscribe === 'function') {
        authenticatedUnsubscribe()
      }
    }
  }, [ur_forceRerender])

  // Context
  let Context_AppData_UrlHistory_Passthrough = { uc_AppData_UrlHistory, uc_setAppData_UrlHistory }
  let Context_RootData_AuthenticatedUser_Passthrough = { uc_RootData_AuthenticatedUser, uc_setRootData_AuthenticatedUser }
  let Context_RootData_ClientKey_Passthrough = { uc_RootData_ClientKey, uc_setRootData_ClientKey }
  let Context_RootData_ClientPermissions_Passthrough = { uc_RootData_ClientPermissions, uc_setRootData_ClientPermissions }
  let Context_RootData_ClientUser_Passthrough = { uc_RootData_ClientUser, uc_setRootData_ClientUser }
  let Context_RootData_GlobalUser_Passthrough = { uc_RootData_GlobalUser, uc_setRootData_GlobalUser }
  let Context_RootData_UserPermissions_Passthrough = { uc_RootData_UserPermissions, uc_setRootData_UserPermissions }
  let Context_UserInterface_AlertDialog_Passthrough = { uc_UserInterface_AlertDialogDisplay, uc_setUserInterface_AlertDialogDisplay }
  let Context_UserInterface_ConfirmDialog_Passthrough = { uc_UserInterface_ConfirmDialogDisplay, uc_setUserInterface_ConfirmDialogDisplay }
  let Context_UserInterface_CustomDialog_Passthrough = { uc_UserInterface_CustomDialogDisplay, uc_setUserInterface_CustomDialogDisplay }
  let Context_UserInterface_ErrorDialog_Passthrough = { uc_UserInterface_ErrorDialogDisplay, uc_setUserInterface_ErrorDialogDisplay }
  let Context_UserInterface_FormDialog_Passthrough = { uc_UserInterface_FormDialogDisplay, uc_setUserInterface_FormDialogDisplay }
  let Context_UserInterface_LoadingBar_Passthrough = { uc_UserInterface_LoadingBarDisplay, uc_setUserInterface_LoadingBarDisplay }
  let Context_UserInterface_NavBadges_Passthrough = { uc_UserInterface_NavBadges, uc_setUserInterface_NavBadges }
  let Context_UserInterface_NavBar_Passthrough = { uc_UserInterface_NavBarDisplay, uc_setUserInterface_NavBarDisplay }
  let Context_UserInterface_PromptDialog_Passthrough = { uc_UserInterface_PromptDialogDisplay, uc_setUserInterface_PromptDialogDisplay }
  let Context_UserInterface_Snackbar_Passthrough = { uc_UserInterface_SnackbarDisplay, uc_setUserInterface_SnackbarDisplay }

  // Handle Dialogs
  let alertDialogJSX: JSX.Element = <></>
  let confirmDialogJSX: JSX.Element = <></>
  let customDialogJSX: JSX.Element = <></>
  let errorDialogJSX: JSX.Element = <></>
  let formDialogJSX: JSX.Element = <></>
  let promptDialogJSX: JSX.Element = <></>
  let snackbarJSX: JSX.Element = <></>

  // Alerts
  const potentiallyRenderAlertDialog = () => {
    if (uc_UserInterface_AlertDialogDisplay.display === true) {
      alertDialogJSX = AlertDialog(uc_UserInterface_AlertDialogDisplay.alert, () => {
        uc_setUserInterface_AlertDialogDisplay(UserInterface_Default_AlertDialogDisplayState)
      })
    }
  }
  potentiallyRenderAlertDialog()

  // Confirms
  const potentiallyRenderConfirmDialog = () => {
    if (uc_UserInterface_ConfirmDialogDisplay.display === true) {
      confirmDialogJSX = ConfirmDialog(
        uc_UserInterface_ConfirmDialogDisplay.confirm,
        () => {
          uc_setUserInterface_ConfirmDialogDisplay(UserInterface_Default_ConfirmDialogDisplayState)
        },
        uc_UserInterface_ConfirmDialogAction,
        uc_setUserInterface_ConfirmDialogAction,
      )
    }
  }

  potentiallyRenderConfirmDialog()

  // Custom Dialog
  const potentiallyRenderCustomDialog = () => {
    if (uc_UserInterface_CustomDialogDisplay.display === true) {
      customDialogJSX = CustomDialog(uc_UserInterface_CustomDialogDisplay.dialog, () => {
        uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
      })
    }
  }

  potentiallyRenderCustomDialog()

  // Errors
  const potentiallyRenderErrorDialog = () => {
    if (uc_UserInterface_ErrorDialogDisplay.display === true) {
      errorDialogJSX = ErrorDialog(uc_UserInterface_ErrorDialogDisplay.error, () => {
        uc_setUserInterface_ErrorDialogDisplay(UserInterface_Default_ErrorDialogDisplayState)
      })
    }
  }

  potentiallyRenderErrorDialog()

  // Forms
  const potentiallyRenderFormDialog = () => {
    if (uc_UserInterface_FormDialogDisplay.display === true) {
      formDialogJSX = FormDialog(
        uc_UserInterface_FormDialogDisplay.form,
        () => {
          uc_setUserInterface_FormDialogDisplay(UserInterface_Default_FormDialogDisplayState)
        },
        uc_UserInterface_FormDialogAction,
        uc_setUserInterface_FormDialogAction,
      )
    }
  }

  potentiallyRenderFormDialog()

  // Prompts
  const potentiallyRenderPromptDialog = () => {
    if (uc_UserInterface_PromptDialogDisplay.display === true) {
      promptDialogJSX = PromptDialog(
        uc_UserInterface_PromptDialogDisplay.prompt,
        () => {
          uc_setUserInterface_PromptDialogDisplay(UserInterface_Default_PromptDialogDisplayState)
        },
        uc_UserInterface_PromptDialogAction,
        uc_setUserInterface_PromptDialogAction,
      )
    }
  }

  potentiallyRenderPromptDialog()

  // Snackbars
  const potentiallyRenderSnackbar = () => {
    snackbarJSX = SnackbarDisplay(uc_UserInterface_SnackbarDisplay.display, uc_UserInterface_SnackbarDisplay.snackbar, () => {
      let copiedSnackbarDisplay = JSON.parse(JSON.stringify(uc_UserInterface_SnackbarDisplay))
      copiedSnackbarDisplay.display = false
      uc_setUserInterface_SnackbarDisplay(copiedSnackbarDisplay)
      // uc_setUserInterface_SnackbarDisplay( UserInterface_Default_SnackbarDisplayState )
    })
  }

  potentiallyRenderSnackbar()

  // Render
  return (
    <StyledEngineProvider injectFirst>
      {/* @ts-expect-error */}
      <CombinedAppContext.Provider
        value={{
          Context_AppData_UrlHistory_Passthrough,
          Context_RootData_AuthenticatedUser_Passthrough,
          Context_RootData_ClientKey_Passthrough,
          Context_RootData_ClientPermissions_Passthrough,
          Context_RootData_ClientUser_Passthrough,
          Context_RootData_GlobalUser_Passthrough,
          Context_RootData_UserPermissions_Passthrough,
          Context_UserInterface_AlertDialog_Passthrough,
          Context_UserInterface_ConfirmDialog_Passthrough,
          Context_UserInterface_CustomDialog_Passthrough,
          Context_UserInterface_ErrorDialog_Passthrough,
          Context_UserInterface_FormDialog_Passthrough,
          Context_UserInterface_LoadingBar_Passthrough,
          Context_UserInterface_NavBadges_Passthrough,
          Context_UserInterface_NavBar_Passthrough,
          Context_UserInterface_PromptDialog_Passthrough,
          Context_UserInterface_Snackbar_Passthrough,
        }}
      >
        <ThemeProvider theme={DefaultAppTheme}>
          <CssBaseline />
          <BrowserRouter>
            <ScrollToTop />
            {alertDialogJSX}
            {confirmDialogJSX}
            {customDialogJSX}
            {errorDialogJSX}
            {formDialogJSX}
            {promptDialogJSX}
            {snackbarJSX}
            <Routes>
              {/* { sort-start } - application pages - scoped sort plugin */}
              <Route
                path={ApplicationPages.AllDataListPage.url()}
                element={<AllDataListPage />}
              />
              <Route
                path={ApplicationPages.ClassesListPage.url()}
                element={<ClassesListPage />}
              />
              <Route
                path={ApplicationPages.ClassesViewPage.url(':id')}
                element={<ClassesViewPage />}
              />
              <Route
                path={ApplicationPages.DataAnalysisListPage.url()}
                element={<DataAnalysisListPage />}
              />
              <Route
                path={ApplicationPages.FormulasIndexPage.url()}
                element={<FormulasIndexPage />}
              />
              <Route
                path={ApplicationPages.FormulasViewPage.url(':id')}
                element={<FormulasViewPage />}
              />
              <Route
                path={ApplicationPages.LookupTablesIndexPage.url()}
                element={<LookupTablesIndexPage />}
              />
              <Route
                path={ApplicationPages.LookupTablesViewPage.url(':id')}
                element={<LookupTablesViewPage />}
              />
              <Route
                path={ApplicationPages.DataAnalysisTemplateViewPage.url(':id')}
                element={<DataAnalysisTemplateViewPage />}
              />
              <Route
                path={ApplicationPages.DataAnalysisViewPage.url(':id')}
                element={<DataAnalysisViewPage />}
              />
              <Route
                path={ApplicationPages.DataBucketsListPage.url()}
                element={<DataBucketsListPage />}
              />
              <Route
                path={ApplicationPages.DataBucketsViewPage.url(':id')}
                element={<DataBucketsViewPage />}
              />
              <Route
                path={ApplicationPages.DirectoryIndexPage.url()}
                element={<DirectoryIndexPage />}
              />
              <Route
                path={ApplicationPages.HomePage.url()}
                element={<HomePage />}
              />
              <Route
                path={ApplicationPages.TasksIndexPage.url()}
                element={<TasksIndexPage />}
              />
              <Route
                path={ApplicationPages.TaskTemplateViewPage.url(':id')}
                element={<TaskTemplateViewPage />}
              />
              <Route
                path={ApplicationPages.UnauthenticatedPrivacyPolicyPage.url()}
                element={<UnauthenticatedPrivacyPolicyPage />}
              />
              <Route
                path={ApplicationPages.UnauthenticatedTermsOfServicePage.url()}
                element={<UnauthenticatedTermsOfServicePage />}
              />
              <Route
                path={ApplicationPages.ReconciliationAutoViewPage.url(':id')}
                element={<ReconciliationAutoViewPage />}
              />
              <Route
                path={ApplicationPages.ReconciliationImpromptuViewPage.url(':id')}
                element={<ReconciliationImpromptuViewPage />}
              />
              <Route
                path={ApplicationPages.ReconciliationListPage.url()}
                element={<ReconciliationListPage />}
              />
              <Route
                path={ApplicationPages.ReportTemplatesViewPage.url(':id')}
                element={<ReportTemplatesViewPage />}
              />
              <Route
                path={ApplicationPages.ReportsListPage.url()}
                element={<ReportsListPage />}
              />
              <Route
                path={ApplicationPages.ScheduledEmailsListPage.url()}
                element={<ScheduledEmailsListPage />}
              />
              <Route
                path={ApplicationPages.UserManagementIndexPage.url()}
                element={<UserManagementIndexPage />}
              />
              <Route
                path={ApplicationPages.APIDocumentationIndexPage.url()}
                element={<APIDocumentationIndexPage />}
              />
              <Route
                path={ApplicationPages.SuperClientPermissionsManagementPage.url()}
                element={<SuperClientPermissionsManagementPage />}
              />
              <Route
                path={ApplicationPages.SuperTestLabPage.url()}
                element={<SuperTestLabPage />}
              />
              <Route
                path={ApplicationPages.UnauthenticatedFormPage.url(':id')}
                element={<UnauthenticatedFormPage />}
              />
              <Route
                path={ApplicationPages.UnauthenticatedLoginPage.url()}
                element={<UnauthenticatedLoginPage />}
              />
              <Route
                path={ApplicationPages.UserSettingsPage.url()}
                element={<UserSettingsPage />}
              />
              {/* <Route path={ ApplicationPages.AssociationsListPage.url() } 						element={ <AssociationsListPage/> } 										/> */}
              {/* <Route path={ ApplicationPages.AssociationsViewPage.url(":id") } 					element={ <AssociationsViewPage/> } 										/> */}
              {/* { sort-end } - application pages */}
              <Route
                path="*"
                element={
                  <Navigate
                    to={ApplicationPages.HomePage.url()}
                    replace
                  />
                }
              />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </CombinedAppContext.Provider>
    </StyledEngineProvider>
  )
}

export default App

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, FormControl, MenuItem, Select } from '@mui/material/'
import { analysisProcedureOptions } from 'app/models/analysis/analysis_functions'
import { returnTableColumnsFromDatabaseFormDataFormat } from 'app/services/forms/form_services'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ClassFields_Collection } from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import {
  DatabaseRef_AnalysisTemplates_ActiveAnalysisProcedures_Query,
  DatabaseRef_AnalysisTemplates_Document,
} from 'rfbp_aux/services/database_endpoints/clients/data_management/analysis_templates'
import { DatabaseRef_Analysis_Document } from 'rfbp_aux/services/database_endpoints/clients/transactional_data/analyses'
import { DatabaseRef_DataForSpecificDataBucket_DateRange_Query } from 'rfbp_aux/services/database_endpoints/clients/transactional_data/data'
import { Icon } from 'rfbp_core/components/icons'
import { TableBasic, TsInterface_TableColumns, TsInterface_TableSettings } from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_Snackbar } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection, DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['DataAnalysisViewPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_associatedForm, us_setAssociatedForm ] = 												useState<TsInterface_UnspecifiedObject>({})
  const [us_allQRCodeData, us_setAllQRCodeData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_analysisTemplate, us_setAnalysisTemplate] = useState<TsInterface_UnspecifiedObject>({})
  const [us_analysisTemplateAnalysisProcedures, us_setAnalysisTemplateAnalysisProcedures] = useState<TsInterface_UnspecifiedObject>({})
  const [us_associatedFormFields, us_setAssociatedFormFields] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filteredQRCodeData, us_setFilteredQRCodeData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_highlightedCells_FilteredQRCodeData, us_setHighlightedCells_FilteredQRCodeData] = useState<TsInterface_TableColumns>({})
  const [us_rootAnalysis, us_setRootAnalysis] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedAnalysisProcedure, us_setSelectedAnalysisProcedure] = useState<string>('ALL_DATA')
  const [us_tableColumns_AllQRCodeData, us_setTableColumns_AllQRCodeData] = useState<TsInterface_TableColumns>({})
  const [us_tableColumns_FilteredQRCodeData, us_setTableColumns_FilteredQRCodeData] = useState<TsInterface_TableColumns>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_SnackbarDisplay } = useContext(Context_UserInterface_Snackbar)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Data Analysis', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRootAnalysis(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Analysis_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAnalysisTemplate(newData)
      ur_forceRerender()
    }
    if (us_rootAnalysis != null && us_rootAnalysis['associated_analysis_template_key'] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_AnalysisTemplates_Document(res_GCK.clientKey, us_rootAnalysis['associated_analysis_template_key']),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_rootAnalysis])

  // useEffect(() => {
  // let unsubscribeLiveData: TsType_VoidFunction
  // const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
  // 	us_setAssociatedForm( newData )
  // 	ur_forceRerender()
  // }
  // if(
  // 	us_analysisTemplate != null &&
  // 	us_analysisTemplate.associated_class_key != null
  // ){
  // 	getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then( ( res_GCK ) => {
  // 		unsubscribeLiveData = DatabaseGetLiveDocument( DatabaseRef_Class_Document( res_GCK.clientKey, us_analysisTemplate.associated_class_key ), updateLiveData )
  // 	}).catch( ( rej_GCK ) => {
  // 		console.error( rej_GCK )
  // 	})
  // }
  // return () => {
  // 	if (typeof unsubscribeLiveData === 'function'){
  // 		unsubscribeLiveData()
  // 	}
  // }
  // }, [us_analysisTemplate, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAssociatedFormFields(newData)
      ur_forceRerender()
    }
    if (us_analysisTemplate != null && us_analysisTemplate.associated_class_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ClassFields_Collection(res_GCK.clientKey, us_analysisTemplate.associated_class_key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [us_analysisTemplate, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAnalysisTemplateAnalysisProcedures(newData)
      ur_forceRerender()
    }
    if (us_rootAnalysis != null && us_rootAnalysis['associated_analysis_template_key'] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_AnalysisTemplates_ActiveAnalysisProcedures_Query(res_GCK.clientKey, us_rootAnalysis['associated_analysis_template_key']),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_rootAnalysis])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAllQRCodeData(newData)
      ur_forceRerender()
    }
    if (
      us_rootAnalysis != null &&
      us_rootAnalysis['associated_data_bucket_key'] != null &&
      us_rootAnalysis['filter_start_date'] != null &&
      us_rootAnalysis['filter_end_date'] != null
    ) {
      let startDate = new Date(us_rootAnalysis['filter_start_date'])
      let endDate = new Date(us_rootAnalysis['filter_end_date'])
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_DataForSpecificDataBucket_DateRange_Query(res_GCK.clientKey, us_rootAnalysis['associated_data_bucket_key'], startDate, endDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_rootAnalysis])

  useEffect(() => {
    us_setTableColumns_AllQRCodeData(returnTableColumnsFromDatabaseFormDataFormat(us_associatedFormFields, {}, {}, uc_setUserInterface_SnackbarDisplay, true))
  }, [uc_setUserInterface_SnackbarDisplay, us_associatedFormFields])

  useEffect(() => {
    us_setTableColumns_FilteredQRCodeData(
      returnTableColumnsFromDatabaseFormDataFormat(
        us_associatedFormFields,
        us_highlightedCells_FilteredQRCodeData,
        {},
        uc_setUserInterface_SnackbarDisplay,
        true,
      ),
    )
  }, [uc_setUserInterface_SnackbarDisplay, us_associatedFormFields, us_highlightedCells_FilteredQRCodeData])

  useEffect(() => {
    if (us_selectedAnalysisProcedure === 'ALL_DATA') {
      us_setFilteredQRCodeData(us_allQRCodeData)
    } else if (
      us_selectedAnalysisProcedure != null &&
      us_selectedAnalysisProcedure !== '' &&
      us_analysisTemplateAnalysisProcedures != null &&
      us_analysisTemplateAnalysisProcedures[us_selectedAnalysisProcedure] != null &&
      us_analysisTemplateAnalysisProcedures[us_selectedAnalysisProcedure]['condition'] != null &&
      analysisProcedureOptions != null &&
      analysisProcedureOptions[us_analysisTemplateAnalysisProcedures[us_selectedAnalysisProcedure]['condition']] != null
    ) {
      let filteredData: TsInterface_UnspecifiedObject = {}
      let analysisProcedure = us_analysisTemplateAnalysisProcedures[us_selectedAnalysisProcedure]
      let conditionObject = analysisProcedureOptions[us_analysisTemplateAnalysisProcedures[us_selectedAnalysisProcedure]['condition']]
      if (conditionObject != null && conditionObject['evaluation'] != null) {
        for (let loopDataKey in us_allQRCodeData) {
          let loopData = us_allQRCodeData[loopDataKey]
          let analysisProcedureCalculationResult = conditionObject['evaluation'](us_allQRCodeData, getProp(loopData, 'data', {}), analysisProcedure)
          if (analysisProcedureCalculationResult === true) {
            filteredData[loopDataKey] = loopData
          }
        }
        us_setFilteredQRCodeData(filteredData)
      } else {
        us_setFilteredQRCodeData({})
      }
      let highlightedColumns: TsInterface_UnspecifiedObject = {}
      if (us_analysisTemplateAnalysisProcedures[us_selectedAnalysisProcedure]['highlighted_cells'] != null) {
        for (let loopRowKey in filteredData) {
          highlightedColumns[loopRowKey] = us_analysisTemplateAnalysisProcedures[us_selectedAnalysisProcedure]['highlighted_cells']
        }
      }
      us_setHighlightedCells_FilteredQRCodeData(highlightedColumns)
    } else {
      us_setFilteredQRCodeData({})
    }
  }, [us_allQRCodeData, us_analysisTemplateAnalysisProcedures, us_selectedAnalysisProcedure])

  // Functions
  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <></>
    if (us_rootAnalysis != null && us_rootAnalysis.name != null) {
      pageHeader = (
        <Box>
          <Box className="tw-inline-block tw-mr-1">{rLIB('Data Analysis')}:</Box>
          <Box className="tw-inline-block tw-opacity-50">{us_rootAnalysis.name}</Box>
        </Box>
      )
    } else {
      pageHeader = rLIB('Data Analysis') as JSX.Element
    }
    return pageHeader
  }

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        // size="small"
        variant="outlined"
        className="tw-mr-2 tw-mb-2"
        onClick={() => {
          un_routerNavigation(ApplicationPages.DataAnalysisListPage.url())
        }}
        startIcon={<Icon icon="angle-left" />}
      >
        {rLIB('Back to all Analyses')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_AnalysisProcedureSelectionDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input"
        sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            us_setSelectedAnalysisProcedure(event.target.value)
          }}
          value={us_selectedAnalysisProcedure}
          disabled={objectToArray(us_associatedFormFields).length === 0}
        >
          <MenuItem value={'ALL_DATA'}>{rLIB('All Data')}</MenuItem>
          {objectToArray(us_analysisTemplateAnalysisProcedures)
            .sort(dynamicSort('name', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['name']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_AllDataTable = (): JSX.Element => {
    let tabContentJSX = <></>
    if (uc_RootData_ClientKey != null && us_tableColumns_AllQRCodeData != null && objectToArray(us_tableColumns_AllQRCodeData).length > 0) {
      // Table Settings
      let tableSettings: TsInterface_TableSettings = {
        paginated: false,
        size: 'small',
        sort_direction: 'asc',
        sort_property_default: 'timestamp_primary_query',
        sortable: false,
        collapsible_columns: true,
        sticky_header: true,
        sticky_table_height: 'calc(100vh - 140px)',
        alternate_row_color_hex: themeVariables.background_highlight,
        alternate_row_colors: true,
      }
      // JSX
      tabContentJSX = (
        <Box>
          <Card className="">
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={us_tableColumns_AllQRCodeData}
              tableData={objectToArray(us_allQRCodeData)}
              tableSettings={tableSettings}
            />
          </Card>
        </Box>
      )
    }
    return tabContentJSX
  }

  const rJSX_FilteredDataTable = (): JSX.Element => {
    let tabContentJSX = <></>
    if (uc_RootData_ClientKey != null && us_tableColumns_FilteredQRCodeData != null && objectToArray(us_tableColumns_FilteredQRCodeData).length > 0) {
      // Table Settings
      let tableSettings: TsInterface_TableSettings = {
        paginated: false,
        size: 'small',
        sort_direction: 'asc',
        sort_property_default: 'timestamp_primary_query',
        sortable: false,
        collapsible_columns: true,
        sticky_header: true,
        sticky_table_height: 'calc(100vh - 140px)',
        alternate_row_color_hex: themeVariables.background_highlight,
        alternate_row_colors: true,
      }
      // JSX
      tabContentJSX = (
        <Box>
          <Card className="">
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={us_tableColumns_FilteredQRCodeData}
              tableData={objectToArray(us_filteredQRCodeData)}
              tableSettings={tableSettings}
            />
          </Card>
        </Box>
      )
    }
    return tabContentJSX
  }

  const rJSX_CurrentTableCount = (): JSX.Element => {
    let tableCountJSX = <></>
    if (us_selectedAnalysisProcedure === 'ALL_DATA') {
      tableCountJSX = (
        <Box className="tw-inline-block tw-align-top tw-mt-1.5 tw-opacity-50">
          {rLIB('Showing')} {objectToArray(us_allQRCodeData).length} {objectToArray(us_allQRCodeData).length === 1 ? rLIB('Record') : rLIB('Records')}
        </Box>
      )
    } else {
      tableCountJSX = (
        <Box className="tw-inline-block tw-align-top tw-mt-1.5 tw-opacity-50">
          {rLIB('Showing')} {objectToArray(us_filteredQRCodeData).length} {objectToArray(us_filteredQRCodeData).length === 1 ? rLIB('Record') : rLIB('Records')}
        </Box>
      )
    }
    return tableCountJSX
  }

  const rJSX_DataTable = (): JSX.Element => {
    let tableJSX = <></>
    if (us_selectedAnalysisProcedure === 'ALL_DATA') {
      tableJSX = rJSX_AllDataTable()
    } else {
      tableJSX = rJSX_FilteredDataTable()
    }
    return tableJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <Box>
              {rJSX_BackButton()}
              {rJSX_AnalysisProcedureSelectionDropdown()}
            </Box>
            <Box>{rJSX_DataTable()}</Box>
            <Box className="tw-text-right">{rJSX_CurrentTableCount()}</Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

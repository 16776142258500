///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Services contain business logic that is completely abstracted from user interfaces

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  DatabaseRef_ReportTemplates_Components_Collection,
  DatabaseRef_ReportTemplates_Document,
} from 'rfbp_aux/services/database_endpoints/clients/data_management/report_templates'
import { downloadPdfFromData } from 'rfbp_core/components/pdf/custom'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseGetCollection, DatabaseGetDocument } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray, returnDateFromUnknownDateFormat, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import * as XLSX from 'xlsx'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

// COPIED TO SERVER
const returnFormattedDateForReport = (timestamp: number, format: string, timezone: string | null) => {
  return returnFormattedDate(timestamp, format)
}

///////////////////////////////
// Exports
///////////////////////////////

// COPIED TO SERVER
export const excelColumnIndexToLetter = (index: number) => {
  let letter = ''
  let letterOptions = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
  if (index <= 25) {
    letter = letterOptions[index]
  } else {
    let firstLetterIndex = Math.floor(index / 26) - 1
    let secondLetterIndex = index % 26
    letter = letterOptions[firstLetterIndex] + '' + letterOptions[secondLetterIndex]
  }
  return letter
}

// COPIED TO SERVER
export const downloadExcelReport = (
  reportData: TsInterface_UnspecifiedObject,
  fileName: string,
  process: 'download' | 'base64',
  timezone: string | null,
  rootReport: TsInterface_UnspecifiedObject,
  reportComponents: TsInterface_UnspecifiedObject,
) => {
  return new Promise((resolve, reject) => {
    try {
      // ========================================
      // Create New Workbook
      // ========================================
      const wb = XLSX.utils.book_new()
      // Loop Through Tabs
      let tabCount = 0
      let orderedTabs: TsInterface_UnspecifiedObject[] = objectToArray(reportComponents).sort(dynamicSort('order', 'asc'))
      for (let loopTabIndex in orderedTabs) {
        const loopTab = orderedTabs[loopTabIndex]
        if (loopTab != null && loopTab.component_type === 'excel_tab') {
          tabCount++
          let rowCount = 0
          let tabData: any[] = []
          let tabFunctions: TsInterface_UnspecifiedObject = {}

          // ========================================
          // Generate Data Arrays
          // ========================================

          // TODO: Header
          // rowCount++

          // Repeated Data
          if (loopTab.include_repeated_data_section === true) {
            // Header Row
            rowCount++
            let rowData: any[] = []
            for (let loopColumnIndex in loopTab.repeated_data) {
              let loopColumn = loopTab.repeated_data[loopColumnIndex]
              rowData.push(getProp(loopColumn, 'header', ''))
            }
            tabData.push(rowData)
            // TODO: sort data rows by other field
            let orderedData: TsInterface_UnspecifiedObject[] = objectToArray(reportData).sort(dynamicSort('order', 'timestamp_primary_query'))
            // Data Rows
            for (let loopDataIndex in orderedData) {
              rowCount++
              let rowData: any[] = []
              let loopData = orderedData[loopDataIndex]
              for (let loopColumnIndex in loopTab.repeated_data) {
                let loopColumn = loopTab.repeated_data[loopColumnIndex]

                // TODO: class key on data item determines which content to render
                // rowData.push( getProp( loopData, "header", "" ) )

                // TODO: add data to row

                // [["Value 1", "Value 2", "Sum"], [10, 20, '']]

                // TODO: excel functions
                // 	ws1["C2"] = { f: "A2+B2" }

                if (
                  loopData != null &&
                  loopData['associated_class_key'] != null &&
                  loopData['associated_class_key'] !== '' &&
                  rootReport != null &&
                  rootReport['associated_class_keys'] != null &&
                  rootReport['associated_class_keys'][loopData['associated_class_key']] === true
                ) {
                  let valueFound = false
                  let value = ''
                  let columnDataType = 'NOT_FOUND'
                  if (
                    loopColumn != null &&
                    loopColumn['content'] != null &&
                    loopColumn['content'][loopData['associated_class_key']] != null &&
                    loopColumn['content'][loopData['associated_class_key']]['cell_type'] != null
                  ) {
                    columnDataType = getProp(loopColumn, 'column_data_type', null)
                    switch (loopColumn['content'][loopData['associated_class_key']]['cell_type']) {
                      case 'hardcode':
                        if (getProp(loopColumn['content'][loopData['associated_class_key']], 'hardcode_value', null) != null) {
                          value = loopColumn['content'][loopData['associated_class_key']]['hardcode_value']
                          valueFound = true
                        } else {
                          valueFound = false
                        }
                        break
                      case 'excel_formula':
                        if (getProp(loopColumn['content'][loopData['associated_class_key']], 'excel_value', null) != null) {
                          let excelFormula = loopColumn['content'][loopData['associated_class_key']]['excel_value']
                          // Replace all instances of #R with row number
                          excelFormula = excelFormula.replace(/#R/g, rowCount)
                          if (excelFormula.startsWith('=')) {
                            excelFormula = excelFormula.slice(1)
                          }
                          tabFunctions[excelColumnIndexToLetter(parseInt(loopColumnIndex)) + rowCount.toString()] = { f: excelFormula }
                          valueFound = false // TODO: Check and See if this works
                        } else {
                          valueFound = false
                        }
                        break
                      case 'mapped_data_field':
                        if (
                          loopColumn['content'][loopData['associated_class_key']]['mapped_data_field_key'] != null &&
                          loopData['data'] != null &&
                          loopData['data'][loopColumn['content'][loopData['associated_class_key']]['mapped_data_field_key']] != null
                        ) {
                          // If the cell starts with https:// or http://, make it a hyperlink
                          // if (
                          //   loopData['data'][loopColumn['content'][loopData['associated_class_key']]['mapped_data_field_key']].startsWith('https://') ||
                          //   loopData['data'][loopColumn['content'][loopData['associated_class_key']]['mapped_data_field_key']].startsWith('http://')
                          // ) {
                          //   tabFunctions[excelColumnIndexToLetter(parseInt(loopColumnIndex)) + rowCount.toString()] = {
                          //     f:
                          //       'HYPERLINK("' +
                          //       loopData['data'][loopColumn['content'][loopData['associated_class_key']]['mapped_data_field_key']] +
                          //       '","' +
                          //       loopData['data'][loopColumn['content'][loopData['associated_class_key']]['mapped_data_field_key']] +
                          //       '")',
                          //     t: 's',
                          //   }
                          //   valueFound = false // TODO: Check and See if this works
                          // } else {
                          value = loopData['data'][loopColumn['content'][loopData['associated_class_key']]['mapped_data_field_key']]
                          valueFound = true
                          // }
                        } else {
                          // Check and see if the field key has a period in it and if so split it and check if it's a multi-level object
                          if (
                            loopColumn != null &&
                            loopColumn['content'] != null &&
                            loopColumn['content'][loopData['associated_class_key']] != null &&
                            loopColumn['content'][loopData['associated_class_key']]['mapped_data_field_key'] != null &&
                            loopColumn['content'][loopData['associated_class_key']]['mapped_data_field_key'].includes('.')
                          ) {
                            let firstPeriodIndex = loopColumn['content'][loopData['associated_class_key']]['mapped_data_field_key'].indexOf('.')
                            let mappedFieldKeySubstring = loopColumn['content'][loopData['associated_class_key']]['mapped_data_field_key'].substring(
                              0,
                              firstPeriodIndex,
                            )
                            let mappedFieldOptionSubstring = loopColumn['content'][loopData['associated_class_key']]['mapped_data_field_key'].substring(
                              firstPeriodIndex + 1,
                            )
                            if (
                              loopData['data'] != null &&
                              loopData['data'][mappedFieldKeySubstring] != null &&
                              loopData['data'][mappedFieldKeySubstring][mappedFieldOptionSubstring] != null
                            ) {
                              value = loopData['data'][mappedFieldKeySubstring][mappedFieldOptionSubstring]
                              valueFound = true
                            } else {
                              valueFound = false
                            }
                          } else {
                            valueFound = false
                          }
                        }
                        break
                      case 'mapped_association_field':
                        if (
                          loopColumn['content'][loopData['associated_class_key']]['mapped_association_field_key'] != null &&
                          loopData['directory_associations'] != null &&
                          loopData['directory_associations'][loopColumn['content'][loopData['associated_class_key']]['mapped_association_field_key']] != null &&
                          loopData['directory_associations'][loopColumn['content'][loopData['associated_class_key']]['mapped_association_field_key']]['name'] !=
                            null
                        ) {
                          ;(value =
                            loopData['directory_associations'][loopColumn['content'][loopData['associated_class_key']]['mapped_association_field_key']][
                              'name'
                            ]),
                            (valueFound = true)
                        } else {
                          valueFound = false
                        }
                        break
                      case 'mapped_metadata_field':
                        if (
                          loopColumn['content'][loopData['associated_class_key']]['mapped_metadata_field_key'] === 'timestamp_submitted' &&
                          loopData['timestamp_submitted'] != null
                        ) {
                          value = returnFormattedDateForReport(loopData['timestamp_submitted'], 'MM/DD/YYYY hh:mm a', timezone)
                          valueFound = true
                        } else if (
                          loopColumn['content'][loopData['associated_class_key']]['mapped_metadata_field_key'] === 'timestamp_primary_query' &&
                          loopData['timestamp_primary_query'] != null
                        ) {
                          value = returnFormattedDateForReport(loopData['timestamp_primary_query'], 'MM/DD/YYYY hh:mm a', timezone)
                          valueFound = true
                        } else if (
                          loopColumn['content'][loopData['associated_class_key']]['mapped_metadata_field_key'] === 'confirmation_number' &&
                          loopData['confirmation_number'] != null
                        ) {
                          value = loopData['confirmation_number']
                          valueFound = true
                        } else if (
                          loopColumn['content'][loopData['associated_class_key']]['mapped_metadata_field_key'] === 'associated_data_bucket_name' &&
                          loopData['associated_data_bucket_name'] != null
                        ) {
                          value = loopData['associated_data_bucket_name']
                          valueFound = true
                        } else if (
                          loopColumn['content'][loopData['associated_class_key']]['mapped_metadata_field_key'] != null &&
                          loopData[loopColumn['content'][loopData['associated_class_key']]['mapped_metadata_field_key']] != null
                        ) {
                          value = loopData[loopColumn['content'][loopData['associated_class_key']]['mapped_metadata_field_key']]
                          valueFound = true
                        } else if (
                          loopColumn['content'][loopData['associated_class_key']]['mapped_metadata_field_key'] != null &&
                          loopData['metadata'] != null &&
                          loopData['metadata'][loopColumn['content'][loopData['associated_class_key']]['mapped_metadata_field_key']] != null
                        ) {
                          value = loopData['metadata'][loopColumn['content'][loopData['associated_class_key']]['mapped_metadata_field_key']]
                          valueFound = true
                        } else {
                          valueFound = false
                        }
                        break
                      case 'mapped_calculated_field':
                        if (
                          loopColumn['content'][loopData['associated_class_key']]['mapped_calculated_field_key'] != null &&
                          loopData['calculated_data'] != null &&
                          loopData['calculated_data'][loopColumn['content'][loopData['associated_class_key']]['mapped_calculated_field_key']] != null
                        ) {
                          value = loopData['calculated_data'][loopColumn['content'][loopData['associated_class_key']]['mapped_calculated_field_key']]
                          valueFound = true
                        } else {
                          valueFound = false
                        }
                        break
                      default:
                        valueFound = false
                    }
                  } else {
                    valueFound = false
                  }
                  // Push empty value if no value found
                  if (valueFound === false) {
                    rowData.push('')
                  } else {
                    switch (columnDataType) {
                      case 'number':
                        if (value !== '' && !isNaN(parseFloat(value))) {
                          rowData.push(parseFloat(value))
                        } else {
                          rowData.push(value)
                        }
                        break
                      case 'string':
                        rowData.push(value)
                        break
                      default:
                        rowData.push(value)
                        break
                    }
                  }
                }
              }
              tabData.push(rowData)
            }
          }

          // TODO: Footer

          // Put Data Array into format

          // Add Tab to Workbook
          const ws = XLSX.utils.aoa_to_sheet(tabData)
          // Add Functions to Tab
          for (let loopCellAddress in tabFunctions) {
            try {
              ws[loopCellAddress] = tabFunctions[loopCellAddress]
            } catch (err) {
              console.error(err)
            }
          }
          // TODO: probably formatting

          XLSX.utils.book_append_sheet(wb, ws, getProp(loopTab, 'name', 'Sheet' + tabCount))
        }
      }
      // ========================================
      // Download Workbook
      // ========================================
      if (process === 'download') {
        // Convert the workbook to a buffer
        const wbout = XLSX.write(wb, { type: 'array', bookType: 'xlsx' })
        // Convert the buffer to a Blob
        const blob = new Blob([wbout], { type: 'application/octet-stream' })
        // Create a URL for the Blob
        const url = URL.createObjectURL(blob)
        // Create a link and click it to trigger the download
        const link = document.createElement('a')
        link.href = url
        link.download = fileName + '.xlsx'
        document.body.appendChild(link)
        link.click()
        // Cleanup
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
        // Resolve
        resolve({ success: true })
      } else if (process === 'base64') {
        // Add xlsx to file name
        fileName = fileName + '.xlsx'
        // Convert the workbook to a base64
        const wbout = XLSX.write(wb, { type: 'base64', bookType: 'xlsx' })
        // Resolve
        resolve({ success: true, fileName: fileName, base64: wbout })
      } else {
        let error = {
          message: rLIB('Failed to download Excel Report'),
          details: rLIB('Invalid Procedure'),
          code: 'ER-D-RHF-DER-01',
        }
        reject({ success: false, error: error })
      }
    } catch (err) {
      let error = {
        message: rLIB('Failed to download Excel Report'),
        details: getProp(err, 'message', null),
        code: 'ER-D-RHF-DER-01',
      }
      reject({ success: false, error: error })
    }
  })
}

// COPIED TO SERVER
export const downloadPdfReport = (
  // TODO: Custom formatting on variable text?
  reportData: TsInterface_UnspecifiedObject,
  fileName: string,
  process: 'download' | 'base64',
  timezone: string | null,
  rootReport: TsInterface_UnspecifiedObject,
  reportComponents: TsInterface_UnspecifiedObject,
) => {
  return new Promise((resolve, reject) => {
    try {
      // Generate Data for each PDF Page
      let pdfPagesData: TsInterface_UnspecifiedObject = {}
      for (let loopDataItemKey in reportData) {
        let dataItemMappedData: TsInterface_UnspecifiedObject = {}
        let loopDataItem = reportData[loopDataItemKey]
        if (loopDataItem != null && loopDataItem['associated_class_key'] != null) {
          // Loop through pages - should only be one so kind of overkill
          for (let loopPageKey in reportComponents) {
            let loopPage = reportComponents[loopPageKey]
            if (loopPage != null && loopPage['pdf_variables'] != null) {
              for (let loopVariableKey in loopPage['pdf_variables']) {
                let loopVariable = loopPage['pdf_variables'][loopVariableKey]
                if (
                  loopVariable != null &&
                  loopVariable['associated_class_mappings'] != null &&
                  loopVariable['associated_class_mappings'][loopDataItem['associated_class_key']] != null
                ) {
                  let loopMapping = loopVariable['associated_class_mappings'][loopDataItem['associated_class_key']]
                  if (loopMapping != null) {
                    switch (loopMapping['mapping_type']) {
                      case 'hardcode':
                        if (loopMapping['hardcode_value'] != null) {
                          dataItemMappedData[loopVariableKey] = loopMapping['hardcode_value']
                        }
                        break
                      case 'mapped_data_field':
                        if (
                          loopMapping['mapped_data_field_key'] != null &&
                          loopDataItem['data'] != null &&
                          loopDataItem['data'][loopMapping['mapped_data_field_key']] != null
                        ) {
                          dataItemMappedData[loopVariableKey] = loopDataItem['data'][loopMapping['mapped_data_field_key']]
                        }
                        break
                      case 'mapped_association_field':
                        if (
                          loopMapping['mapped_data_field_key'] != null &&
                          loopDataItem['directory_associations'] != null &&
                          loopDataItem['directory_associations'][loopMapping['mapped_data_field_key']] != null
                        ) {
                          dataItemMappedData[loopVariableKey] = loopDataItem['directory_associations'][loopMapping['mapped_data_field_key']]
                        }
                        break
                      case 'mapped_metadata_field':
                        if (loopMapping['mapped_metadata_field_key'] === 'timestamp_submitted' && loopDataItem['timestamp_submitted'] != null) {
                          dataItemMappedData[loopVariableKey] = returnFormattedDateForReport(
                            loopDataItem['timestamp_submitted'],
                            'MM/DD/YYYY hh:mm a',
                            timezone,
                          )
                        } else if (loopMapping['mapped_metadata_field_key'] === 'timestamp_primary_query' && loopDataItem['timestamp_primary_query'] != null) {
                          dataItemMappedData[loopVariableKey] = returnFormattedDateForReport(
                            loopDataItem['timestamp_primary_query'],
                            'MM/DD/YYYY hh:mm a',
                            timezone,
                          )
                        } else if (loopMapping['mapped_metadata_field_key'] === 'confirmation_number' && loopDataItem['confirmation_number'] != null) {
                          dataItemMappedData[loopVariableKey] = loopDataItem['confirmation_number']
                        } else if (
                          loopMapping['mapped_metadata_field_key'] === 'associated_data_bucket_name' &&
                          loopDataItem['associated_data_bucket_name'] != null
                        ) {
                          dataItemMappedData[loopVariableKey] = loopDataItem['associated_data_bucket_name']
                        } else if (loopMapping['mapped_metadata_field_key'] != null && loopDataItem[loopMapping['mapped_metadata_field_key']] != null) {
                          dataItemMappedData[loopVariableKey] = loopDataItem[loopMapping['mapped_metadata_field_key']]
                        } else if (
                          loopMapping['mapped_metadata_field_key'] != null &&
                          loopDataItem['metadata'] != null &&
                          loopDataItem['metadata'][loopMapping['mapped_metadata_field_key']] != null
                        ) {
                          dataItemMappedData[loopVariableKey] = loopDataItem['metadata'][loopMapping['mapped_metadata_field_key']]
                        }
                        break
                      case 'mapped_calculated_field':
                        if (
                          loopMapping['mapped_data_field_key'] != null &&
                          loopDataItem['calculated_data'] != null &&
                          loopDataItem['calculated_data'][loopMapping['mapped_data_field_key']] != null
                        ) {
                          dataItemMappedData[loopVariableKey] = loopDataItem['calculated_data'][loopMapping['mapped_data_field_key']]
                        }
                        break
                    }
                  }
                }
              }
            }
          }
        }
        // Set Data - call later when generating PDF
        pdfPagesData[loopDataItemKey] = dataItemMappedData
        pdfPagesData[loopDataItemKey]['timestamp_primary_query'] = returnDateFromUnknownDateFormat(loopDataItem['timestamp_primary_query']).getTime()
      }
      downloadPdfFromData(rootReport, reportComponents, pdfPagesData)
        .then((res_DPFD) => {
          console.log(res_DPFD)
          resolve(res_DPFD)
        })
        .catch((rej_DPFD) => {
          reject(rej_DPFD)
        })
    } catch (err) {
      console.error(err)
      let error = {
        message: rLIB('Failed to download PDF Report'),
        details: getProp(err, 'message', null),
        code: 'ER-D-RHF-DPR-01',
      }
      reject({ success: false, error: error })
    }
  })
}

// COPIED TO SERVER
export const downloadCustomReport = (
  clientKey: string,
  reportKey: string,
  reportData: TsInterface_UnspecifiedObject,
  fileName: string,
  process: 'download' | 'base64',
  timezone: string | null,
) => {
  return new Promise((resolve, reject) => {
    let rootReport: TsInterface_UnspecifiedObject = {}
    let reportComponents: TsInterface_UnspecifiedObject = {}
    DatabaseGetDocument(DatabaseRef_ReportTemplates_Document(clientKey, reportKey))
      .then((res_DGD) => {
        rootReport = res_DGD.data
        DatabaseGetCollection(DatabaseRef_ReportTemplates_Components_Collection(clientKey, reportKey))
          .then((res_DGC) => {
            reportComponents = res_DGC.data
            if (rootReport.file_type === 'excel') {
              downloadExcelReport(reportData, fileName, process, timezone, rootReport, reportComponents)
                .then((res_DER) => {
                  resolve(res_DER)
                })
                .catch((rej_DER) => {
                  reject(rej_DER)
                })
            } else if (rootReport.file_type === 'pdf') {
              downloadPdfReport(reportData, fileName, process, timezone, rootReport, reportComponents)
                .then((res_DPR) => {
                  resolve(res_DPR)
                })
                .catch((rej_DPR) => {
                  reject(rej_DPR)
                })
            } else {
              let error = {
                message: rLIB('Failed to download Report'),
                details: rLIB('Unsupported File Type'),
                code: 'ER-D-RHF-DCR-01',
              }
              reject({ success: false, error: error })
            }
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_DGD) => {
        console.error(rej_DGD)
      })
  })
}

// TODO: - CSV

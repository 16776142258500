//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Imports
///////////////////////////////

// React

// Components
// Context
// Services
import { getProp } from 'rfbp_core/services/helper_functions'
// Typescript
// Config
// MUI Icons
// MUI Components
import { Box } from '@mui/material'

// Third Party

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  height: string
  l_color: string
  s_color: string
  d_color: string
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const LogoSecureDelivery = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let height: TsInterface_ComponentProps['height'] = getProp(props, 'height', '30px')
  let l_color: TsInterface_ComponentProps['l_color'] = getProp(props, 'l_color', '#000000')
  let s_color: TsInterface_ComponentProps['s_color'] = getProp(props, 's_color', '#000000')
  let d_color: TsInterface_ComponentProps['d_color'] = getProp(props, 'd_color', '#000000')

  // Hooks - useContext, useState, useReducer, other

  // Hooks - useEffect

  // Functions

  // JSX Generation
  const Logo = (
    <Box>
      <svg
        height={height}
        viewBox="0 0 2363 709"
        version="1.1"
      >
        <g
          id="delivery"
          transform="matrix(1.01927,0,0,1,-284.08,-462.801)"
        >
          <g transform="matrix(0.985663,0,0,0.985663,3.70902,6.63499)">
            <path
              fill={d_color}
              d="M912.559,1090L916.412,1048.77L944.54,1036.83L944.54,866.136L908.706,856.118L912.559,814.89L1039.71,814.89C1084.15,814.89 1118.31,826.256 1142.2,848.989C1166.09,871.723 1178.04,904.281 1178.04,946.665C1178.04,993.673 1165.19,1029.25 1139.51,1053.4C1113.56,1077.8 1078.24,1090 1033.55,1090L912.559,1090ZM1018.9,1036.83L1035.47,1036.83C1056.54,1036.83 1072.08,1029.7 1082.1,1015.44C1092.11,1001.19 1097.12,979.16 1097.12,949.362C1097.12,922.904 1092.31,902.74 1082.67,888.869C1073.04,874.998 1058.98,868.062 1040.48,868.062L1018.9,868.062L1018.9,1036.83Z"
            />
          </g>
          <g transform="matrix(0.985663,0,0,0.985663,3.70902,6.63499)">
            <path
              fill={d_color}
              d="M1208.48,984.811C1208.48,948.335 1217.72,920.593 1236.22,901.584C1254.71,882.575 1280.53,873.071 1313.67,873.071C1345,873.071 1368.96,881.869 1385.53,899.465C1402.09,917.061 1410.38,943.326 1410.38,978.26L1410.38,995.214L1362.6,1002.92L1282.84,1003.31C1283.36,1014.87 1287.91,1024.11 1296.52,1031.05C1305.12,1037.98 1316.75,1041.45 1331.39,1041.45C1351.68,1041.45 1373.26,1037.98 1396.12,1031.05L1402.29,1079.6C1380.97,1089.1 1354.38,1093.85 1322.53,1093.85C1287.85,1093.85 1260.24,1084.86 1239.69,1066.88C1218.88,1048.9 1208.48,1021.54 1208.48,984.811ZM1284.38,957.068L1343.72,957.068C1343.46,946.794 1340.89,938.574 1336.01,932.409C1331.13,926.244 1324.58,923.161 1316.36,923.161C1307.12,923.161 1299.73,926.051 1294.21,931.831C1288.68,937.61 1285.41,946.023 1284.38,957.068Z"
            />
          </g>
          <g transform="matrix(0.985663,0,0,0.985663,3.70902,6.63499)">
            <path
              fill={d_color}
              d="M1432.73,1090L1435.42,1050.7L1456.62,1039.53L1456.62,847.641L1431.57,836.467L1428.87,797.165L1530.21,793.312L1530.21,1044.53L1561.42,1042.99L1558.72,1090L1432.73,1090Z"
            />
          </g>
          <g transform="matrix(0.985663,0,0,0.985663,3.70902,6.63499)">
            <path
              fill={d_color}
              d="M1602.65,823.752C1602.65,801.917 1615.62,791 1641.56,791C1667.76,791 1680.87,801.917 1680.87,823.752C1680.87,845.329 1667.76,856.118 1641.56,856.118C1615.62,856.118 1602.65,845.329 1602.65,823.752ZM1576.83,1090L1579.53,1050.7L1604.57,1039.53L1604.57,927.4L1579.53,916.226L1576.83,876.924L1678.17,873.071L1678.17,1044.53L1710.15,1042.99L1707.45,1090L1576.83,1090Z"
            />
          </g>
          <g transform="matrix(0.985663,0,0,0.985663,3.70902,6.63499)">
            <path
              fill={d_color}
              d="M1800.7,1090L1736.74,927.4L1715.93,916.226L1718.62,876.924L1835.37,876.924L1833.06,920.079L1815.34,923.547L1839.23,995.214C1842.57,1004.46 1844.24,1013.32 1844.24,1021.8C1846.81,1008.19 1849.12,998.553 1851.17,992.902L1873.9,923.547L1853.87,916.226L1856.57,876.924L1957.52,876.924L1955.2,920.079L1936.33,927.4L1872.36,1090L1800.7,1090Z"
            />
          </g>
          <g transform="matrix(0.985663,0,0,0.985663,3.70902,6.63499)">
            <path
              fill={d_color}
              d="M1969.08,984.811C1969.08,948.335 1978.32,920.593 1996.82,901.584C2015.31,882.575 2041.13,873.071 2074.26,873.071C2105.6,873.071 2129.56,881.869 2146.12,899.465C2162.69,917.061 2170.98,943.326 2170.98,978.26L2170.98,995.214L2123.2,1002.92L2043.44,1003.31C2043.95,1014.87 2048.51,1024.11 2057.12,1031.05C2065.72,1037.98 2077.35,1041.45 2091.99,1041.45C2112.28,1041.45 2133.86,1037.98 2156.72,1031.05L2162.89,1079.6C2141.57,1089.1 2114.98,1093.85 2083.13,1093.85C2048.45,1093.85 2020.84,1084.86 2000.29,1066.88C1979.48,1048.9 1969.08,1021.54 1969.08,984.811ZM2044.98,957.068L2104.32,957.068C2104.06,946.794 2101.49,938.574 2096.61,932.409C2091.73,926.244 2085.18,923.161 2076.96,923.161C2067.72,923.161 2060.33,926.051 2054.81,931.831C2049.28,937.61 2046.01,946.023 2044.98,957.068Z"
            />
          </g>
          <g transform="matrix(0.985663,0,0,0.985663,3.70902,6.63499)">
            <path
              fill={d_color}
              d="M2193.33,1090L2196.02,1050.7L2219.91,1039.53L2219.91,929.326L2194.87,918.152L2192.17,878.851L2292.35,874.998L2288.88,910.831L2292.35,910.831C2305.96,885.658 2323.43,873.071 2344.75,873.071C2357.6,873.071 2369.41,875.768 2380.2,881.163L2377.5,958.61L2336.66,958.61L2333.19,939.73C2330.37,938.959 2327.54,938.574 2324.72,938.574C2315.98,938.574 2308.6,942.363 2302.56,949.94C2296.52,957.518 2293.51,969.27 2293.51,985.196L2293.51,1044.53L2337.05,1042.99L2334.35,1090L2193.33,1090Z"
            />
          </g>
          <g transform="matrix(0.985663,0,0,0.985663,3.70902,6.63499)">
            <path
              fill={d_color}
              d="M2402.55,1177.08L2407.94,1121.6C2415.39,1123.14 2422.97,1123.91 2430.68,1123.91C2440.69,1123.91 2448.4,1121.79 2453.79,1117.55C2459.19,1113.31 2463.68,1106.95 2467.28,1098.48L2471.52,1088.84L2408.33,927.014L2387.91,916.226L2390.6,876.924L2506.97,876.924L2504.66,920.079L2486.93,923.547L2510.82,1002.92C2513.13,1011.14 2514.67,1017.31 2515.44,1021.42C2515.96,1017.31 2517.5,1011.27 2520.07,1003.31L2545.5,923.547L2525.85,916.226L2528.93,876.924L2630.27,876.924L2627.95,920.079L2606.76,927.4L2541.64,1099.25C2530.09,1129.56 2516.99,1150.94 2502.34,1163.4C2487.7,1175.86 2467.67,1182.09 2442.24,1182.09C2428.36,1182.09 2415.14,1180.42 2402.55,1177.08Z"
            />
          </g>
        </g>
        <g
          id="secure"
          transform="matrix(1.01927,0,0,1,-293.506,-807.183)"
        >
          <g transform="matrix(0.985663,0,0,0.985663,3.8416,11.5723)">
            <path
              fill={s_color}
              d="M917.954,1076.51L917.954,997.526L961.108,997.526L970.356,1031.82C983.199,1037.21 995.786,1039.91 1008.12,1039.91C1026.87,1039.91 1036.24,1032.97 1036.24,1019.1C1036.24,1011.65 1032.78,1005.1 1025.84,999.452C1018.9,993.801 1005.8,986.223 986.539,976.719C962.649,965.417 945.696,953.922 935.678,942.234C925.66,930.546 920.394,913.657 919.88,891.566C919.88,865.879 928.871,846.1 946.852,832.228C964.833,818.1 988.337,811.036 1017.36,811.036C1029.69,811.036 1042.41,812.578 1055.51,815.66L1058.59,807.183L1099.43,807.183L1099.43,900.428L1054.74,900.428L1050.5,869.603C1039.2,865.75 1029.44,863.824 1021.22,863.824C1002.72,863.824 993.474,870.631 993.474,884.245C994.245,891.951 997.648,898.245 1003.69,903.125C1009.72,908.006 1023.01,915.584 1043.56,925.858C1065.91,936.133 1082.74,947.307 1094.04,959.38C1105.09,971.196 1110.61,987.636 1110.61,1008.7C1110.61,1033.36 1101.55,1053.72 1083.44,1069.77C1065.33,1085.83 1040.1,1093.85 1007.73,1093.85C972.539,1093.85 942.613,1088.07 917.954,1076.51Z"
            />
          </g>
          <g transform="matrix(0.985663,0,0,0.985663,3.8416,11.5723)">
            <path
              fill={s_color}
              d="M1134.88,984.811C1134.88,948.335 1144.13,920.593 1162.62,901.584C1181.12,882.575 1206.93,873.071 1240.07,873.071C1271.41,873.071 1295.36,881.869 1311.93,899.465C1328.5,917.061 1336.78,943.326 1336.78,978.26L1336.78,995.214L1289.01,1002.92L1209.25,1003.31C1209.76,1014.87 1214.32,1024.11 1222.92,1031.05C1231.53,1037.98 1243.15,1041.45 1257.8,1041.45C1278.09,1041.45 1299.67,1037.98 1322.53,1031.05L1328.69,1079.6C1307.37,1089.1 1280.79,1093.85 1248.93,1093.85C1214.26,1093.85 1186.64,1084.86 1166.09,1066.88C1145.29,1048.9 1134.88,1021.54 1134.88,984.811ZM1210.79,957.068L1270.13,957.068C1269.87,946.794 1267.3,938.574 1262.42,932.409C1257.54,926.244 1250.99,923.161 1242.77,923.161C1233.52,923.161 1226.14,926.051 1220.61,931.831C1215.09,937.61 1211.82,946.023 1210.79,957.068Z"
            />
          </g>
          <g transform="matrix(0.985663,0,0,0.985663,3.8416,11.5723)">
            <path
              fill={s_color}
              d="M1359.9,984.425C1359.9,948.206 1369.66,920.593 1389.19,901.584C1408.71,882.575 1434.14,873.071 1465.48,873.071C1472.41,873.071 1482.05,873.713 1494.38,874.998L1502.08,871.145L1537.91,871.145L1538.3,955.142L1493.61,955.142L1487.44,930.867C1482.82,929.583 1477.68,928.941 1472.03,928.941C1460.47,928.941 1451.35,933.757 1444.67,943.39C1437.99,953.023 1434.65,966.573 1434.65,984.04C1434.65,1000.99 1438.44,1013.77 1446.02,1022.38C1453.6,1030.98 1464.19,1035.29 1477.81,1035.29C1495.79,1035.29 1513.51,1031.82 1530.98,1024.88L1539.84,1077.67C1519.81,1088.46 1495.28,1093.85 1466.25,1093.85C1434.4,1093.85 1408.58,1084.73 1388.8,1066.5C1369.54,1048.26 1359.9,1020.9 1359.9,984.425Z"
            />
          </g>
          <g transform="matrix(0.985663,0,0,0.985663,3.8416,11.5723)">
            <path
              fill={s_color}
              d="M1583.77,1015.63L1583.77,929.326L1556.03,918.152L1553.33,878.851L1657.36,874.998L1657.36,993.287C1657.36,1021.8 1665.97,1036.06 1683.18,1036.06C1706.55,1036.06 1718.24,1018.59 1718.24,983.655L1718.24,929.326L1694.35,918.152L1691.65,878.851L1791.83,874.998L1791.83,1037.6L1818.81,1048.77L1813.8,1088.07L1723.63,1091.93L1725.56,1056.09L1722.86,1056.09C1706.94,1081.27 1683.43,1093.85 1652.35,1093.85C1632.06,1093.85 1615.56,1087.05 1602.84,1073.43C1590.12,1059.82 1583.77,1040.55 1583.77,1015.63Z"
            />
          </g>
          <g transform="matrix(0.985663,0,0,0.985663,3.8416,11.5723)">
            <path
              fill={s_color}
              d="M1835.37,1090L1838.07,1050.7L1861.96,1039.53L1861.96,929.326L1836.91,918.152L1834.22,878.851L1934.4,874.998L1930.93,910.831L1934.4,910.831C1948.01,885.658 1965.48,873.071 1986.8,873.071C1999.64,873.071 2011.46,875.768 2022.25,881.163L2019.55,958.61L1978.71,958.61L1975.24,939.73C1972.41,938.959 1969.59,938.574 1966.76,938.574C1958.03,938.574 1950.64,942.363 1944.61,949.94C1938.57,957.518 1935.55,969.27 1935.55,985.196L1935.55,1044.53L1979.09,1042.99L1976.4,1090L1835.37,1090Z"
            />
          </g>
          <g transform="matrix(0.985663,0,0,0.985663,3.8416,11.5723)">
            <path
              fill={s_color}
              d="M2031.88,984.811C2031.88,948.335 2041.13,920.593 2059.62,901.584C2078.12,882.575 2103.93,873.071 2137.07,873.071C2168.41,873.071 2192.36,881.869 2208.93,899.465C2225.5,917.061 2233.78,943.326 2233.78,978.26L2233.78,995.214L2186.01,1002.92L2106.25,1003.31C2106.76,1014.87 2111.32,1024.11 2119.92,1031.05C2128.53,1037.98 2140.15,1041.45 2154.8,1041.45C2175.09,1041.45 2196.66,1037.98 2219.53,1031.05L2225.69,1079.6C2204.37,1089.1 2177.78,1093.85 2145.93,1093.85C2111.26,1093.85 2083.64,1084.86 2063.09,1066.88C2042.29,1048.9 2031.88,1021.54 2031.88,984.811ZM2107.79,957.068L2167.12,957.068C2166.87,946.794 2164.3,938.574 2159.42,932.409C2154.54,926.244 2147.99,923.161 2139.77,923.161C2130.52,923.161 2123.14,926.051 2117.61,931.831C2112.09,937.61 2108.82,946.023 2107.79,957.068Z"
            />
          </g>
        </g>
        <g
          id="shield"
          transform="matrix(29.9751,0,0,29.4085,-89.9253,-29.4085)"
        >
          <path
            fill={l_color}
            d="M12,1L3,5L3,11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11L21,5L12,1M12,7C13.4,7 14.8,8.1 14.8,9.5L14.8,11C15.4,11 16,11.6 16,12.3L16,15.8C16,16.4 15.4,17 14.7,17L9.2,17C8.6,17 8,16.4 8,15.7L8,12.2C8,11.6 8.6,11 9.2,11L9.2,9.5C9.2,8.1 10.6,7 12,7M12,8.2C11.2,8.2 10.5,8.7 10.5,9.5L10.5,11L13.5,11L13.5,9.5C13.5,8.7 12.8,8.2 12,8.2Z"
          />
        </g>
      </svg>
    </Box>
  )

  return Logo
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, Switch, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Formula_Document } from 'rfbp_aux/services/database_endpoints/clients/data_management/formulas'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_PromptDialog,
  TsInterface_FormDialogObject,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import { rJSX_LogicPathRender } from './components/formulas_edit'
import { evaluateFormulaLogicPath, libraryFormulas } from './services/calculation_functions'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['FormulasViewPage']['key']

// Data Types
const dataTypeOptions: TsInterface_UnspecifiedObject = {
  string: {
    key: 'string',
    value: rLIB('String'),
    icon: 'pencil',
    formType: 'text_basic',
    dataType: 'string',
  },
  number: {
    key: 'number',
    value: rLIB('Number'),
    icon: 'calculator-simple',
    formType: 'text_number',
    dataType: 'number',
  },
  boolean: {
    key: 'boolean',
    value: rLIB('Boolean'),
    icon: 'toggle-on',
    formType: 'boolean_checkbox',
    dataType: 'boolean',
  },
  date: {
    key: 'date',
    value: rLIB('Date'),
    icon: 'calendar',
    formType: 'timestamp_date',
    dataType: 'string',
  },
  time: {
    key: 'time',
    value: rLIB('Time'),
    icon: 'clock',
    formType: 'timestamp_time',
    dataType: 'string',
  },
  datetime: {
    key: 'datetime',
    value: rLIB('Datetime'),
    icon: 'calendar-clock',
    formType: 'timestamp_datetime',
    dataType: 'string',
  },
}

const formulaTypeOptions: TsInterface_UnspecifiedObject = {
  custom: {
    key: 'custom',
    value: rLIB('Custom'),
    icon: 'pencil',
  },
  library: {
    key: 'library',
    value: rLIB('Library'),
    icon: 'book',
  },
}

const logicViewModeOptions = {
  view: {
    key: 'view',
    value: rLIB('View'),
  },
  edit: {
    key: 'edit',
    value: rLIB('Edit'),
  },
  trace: {
    key: 'trace',
    value: rLIB('Trace'),
  },
}

// Tables
const tableSettings_FormulaVariables: TsInterface_TableSettings = {
  size: 'small',
  sortable: true,
  sort_property_default: 'name',
  paginated: false,
  pagination_rows_per_page_default: 20,
  pagination_rows_per_page_options: [10, 20, 50],
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'inactive',
        conditions: [],
      },
    },
  ],
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

const tableColumns_CustomFormulaVariables: TsInterface_TableColumns = {
  // name: TableCellBasic('name', rLIB('Variable Name'), 'name'),
  name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'name'
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let editIconJSX = (
          <Icon
            icon="pen-to-square"
            className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-green-600"
            tooltip={rLIB('Edit')}
            tooltipPlacement="top"
            onClick={() => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: rowData,
                    formInputs: {
                      name: {
                        data_type: 'string',
                        input_type: 'text_basic',
                        key: 'name',
                        label: <>{rLIB('Variable Name')}</>,
                        required: true,
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject: TsInterface_UnspecifiedObject = {
                              variables: {},
                            }
                            updateObject['variables'][rowData.key as string] = {
                              name: formSubmittedData.name,
                            }
                            DatabaseSetMergeDocument(DatabaseRef_Formula_Document(res_GCK.clientKey, tableAdditionalData.itemKey), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                reject(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: <>{rLIB('Create New Form')}</>,
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )
        let deleteIconJSX = (
          <Icon
            icon="trash"
            className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-red-600"
            tooltip={rLIB('Delete')}
            tooltipPlacement="top"
            onClick={() => {
              tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
                display: true,
                confirm: {
                  color: 'error',
                  icon: <Icon icon="lock" />,
                  header: rLIB('Delete Formula Variable'),
                  text: rLIB('Are you sure that you want to delete this formula variable?'),
                  submit_text: rLIB('Delete Variable'),
                  submit_callback: () => {
                    return new Promise((resolve, reject) => {
                      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          let updateObject: TsInterface_UnspecifiedObject = {
                            variables: {},
                          }
                          updateObject['variables'][rowData.key as string] = {
                            status: 'deleted',
                          }
                          DatabaseSetMergeDocument(DatabaseRef_Formula_Document(res_GCK.clientKey, tableAdditionalData.itemKey), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              reject(rej_DSMD)
                            })
                        })
                        .catch((rej_GCK) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          reject(rej_GCK)
                        })
                    })
                  },
                },
              })
            }}
          />
        )
        cellJSX = (
          <Box>
            {getProp(rowData, 'name', rLIB('Missing'))}
            {editIconJSX}
            {deleteIconJSX}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Test Value')
      },
      header_sort_by: null,
    },
  },
  test_value: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let propValueJSX = <Box className="tw-italic tw-opacity-30 tw-inline-block">{rLIB('Missing')}</Box>
        let rowDataCopy = { ...rowData }
        if (rowDataCopy != null && rowDataCopy['test_value'] != null) {
          if (rowDataCopy['test_value'] === true) {
            rowDataCopy['test_value'] = 'true'
            // @ts-ignore
          } else if (rowDataCopy['test_value'] === false) {
            rowDataCopy['test_value'] = 'false'
          }
          propValueJSX = <Box className="tw-inline-block">{rowDataCopy['test_value']}</Box>
        }
        let editIconJSX = <></>
        if (rowData != null && rowData.data_type != null) {
          editIconJSX = (
            <Box
              sx={{
                'color': themeVariables.success_light,
                '&:hover': {
                  color: themeVariables.success_main,
                },
              }}
              className="tw-inline-block tw-ml-2 tw-cursor-pointer"
              onClick={() => {
                let inputFormType = null
                let inputDataType = null
                if (dataTypeOptions != null && dataTypeOptions[rowData.data_type as any] != null) {
                  inputFormType = getProp(dataTypeOptions[rowData.data_type as any], 'formType', null)
                  inputDataType = getProp(dataTypeOptions[rowData.data_type as any], 'dataType', null)
                }
                const FormDialogObject: TsInterface_FormDialogObject = {
                  form: {
                    formAdditionalData: {},
                    formData: rowData,
                    formInputs: {
                      test_value: {
                        key: 'test_value',
                        label: (
                          <>
                            {rLIB('Test Value')} - {rowData.name}
                          </>
                        ),
                        input_type: inputFormType,
                        required: true,
                        data_type: inputDataType,
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        if (tableAdditionalData.itemKey != null && rowData.key != null && formSubmittedData.test_value != null) {
                          let updateObject: TsInterface_UnspecifiedObject = {
                            variables: {},
                          }
                          updateObject['variables'][rowData.key as string] = {
                            test_value: formSubmittedData.test_value,
                          }
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              DatabaseSetMergeDocument(DatabaseRef_Formula_Document(res_GCK.clientKey, tableAdditionalData.itemKey), updateObject)
                                .then((res_DSMD) => {
                                  resolve(res_DSMD)
                                })
                                .catch((rej_DSMD) => {
                                  reject(rej_DSMD)
                                })
                            })
                            .catch((rej_GCK) => {
                              reject(rej_GCK)
                            })
                        } else {
                          reject({ success: false })
                        }
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Edit Test Value')} - {rowData.name}
                      </>
                    ),
                    formDialogIcon: <Icon icon="pen-to-square" />,
                  },
                }
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: FormDialogObject,
                })
              }}
            >
              <Icon icon="pen-to-square" />
            </Box>
          )
        }
        cellJSX = (
          <Box>
            {propValueJSX}
            {editIconJSX}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Test Value')
      },
      header_sort_by: null,
    },
  },
  data_type: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellSX: TsInterface_UnspecifiedObject = {}
        if (rowData.data_type == null) {
          cellSX['background'] = themeVariables.warning_light
        }
        let cellJSX = (
          <Box
            className="tw-rounded-md"
            sx={cellSX}
          >
            <FormControl
              className="bp_thin_select_input bp_thin_select_multiple_input"
              sx={{ minWidth: '130px', width: 'calc(100%)' }}
            >
              <InputLabel>{rLIB('Data Type')}</InputLabel>
              <Select
                autoWidth={true}
                label={rLIB('Data Type')}
                onChange={(event, value) => {
                  if (rowData.key != null) {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      variables: {},
                    }
                    updateObject['variables'][rowData.key as string] = {
                      data_type: event.target.value,
                      test_value: null,
                    }

                    console.log(updateObject)

                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_Formula_Document(res_GCK.clientKey, tableAdditionalData.itemKey), updateObject)
                          .then((res_DSMD) => {
                            // Nothing
                          })
                          .catch((rej_DSMD) => {
                            console.error(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                      })
                  }
                }}
                value={rowData.data_type || ''}
              >
                {objectToArray(dataTypeOptions).map((option: TsInterface_UnspecifiedObject) => (
                  <MenuItem
                    key={option['key']}
                    value={option['key']}
                    disabled={option['disabled']}
                  >
                    <Box
                      className="tw-inline-block"
                      sx={{ marginRight: '8px', color: themeVariables.error_main }}
                    >
                      <Icon icon={option['icon']} />
                    </Box>
                    {option['value']}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Data Type')
      },
      header_sort_by: null,
    },
  },
  status: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let checked = false
        if (rowData.status === 'active') {
          checked = true
        }
        cellJSX = (
          <Box>
            <Switch
              color="info"
              checked={checked}
              onChange={(event, value) => {
                if (rowData.key != null && value === true) {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    variables: {},
                  }
                  updateObject['variables'][rowData.key as string] = {
                    status: 'active',
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_Formula_Document(res_GCK.clientKey, tableAdditionalData.itemKey), updateObject)
                        .then((res_DSMD) => {
                          // Nothing
                        })
                        .catch((rej_DSMD) => {
                          console.error(rej_DSMD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                } else if (rowData.key != null && value === false) {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    variables: {},
                  }
                  updateObject['variables'][rowData.key as string] = {
                    status: 'inactive',
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_Formula_Document(res_GCK.clientKey, tableAdditionalData.itemKey), updateObject)
                        .then((res_DSMD) => {
                          // Nothing
                        })
                        .catch((rej_DSMD) => {
                          console.error(rej_DSMD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                }
              }}
            />
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Active')
      },
      header_sort_by: null,
    },
  },
}

const tableColumns_LibraryFormulaVariables: TsInterface_TableColumns = {
  value: TableCellBasic('value', rLIB('Variable Name'), 'value'),
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_formula, us_setFormula] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  const [us_selectedLogicViewType, us_setSelectedLogicViewType] = useState<'view' | 'edit' | 'trace'>('view')
  const [us_logicPathTraceData, us_setLogicPathTraceData] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const un_routerNavigation = useNavigate()
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Formula', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setFormula(newData)
      // us_setLoadedCalculationData(true)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Formula_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    if (us_selectedLogicViewType === 'trace' && us_formula != null && us_formula.logic != null && us_formula.variables != null) {
      us_setLogicPathTraceData(evaluateFormulaLogicPath(us_formula.logic, returnTestVariables(us_formula.variables), {}))
    }
    return () => {}
  }, [us_formula, us_selectedLogicViewType])

  // Functions
  const saveFormulaChangeToDatabase = (updateObject: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          console.log(updateObject)

          DatabaseSetMergeDocument(DatabaseRef_Formula_Document(res_GCK.clientKey, itemKey), updateObject)
            .then((res_DSMD) => {
              resolve(res_DSMD)
            })
            .catch((rej_DSMD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
              reject(rej_DSMD)
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          reject(rej_GCK)
        })
    })
  }

  const returnTestVariables = (variables: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject => {
    let cleanVariables: TsInterface_UnspecifiedObject = {}
    for (let variableKey in variables) {
      cleanVariables[variableKey] = getProp(variables[variableKey], 'test_value', null)
    }
    return cleanVariables
  }

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        // size="small"
        variant="outlined"
        className="tw-mr-2 tw-mb-2"
        onClick={() => {
          un_routerNavigation(ApplicationPages.FormulasIndexPage.url())
        }}
        startIcon={<Icon icon="angle-left" />}
      >
        {rLIB('Back to all Formulas')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_LineItem = (propName: JSX.Element | string, data: TsInterface_UnspecifiedObject, propKey: string, editable: boolean): JSX.Element => {
    let lineJSX = <></>
    if (data == null) {
      data = {}
    }
    let propValue = getProp(data, propKey, null)
    let propJSX = <Box className="tw-inline-block tw-italic tw-opacity-30 tw-mr-2">{rLIB('Missing')}</Box>
    if (propValue != null) {
      propJSX = <Box className="tw-inline-block tw-opacity-70 tw-mr-2">{propValue}</Box>
    }
    let editJSX = <></>
    if (editable === true) {
      editJSX = (
        <Box
          className="tw-inline-block tw-cursor-pointer"
          sx={{
            'color': themeVariables.success_light,
            '&:hover': {
              color: themeVariables.success_main,
            },
          }}
          onClick={() => {
            uc_setUserInterface_PromptDialogDisplay({
              display: true,
              prompt: {
                color: 'success',
                confirm_text: <>{rLIB('Update')}</>,
                default_value: propValue,
                header: (
                  <>
                    {rLIB('Update')}: {propName}
                  </>
                ),
                icon: (
                  <Icon
                    icon="pen-to-square"
                    type="solid"
                  />
                ),
                input_label: propName as JSX.Element,
                input_type: 'text',
                text: (
                  <>
                    {rLIB('Enter a new value for')} {propName}
                  </>
                ),
                submit_callback: (promptValue: string) => {
                  return new Promise((resolve, reject) => {
                    let updateObject: TsInterface_UnspecifiedObject = {}
                    updateObject[propKey] = promptValue
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_Formula_Document(res_GCK.clientKey, itemKey), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
            })
          }}
        >
          <Icon icon="pen-to-square" />
        </Box>
      )
    }
    lineJSX = (
      <Box>
        <Typography variant="h6">
          <Box className="tw-inline-block tw-mr-2 tw-font-bold">{propName}:</Box>
          {propJSX}
          {editJSX}
        </Typography>
      </Box>
    )
    return lineJSX
  }

  const rJSX_CustomVariablesTable = (): JSX.Element => {
    let tableJSX = <></>
    let variables = getProp(us_formula, 'variables', {})
    // Filter out variables that are not active
    variables = objectToArray(variables).filter((variable: TsInterface_UnspecifiedObject) => {
      return variable.status === 'active'
    })
    if (variables.length > 0) {
      tableJSX = (
        <TableBasic
          tableAdditionalData={{ itemKey: itemKey }}
          tableColumns={tableColumns_CustomFormulaVariables}
          tableData={variables}
          tableSettings={tableSettings_FormulaVariables}
        />
      )
    } else {
      tableJSX = (
        <Box className="tw-text-center tw-p-4">
          <Typography variant="h6">{rLIB('No variables created yet')}</Typography>
        </Box>
      )
    }
    let tableContainerJSX = (
      <Card className="tw-mb-2">
        <Box className="tw-p-4">
          <Button
            className="tw-mb-2"
            variant="contained"
            // size=""
            color="success"
            onClick={() => {
              uc_setUserInterface_PromptDialogDisplay({
                display: true,
                prompt: {
                  color: 'success',
                  confirm_text: rLIB('Create Variable') as JSX.Element,
                  default_value: null,
                  header: rLIB('Create Variable for Formula') as JSX.Element,
                  icon: (
                    <Icon
                      icon="pen-to-square"
                      type="solid"
                    />
                  ),
                  input_label: rLIB('Variable Name') as JSX.Element,
                  input_type: 'text',
                  text: rLIB('Enter a name for the new variable.') as JSX.Element,
                  submit_callback: (promptValue: string) => {
                    return new Promise((resolve, reject) => {
                      let variableKey = uuidv4()
                      let updateObject: TsInterface_UnspecifiedObject = {
                        variables: {},
                      }
                      updateObject['variables'][variableKey] = {
                        status: 'active',
                        name: promptValue,
                        key: variableKey,
                      }
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(DatabaseRef_Formula_Document(res_GCK.clientKey, itemKey), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        })
                        .catch((rej_GCK) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          reject(rej_GCK)
                        })
                    })
                  },
                },
              })
            }}
          >
            <Icon
              icon="circle-plus"
              className="tw-mr-2"
            />
            {rLIB('Create Variable')}
          </Button>
        </Box>
        {tableJSX}
      </Card>
    )
    return tableContainerJSX
  }

  const rJSX_LibraryVariablesTable = (): JSX.Element => {
    let libraryVariables: TsInterface_UnspecifiedObject = {}
    if (
      us_formula != null &&
      us_formula.associated_library_formula_key != null &&
      libraryFormulas != null &&
      libraryFormulas[us_formula.associated_library_formula_key] != null &&
      libraryFormulas[us_formula.associated_library_formula_key].variables != null
    ) {
      libraryVariables = libraryFormulas[us_formula.associated_library_formula_key].variables
    }
    let tableContainerJSX = (
      <Card className="tw-mb-2">
        <Box className="tw-p-4">
          <Button
            className="tw-mb-2"
            variant="contained"
            disabled={true}
            color="success"
          >
            <Icon
              icon="circle-plus"
              className="tw-mr-2"
            />
            {rLIB('Create Variable')}
          </Button>
        </Box>
        <TableBasic
          tableAdditionalData={{ itemKey: itemKey }}
          tableColumns={tableColumns_LibraryFormulaVariables}
          tableData={objectToArray(libraryVariables)}
          tableSettings={tableSettings_FormulaVariables}
        />
      </Card>
    )
    return tableContainerJSX
  }

  const rJSX_LogicPathHeader = (): JSX.Element => {
    let headerJSX = <></>
    let logicTraceResult = <></>
    if (us_selectedLogicViewType === 'trace' && us_logicPathTraceData != null && us_logicPathTraceData.success === true) {
      logicTraceResult = (
        <Box className="tw-inline-block tw-ml-2">
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-2">{rLIB('Calculation Result')}:</Box>
            <Box
              className="tw-inline-block tw-font-bold"
              sx={{ color: themeVariables.success_light }}
            >
              {us_logicPathTraceData.value}
            </Box>
          </Typography>
        </Box>
      )
    }
    if (us_selectedLogicViewType === 'trace' && us_logicPathTraceData != null && us_logicPathTraceData.success === false) {
      logicTraceResult = (
        <Box className="tw-inline-block tw-ml-2">
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-2">{rLIB('Calculation Error')}:</Box>
            <Box
              className="tw-inline-block"
              sx={{ color: themeVariables.error_main }}
            >
              {us_logicPathTraceData.error_type} ({us_logicPathTraceData.error_path})
            </Box>
          </Typography>
        </Box>
      )
    }
    headerJSX = (
      <Box>
        <FormControl
          className="bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '130px' }}
        >
          <InputLabel>{rLIB('Logic View Mode')}</InputLabel>
          <Select
            autoWidth={true}
            label={rLIB('Logic View Mode')}
            onChange={(event, value) => {
              if (event.target.value != null) {
                us_setSelectedLogicViewType(event.target.value as 'view' | 'edit' | 'trace')
              }
            }}
            value={us_selectedLogicViewType}
          >
            {objectToArray(logicViewModeOptions).map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
                disabled={option['disabled']}
              >
                {option['value']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {logicTraceResult}
      </Box>
    )
    return headerJSX
  }

  const rJSX_FormulaTypeDropdown = (): JSX.Element => {
    let dropdownJSX = <></>
    if (us_formula != null) {
      dropdownJSX = (
        <Box className="tw-mt-2">
          <Typography
            variant="h6"
            className="tw-font-bold tw-inline-block tw-mr-2"
          >
            {rLIB('Formula Type')}:
          </Typography>
          <FormControl
            className="bp_thin_select_input bp_thin_select_multiple_input"
            sx={{ minWidth: '130px' }}
          >
            <Select
              autoWidth={true}
              onChange={(event, value) => {
                let updateObject: TsInterface_UnspecifiedObject = {
                  formula_type: event.target.value,
                }
                saveFormulaChangeToDatabase(updateObject)
              }}
              value={us_formula.formula_type || ''}
            >
              {objectToArray(formulaTypeOptions).map((option: TsInterface_UnspecifiedObject) => (
                <MenuItem
                  key={option['key']}
                  value={option['key']}
                  disabled={option['disabled']}
                >
                  <Box
                    className="tw-inline-block"
                    sx={{ marginRight: '8px', color: themeVariables.error_main }}
                  >
                    <Icon icon={option['icon']} />
                  </Box>
                  {option['value']}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )
    }
    return dropdownJSX
  }

  const rJSX_LibraryFormulaDropdown = (): JSX.Element => {
    let dropdownJSX = <></>
    if (us_formula != null && us_formula.formula_type === 'library') {
      dropdownJSX = (
        <Box className="tw-mt-2">
          <Typography
            variant="h6"
            className="tw-font-bold tw-inline-block tw-mr-2"
          >
            {rLIB('Library Formula')}:
          </Typography>
          <FormControl
            className="bp_thin_select_input bp_thin_select_multiple_input"
            sx={{ minWidth: '130px' }}
          >
            <Select
              autoWidth={true}
              onChange={(event, value) => {
                let updateObject: TsInterface_UnspecifiedObject = {
                  associated_library_formula_key: event.target.value,
                }
                saveFormulaChangeToDatabase(updateObject)
              }}
              value={us_formula.associated_library_formula_key || ''}
            >
              {objectToArray(libraryFormulas).map((option: TsInterface_UnspecifiedObject) => (
                <MenuItem
                  key={option['key']}
                  value={option['key']}
                  disabled={option['disabled']}
                >
                  <Box
                    className="tw-inline-block"
                    sx={{ marginRight: '8px', color: themeVariables.error_main }}
                  >
                    <Icon icon={option['icon']} />
                  </Box>
                  {option['value']}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )
    }
    return dropdownJSX
  }

  const rJSX_CustomVariableSection = (): JSX.Element => {
    let sectionJSX = <></>
    if (getProp(us_formula, 'formula_type', null) === 'custom') {
      sectionJSX = (
        <Box>
          <Box className="tw-mt-8">
            <Typography
              variant="h5"
              className="tw-font-bold tw-inline-block"
            >
              {rLIB('Variables')}
            </Typography>
          </Box>
          <Box>{rJSX_CustomVariablesTable()}</Box>
        </Box>
      )
    }
    return sectionJSX
  }

  const rJSX_LibraryVariableSection = (): JSX.Element => {
    let sectionJSX = <></>
    if (getProp(us_formula, 'formula_type', null) === 'library' && getProp(us_formula, 'associated_library_formula_key', null) != null) {
      sectionJSX = (
        <Box>
          <Box className="tw-mt-8">
            <Typography
              variant="h5"
              className="tw-font-bold tw-inline-block"
            >
              {rLIB('Variables')}
            </Typography>
          </Box>
          <Box>{rJSX_LibraryVariablesTable()}</Box>
        </Box>
      )
    }
    return sectionJSX
  }

  const rJSX_CustomLogicPathSection = (): JSX.Element => {
    let sectionJSX = <></>
    if (getProp(us_formula, 'formula_type', null) === 'custom') {
      sectionJSX = (
        <Box>
          <Box className="tw-mt-8">
            <Typography
              variant="h5"
              className="tw-font-bold tw-inline-block"
            >
              {rLIB('Logic Path')}
            </Typography>
          </Box>
          <Card className="tw-mb-2 tw-p-4">
            {rJSX_LogicPathHeader()}
            <Divider className="tw-my-2" />
            {rJSX_LogicPathRender(
              getProp(us_formula, 'logic', {}),
              us_selectedLogicViewType,
              { variables: returnTestVariables(getProp(us_formula, 'variables', {})), calculationPath: us_logicPathTraceData },
              0,
              saveFormulaChangeToDatabase,
              uc_setUserInterface_ConfirmDialogDisplay,
              us_formula,
            )}
          </Card>
        </Box>
      )
    }
    return sectionJSX
  }

  const rJSX_ContentContainer = (): JSX.Element => {
    let containerJSX = <></>
    if (us_formula != null && us_formula.name != null) {
      containerJSX = (
        <Box>
          <Box className="tw-mt-4">
            <Typography
              variant="h5"
              className="tw-font-bold tw-inline-block"
            >
              {rLIB('Formula')}
            </Typography>
          </Box>
          <Card className="tw-p-4 tw-mb-2">
            {rJSX_LineItem(rLIB('Formula'), us_formula, 'name', false)}
            {rJSX_LineItem(rLIB('Description'), us_formula, 'description', true)}
            {rJSX_LineItem(rLIB('Created By'), us_formula, 'associated_creator_name', false)}
            <Box>{rJSX_FormulaTypeDropdown()}</Box>
            <Box>{rJSX_LibraryFormulaDropdown()}</Box>
          </Card>
          {rJSX_CustomVariableSection()}
          {rJSX_CustomLogicPathSection()}
          {rJSX_LibraryVariableSection()}
        </Box>
      )
    } else {
      containerJSX = (
        <Box className="tw-text-center">
          <CircularProgress />
        </Box>
      )
    }
    return containerJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={
          <>
            {rLIB('Formula')}
            {': ' + getProp(us_formula, 'name', '')}
          </>
        }
        pageKey={pageKey}
        content={
          <Box>
            {rJSX_BackButton()}
            {rJSX_ContentContainer()}
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

//======================================//
// MUI
import { createTheme } from '@mui/material/styles'

///////////////////////////////
// Variables
///////////////////////////////

// Google Maps
const googleMapsLightTheme: any[] = []
// const googleMapsDarkTheme = [
// { "featureType": "all",								"elementType": "geometry",				"stylers": [ { "color": "#242f3e" } ] },
// { "featureType": "all",								"elementType": "labels.text.fill",		"stylers": [ { "color": "#746855" } ] },
// { "featureType": "all"								"elementType": "labels.text.stroke",	"stylers": [ { "color": "#242f3e" } ] },
// { "featureType": "administrative.locality",			"elementType": "labels.text.fill",		"stylers": [ { "color": "#d59563" } ] },
// { "featureType": "poi",								"elementType": "labels.text.fill",		"stylers": [ { "color": "#d59563" } ] },
// { "featureType": "poi.park",							"elementType": "geometry",				"stylers": [ { "color": "#263c3f" } ] },
// { "featureType": "poi.park",							"elementType": "labels.text.fill",		"stylers": [ { "color": "#6b9a76" } ] },
// { "featureType": "road",								"elementType": "geometry",				"stylers": [ { "color": "#38414e" } ] },
// { "featureType": "road",								"elementType": "geometry.stroke",		"stylers": [ { "color": "#212a37" } ] },
// { "featureType": "road",								"elementType": "labels.text.fill",		"stylers": [ { "color": "#9ca5b3" } ] },
// { "featureType": "road.highway",						"elementType": "geometry",				"stylers": [ { "color": "#746855" } ] },
// { "featureType": "road.highway",						"elementType": "geometry.stroke",		"stylers": [ { "color": "#1f2835" } ] },
// { "featureType": "road.highway",						"elementType": "labels.text.fill",		"stylers": [ { "color": "#f3d19c" } ] },
// { "featureType": "transit",							"elementType": "geometry",				"stylers": [ { "color": "#2f3948" } ] },
// { "featureType": "transit.station",					"elementType": "labels.text.fill",		"stylers": [ { "color": "#d59563" } ] },
// { "featureType": "water",							"elementType": "geometry",				"stylers": [ { "color": "#17263c" } ] },
// { "featureType": "water",							"elementType": "labels.text.fill",		"stylers": [ { "color": "#515c6d" } ] },
// { "featureType": "water",							"elementType": "labels.text.stroke",	"stylers": [ { "color": "#17263c" } ]}
// ]

//=========================//
//   USED BY BOILERPLATE   //
//=========================//

// Default Theme

const palettes = {
  primary: {
    '50': '#e9ebf0',
    '100': '#c8ccda',
    '200': '#a6acc1',
    '300': '#858ca8',
    '400': '#6b7296',
    '500': '#545a86',
    '600': '#4d537d', //
    '700': '#444971',
    '800': '#3d4064',
    '900': '#30304b',
  },
  secondary: {
    '50': '#f4e6f8',
    '100': '#e2c1ee',
    '200': '#d097e4',
    '300': '#bd6dd8',
    '400': '#af4dcf',
    '500': '#a02fc6', //
    '600': '#912cbf',
    '700': '#7c27b7',
    '800': '#6a23af',
    '900': '#451da1',
  },
  accent: {
    '50': '#e2f8fc',
    '100': '#b7edf8',
    '200': '#8be2f3',
    '300': '#61d5ec',
    '400': '#49cce6',
    '500': '#41c3e0', //
    '600': '#3cb3cc',
    '700': '#359db2',
    '800': '#2f8999',
    '900': '#25666d',
  },
  blue: {
    '50': '#e3f3ff',
    '100': '#bae1ff',
    '200': '#8bceff',
    '300': '#55bbff',
    '400': '#1babff',
    '500': '#009bff',
    '600': '#008cff',
    '700': '#0079f7', //
    '800': '#0067e5',
    '900': '#1346c6',
    // "50":  "#e7eaf8",
    // "100": "#c3cbee",
    // "200": "#9aaae3",
    // "300": "#7088d7",
    // "400": "#4d6ecf",
    // "500": "#2054c6",
    // "600": "#184cbc",
    // "700": "#0442b0", //
    // "800": "#0037a4",
    // "900": "#00258f",
  },
  green: {
    '50': '#e5f5eb',
    '100': '#c1e6ce',
    '200': '#99d6af',
    '300': '#6dc690',
    '400': '#4aba78',
    '500': '#1dae61',
    '600': '#149f57',
    '700': '#068d4b', //
    '800': '#007c40',
    '900': '#005c2c',
  },
  yellow: {
    '50': '#fffde8',
    '100': '#fff9c5',
    '200': '#fff4a0',
    '300': '#fff07a',
    '400': '#fdeb5c',
    '500': '#fbe540',
    '600': '#fdd73f',
    '700': '#fabf37', //
    '800': '#f7a830',
    '900': '#f28023',
  },
  red: {
    '50': '#ffebef',
    '100': '#ffcdd4',
    '200': '#f79a9c',
    '300': '#f07276',
    '400': '#fc4f52',
    '500': '#ff3a37',
    '600': '#f33037',
    '700': '#e12431', //
    '800': '#d41b29',
    '900': '#c6011c',
  },
  teal: {
    '50': '#f0fdfa',
    '100': '#ccfbf1',
    '200': '#99f6e4',
    '300': '#5eead4',
    '400': '#2dd4bf',
    '500': '#14b8a6',
    '600': '#0d9488',
    '700': '#0f766e',
    '800': '#115e59',
    '900': '#134e4a',
  },
}

const themeVariables = {
  // Background
  mode: 'light',
  background_paper: '#fff',
  // background_default: 		"#F4F6F7",
  background_default: '#f1f1f1',
  background_highlight: '#eeeeee',
  contrast_text: '#000000',
  map_styles: googleMapsLightTheme,
  // Nav
  navGradientTopColor: 'rgb(212, 27, 41)',
  navGradientBottomColor: 'rgb(198, 1, 28)',
  // mode: "dark",
  // background_paper: 			"#121212",
  // background_default: 			"#090909",
  // background_highlight: 		"#121212",
  // contrast_text: 				"#ffffff",
  // map_styles: 					googleMapsDarkTheme,
  // Common
  black: '#000',
  white: '#fff',
  // Primary
  primary_dark: palettes['primary']['900'],
  primary_main: palettes['primary']['700'],
  primary_light: palettes['primary']['500'],
  primary_wash: palettes['primary']['100'],
  // Secondary
  secondary_dark: palettes['secondary']['800'],
  secondary_main: palettes['secondary']['600'],
  secondary_light: palettes['secondary']['400'],
  secondary_wash: palettes['secondary']['100'],
  // Accent
  accent_dark: palettes['accent']['800'],
  accent_main: palettes['accent']['700'],
  accent_light: palettes['accent']['600'],
  accent_wash: palettes['accent']['100'],
  // Info - Blue
  info_dark: palettes['blue']['900'],
  info_main: palettes['blue']['800'],
  info_light: palettes['blue']['600'],
  info_wash: palettes['blue']['100'],
  // Success - Green
  success_dark: palettes['green']['900'],
  success_main: palettes['green']['800'],
  success_light: palettes['green']['600'],
  success_wash: palettes['green']['100'],
  // Warning - Yellow
  warning_dark: palettes['yellow']['900'],
  warning_main: palettes['yellow']['800'],
  warning_light: palettes['yellow']['600'],
  warning_wash: palettes['yellow']['100'],
  // Error - Red
  error_dark: palettes['red']['900'],
  error_main: palettes['red']['800'],
  error_light: palettes['red']['600'],
  error_wash: palettes['red']['100'],
  // Other
  teal_700: palettes['teal']['700'],
  purple_700: '#8e44ad',
  // Gray
  gray_50: '#fafafa',
  gray_100: '#f5f5f5',
  gray_200: '#eeeeee',
  gray_300: '#e0e0e0',
  gray_400: '#bdbdbd',
  gray_500: '#9e9e9e',
  gray_600: '#757575',
  gray_700: '#616161',
  gray_800: '#424242',
  gray_900: '#212121',
  // Custom
  background_json: '#121212',
}

const themeOptions: any = {
  palette: {
    mode: themeVariables.mode,
    primary: {
      main: themeVariables.primary_main,
      light: themeVariables.primary_light,
      dark: themeVariables.primary_dark,
      contrastText: themeVariables.white,
    },
    secondary: {
      main: themeVariables.secondary_main,
      light: themeVariables.secondary_light,
      dark: themeVariables.secondary_dark,
      contrastText: themeVariables.white,
    },
    error: {
      main: themeVariables.error_main,
      light: themeVariables.error_light,
      dark: themeVariables.error_dark,
      contrastText: themeVariables.white,
    },
    warning: {
      main: themeVariables.warning_main,
      light: themeVariables.warning_light,
      dark: themeVariables.warning_dark,
      contrastText: themeVariables.white,
    },
    info: {
      main: themeVariables.info_main,
      light: themeVariables.info_light,
      dark: themeVariables.info_dark,
      contrastText: themeVariables.white,
    },
    success: {
      main: themeVariables.success_main,
      light: themeVariables.success_light,
      dark: themeVariables.success_dark,
      contrastText: themeVariables.white,
    },
    grey: {
      gray_50: themeVariables.gray_50,
      gray_100: themeVariables.gray_100,
      gray_200: themeVariables.gray_200,
      gray_300: themeVariables.gray_300,
      gray_400: themeVariables.gray_400,
      gray_500: themeVariables.gray_500,
      gray_600: themeVariables.gray_600,
      gray_700: themeVariables.gray_700,
      gray_800: themeVariables.gray_800,
      gray_900: themeVariables.gray_900,
      gray_A100: themeVariables.gray_100,
      gray_A200: themeVariables.gray_200,
      gray_A400: themeVariables.gray_400,
      gray_A700: themeVariables.gray_700,
    },
    background: {
      paper: themeVariables.background_paper,
      default: themeVariables.background_default,
    },
  },
  typography: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeightExtraLight: 200,
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const DefaultAppTheme = createTheme(themeOptions)
export { themeVariables }

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Stack } from '@mui/material/'
import { useContext, useEffect } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_TaskTemplates_Collection,
  DatabaseRef_TaskTemplates_Document,
} from 'rfbp_aux/services/database_endpoints/clients/architecture/task_templates'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp, keyFromString } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['TasksIndexPage']['key']

export const tableColumns_TaskTemplates: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.un_routerNavigation(ApplicationPages.TaskTemplateViewPage.url(rowData.key as string))
        }
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete Task Template'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure that you want to delete this Task Template?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        status: 'deleted',
                      }
                      DatabaseSetMergeDocument(DatabaseRef_TaskTemplates_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DAD) => {
                          resolve(res_DAD)
                        })
                        .catch((rej_DAD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                          reject(rej_DAD)
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  name: TableCellBasic('name', rLIB('Task Template Name'), 'name'),
}

export const tableSettings_TaskTemplates: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp',
  use_live_data: true,
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ items, setItems ] = 			useState<TsInterface_UnspecifiedObject>({})
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Tasks', false) as string
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  // Functions
  const tableDatabaseEndpoint_TaskTemplates = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}

    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_TaskTemplates_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // JSX Generation
  const rJSX_NewTaskButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        // size="small"
        variant="contained"
        color="success"
        className="tw-mb-2"
        startIcon={<Icon icon="circle-plus" />}
        onClick={() => {
          // TODO: Fill out
        }}
        disableElevation
      >
        {rLIB('New Task')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_NewDisabledTaskButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        disabled={true}
        variant="contained"
        color="success"
        className="tw-mb-2"
        startIcon={<Icon icon="circle-plus" />}
        onClick={() => {}}
        disableElevation
      >
        {rLIB('New Task')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_NewTaskTemplateButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        // size="small"
        variant="contained"
        color="success"
        className="tw-mb-2"
        startIcon={<Icon icon="circle-plus" />}
        onClick={() => {
          // TODO: Fill out
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: {
                  name: {
                    data_type: 'string',
                    input_type: 'text_basic',
                    key: 'name',
                    label: <>{rLIB('Name')}</>,
                    required: true,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    let taskTemplateKey = keyFromString(formSubmittedData.name)
                    getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject = {
                          name: formSubmittedData.name,
                          key: taskTemplateKey,
                          status: 'active',
                        }
                        DatabaseSetMergeDocument(DatabaseRef_TaskTemplates_Document(res_GCK.clientKey, taskTemplateKey), updateObject)
                          .then((res_DSMD) => {
                            resolve({ close_dialog: true })
                          })
                          .catch((rej_DSMD) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD.error)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK.error)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: <>{rLIB('Create New Task Template')}</>,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
        disableElevation
      >
        {rLIB('New Task Template')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_UnassignedTasksTabContent = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card>
        <Box className="tw-p-4"></Box>
      </Card>
    )
    return tabJSX
  }

  const rJSX_AssignedTasksTabContent = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card>
        <Box className="tw-p-4"></Box>
      </Card>
    )
    return tabJSX
  }

  const rJSX_InProgressTasksTabContent = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card>
        <Box className="tw-p-4"></Box>
      </Card>
    )
    return tabJSX
  }

  const rJSX_CompletedTasksTabContent = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card>
        <Box className="tw-p-4"></Box>
      </Card>
    )
    return tabJSX
  }

  const rJSX_CancelledTasksTabContent = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card>
        <Box className="tw-p-4"></Box>
      </Card>
    )
    return tabJSX
  }

  const rJSX_TaskTemplatesTabContent = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_TaskTemplates}
            tableDatabaseEndpoint={tableDatabaseEndpoint_TaskTemplates}
            tableSettings={tableSettings_TaskTemplates}
          />
        </Card>
      )
    }
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Tasks')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'unassigned',
                  tabHeader: (
                    <Stack direction="row">
                      <Icon
                        icon="circle-pause"
                        className="tw-mr-2 tw-inline-block"
                        sx={{ fontSize: '18px', color: themeVariables.gray_500 }}
                      />
                      {rLIB('Unassigned')}
                    </Stack>
                  ),
                  tabOnChange: () => {},
                  tabContent: rJSX_UnassignedTasksTabContent(),
                  tabButtons: [{ fullJSX: rJSX_NewTaskButton(false), minJSX: rJSX_NewTaskButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'assigned',
                  tabHeader: (
                    <Stack direction="row">
                      <Icon
                        icon="circle-user"
                        className="tw-mr-2 tw-inline-block"
                        sx={{ fontSize: '18px', color: themeVariables.info_main }}
                      />
                      {rLIB('Assigned')}
                    </Stack>
                  ),
                  tabOnChange: () => {},
                  tabContent: rJSX_AssignedTasksTabContent(),
                  tabButtons: [{ fullJSX: rJSX_NewDisabledTaskButton(false), minJSX: rJSX_NewDisabledTaskButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'in_progress',
                  tabHeader: (
                    <Stack direction="row">
                      <Icon
                        icon="circle-play"
                        className="tw-mr-2 tw-inline-block"
                        sx={{ fontSize: '18px', color: themeVariables.warning_main }}
                      />
                      {rLIB('In Progress')}
                    </Stack>
                  ),
                  tabOnChange: () => {},
                  tabContent: rJSX_InProgressTasksTabContent(),
                  tabButtons: [{ fullJSX: rJSX_NewDisabledTaskButton(false), minJSX: rJSX_NewDisabledTaskButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'completed',
                  tabHeader: (
                    <Stack direction="row">
                      <Icon
                        icon="circle-check"
                        className="tw-mr-2 tw-inline-block"
                        sx={{ fontSize: '18px', color: themeVariables.success_main }}
                      />
                      {rLIB('Completed')}
                    </Stack>
                  ),
                  tabOnChange: () => {},
                  tabContent: rJSX_CompletedTasksTabContent(),
                  tabButtons: [{ fullJSX: rJSX_NewDisabledTaskButton(false), minJSX: rJSX_NewDisabledTaskButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'cancelled',
                  tabHeader: (
                    <Stack direction="row">
                      <Icon
                        icon="circle-x"
                        className="tw-mr-2 tw-inline-block"
                        sx={{ fontSize: '18px', color: themeVariables.error_main }}
                      />
                      {rLIB('Cancelled')}
                    </Stack>
                  ),
                  tabOnChange: () => {},
                  tabContent: rJSX_CancelledTasksTabContent(),
                  tabButtons: [{ fullJSX: rJSX_NewDisabledTaskButton(false), minJSX: rJSX_NewDisabledTaskButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'templates',
                  tabHeader: (
                    <Stack direction="row">
                      <Icon
                        icon="compass-drafting"
                        className="tw-mr-2 tw-inline-block"
                        sx={{ fontSize: '18px' }}
                      />
                      {rLIB('Task Templates')}
                    </Stack>
                  ),
                  tabOnChange: () => {},
                  tabContent: rJSX_TaskTemplatesTabContent(),
                  tabButtons: [{ fullJSX: rJSX_NewTaskTemplateButton(false), minJSX: rJSX_NewTaskTemplateButton(true), sizeCutoff: 0 }],
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.TasksIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Tasks', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

		- Lock / Unlock Button (just disabled inputs)
		- Map on geocode
		- Associations and Metadata
		- PDF Download needs some cleanup
		- Edit form inputs (change requirement, display, options on Multiple choice, etc)
			- basically boolean to override form input
			- multiple choice options pulling from list somewhere else
		- Data list page (just pull from database and display)

		- Staging and Prod (probably not)

		MOVE CALCULATED FIELDS FROM DATA BUCKET TO CLASS???

		RECONCILIATION

			FORM FIELDS
				- Email Send Time and Timezone
				- Maybe show reconciliation data and just fade it 50% if the toggle is not true?
			POST DATA PROCESSING PROCESS
				- Post Data Processing Function
					- Should be triggered on edit of data
					- Process
						- Get Data Bucket for data item since it has "associated_reconciliation_class_mapping" object
						- Also it has the "associated_reconciliation_procedure" which determines how to proceed
						- Loop through the associated_reconciliation_class_mapping for all field key options for unique_identifier
						- Query collection for each unique_identifier to look for potential matches
						- ON data item update object, set the following update object

						updateObject = {
							reconciliation_data: {
								associated_linked_data_item_keys: {
									$key: {
										associated_data_bucket_key: $key,
										associated_class_key: $key,
										key: $key,
										start_meter: value
										stop_meter: value
										unique_identifier: value
										volume: value
										timestamp: value (primary???)
									}
								}
							}
						}

						- Update Object for each linked data item in update Array for Batch Update

						- GOAL - All data items should have all the reconciliation data needed so that emails are a simple query, loop through, and send WITHOUT additional data queries

						- NOTE - need to make sure that the data item is not matched with itself - which should always be a match

						- QUESTION - how to handle change to unique identifier or other fields... need to go back and clean up old link data
						- QUESTION - how to handle duplicates - might need to have options like date needing to be within 1 day, etc
							- Might show all but then have time discrepancies listed in email

			CRON JOB
				- Get data items for the bucket with reconciliation on
				- Post data processing should already be done
				- Loop through to generate email data
				- Send Email


	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material/'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { returnPDFDownloadDocument } from 'app/models/qr_codes/qr_code_file_generation'
import { returnTableColumnsFromDatabaseFormDataFormat } from 'app/services/forms/form_services'
import { MuiChipsInput } from 'mui-chips-input'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { useCSVReader } from 'react-papaparse'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { getBaseURL } from 'rfbp_aux/functions/get_base_url'
import {
  DatabaseRef_ActiveClassCustomForms_Query,
  DatabaseRef_ActiveClasses_Query,
  DatabaseRef_ClassFields_Collection,
  DatabaseRef_Class_Document,
} from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import {
  DatabaseRef_DataBucketsActiveCalculations_Query,
  DatabaseRef_DataBuckets_Document,
  DatabaseRef_DataBucket_CalculatedField_Document,
  DatabaseRef_DataBucket_ImportSessions_Collection,
  DatabaseRef_DataBucket_ImportSession_Document,
} from 'rfbp_aux/services/database_endpoints/clients/architecture/data_buckets'
import {
  DatabaseRef_ActiveDataBucketGroups_Query,
  DatabaseRef_DataBucketGroups_Document,
} from 'rfbp_aux/services/database_endpoints/clients/architecture/data_bucket_groups'
import { DatabaseRef_ActiveCalculatedFields_Query } from 'rfbp_aux/services/database_endpoints/clients/data_management/calculated_fields'
import { DatabaseRef_ActiveFormulas_Query } from 'rfbp_aux/services/database_endpoints/clients/data_management/formulas'
import { DatabaseRef_ActiveLookupTables_Query } from 'rfbp_aux/services/database_endpoints/clients/data_management/lookup_tables'
import { DatabaseRef_ActiveReportTemplates_Query } from 'rfbp_aux/services/database_endpoints/clients/data_management/report_templates'
import { DatabaseRef_ActiveDirectory_Query } from 'rfbp_aux/services/database_endpoints/clients/directory_data/all'
import {
  DatabaseRef_ActiveData_SingleDataPoint_Query,
  DatabaseRef_Data_Collection,
  DatabaseRef_Data_Document,
} from 'rfbp_aux/services/database_endpoints/clients/transactional_data/data'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInput,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
  TsType_InputChangeCallback,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TableCellTimestamp,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_Snackbar,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseStagedBatchUpdate,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { downloadCSV, dynamicSort, generateRandomString, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { isValidEmail } from '../export_scheduled_emails/components/recurring_email_form_dialog'
import { libraryFormulas } from '../refine_formulas/services/calculation_functions'
import { tableColumns_CalculatedFieldVariables, tableSettings_CalculatedFieldVariables } from './tables/calculated_fields'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['DataBucketsViewPage']['key']

// Table
const tableSettings_QRCodeData: TsInterface_TableDatabaseSettings = {
  rows_per_page: 20,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp_primary_query',
  use_live_data: true,
  collapsible_columns: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 240px)',
}

const tableColumns_ImportSessions: TsInterface_TableColumns = {
  timestamp: TableCellTimestamp('timestamp', rLIB('Timestamp'), 'timestamp', 'D MMM YYYY h:mm a', true),
  import_session_type: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Data Source')
      },
      header_sort_by: 'import_session_type',
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        switch (rowData.import_session_type) {
          case 'csv_import':
            cellJSX = (
              <Chip
                variant="outlined"
                color="success"
                label={
                  <Box>
                    <Icon
                      icon="file-csv"
                      className="tw-mr-2"
                    />
                    {rLIB('CSV Import')}
                  </Box>
                }
              />
            )
            break
          case 'email_file_attachment':
            cellJSX = (
              <Chip
                variant="outlined"
                color="info"
                label={
                  <Box>
                    <Icon
                      icon="envelope"
                      className="tw-mr-2"
                    />
                    {rLIB('Emailed CSV')}
                  </Box>
                }
              />
            )
            break
          default:
            cellJSX = (
              <Chip
                variant="outlined"
                color="primary"
                label={
                  <Box>
                    <Icon
                      icon="file-import"
                      className="tw-mr-2"
                    />
                    {rowData.import_session_type}
                  </Box>
                }
              />
            )
            break
        }

        return cellJSX
      },
    },
  },
  success_count: TableCellBasic('success_count', rLIB('Successful Imports'), 'success_count'),
  failure_count: TableCellBasic('failure_count', rLIB('Failed Imports'), 'failure_count'),
}

const tableSettings_ImportSessions: TsInterface_TableDatabaseSettings = {
  rows_per_page: 20,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp',
  use_live_data: true,
  collapsible_columns: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 240px)',
}

const formInputs_TriggeredEvents: TsInterface_FormInputs = {
  email_on_form_submission_to: {
    data_type: 'string',
    key: 'email_on_form_submission_to',
    label: rLIB('To'),
    input_type: 'custom_form_input_jsx',
    required: true,
    renderCustomFormInput: (
      formInput: TsInterface_FormInput,
      formInputs: TsInterface_FormInputs,
      formData: TsInterface_FormData,
      formInputChange: TsType_InputChangeCallback,
      formSettings: TsInterface_FormSettings,
      formAdditionalData: TsInterface_FormAdditionalData,
      formHooks: TsInterface_FormHooksObject,
    ) => {
      let inputJSX = (
        <Box
          className=""
          sx={{ marginBottom: '16px' }}
        >
          <MuiChipsInput
            label={rLIB('Email Recipients')}
            placeholder={rLIB('Add Emails', false) as string}
            fullWidth={true}
            value={getProp(formData, formInput.key, [])}
            onChange={(chipsArray: string[]) => {
              formInputChange(formInput.key, chipsArray, true)
            }}
            disabled={false}
            disableEdition={true}
            disableDeleteOnBackspace={true}
            onKeyDown={(event: any) => {
              // Nothing
            }}
            addOnBlur={true}
            renderChip={(Component, key, props) => {
              // eslint-disable-next-line
              if (isValidEmail(props.label) === true) {
                return (
                  <Component
                    {...props}
                    key={key}
                  />
                )
              } else {
                return (
                  <Component
                    {...props}
                    key={key}
                    color="error"
                  />
                )
              }
            }}
          />
        </Box>
      )
      return inputJSX
    },
  },
  email_on_form_submission_subject: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email_on_form_submission_subject',
    label: rLIB('Subject'),
    required: true,
  },
  email_on_form_submission_body: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'email_on_form_submission_body',
    label: rLIB('Body'),
    required: true,
  },
  email_on_form_submission_attachment: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'email_on_form_submission_attachment',
    label: rLIB('Attachment'),
    required: true,
    options: [],
  },
  email_on_form_submission_attachment_name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email_on_form_submission_attachment_name',
    label: rLIB('Attachment Name'),
    required: true,
  },
}

///////////////////////////////
// Functions
///////////////////////////////

function isValidDate(dateString: string) {
  const parsedDate = Date.parse(dateString)
  return !isNaN(parsedDate) && !isNaN(new Date(parsedDate).getDate())
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_selectedClassCustomForms, us_setSelectedClassCustomForms] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeDataBucketGroups, us_setActiveDataBucketGroups] = useState<TsInterface_UnspecifiedObject>({})
  const [us_calculatedFieldsList, us_setCalculatedFieldsList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeLookupTables, us_setActiveLookupTables] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeClasses, us_setActiveClasses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_dataBucket, us_setDataBucket] = useState<TsInterface_UnspecifiedObject>({})
  const [us_parsingData, us_setParsingData] = useState<boolean>(false)
  const [us_selectedClass, us_setSelectedClass] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedClassFields, us_setSelectedClassFields] = useState<TsInterface_UnspecifiedObject>({})
  const [us_singleQRCodeDataPoint, us_setSingleQRCodeDataPoint] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeFormulas, us_setActiveFormulas] = useState<TsInterface_UnspecifiedObject>({})
  const [us_tableColumns_QRCodeData, us_setTableColumns_QRCodeData] = useState<TsInterface_TableColumns>({})
  const [us_activeCalculatedFields, us_setActiveCalculatedFields] = useState<TsInterface_TableColumns>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { CSVReader } = useCSVReader()
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_SnackbarDisplay } = useContext(Context_UserInterface_Snackbar)
  const [us_activeReportTemplates, us_setActiveReportTemplates] = useState<TsInterface_UnspecifiedObject>({})
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    if (us_dataBucket != null && us_dataBucket.name != null) {
      document.title = (rLIB('Data Buckets', false) as string) + ' - ' + us_dataBucket.name
    } else {
      document.title = rLIB('Data Buckets', false) as string
    }
  }, [us_dataBucket])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveReportTemplates(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveReportTemplates_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setDataBucket(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_DataBuckets_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let activeTransactionalClasses: TsInterface_UnspecifiedObject = {}
      for (let loopClassKey in newData) {
        let loopClass = newData[loopClassKey]
        if (loopClass != null && loopClass.class_type === 'transactional_data') {
          activeTransactionalClasses[loopClassKey] = loopClass
        }
      }
      us_setActiveClasses(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveClasses_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSelectedClass(newData)
      ur_forceRerender()
    }
    if (us_dataBucket != null && us_dataBucket.associated_class_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Class_Document(res_GCK.clientKey, us_dataBucket.associated_class_key), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_dataBucket])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setCalculatedFieldsList(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveCalculatedFields_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSelectedClassFields(newData)
      ur_forceRerender()
    }
    if (us_dataBucket != null && us_dataBucket.associated_class_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ClassFields_Collection(res_GCK.clientKey, us_dataBucket.associated_class_key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_dataBucket])

  useEffect(() => {
    us_setTableColumns_QRCodeData(returnTableColumnsFromDatabaseFormDataFormat(us_selectedClassFields, {}, {}, uc_setUserInterface_SnackbarDisplay, true))
  }, [uc_setUserInterface_SnackbarDisplay, us_selectedClassFields])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSingleQRCodeDataPoint(newData)
      // ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveData_SingleDataPoint_Query(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveCalculatedFields(newData)
      // ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_DataBucketsActiveCalculations_Query(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveFormulas(newData)
      // ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveFormulas_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveLookupTables(newData)
      // ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveLookupTables_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveDataBucketGroups(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveDataBucketGroups_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSelectedClassCustomForms(newData)
    }
    if (us_dataBucket != null && us_dataBucket.associated_class_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ActiveClassCustomForms_Query(res_GCK.clientKey, us_dataBucket.associated_class_key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_dataBucket])

  // Functions
  const tableDatabaseEndpoint_QRCodeData = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'status', comparator: '==', value: 'active' },
      { prop: 'associated_data_bucket_key', comparator: '==', value: itemKey },
    ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_primary_query', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(DatabaseRef_Data_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableDatabaseEndpoint_DataBucketImportSessions = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_DataBucket_ImportSessions_Collection(uc_RootData_ClientKey as string, itemKey),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const downloadImportTemplate = () => {
    let sortedFormFields = objectToArray(us_selectedClassFields).sort(dynamicSort('order', null))
    let downloadData = ['Submitted Date']
    for (let loopFieldIndex in sortedFormFields) {
      let loopField = sortedFormFields[loopFieldIndex]
      if (loopField.status === 'active') {
        if (loopField.form_type === 'checkboxes') {
          let sortedOrderArray = objectToArray(loopField.options).sort(dynamicSort('order', null))
          for (let loopOptionIndex in sortedOrderArray) {
            let loopOption = sortedOrderArray[loopOptionIndex]
            downloadData.push(loopField.name + ' - ' + loopOption.value)
          }
        } else if (
          loopField.form_type !== 'display'
          // TODO: photo, signature, etc
        ) {
          downloadData.push(loopField.name)
        }
      }
    }
    downloadCSV(us_dataBucket.name + ' - import', [downloadData])
  }

  const handleFileSelection = (data: any): void => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        // Instantiate variables
        let importSessionKey = new Date().getTime() + '_' + generateRandomString(6, null)
        let unformattedData: TsInterface_UnspecifiedObject[] = []
        let uploadedFileColumns: string[] = []
        us_setParsingData(true)
        // let dataMapping: string[] = []
        // Loop through and format data
        for (let cellIndex = 0; cellIndex < data[0].length; cellIndex++) {
          uploadedFileColumns[cellIndex] = data[0][cellIndex]
        }
        for (let rowIndex = 1; rowIndex < data.length; rowIndex++) {
          if (data != null && data[rowIndex] != null) {
            let loopRow = data[rowIndex]
            let unformattedDataRow: TsInterface_UnspecifiedObject = {}
            for (let cellIndex = 0; cellIndex < loopRow.length; cellIndex++) {
              if (loopRow[cellIndex] != null && uploadedFileColumns[cellIndex] != null && uploadedFileColumns[cellIndex] !== '') {
                unformattedDataRow[uploadedFileColumns[cellIndex]] = loopRow[cellIndex]
              }
            }
            unformattedData.push(unformattedDataRow)
          }
        }
        // Parse Into actual upload
        let formattedDataArray: TsInterface_UnspecifiedObject[] = []
        let loopCount = 0
        let successCount = 0
        let failureCount = 0
        for (let loopRow in unformattedData) {
          let loopRowData = unformattedData[loopRow]
          if (loopRowData != null && loopRowData['Submitted Date'] != null && isValidDate(loopRowData['Submitted Date']) === true) {
            loopCount++
            successCount++
            let formattedRowData: TsInterface_UnspecifiedObject = {
              associated_import_session_key: importSessionKey,
              associated_data_origination_type: 'csv_import',
              associated_class_key: us_dataBucket.associated_class_key,
              associated_data_bucket_key: itemKey,
              associated_data_bucket_name: getProp(us_dataBucket, 'name', ''),
              confirmation_number: null,
              data: {},
              original_data: {},
              directory_associations: getProp(us_dataBucket, 'directory_associations', {}),
              key: importSessionKey + '_' + loopCount.toString(),
              metadata: {},
              status: 'active',
              timestamp_submitted: new Date(loopRowData['Submitted Date']),
              timestamp_primary_query: new Date(loopRowData['Submitted Date']),
            }
            for (let loopFieldIndex in us_selectedClassFields) {
              let loopField = us_selectedClassFields[loopFieldIndex]
              if (loopField.form_type === 'checkboxes' && loopField.key != null) {
                formattedRowData['data'][loopField.key] = {}
                formattedRowData['original_data'][loopField.key] = {}
                for (let loopOptionKey in loopField['options']) {
                  let loopOption = loopField['options'][loopOptionKey]
                  if (loopRowData[loopField.name + ' - ' + loopOption.value] != null && loopRowData[loopField.name + ' - ' + loopOption.value] !== '') {
                    formattedRowData['data'][loopField.key][loopOption.value] = loopRowData[loopField.name + ' - ' + loopOption.value]
                    formattedRowData['original_data'][loopField.key][loopOption.value] = loopRowData[loopField.name + ' - ' + loopOption.value]
                  }
                }
              } else if (
                loopField.form_type !== 'display'
                // TODO: photo, signature, etc
              ) {
                if (loopField != null && loopField.name != null && loopField.key != null && loopRowData[loopField.name] != null) {
                  formattedRowData['data'][loopField.key] = loopRowData[loopField.name]
                  formattedRowData['original_data'][loopField.key] = loopRowData[loopField.name]
                }
              }
            }
            formattedDataArray.push(formattedRowData)
          } else {
            failureCount++
          }
        }
        // Generate Batch Update
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
        for (let loopIndex in formattedDataArray) {
          let loopRow = formattedDataArray[loopIndex]
          if (loopRow != null && getProp(loopRow, 'key', null) != null) {
            updateArray.push({
              type: 'setMerge',
              ref: DatabaseRef_Data_Document(res_GCK.clientKey, getProp(loopRow, 'key', null)),
              data: loopRow,
            })
          }
        }
        // Add Import Session Data
        updateArray.push({
          type: 'setMerge',
          ref: DatabaseRef_DataBucket_ImportSession_Document(res_GCK.clientKey, itemKey, importSessionKey),
          data: {
            associated_class_key: getProp(us_dataBucket, 'associated_class_key', null),
            associated_data_bucket_key: itemKey,
            associated_data_bucket_name: getProp(us_dataBucket, 'name', ''),
            client_key: res_GCK.clientKey,
            failure_count: failureCount,
            key: importSessionKey,
            success_count: successCount,
            timestamp: new Date(),
            import_session_type: 'csv_import',
          },
        })
        DatabaseStagedBatchUpdate(updateArray)
          .then((res_DSBU) => {
            us_setParsingData(false)
            uc_setUserInterface_AlertDialogDisplay({
              display: true,
              alert: {
                color: 'info',
                header: rLIB('Data Import Complete') as JSX.Element,
                icon: <Icon icon="badge-check" />,
                text: (
                  <>
                    {loopCount} {rLIB('Successful Imports')}
                  </>
                ),
              },
            })
          })
          .catch((rej_DSBU) => {
            us_setParsingData(false)
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSBU.error })
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const setMergeDataBucketRootData = (newData: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_DataBuckets_Document(res_GCK.clientKey, itemKey), newData)
            .then((res_DSMD) => {
              resolve(res_DSMD)
            })
            .catch((rej_DSMD) => {
              reject(rej_DSMD)
            })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
        })
    })
  }

  const addCalculatedField = () => {
    let calculatedFieldOptions = []
    for (let loopCalculatedFieldKey in us_calculatedFieldsList) {
      let loopCalculatedField = us_calculatedFieldsList[loopCalculatedFieldKey]
      if (loopCalculatedField != null && loopCalculatedField.status === 'active') {
        calculatedFieldOptions.push({
          key: loopCalculatedFieldKey,
          value: loopCalculatedField.name,
        })
      }
    }
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            // name: {
            //   data_type: 'string',
            //   input_type: 'text_basic',
            //   key: 'name',
            //   label: rLIB('Calculated Field Name'),
            //   required: true,
            // },
            associated_calculated_field_key: {
              data_type: 'string',
              input_type: 'multiple_choice_select',
              key: 'associated_calculated_field_key',
              label: rLIB('Calculated Field'),
              options: calculatedFieldOptions,
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let updateObject = { ...formSubmittedData }
              updateObject['status'] = 'active'
              updateObject['key'] = formSubmittedData['associated_calculated_field_key']
              updateObject['name'] = getProp(us_calculatedFieldsList, formSubmittedData['associated_calculated_field_key'] + '.name', '')
              updateObject['associated_calculated_field_name'] = getProp(
                us_calculatedFieldsList,
                formSubmittedData['associated_calculated_field_key'] + '.name',
                '',
              )
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_DataBucket_CalculatedField_Document(res_GCK.clientKey, itemKey, updateObject['key']), updateObject)
                    .then((res_DAD) => {
                      resolve(res_DAD)
                    })
                    .catch((rej_DAD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                      reject(rej_DAD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('New Calculated Field')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const editCalculatedFieldName = (calculatedField: TsInterface_UnspecifiedObject, calculatedFieldKey: string) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: calculatedField,
          formInputs: {
            name: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'name',
              label: rLIB('Calculated Field Name'),
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = { ...formSubmittedData }
                  DatabaseSetMergeDocument(DatabaseRef_DataBucket_CalculatedField_Document(res_GCK.clientKey, itemKey, calculatedFieldKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('New Calculated Field')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const editCalculatedFieldAssociatedFormula = (calculatedField: TsInterface_UnspecifiedObject, calculatedFieldKey: string) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: calculatedField,
          formInputs: {
            associated_formula_key: {
              data_type: 'string',
              input_type: 'multiple_choice_select',
              key: 'associated_formula_key',
              label: rLIB('Formula'),
              required: true,
              options: objectToArray(us_activeFormulas)
                .sort(dynamicSort('name', 'asc'))
                .map((loopFormula) => {
                  return {
                    key: loopFormula.key,
                    value: loopFormula.name,
                  }
                }),
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = { ...formSubmittedData }
                  updateObject['associated_formula_name'] = getProp(us_activeFormulas, formSubmittedData['associated_formula_key'] + '.name', '')
                  DatabaseSetMergeDocument(DatabaseRef_DataBucket_CalculatedField_Document(res_GCK.clientKey, itemKey, calculatedFieldKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('New Calculated Field')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_BackButton = (shrinkButton: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrinkButton === true) {
      buttonJSX = (
        <Tooltip
          title={rLIB('Back to all Data Buckets')}
          placement="top"
        >
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => {
              un_routerNavigation(ApplicationPages.DataBucketsListPage.url())
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button tw-mb-2"
          >
            <Icon icon="chevron-left" />
          </Button>
        </Tooltip>
      )
    } else {
      buttonJSX = (
        <Button
          color="inherit"
          variant="outlined"
          onClick={() => {
            un_routerNavigation(ApplicationPages.DataBucketsListPage.url())
          }}
          disableElevation
          startIcon={<Icon icon="chevron-left" />}
          className="tw-mr-2 tw-mb-2"
        >
          {rLIB('Back to all Data Buckets')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_NewCalculatedFieldButton = (shrinkButton: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrinkButton === true) {
      buttonJSX = (
        <Tooltip
          title={rLIB('New Calculated Field')}
          placement="top"
        >
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              addCalculatedField()
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button tw-mb-2"
          >
            <Icon icon="circle-plus" />
          </Button>
        </Tooltip>
      )
    } else {
      buttonJSX = (
        <Button
          color="success"
          variant="contained"
          onClick={() => {
            addCalculatedField()
          }}
          disableElevation
          startIcon={<Icon icon="circle-plus" />}
          className="tw-mr-2 tw-mb-2"
        >
          {rLIB('Add Calculated Field')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_DownloadQRCodePDFButtonProper = (disabled: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        color="error"
        variant="contained"
        disableElevation
        disabled={disabled}
        startIcon={<Icon icon="qrcode" />}
        className="tw-mr-2"
      >
        {rLIB('Download QR Code PDF')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_DownloadQRCodePDFButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_dataBucket != null && us_dataBucket.available_for_use === true) {
      buttonJSX = (
        <PDFDownloadLink
          document={returnPDFDownloadDocument(itemKey, us_dataBucket.name)}
          fileName={'Secure Delivery QR Code - ' + us_dataBucket.name + '.pdf'}
        >
          {({ blob, url, loading, error }) => (loading ? rJSX_DownloadQRCodePDFButtonProper(true) : rJSX_DownloadQRCodePDFButtonProper(false))}
        </PDFDownloadLink>
      )
    } else {
      buttonJSX = rJSX_DownloadQRCodePDFButtonProper(true)
    }
    return buttonJSX
  }

  const rJSX_GeofenceSettings = (): JSX.Element => {
    let geofenceSettings = <></>
    if (us_dataBucket.require_geofence === true) {
      geofenceSettings = (
        <Box>
          <FormControl className="tw-mr-2">
            <TextField
              color="primary"
              sx={{ paddding: '2px' }}
              // className="bp_thin_text_input tw-m-0"
              value={getProp(us_dataBucket, 'location_latitude', '')}
              margin="normal"
              type="number"
              className="bp_thin_text_input"
              placeholder={'Latitude'}
              // @ts-expect-error
              onWheel={(event) => event.target.blur()}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  setMergeDataBucketRootData({ location_latitude: event.target.value })
                }
              }}
              variant="outlined"
            />
          </FormControl>
          <FormControl className="tw-mr-2">
            <TextField
              color="primary"
              sx={{ paddding: '2px' }}
              // className="bp_thin_text_input tw-m-0"
              value={getProp(us_dataBucket, 'location_longitude', '')}
              margin="normal"
              type="number"
              className="bp_thin_text_input"
              placeholder={'Longitude'}
              // @ts-expect-error
              onWheel={(event) => event.target.blur()}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  setMergeDataBucketRootData({ location_longitude: event.target.value })
                }
              }}
              variant="outlined"
            />
          </FormControl>
        </Box>
      )
    }
    return geofenceSettings
  }

  const rJSX_InactiveBanner = (): JSX.Element => {
    let bannerJSX = <></>
    if (us_dataBucket.available_for_use !== true) {
      bannerJSX = (
        <Box
          className="tw-p-1 tw-mb-2 tw-text-center"
          sx={{ width: '100%', background: themeVariables.error_main }}
        >
          <Typography
            variant="h6"
            className="tw-font-bold tw-text-white"
          >
            {rLIB('Data Bucket Not Active')}
          </Typography>
        </Box>
      )
    }
    return bannerJSX
  }

  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <></>
    if (us_dataBucket != null && us_dataBucket.name != null) {
      pageHeader = (
        <Box>
          <Box className="tw-inline-block tw-mr-1">{rLIB('Data Bucket')}:</Box>
          <Box className="tw-inline-block tw-opacity-50">{us_dataBucket.name}</Box>
        </Box>
      )
    } else {
      pageHeader = rLIB('Data Bucket') as JSX.Element
    }
    return pageHeader
  }

  const rJSX_AssociationCategorySelection = (associationClass: TsInterface_UnspecifiedObject): JSX.Element => {
    let associationClassSelectionJSX = <></>
    let editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-cursor-pointer tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
        tooltip={
          <>
            {rLIB('Edit')} {getProp(associationClass, 'name', rLIB('Association'))}
          </>
        }
        tooltipPlacement="right"
        sx={{
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        onClick={() => {
          DatabaseGetCollection(DatabaseRef_ActiveDirectory_Query(uc_RootData_ClientKey as string, associationClass.key))
            .then((res_DGC) => {
              let associationsObject: TsInterface_UnspecifiedObject = res_DGC.data
              let associationsArray: TsInterface_UnspecifiedObject[] = []
              for (let loopAssociationKey in res_DGC.data) {
                let loopAssociation = res_DGC.data[loopAssociationKey]
                associationsArray.push({
                  key: loopAssociationKey,
                  value: loopAssociation.name,
                })
              }
              uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: {},
                    formInputs: {
                      association_key: {
                        data_type: 'string',
                        input_type: 'multiple_choice_select',
                        key: 'association_key',
                        label: <>{getProp(associationClass, 'name', rLIB('Association'))}</>,
                        options: associationsArray,
                        required: true,
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              directory_associations: {
                                [associationClass.key]: {
                                  name: associationsObject[formSubmittedData.association_key]['name'],
                                  key: formSubmittedData.association_key,
                                },
                              },
                            }
                            DatabaseSetMergeDocument(DatabaseRef_DataBuckets_Document(res_GCK.clientKey, itemKey), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Edit')} {getProp(associationClass, 'name', rLIB('Association'))}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            })
            .catch((rej_DGC) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
            })
        }}
      />
    )
    let deleteIconJSX = (
      <Icon
        icon="trash"
        className="tw-cursor-pointer tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
        tooltip={
          <>
            {rLIB('Remove')} {getProp(associationClass, 'name', rLIB('Association'))}
          </>
        }
        tooltipPlacement="right"
        sx={{
          '&:hover': {
            color: themeVariables.error_main,
          },
        }}
        onClick={() => {
          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
            .then((res_GCK) => {
              let updateObject = {
                directory_associations: {
                  [associationClass.key]: {},
                },
              }
              DatabaseSetMergeDocument(DatabaseRef_DataBuckets_Document(res_GCK.clientKey, itemKey), updateObject)
                .then((res_DSMD) => {
                  ur_forceRerender()
                })
                .catch((rej_DSMD) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                })
            })
            .catch((rej_GCK) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            })
        }}
      />
    )
    if (us_dataBucket != null && us_dataBucket.directory_associations != null && us_dataBucket.directory_associations[associationClass.key] != null) {
      if (us_dataBucket.directory_associations[associationClass.key]['name'] != null) {
        associationClassSelectionJSX = (
          <Box>
            <Typography
              variant="subtitle1"
              className="tw-inline-block"
            >
              {us_dataBucket.directory_associations[associationClass.key]['name']}
            </Typography>
            {editIconJSX}
            {deleteIconJSX}
          </Box>
        )
      } else if (us_dataBucket.directory_associations[associationClass.key]['key'] != null) {
        associationClassSelectionJSX = (
          <Box>
            <Typography
              variant="subtitle1"
              className="tw-inline-block"
            >
              {us_dataBucket.directory_associations[associationClass.key]['key']}
            </Typography>
            {editIconJSX}
            {deleteIconJSX}
          </Box>
        )
      } else {
        associationClassSelectionJSX = (
          <Box>
            <Typography
              variant="subtitle1"
              className="tw-opacity-30 tw-italic tw-inline-block"
            >
              {rLIB('Not Set')}
            </Typography>
            {editIconJSX}
            {/* { deleteIconJSX } */}
          </Box>
        )
      }
    } else {
      associationClassSelectionJSX = (
        <Box>
          <Typography
            variant="subtitle1"
            className="tw-opacity-30 tw-italic tw-inline-block"
          >
            {rLIB('Not Set')}
          </Typography>
          {editIconJSX}
        </Box>
      )
    }
    return associationClassSelectionJSX
  }

  const rJSX_TimezoneSettings = (): JSX.Element => {
    let timezoneSettings = <></>
    timezoneSettings = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Data Bucket Timezone')}
        </Typography>
        <Box className="tw-ml-4">{rJSX_TimezoneDropdown()}</Box>
      </Box>
    )
    return timezoneSettings
  }

  const rJSX_AssociationSelection = (): JSX.Element => {
    let associationSelectionJSX = <></>
    let relatedClassKeys: TsInterface_UnspecifiedObject = {}
    for (let loopClassFieldKey in getProp(us_selectedClass, 'associated_related_class_keys', {})) {
      if (
        us_selectedClass != null &&
        us_selectedClass['associated_related_class_keys'] != null &&
        us_selectedClass['associated_related_class_keys'][loopClassFieldKey] === true &&
        us_activeClasses != null &&
        us_activeClasses[loopClassFieldKey] != null
      ) {
        relatedClassKeys[loopClassFieldKey] = us_activeClasses[loopClassFieldKey]
      }
    }
    // Selection JSX
    associationSelectionJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Associations')}
        </Typography>
        <Box className="tw-ml-4">
          <TableContainer>
            <Table size="small">
              <TableBody>
                {objectToArray(relatedClassKeys)
                  .sort(dynamicSort('name', null))
                  .map((associationClass: TsInterface_UnspecifiedObject, index: number) => (
                    <TableRow
                      key={index}
                      className="tw-cursor-pointer"
                    >
                      <TableCell>
                        <Typography variant="subtitle1">{associationClass.name}</Typography>
                      </TableCell>
                      <TableCell>{rJSX_AssociationCategorySelection(associationClass)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography
            variant="body1"
            className="tw-mt-1 tw-mb-2 tw-opacity-40 tw-italic"
          >
            *{rLIB('Changes made to associations are not retroactive on previously collected data')}
          </Typography>
        </Box>
      </Box>
    )
    return associationSelectionJSX
  }

  const rJSX_AvailableForUseToggle = (): JSX.Element => {
    let toggleJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Data Bucket Active for use')}
        </Typography>
        <Box className="tw-ml-4">
          <Switch
            color="info"
            checked={us_dataBucket.available_for_use === true ? true : false}
            disabled={false}
            onChange={(event, value) => {
              if (value === true) {
                setMergeDataBucketRootData({ available_for_use: true })
              } else if (value === false) {
                setMergeDataBucketRootData({ available_for_use: false })
              }
            }}
          />
          {us_dataBucket.available_for_use === true ? rLIB('Data Bucket Active') : rLIB('Data Bucket Not Active')}
        </Box>
      </Box>
    )
    return toggleJSX
  }

  const rJSX_ConfirmationCodeToggle = (): JSX.Element => {
    let toggleJSX = (
      <Box>
        {/* <Typography variant="h6" className="tw-font-bold">{ s_CONFIRMATION_CODE }</Typography> */}
        <Box className="tw-ml-4">
          <Switch
            color="info"
            checked={us_dataBucket.provide_confirmation_code === true ? true : false}
            disabled={false}
            onChange={(event, value) => {
              if (value === true) {
                setMergeDataBucketRootData({ provide_confirmation_code: true })
              } else if (value === false) {
                setMergeDataBucketRootData({ provide_confirmation_code: false })
              }
            }}
          />
          {us_dataBucket.provide_confirmation_code === true ? rLIB('Provide confirmation code upon form submission') : rLIB('No confirmation code')}
        </Box>
      </Box>
    )
    return toggleJSX
  }

  const rJSX_PreventMultipleUseLinkToggle = (): JSX.Element => {
    let toggleJSX = (
      <Box>
        {/* <Typography variant="h6" className="tw-font-bold">{ s_CONFIRMATION_CODE }</Typography> */}
        <Box className="tw-ml-4">
          <Switch
            color="info"
            checked={us_dataBucket.prevent_multiple_use_link !== true ? true : false}
            disabled={false}
            onChange={(event, value) => {
              if (value === true) {
                setMergeDataBucketRootData({ prevent_multiple_use_link: false })
              } else if (value === false) {
                setMergeDataBucketRootData({ prevent_multiple_use_link: true })
              }
            }}
          />
          {us_dataBucket.prevent_multiple_use_link === true ? rLIB('Multiple Use Links Disabled') : rLIB('Multiple Use Links Enabled')}
        </Box>
      </Box>
    )
    return toggleJSX
  }

  const rJSX_DataTypeDropdown = (): JSX.Element => {
    let filteredActiveClasses: TsInterface_UnspecifiedObject = {}
    for (let loopClassKey in us_activeClasses) {
      if (us_activeClasses[loopClassKey].class_type === 'transactional_data') {
        filteredActiveClasses[loopClassKey] = us_activeClasses[loopClassKey]
      }
    }
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input"
        sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            //
            // let updateObject = { data_type: event.target.value }
            let updateObject: any = { associated_class_key: event.target.value }
            if (us_activeClasses != null && us_activeClasses[event.target.value] != null && us_activeClasses[event.target.value].name != null) {
              updateObject['associated_class_name'] = us_activeClasses[event.target.value].name
            }
            setMergeDataBucketRootData(updateObject)
            // setMergeDataBucketRootData({ associated_class_key: event.target.value })
          }}
          value={getProp(us_dataBucket, 'associated_class_key', '')}
          disabled={objectToArray(us_singleQRCodeDataPoint).length > 0 || us_dataBucket.available_for_use === true}
        >
          {objectToArray(filteredActiveClasses)
            .sort(dynamicSort('name', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['name']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_CustomFormBoolean = (): JSX.Element => {
    let toggleJSX = (
      <Box>
        <Box className="tw-ml-0">
          <Switch
            color="info"
            checked={us_dataBucket.use_custom_form === true ? true : false}
            disabled={false}
            onChange={(event, value) => {
              if (value === true) {
                setMergeDataBucketRootData({ use_custom_form: true })
              } else if (value === false) {
                setMergeDataBucketRootData({ use_custom_form: false })
              }
            }}
          />
          {us_dataBucket.use_custom_form === true ? rLIB('Use Custom Form') : rLIB('Use Default Form')}
        </Box>
      </Box>
    )
    return toggleJSX
  }

  const rJSX_CustomFormDropdown = (): JSX.Element => {
    let filteredCustomForms: TsInterface_UnspecifiedObject = {}
    for (let loopCustomFormKey in us_selectedClassCustomForms) {
      if (
        us_selectedClassCustomForms[loopCustomFormKey] != null &&
        us_selectedClassCustomForms[loopCustomFormKey].status === 'active' &&
        us_selectedClassCustomForms[loopCustomFormKey].name != null &&
        us_selectedClassCustomForms[loopCustomFormKey].key != null &&
        us_selectedClassCustomForms[loopCustomFormKey].fields != null &&
        objectToArray(us_selectedClassCustomForms[loopCustomFormKey].fields).length > 0
      ) {
        filteredCustomForms[loopCustomFormKey] = us_selectedClassCustomForms[loopCustomFormKey]
      }
    }
    let dropdownJSX = <></>
    if (us_dataBucket.use_custom_form === true) {
      dropdownJSX = (
        <FormControl
          className="bp_thin_select_input"
          sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
        >
          <Select
            onChange={(event, value) => {
              let updateObject: any = { associated_class_custom_form_key: event.target.value }
              setMergeDataBucketRootData(updateObject)
            }}
            value={getProp(us_dataBucket, 'associated_class_custom_form_key', '')}
          >
            {objectToArray(filteredCustomForms)
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject) => (
                <MenuItem
                  key={option['key']}
                  value={option['key']}
                >
                  {option['name']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )
    }
    return dropdownJSX
  }

  const rJSX_TimezoneDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input"
        sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            setMergeDataBucketRootData({ timezone: event.target.value })
          }}
          value={getProp(us_dataBucket, 'timezone', '')}
        >
          <MenuItem value="America/New_York"> {rLIB('Eastern Time')}</MenuItem>
          <MenuItem value="America/Chicago"> {rLIB('Central Time')}</MenuItem>
          <MenuItem value="America/Denver"> {rLIB('Mountain Time')}</MenuItem>
          <MenuItem value="America/Los_Angeles"> {rLIB('Pacific Time')}</MenuItem>
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_DataBucketGroupSelection = (): JSX.Element => {
    let checklistJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Data Bucket Groups')}
        </Typography>
        {objectToArray(us_activeDataBucketGroups)
          .sort(dynamicSort('name', null))
          .map((dataBucketGroup: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>
              <Checkbox
                checked={getProp(getProp(us_dataBucket, 'associated_data_bucket_group_keys', {}), dataBucketGroup.key, false)}
                onChange={(event, value) => {
                  if (value != null) {
                    let dataBucketUpdateObject: TsInterface_UnspecifiedObject = {
                      associated_data_bucket_group_keys: {
                        [dataBucketGroup.key]: value,
                      },
                    }
                    let groupUpdateObject: TsInterface_UnspecifiedObject = {}
                    if (value === true) {
                      groupUpdateObject = {
                        associated_data_bucket_keys: {
                          [us_dataBucket.key]: us_dataBucket.name,
                        },
                      }
                    } else {
                      groupUpdateObject = {
                        associated_data_bucket_keys: {
                          [us_dataBucket.key]: null,
                        },
                      }
                    }
                    // Save to database
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                        updateArray.push({
                          type: 'setMerge',
                          ref: DatabaseRef_DataBuckets_Document(res_GCK.clientKey, itemKey),
                          data: dataBucketUpdateObject,
                        })
                        updateArray.push({
                          type: 'setMerge',
                          ref: DatabaseRef_DataBucketGroups_Document(res_GCK.clientKey, dataBucketGroup.key),
                          data: groupUpdateObject,
                        })
                        DatabaseBatchUpdate(updateArray)
                          .then((res_DBU) => {
                            // Nothing
                          })
                          .catch((rej_DBU) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                          })
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                      })
                  }
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              {dataBucketGroup.name}
            </Box>
          ))}
      </Box>
    )
    return checklistJSX
  }

  const rJSX_SettingsTabContent = (): JSX.Element => {
    let tabJSX = (
      <Box>
        <Card>
          <Box>{rJSX_InactiveBanner()}</Box>
          <Box className="tw-p-2">
            {rJSX_AvailableForUseToggle()}
            <Box>
              <Typography
                variant="h6"
                className="tw-font-bold"
              >
                {rLIB('Form for Data Bucket')}
              </Typography>
              <Box className="tw-ml-4">
                {rJSX_DataTypeDropdown()}
                {rJSX_CustomFormBoolean()}
                {rJSX_CustomFormDropdown()}
              </Box>
            </Box>
            <Box className="">{rJSX_AssociationSelection()}</Box>
            <Box className="">{rJSX_TimezoneSettings()}</Box>
            <Box className="">{rJSX_DataBucketGroupSelection()}</Box>
          </Box>
        </Card>
      </Box>
    )
    return tabJSX
  }

  const rJSX_DirectFormLinkCard = (): JSX.Element => {
    return (
      <Card className="tw-p-2 tw-mb-2">
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Direct Form Link')}
        </Typography>
        {us_dataBucket.prevent_multiple_use_link !== true ? (
          <>
            <Box className="tw-ml-4">
              <a
                className="tw-text-blue-600 tw-underline"
                href={getBaseURL() + '/form/' + itemKey}
                target="_blank"
                rel="noreferrer"
              >
                {getBaseURL() + '/form/' + itemKey}
              </a>
            </Box>
            {rJSX_ConfirmationCodeToggle()}
          </>
        ) : (
          <></>
        )}
        {rJSX_PreventMultipleUseLinkToggle()}
      </Card>
    )
  }

  const rJSX_QRCodeCard = (): JSX.Element => {
    return (
      <Card className="tw-p-2 tw-mb-2">
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('QR Code')}
        </Typography>
        {us_dataBucket.prevent_multiple_use_link !== true ? (
          <>
            <Box className="tw-ml-4">{rJSX_DownloadQRCodePDFButton()}</Box>
            {rJSX_ConfirmationCodeToggle()}

            <Box>
              {/* <Typography variant="h6" className="tw-font-bold">{ s_ACCESS_TYPE }</Typography> */}
              <Box className="tw-ml-4">
                <Switch
                  color="info"
                  checked={us_dataBucket.require_app === true ? true : false}
                  disabled={false}
                  onChange={(event, value) => {
                    if (value === true) {
                      setMergeDataBucketRootData({ require_app: true })
                    } else if (value === false) {
                      setMergeDataBucketRootData({ require_app: false, require_geofence: false })
                    }
                  }}
                />
                {us_dataBucket.require_app === true ? rLIB('Drivers must scan in the app') : rLIB('Driver can scan with the app or from the browser')}
              </Box>
            </Box>
            <Box>
              {/* <Typography variant="h6" className="tw-font-bold">{ s_FORCE_GEOFENCE_VERIFICATION }</Typography> */}
              <Box className="tw-ml-4">
                <Switch
                  color="info"
                  checked={us_dataBucket.require_geofence === true ? true : false}
                  disabled={getProp(us_dataBucket, 'require_app', false) === false}
                  onChange={(event, value) => {
                    if (value === true) {
                      setMergeDataBucketRootData({ require_geofence: true })
                    } else if (value === false) {
                      setMergeDataBucketRootData({ require_geofence: false })
                    }
                  }}
                />
                {us_dataBucket.require_geofence === true ? rLIB('Geofence verification required') : rLIB('Geofence verification not required')}
              </Box>
            </Box>
            <Box className="tw-ml-4">{rJSX_GeofenceSettings()}</Box>
          </>
        ) : (
          <></>
        )}

        {rJSX_PreventMultipleUseLinkToggle()}
      </Card>
    )
  }

  const rJSX_ManualImportCard = (): JSX.Element => {
    return (
      <Card className="tw-p-2 tw-mb-2">
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Manual Import')}
        </Typography>
        <Box className="tw-ml-4">
          <Button
            color="success"
            variant="contained"
            disableElevation
            className="tw-mr-2"
            onClick={() => {
              downloadImportTemplate()
            }}
            startIcon={<Icon icon="cloud-arrow-down" />}
          >
            {rLIB('Download Template')}
          </Button>
          <CSVReader
            // https://react-papaparse.js.org/docs#config
            // https://github.com/Bunlong/react-papaparse/blob/v4.0.0/examples/CSVReaderBasicUpload.tsx
            onUploadAccepted={(results: any) => {
              handleFileSelection(results.data)
            }}
            noDrag
          >
            {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }: any) => (
              <Button
                color="info"
                variant="contained"
                disableElevation
                disabled={us_parsingData === true}
                className="tw-mr-2"
                {...getRootProps()}
                startIcon={<Icon icon="cloud-arrow-up" />}
              >
                {rLIB('Upload CSV')}
              </Button>
            )}
          </CSVReader>
        </Box>
      </Card>
    )
  }

  const rJSX_InboundCSVEmailsCard = (): JSX.Element => {
    return (
      <Card className="tw-p-2 tw-mb-2">
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Inbound CSV Email')}
        </Typography>
        <Box className="tw-ml-4">
          <a
            className="tw-text-blue-600 tw-underline"
            href={'mailto:' + itemKey + '@upload.loadcall.com'}
          >
            {itemKey + '@upload.loadcall.com'}
          </a>
        </Box>
      </Card>
    )
  }

  const rJSX_DataSourcesTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    if (us_dataBucket.available_for_use !== true) {
      tabContentJSX = <Card className="tw-p-4">{rJSX_AvailableForUseToggle()}</Card>
    } else {
      tabContentJSX = (
        <Card className="tw-p-4">
          {rJSX_DirectFormLinkCard()}
          {rJSX_QRCodeCard()}
          {rJSX_ManualImportCard()}
          {rJSX_InboundCSVEmailsCard()}
        </Card>
      )
    }
    return tabContentJSX
  }

  const rJSX_DataTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    if (uc_RootData_ClientKey != null && us_tableColumns_QRCodeData != null && objectToArray(us_tableColumns_QRCodeData).length > 0) {
      tabContentJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{
                editable: true,
              }}
              tableColumns={us_tableColumns_QRCodeData}
              tableDatabaseEndpoint={tableDatabaseEndpoint_QRCodeData}
              tableSettings={tableSettings_QRCodeData}
            />
          </Card>
        </Box>
      )
    }
    return tabContentJSX
  }

  const rJSX_ImportSessionsTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabContentJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_ImportSessions}
              tableDatabaseEndpoint={tableDatabaseEndpoint_DataBucketImportSessions}
              tableSettings={tableSettings_ImportSessions}
            />
          </Card>
        </Box>
      )
    }
    return tabContentJSX
  }

  // Calculated Fields // TODO: Edit in classes_view.tsx as well
  const rJSX_CalculatedFieldIcon = (calculatedField: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = <></>
    if (calculatedField != null && calculatedField['associated_formula_key'] == null) {
      iconJSX = (
        <Icon
          icon="triangle-exclamation"
          className="tw-mr-2"
          tooltip={rLIB('Calculated Field Missing Formula')}
          tooltipPlacement="right"
          sx={{ color: themeVariables.error_main }}
        />
      )
    } else {
      // TODO: Add other bad format cases
      iconJSX = (
        <Icon
          icon="badge-check"
          className="tw-mr-2"
          tooltip={rLIB('Calculated Field Setup Complete')}
          tooltipPlacement="right"
          sx={{ color: themeVariables.success_main }}
        />
      )
    }
    return iconJSX
  }

  const rJSX_EditCalculatedFieldButton = (calculatedField: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = (
      <Box
        className="tw-inline-block tw-opacity-30 tw-cursor-pointer tw-ml-2 hover:tw-opacity-100"
        sx={{
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        onClick={(event: any) => {
          event.stopPropagation()
          editCalculatedFieldName(calculatedField, calculatedField.key)
        }}
      >
        <Icon
          icon="pen-to-square"
          tooltip={rLIB('Edit Calculated Field')}
          tooltipPlacement="right"
        />
      </Box>
    )
    return iconJSX
  }

  const rJSX_EditCalculatedFieldFormulaIcon = (calculatedField: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = (
      <Box
        className="tw-inline-block tw-opacity-30 tw-cursor-pointer tw-ml-2 hover:tw-opacity-100"
        sx={{
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        onClick={(event: any) => {
          event.stopPropagation()
          editCalculatedFieldAssociatedFormula(calculatedField, calculatedField.key)
        }}
      >
        <Icon
          icon="pen-to-square"
          tooltip={rLIB('Edit Calculated Field Formula')}
          tooltipPlacement="right"
        />
      </Box>
    )
    return iconJSX
  }

  const rJSX_CalculationFormulaName = (calculatedField: TsInterface_UnspecifiedObject): string => {
    let formulaName = ''
    if (
      calculatedField != null &&
      calculatedField.associated_formula_key != null &&
      us_activeFormulas != null &&
      us_activeFormulas[calculatedField.associated_formula_key] != null &&
      us_activeFormulas[calculatedField.associated_formula_key].name != null
    ) {
      formulaName = us_activeFormulas[calculatedField.associated_formula_key].name
    } else if (calculatedField != null && calculatedField.associated_formula_name != null) {
      formulaName = calculatedField.associated_formula_name
    }
    return formulaName
  }

  const rJSX_CalculatedFieldCard = (calculatedField: TsInterface_UnspecifiedObject): JSX.Element => {
    let calculatedFieldJSX = <></>
    let variablesForCalculatedField: TsInterface_UnspecifiedObject[] = []
    if (
      calculatedField != null &&
      calculatedField.associated_formula_key != null &&
      us_activeFormulas != null &&
      us_activeFormulas[calculatedField.associated_formula_key] != null &&
      us_activeFormulas[calculatedField.associated_formula_key]['formula_type'] === 'library' &&
      us_activeFormulas[calculatedField.associated_formula_key]['associated_library_formula_key'] != null &&
      libraryFormulas != null &&
      libraryFormulas[us_activeFormulas[calculatedField.associated_formula_key]['associated_library_formula_key']] != null &&
      libraryFormulas[us_activeFormulas[calculatedField.associated_formula_key]['associated_library_formula_key']].variables != null
    ) {
      for (let loopVariableKey in libraryFormulas[us_activeFormulas[calculatedField.associated_formula_key]['associated_library_formula_key']].variables) {
        let loopVariable =
          libraryFormulas[us_activeFormulas[calculatedField.associated_formula_key]['associated_library_formula_key']].variables[loopVariableKey]
        variablesForCalculatedField.push({
          key: loopVariableKey,
          name: loopVariable.value,
          data_type: loopVariable.data_type,
        })
      }
    } else if (
      calculatedField != null &&
      calculatedField.associated_formula_key != null &&
      us_activeFormulas != null &&
      us_activeFormulas[calculatedField.associated_formula_key] != null &&
      us_activeFormulas[calculatedField.associated_formula_key].variables != null
    ) {
      for (let loopVariableKey in us_activeFormulas[calculatedField.associated_formula_key].variables) {
        let loopVariable = us_activeFormulas[calculatedField.associated_formula_key].variables[loopVariableKey]
        if (loopVariable != null && loopVariable.status === 'active') {
          variablesForCalculatedField.push(loopVariable)
        }
      }
    }
    calculatedFieldJSX = (
      <Accordion className="tw-mb-2">
        <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {rJSX_CalculatedFieldIcon(calculatedField)}
            {calculatedField.name}
            {rJSX_EditCalculatedFieldButton(calculatedField)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: '0px' }}>
          <Divider className="tw-mb-3" />
          <Typography variant="h6">
            <Box
              className="tw-inline-block tw-mr-1"
              sx={{ fontWeight: 900 }}
            >
              {rLIB('Formula')}:
            </Box>
            {calculatedField.associated_formula_key != null ? (
              <Box className="tw-inline-block tw-mr-1 tw-opacity-40">{rJSX_CalculationFormulaName(calculatedField)}</Box>
            ) : (
              <Box className="tw-inline-block tw-mr-1 tw-opacity-40 tw-italic">{rLIB('Formula Missing')}</Box>
            )}
            {rJSX_EditCalculatedFieldFormulaIcon(calculatedField)}
          </Typography>
          <Divider className="tw-my-3" />
          <Typography variant="h6">
            <Box
              className="tw-inline-block tw-mr-1 tw-mb-2"
              sx={{ fontWeight: 900 }}
            >
              {rLIB('Variables')}:
            </Box>
          </Typography>
          <Box
            className="tw-rounded-xl tw-ml-8"
            sx={{ border: '1px solid rgba(0,0,0,0.2)' }}
          >
            <TableBasic
              tableAdditionalData={{
                itemKey: itemKey,
                calculatedField: calculatedField,
                us_selectedClassFields: us_selectedClassFields,
                table_data_source: 'data_bucket',
                us_activeLookupTables: us_activeLookupTables,
              }}
              tableColumns={tableColumns_CalculatedFieldVariables}
              tableData={objectToArray(variablesForCalculatedField)}
              tableSettings={tableSettings_CalculatedFieldVariables}
            />
          </Box>
          <Box className="tw-mt-2">
            {/* <Json
              data={calculatedField}
              alphabetized={true}
            /> */}
          </Box>
        </AccordionDetails>
      </Accordion>
    )
    return calculatedFieldJSX
  }

  const rJSX_CalculatedFieldsTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    tabContentJSX = (
      <Card className="tw-p-4">
        {objectToArray(objectToArray(us_activeCalculatedFields).sort(dynamicSort('name', 'asc')))
          .sort(dynamicSort('name', null))
          .map((calculatedField: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>{rJSX_CalculatedFieldCard(calculatedField)}</Box>
          ))}
      </Card>
    )
    return tabContentJSX
  }

  const rJSX_TriggeredEventsFormLineItem = (lineItemKey: string, lineItemName: string | JSX.Element): JSX.Element => {
    // TODO: Form Inputs
    let formInputs: TsInterface_FormInputs = {}
    if (formInputs_TriggeredEvents != null && formInputs_TriggeredEvents[lineItemKey] != null) {
      formInputs[lineItemKey] = formInputs_TriggeredEvents[lineItemKey]
    }
    let templateOptions: TsInterface_UnspecifiedObject[] = []
    if (
      lineItemKey === 'email_on_form_submission_attachment' &&
      us_activeReportTemplates != null &&
      formInputs != null &&
      formInputs['email_on_form_submission_attachment'] != null &&
      formInputs['email_on_form_submission_attachment'].options != null
    ) {
      for (let loopTemplateKey in us_activeReportTemplates) {
        let loopTemplate = us_activeReportTemplates[loopTemplateKey]
        if (loopTemplate != null && loopTemplate.file_type === 'pdf') {
          templateOptions.push({
            key: loopTemplate.key,
            value: loopTemplate.name,
          })
        }
      }
      formInputs['email_on_form_submission_attachment'].options = templateOptions
    }

    let editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-inline-block tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: getProp(us_dataBucket, 'triggered_events', {}),
                formInputs: formInputs,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    setMergeDataBucketRootData({ triggered_events: { [lineItemKey]: formSubmittedData[lineItemKey] } })
                      .then(() => {
                        resolve({ success: true })
                      })
                      .catch(() => {
                        reject({ success: false })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: (
                  <>
                    {rLIB('Edit')} {lineItemName}
                  </>
                ),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    let linItemJSX = <></>
    if (us_dataBucket.triggered_events != null && us_dataBucket.triggered_events[lineItemKey] != null) {
      if (lineItemKey === 'email_on_form_submission_to') {
        linItemJSX = <Box className="tw-inline-block">{us_dataBucket.triggered_events[lineItemKey].join(', ')}</Box>
      } else if (lineItemKey === 'email_on_form_submission_attachment') {
        if (
          us_activeReportTemplates != null &&
          us_dataBucket.triggered_events[lineItemKey] != null &&
          us_activeReportTemplates[us_dataBucket.triggered_events[lineItemKey]] != null
        ) {
          linItemJSX = <Box className="tw-inline-block">{us_activeReportTemplates[us_dataBucket.triggered_events[lineItemKey]].name}</Box>
        } else {
          linItemJSX = <Box className="tw-inline-block tw-italic tw-opacity-40">{rLIB('Missing')}</Box>
        }
      } else {
        linItemJSX = <Box className="tw-inline-block">{us_dataBucket.triggered_events[lineItemKey]}</Box>
      }
    } else {
      linItemJSX = <Box className="tw-inline-block tw-italic tw-opacity-40">{rLIB('Missing')}</Box>
    }
    return (
      <Box className="tw-inline-block">
        {linItemJSX}
        {editIconJSX}
      </Box>
    )
  }

  const rJSX_MissingTriggerFieldIcon = (fieldName: string): JSX.Element => {
    let iconJSX = <></>
    if (us_dataBucket.triggered_events == null || us_dataBucket.triggered_events[fieldName] == null) {
      iconJSX = (
        <Icon
          icon="triangle-exclamation"
          className="tw-mr-2"
          sx={{ color: themeVariables.error_main }}
        />
      )
    } else {
      iconJSX = (
        <Icon
          icon="circle-check"
          className="tw-mr-2"
          sx={{ color: themeVariables.success_main }}
        />
      )
    }
    return iconJSX
  }

  const rJSX_EmailOnFormSubmissionForm = (): JSX.Element => {
    return (
      <Box className="tw-ml-6">
        <Box>
          <Box>
            {rJSX_MissingTriggerFieldIcon('email_on_form_submission_to')}
            <Typography
              variant="body1"
              className="tw-font-bold tw-mr-2 tw-inline-block"
            >
              {rLIB('Email Recipients')}:
            </Typography>
            {rJSX_TriggeredEventsFormLineItem('email_on_form_submission_to', rLIB('Email Recipients'))}
          </Box>
          <Box>
            {rJSX_MissingTriggerFieldIcon('email_on_form_submission_subject')}
            <Typography
              variant="body1"
              className="tw-font-bold tw-mr-2 tw-inline-block"
            >
              {rLIB('Subject')}:
            </Typography>
            {rJSX_TriggeredEventsFormLineItem('email_on_form_submission_subject', rLIB('Subject'))}
          </Box>
          <Box>
            {rJSX_MissingTriggerFieldIcon('email_on_form_submission_body')}
            <Typography
              variant="body1"
              className="tw-font-bold tw-mr-2 tw-inline-block"
            >
              {rLIB('Body')}:
            </Typography>
            {rJSX_TriggeredEventsFormLineItem('email_on_form_submission_body', rLIB('Body'))}
          </Box>
          <Box>
            {rJSX_MissingTriggerFieldIcon('email_on_form_submission_attachment')}
            <Typography
              variant="body1"
              className="tw-font-bold tw-mr-2 tw-inline-block"
            >
              {rLIB('Attachment')}:
            </Typography>
            {rJSX_TriggeredEventsFormLineItem('email_on_form_submission_attachment', rLIB('Attachment'))}
          </Box>
          <Box>
            {rJSX_MissingTriggerFieldIcon('email_on_form_submission_attachment_name')}
            <Typography
              variant="body1"
              className="tw-font-bold tw-mr-2 tw-inline-block"
            >
              {rLIB('Attachment Name')}:
            </Typography>
            {rJSX_TriggeredEventsFormLineItem('email_on_form_submission_attachment_name', rLIB('Attachment Name'))}
          </Box>
        </Box>
      </Box>
    )
  }

  const rJSX_TriggeredEventsTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    tabContentJSX = (
      <Card className="tw-p-2">
        <Box>
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {rLIB('Email when form is submitted')}
          </Typography>
          <Box className="tw-ml-4">
            <Switch
              color="info"
              checked={getProp(getProp(us_dataBucket, 'triggered_events', {}), 'email_on_form_submission', false) === true ? true : false}
              disabled={false}
              onChange={(event, value) => {
                if (value === true) {
                  setMergeDataBucketRootData({ triggered_events: { email_on_form_submission: true } })
                } else if (value === false) {
                  setMergeDataBucketRootData({ triggered_events: { email_on_form_submission: false } })
                }
              }}
            />
            {getProp(getProp(us_dataBucket, 'triggered_events', {}), 'email_on_form_submission', false) === true
              ? rLIB('Send email when form is submitted')
              : rLIB('Do not send email when form is submitted')}
          </Box>
          {getProp(getProp(us_dataBucket, 'triggered_events', {}), 'email_on_form_submission', false) === true ? rJSX_EmailOnFormSubmissionForm() : <></>}
        </Box>
      </Card>
    )
    return tabContentJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Settings',
                  tabHeader: rLIB('Settings'),
                  tabOnChange: () => {},
                  tabContent: rJSX_SettingsTabContent(),
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'Data_Sources',
                  tabHeader: rLIB('Data Sources'),
                  tabOnChange: () => {},
                  tabContent: rJSX_DataSourcesTabContent(),
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'Calculated_Fields',
                  tabHeader: rLIB('Calculated Fields'),
                  tabOnChange: () => {},
                  tabContent: rJSX_CalculatedFieldsTabContent(),
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_NewCalculatedFieldButton(false), minJSX: rJSX_NewCalculatedFieldButton(true), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'Triggers',
                  tabHeader: rLIB('Triggered Events'),
                  tabOnChange: () => {},
                  tabContent: rJSX_TriggeredEventsTabContent(),
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'Data',
                  tabHeader: rLIB('Data'),
                  tabOnChange: () => {},
                  tabContent: rJSX_DataTabContent(),
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'Imports',
                  tabHeader: rLIB('Import Sessions'),
                  tabOnChange: () => {},
                  tabContent: rJSX_ImportSessionsTabContent(),
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.DataBucketsViewPage.url(itemKey),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Data Bucket', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			1) Defines Client Types, Application Pages, and Application Navigation
			2) Provides functions to generate navigation and permissions dynamically from specified variables

			ApplicationNavPages ACCESS LEVELS
				always_yes
				default_yes
				default_no
				always_no
				true
				false

			ADDING A NEW PAGE
				1) Create file in containers folder
				2) Import page into "App.tsx"
				3) Add Route into the Router in the "App.tsx" at the bottom
				4) Add Page into the "ApplicationPages" variable of "applicationStructure.tsx" (this file)
				5) IF the page is a root level page, add it to ApplicationNavPages (and ApplicationNavSections if it is in a new section) in "applicationStructure.tsx" (this file)

		TODO:
			[ ] Typescript - might need to import or export interfaces to other files to avoid duplicates?

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_RootData_ClientPermissions, TsInterface_RootData_ClientUser, TsInterface_RootData_GlobalUser } from 'rfbp_core/services/context'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

type TsType_PageAccess = 'always_yes' | 'default_yes' | 'default_no' | 'always_no' | boolean | null

export type TsType_ClientTypes = 'Development' | 'Main'
export type TsType_UserRoles = 'admin' | 'owner' | 'limited'

interface TsInterface_PageRoleAccessPermissionsObject {
  access: TsType_PageAccess
  highlighted_nav_section: string
  highlighted_nav_page: string
}

type TsType_ApplicationPageUrlOneParam = (singleParam?: string) => string
type TsType_ApplicationPageUrlTwoParams = (paramOne?: string, paramTwo?: string) => string

interface TsInterface_ApplicationPages {
  [key: string]: {
    key: string
    root_nav_page_permission: string
    url: TsType_ApplicationPageUrlOneParam | TsType_ApplicationPageUrlTwoParams
  }
}

interface TsInterface_ClientTypes {
  [key: string]: {
    key: TsType_ClientTypes
    name: string | JSX.Element
    user_roles: {
      [key: string]: {
        key: TsType_UserRoles
        name: string | JSX.Element
        icon: JSX.Element
        home_redirect_page?: any
      }
    }
  }
}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//
export interface TsInterface_NavPage {
  name: JSX.Element
  key: string
  icon: string
  url: string
  page_role_access_permissions: {
    [key: string]: TsInterface_PageRoleAccessPermissionsObject
  }
  nav_badges: {
    [key: string]: boolean
  }
}

interface TsInterface_ApplicationNavPages {
  [key: string]: TsInterface_NavPage
}

interface TsInterface_ApplicationNavSections {
  [key: string]: {
    name: JSX.Element
    key: string
    links: TsInterface_ApplicationNavPages
  }
}

interface TsInterface_SideBarNavObject {
  [key: string]: {
    name: JSX.Element
    links: {
      [key: string]: TsInterface_NavPage
    }
  }
}

interface TsInterface_FlatUserPermissions {
  [key: string]: {
    key: string
    access: TsType_PageAccess
  }
}

interface TsInterface_AvailableUserTypePermissionsObject {
  [key: string]: {
    sectionKey: string
    sectionName: JSX.Element
    permissions: {
      [key: string]: {
        access: TsType_PageAccess
        permissionKey: string
        pageName: JSX.Element
      }
    }
  }
}

interface TsInterface_AvailableClientTypePermissionsObject {
  [clientTypeKey: string]: {
    [sectionKey: string]: {
      sectionKey: string
      sectionName: JSX.Element
      permissions: {
        [key: string]: {
          key: string
          pageName: JSX.Element
        }
      }
    }
  }
}

interface TsInterface_PermissionObject {
  [permissionKey: string]: boolean
}

interface TsInterface_GenerateActiveUserApplicationPermissionsResult {
  success: boolean
  permissions: TsInterface_PermissionObject
  error: object
}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//
export interface TsInterface_NavigationObject {
  [sectionKey: string]: {
    key: string
    name: JSX.Element
    links: {
      [linkKey: string]: {
        icon: string
        key: string
        name: JSX.Element
        page_role_access_permissions: {
          [roleKey: string]: TsInterface_PageRoleAccessPermissionsObject
        }
        // url(): string
        url: string
      }
    }
  }
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

// Client Types
export const ClientUserRoles: TsInterface_UnspecifiedObject = {
  admin: {
    color: 'warning',
    icon: (
      <Icon
        type="solid"
        icon="crown"
      />
    ),
    key: 'admin',
    name: rLIB('Admin'),
  },
  owner: {
    color: 'warning',
    icon: (
      <Icon
        type="solid"
        icon="crown"
      />
    ),
    key: 'owner',
    name: rLIB('Owner'),
  },
  limited: {
    color: 'error',
    icon: (
      <Icon
        type="solid"
        icon="octagon-exclamation"
      />
    ),
    key: 'limited',
    name: rLIB('Limited'),
  },
}

export const ClientUserRolesFormOptions: TsInterface_UnspecifiedObject[] = [
  { key: 'admin', value: rLIB('Admin') },
  { key: 'owner', value: rLIB('Owner'), disabled: true },
  { key: 'limited', value: rLIB('Limited User'), disabled: true },
]

//=========================//
//   USED BY BOILERPLATE   //
//=========================//

// All Pages URLs
export const ApplicationPages: TsInterface_ApplicationPages = {
  // { sort-start } - application pages - scoped sort plugin
  UnauthenticatedPrivacyPolicyPage: {
    key: 'UnauthenticatedPrivacyPolicyPage',
    root_nav_page_permission: 'UnauthenticatedPrivacyPolicyPage',
    url: () => '/privacy_policy',
  },
  UnauthenticatedTermsOfServicePage: {
    key: 'UnauthenticatedTermsOfServicePage',
    root_nav_page_permission: 'UnauthenticatedTermsOfServicePage',
    url: () => '/terms_of_service',
  },
  AllDataListPage: { key: 'AllDataListPage', root_nav_page_permission: 'AllDataListPage', url: () => '/all_data' },
  ClassesListPage: { key: 'ClassesListPage', root_nav_page_permission: 'ClassesListPage', url: () => '/data_types' },
  ClassesViewPage: { key: 'ClassesViewPage', root_nav_page_permission: 'ClassesListPage', url: (id: string | undefined) => '/data_types/' + id },
  LookupTablesIndexPage: { key: 'LookupTablesIndexPage', root_nav_page_permission: 'LookupTablesIndexPage', url: () => '/lookup_tables' },
  LookupTablesViewPage: {
    key: 'LookupTablesViewPage',
    root_nav_page_permission: 'LookupTablesIndexPage',
    url: (id: string | undefined) => '/lookup_tables/' + id,
  },
  FormulasIndexPage: { key: 'FormulasIndexPage', root_nav_page_permission: 'FormulasIndexPage', url: () => '/formulas' },
  FormulasViewPage: { key: 'FormulasViewPage', root_nav_page_permission: 'FormulasIndexPage', url: (id: string | undefined) => '/formulas/' + id },
  DataAnalysisListPage: { key: 'DataAnalysisListPage', root_nav_page_permission: 'DataAnalysisListPage', url: () => '/data_analysis' },
  DataAnalysisTemplateViewPage: {
    key: 'DataAnalysisTemplateViewPage',
    root_nav_page_permission: 'DataAnalysisListPage',
    url: (id: string | undefined) => '/data_analysis_templates/' + id,
  },
  DataAnalysisViewPage: {
    key: 'DataAnalysisViewPage',
    root_nav_page_permission: 'DataAnalysisListPage',
    url: (id: string | undefined) => '/data_analysis/' + id,
  },
  DataBucketsListPage: { key: 'DataBucketsListPage', root_nav_page_permission: 'DataBucketsListPage', url: () => '/data_buckets' },
  DataBucketsViewPage: {
    key: 'DataBucketsViewPage',
    root_nav_page_permission: 'DataBucketsListPage',
    url: (id: string | undefined) => '/data_buckets/' + id,
  },
  DirectoryIndexPage: { key: 'DirectoryIndexPage', root_nav_page_permission: 'DirectoryIndexPage', url: () => '/directory' },
  TasksIndexPage: { key: 'TasksIndexPage', root_nav_page_permission: 'TasksIndexPage', url: () => '/tasks' },
  TaskTemplateViewPage: { key: 'TaskTemplateViewPage', root_nav_page_permission: 'TasksIndexPage', url: (id: string | undefined) => '/tasks/templates/' + id },
  HomePage: { key: 'HomePage', root_nav_page_permission: 'HomePage', url: () => '/' },
  ReconciliationAutoViewPage: {
    key: 'ReconciliationAutoViewPage',
    root_nav_page_permission: 'ReconciliationListPage',
    url: (id: string | undefined) => '/auto_reconciliation/' + id,
  },
  ReconciliationImpromptuViewPage: {
    key: 'ReconciliationImpromptuViewPage',
    root_nav_page_permission: 'ReconciliationListPage',
    url: (id: string | undefined) => '/impromptu_reconciliation/' + id,
  },
  ReconciliationListPage: { key: 'ReconciliationListPage', root_nav_page_permission: 'ReconciliationListPage', url: () => '/reconciliation' },
  ReportTemplatesViewPage: {
    key: 'ReportTemplatesViewPage',
    root_nav_page_permission: 'ReportsListPage',
    url: (id: string | undefined) => '/report_templates/' + id,
  },
  ReportsListPage: { key: 'ReportsListPage', root_nav_page_permission: 'ReportsListPage', url: () => '/reports' },
  ScheduledEmailsListPage: { key: 'ScheduledEmailsListPage', root_nav_page_permission: 'ScheduledEmailsListPage', url: () => '/scheduled_emails' },
  UserManagementIndexPage: { key: 'UserManagementIndexPage', root_nav_page_permission: 'UserManagementIndexPage', url: () => '/user_management' },
  APIDocumentationIndexPage: { key: 'APIDocumentationIndexPage', root_nav_page_permission: 'APIDocumentationIndexPage', url: () => '/api_docs' },
  SuperClientPermissionsManagementPage: {
    key: 'SuperClientPermissionsManagementPage',
    root_nav_page_permission: 'SuperClientPermissionsManagementPage',
    url: () => '/super/client_permissions/',
  },
  SuperTestLabPage: { key: 'SuperTestLabPage', root_nav_page_permission: 'SuperTestLabPage', url: () => '/super/test_lab/' },
  UnauthenticatedFormPage: {
    key: 'UnauthenticatedFormPage',
    root_nav_page_permission: 'unauthenticated',
    url: (id: string | undefined) => '/form/' + id,
  },
  UnauthenticatedLoginPage: { key: 'UnauthenticatedLoginPage', root_nav_page_permission: 'unauthenticated', url: () => '/login' },
  UserSettingsPage: { key: 'UserSettingsPage', root_nav_page_permission: 'UserSettingsPage', url: () => '/settings' },
  // { sort-end } - application pages
}

export const ClientTypes: TsInterface_ClientTypes = {
  Development: {
    key: 'Development',
    name: rLIB('Development'),
    user_roles: {
      admin: {
        key: 'admin',
        name: ClientUserRoles['admin']['name'],
        icon: ClientUserRoles['admin']['icon'],
        home_redirect_page: ApplicationPages.UserSettingsPage, // TODO
      },
    },
  },
  Main: {
    key: 'Main',
    name: rLIB('Main'),
    user_roles: {
      admin: {
        key: 'admin',
        name: ClientUserRoles['admin']['name'],
        icon: ClientUserRoles['admin']['icon'],
        home_redirect_page: ApplicationPages.UserSettingsPage, // TODO
      },
      owner: {
        key: 'owner',
        name: ClientUserRoles['owner']['name'],
        icon: ClientUserRoles['owner']['icon'],
        home_redirect_page: ApplicationPages.UserSettingsPage, // TODO
      },
      limited: {
        key: 'limited',
        name: ClientUserRoles['limited']['name'],
        icon: ClientUserRoles['limited']['icon'],
        home_redirect_page: ApplicationPages.UserSettingsPage, // TODO
      },
    },
  },
}

export const ApplicationMajorPages: TsInterface_ApplicationPages = {
  HomePage: ApplicationPages['HomePage'],
  UserSettingsPage: ApplicationPages['UserSettingsPage'],
}

// Nav Sections
export const ApplicationNavSections: TsInterface_ApplicationNavSections = {
  HomeSection: {
    name: <></>,
    key: 'HomeSection',
    links: {},
  },
  DirectorySection: {
    name: <></>,
    key: 'DirectorySection',
    links: {},
  },
  DataSection: {
    name: <></>,
    key: 'DataSection',
    links: {},
  },
  TaskSection: {
    name: <></>,
    key: 'TaskSection',
    links: {},
  },
  ReportsSection: {
    name: <></>,
    key: 'ReportsSection',
    links: {},
  },
  AggregateSection: {
    name: <></>,
    key: 'AggregateSection',
    links: {},
  },
  CalculationsSection: {
    name: <></>,
    key: 'CalculationsSection',
    links: {},
  },
  AnalysisSection: {
    name: <></>,
    key: 'AnalysisSection',
    links: {},
  },
  ExportSection: {
    name: <></>,
    key: 'ExportSection',
    links: {},
  },
  AdminSection: {
    name: <></>,
    key: 'AdminSection',
    links: {},
  },
  SuperSection: {
    name: <></>,
    key: 'SuperSection',
    links: {},
  },
}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//

// Nav Pages
export const ApplicationNavPages: TsInterface_ApplicationNavPages = {
  HomePage: {
    name: rLIB('Home') as JSX.Element,
    key: 'HomePage',
    icon: 'house-blank',
    url: ApplicationPages.HomePage.url(),
    page_role_access_permissions: {
      Development_admin: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'HomePage' },
      Development_owner: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'HomePage' },
      Main_admin: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'HomePage' },
      Main_owner: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'HomePage' },
      Temp_limited: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'HomePage' },
    },
    nav_badges: {},
  },
  UserSettingsPage: {
    name: rLIB('Settings') as JSX.Element,
    key: 'UserSettingsPage',
    icon: 'gear',
    url: ApplicationPages.UserSettingsPage.url(),
    page_role_access_permissions: {
      // Development_admin: {		access: "always_yes",		highlighted_nav_section: "HomeSection", 			highlighted_nav_page: "UserSettingsPage" },
      // Development_owner: {		access: "always_yes",		highlighted_nav_section: "HomeSection", 			highlighted_nav_page: "UserSettingsPage" },
      Main_admin: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'UserSettingsPage' },
      Main_owner: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'UserSettingsPage' },
      Temp_limited: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'UserSettingsPage' },
    },
    nav_badges: {},
  },
  ClassesListPage: {
    name: rLIB('Forms') as JSX.Element,
    key: 'ClassesListPage',
    icon: 'clipboard-list',
    url: ApplicationPages.ClassesListPage.url(),
    page_role_access_permissions: {
      Main_admin: { access: 'always_yes', highlighted_nav_section: 'DataSection', highlighted_nav_page: 'ClassesListPage' },
      Main_owner: { access: 'always_yes', highlighted_nav_section: 'DataSection', highlighted_nav_page: 'ClassesListPage' },
    },
    nav_badges: {},
  },
  DataBucketsListPage: {
    name: rLIB('Data Buckets') as JSX.Element,
    key: 'DataBucketsListPage',
    icon: 'qrcode',
    url: ApplicationPages.DataBucketsListPage.url(),
    page_role_access_permissions: {
      Main_admin: { access: 'always_yes', highlighted_nav_section: 'DataSection', highlighted_nav_page: 'DataBucketsListPage' },
      Main_owner: { access: 'always_yes', highlighted_nav_section: 'DataSection', highlighted_nav_page: 'DataBucketsListPage' },
    },
    nav_badges: {},
  },
  DirectoryIndexPage: {
    name: rLIB('Directory') as JSX.Element,
    key: 'DirectoryIndexPage',
    icon: 'cabinet-filing',
    url: ApplicationPages.DirectoryIndexPage.url(),
    page_role_access_permissions: {
      Main_admin: { access: 'always_yes', highlighted_nav_section: 'DataSection', highlighted_nav_page: 'DirectoryIndexPage' },
      Main_owner: { access: 'always_yes', highlighted_nav_section: 'DataSection', highlighted_nav_page: 'DirectoryIndexPage' },
    },
    nav_badges: {},
  },
  AllDataListPage: {
    name: rLIB('All Data') as JSX.Element,
    key: 'AllDataListPage',
    // icon: "receipt",
    icon: 'database',
    url: ApplicationPages.AllDataListPage.url(),
    page_role_access_permissions: {
      Main_admin: { access: 'always_yes', highlighted_nav_section: 'DataSection', highlighted_nav_page: 'AllDataListPage' },
      Main_owner: { access: 'always_yes', highlighted_nav_section: 'DataSection', highlighted_nav_page: 'AllDataListPage' },
    },
    nav_badges: {},
  },
  TasksIndexPage: {
    name: rLIB('Tasks') as JSX.Element,
    key: 'TasksIndexPage',
    icon: 'list-check',
    url: ApplicationPages.TasksIndexPage.url(),
    page_role_access_permissions: {
      Main_admin: { access: 'always_yes', highlighted_nav_section: 'TaskSection', highlighted_nav_page: 'TasksIndexPage' },
      Main_owner: { access: 'always_yes', highlighted_nav_section: 'TaskSection', highlighted_nav_page: 'TasksIndexPage' },
    },
    nav_badges: {},
  },

  FormulasIndexPage: {
    name: rLIB('Formulas') as JSX.Element,
    key: 'FormulasIndexPage',
    icon: 'calculator-simple',
    url: ApplicationPages.FormulasIndexPage.url(),
    page_role_access_permissions: {
      Main_admin: { access: 'always_yes', highlighted_nav_section: 'CalculationsSection', highlighted_nav_page: 'FormulasIndexPage' },
      Main_owner: { access: 'always_yes', highlighted_nav_section: 'CalculationsSection', highlighted_nav_page: 'FormulasIndexPage' },
    },
    nav_badges: {},
  },
  LookupTablesIndexPage: {
    name: rLIB('Lookup Tables') as JSX.Element,
    key: 'LookupTablesIndexPage',
    icon: 'table',
    url: ApplicationPages.LookupTablesIndexPage.url(),
    page_role_access_permissions: {
      Main_admin: { access: 'always_yes', highlighted_nav_section: 'CalculationsSection', highlighted_nav_page: 'LookupTablesIndexPage' },
      Main_owner: { access: 'always_yes', highlighted_nav_section: 'CalculationsSection', highlighted_nav_page: 'LookupTablesIndexPage' },
    },
    nav_badges: {},
  },
  ReportsListPage: {
    name: rLIB('Reports') as JSX.Element,
    key: 'ReportsListPage',
    icon: 'file-pen',
    url: ApplicationPages.ReportsListPage.url(),
    page_role_access_permissions: {
      Main_admin: { access: 'always_yes', highlighted_nav_section: 'ReportsSection', highlighted_nav_page: 'ReportsListPage' },
      Main_owner: { access: 'always_yes', highlighted_nav_section: 'ReportsSection', highlighted_nav_page: 'ReportsListPage' },
    },
    nav_badges: {},
  },
  ScheduledEmailsListPage: {
    name: rLIB('Emails') as JSX.Element,
    key: 'ScheduledEmailsListPage',
    icon: 'paper-plane',
    url: ApplicationPages.ScheduledEmailsListPage.url(),
    page_role_access_permissions: {
      Main_admin: { access: 'always_yes', highlighted_nav_section: 'ReportsSection', highlighted_nav_page: 'ScheduledEmailsListPage' },
      Main_owner: { access: 'always_yes', highlighted_nav_section: 'ReportsSection', highlighted_nav_page: 'ScheduledEmailsListPage' },
    },
    nav_badges: {},
  },
  DataAnalysisListPage: {
    name: rLIB('Data Analysis') as JSX.Element,
    key: 'DataAnalysisListPage',
    icon: 'magnifying-glass-chart',
    url: ApplicationPages.DataAnalysisListPage.url(),
    page_role_access_permissions: {
      Main_admin: { access: 'always_yes', highlighted_nav_section: 'AggregateSection', highlighted_nav_page: 'DataAnalysisListPage' },
      Main_owner: { access: 'always_yes', highlighted_nav_section: 'AggregateSection', highlighted_nav_page: 'DataAnalysisListPage' },
    },
    nav_badges: {},
  },
  ReconciliationListPage: {
    name: rLIB('Reconciliation') as JSX.Element,
    key: 'ReconciliationListPage',
    icon: 'scale-balanced',
    url: ApplicationPages.ReconciliationListPage.url(),
    page_role_access_permissions: {
      Main_admin: { access: 'always_yes', highlighted_nav_section: 'AggregateSection', highlighted_nav_page: 'ReconciliationListPage' },
      Main_owner: { access: 'always_yes', highlighted_nav_section: 'AggregateSection', highlighted_nav_page: 'ReconciliationListPage' },
    },
    nav_badges: {},
  },
  UserManagementIndexPage: {
    name: rLIB('User Management') as JSX.Element,
    key: 'UserManagementIndexPage',
    icon: 'users',
    url: ApplicationPages.UserManagementIndexPage.url(),
    page_role_access_permissions: {
      Main_owner: { access: 'always_yes', highlighted_nav_section: 'AdminSection', highlighted_nav_page: 'UserManagementIndexPage' },
    },
    nav_badges: {},
  },
  APIDocumentationIndexPage: {
    name: rLIB('API Docs') as JSX.Element,
    key: 'APIDocumentationIndexPage',
    icon: 'code',
    url: ApplicationPages.APIDocumentationIndexPage.url(),
    page_role_access_permissions: {
      Main_owner: { access: 'always_yes', highlighted_nav_section: 'AdminSection', highlighted_nav_page: 'APIDocumentationIndexPage' },
    },
    nav_badges: {},
  },
  // Development
  SuperClientPermissionsManagementPage: {
    name: rLIB('Client Permissions') as JSX.Element,
    key: 'SuperClientPermissionsManagementPage',
    icon: 'square-check',
    url: ApplicationPages.SuperClientPermissionsManagementPage.url(),
    page_role_access_permissions: {
      Development_admin: { access: 'always_yes', highlighted_nav_section: 'SuperSection', highlighted_nav_page: 'SuperClientPermissionsManagementPage' },
      Development_owner: { access: 'always_yes', highlighted_nav_section: 'SuperSection', highlighted_nav_page: 'SuperClientPermissionsManagementPage' },
    },
    nav_badges: {},
  },
  SuperTestLabPage: {
    name: rLIB('Test Lab') as JSX.Element,
    key: 'SuperTestLabPage',
    icon: 'flask',
    url: ApplicationPages.SuperTestLabPage.url(),
    page_role_access_permissions: {
      Development_admin: { access: 'always_yes', highlighted_nav_section: 'SuperSection', highlighted_nav_page: 'SuperTestLabPage' },
      Development_owner: { access: 'always_yes', highlighted_nav_section: 'SuperSection', highlighted_nav_page: 'SuperTestLabPage' },
    },
    nav_badges: {},
  },
}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//
export const EmptyApplicationNavigationObject: TsInterface_ApplicationNavSections = {}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//
export const generateApplicationNavigationObject = (clientType: string, userRole: string): TsInterface_SideBarNavObject => {
  let sideBarNavObject: TsInterface_SideBarNavObject = {}
  for (let rootNavPageKey in ApplicationNavPages) {
    let rootNavPage: TsInterface_NavPage = ApplicationNavPages[rootNavPageKey]
    let userRoleAccess = getProp(rootNavPage['page_role_access_permissions'][clientType + '_' + userRole], 'access', false)
    if (userRoleAccess !== false) {
      let userSpecificRootNavPage = rootNavPage['page_role_access_permissions'][clientType + '_' + userRole]
      if (
        sideBarNavObject[userSpecificRootNavPage.highlighted_nav_section] == null &&
        ApplicationNavSections[userSpecificRootNavPage.highlighted_nav_section] != null
      ) {
        sideBarNavObject[userSpecificRootNavPage.highlighted_nav_section] = ApplicationNavSections[userSpecificRootNavPage.highlighted_nav_section]
      }
      sideBarNavObject[userSpecificRootNavPage.highlighted_nav_section]['links'][userSpecificRootNavPage.highlighted_nav_page] = rootNavPage
    }
  }
  return sideBarNavObject
}

export const generateAvailableClientLevelPermissions = (): TsInterface_AvailableClientTypePermissionsObject => {
  let availableClientTypePermissions: TsInterface_AvailableClientTypePermissionsObject = {}
  // Loop through each client type to
  for (let clientTypeKey in ClientTypes) {
    availableClientTypePermissions[clientTypeKey] = {}
    let clientTypeUserRoles = ClientTypes[clientTypeKey]['user_roles']
    for (let userRoleKey in clientTypeUserRoles) {
      let emulatedNav = generateApplicationNavigationObject(clientTypeKey, userRoleKey)
      for (let emulatedSectionKey in emulatedNav) {
        let emulatedSection = emulatedNav[emulatedSectionKey]
        if (availableClientTypePermissions[clientTypeKey][emulatedSectionKey] == null) {
          availableClientTypePermissions[clientTypeKey][emulatedSectionKey] = {
            sectionKey: emulatedSectionKey,
            sectionName: emulatedSection.name,
            permissions: {},
          }
        }
        for (let emulatedRootNavLinkKey in emulatedSection['links']) {
          let emulatedRootNavLink = emulatedSection['links'][emulatedRootNavLinkKey]
          let compositePermissionKey = 'desktop_page_access_' + clientTypeKey + '_' + emulatedSectionKey + '_' + emulatedRootNavLinkKey
          let userRoleWithAccess = false
          for (let roleKey in ApplicationNavPages[emulatedRootNavLinkKey]['page_role_access_permissions']) {
            if (roleKey.substring(0, clientTypeKey.length) === clientTypeKey) {
              if (
                ApplicationNavPages[emulatedRootNavLinkKey]['page_role_access_permissions'][roleKey] != null &&
                ApplicationNavPages[emulatedRootNavLinkKey]['page_role_access_permissions'][roleKey]['access'] !== false
              ) {
                userRoleWithAccess = true
              }
            }
          }
          if (userRoleWithAccess === true) {
            availableClientTypePermissions[clientTypeKey][emulatedSectionKey]['permissions'][compositePermissionKey] = {
              key: compositePermissionKey,
              pageName: emulatedRootNavLink.name,
            }
          }
        }
      }
    }
  }
  return availableClientTypePermissions
}

export const generateAvailableUserLevelPermissions = (
  userRole: TsType_UserRoles,
  clientType: TsType_ClientTypes,
  rootClientPermissions: TsInterface_RootData_ClientPermissions,
): TsInterface_AvailableUserTypePermissionsObject => {
  let availableUserTypePermissionsObject: TsInterface_AvailableUserTypePermissionsObject = {}
  let flatUserPermissions: TsInterface_FlatUserPermissions = {}
  if (userRole != null && clientType != null && rootClientPermissions != null) {
    let emulatedNav = generateApplicationNavigationObject(clientType, userRole)
    // Loop through top level nav pages (which permissions are based on)
    for (let navPageKey in ApplicationNavPages) {
      let navPage = ApplicationNavPages[navPageKey]
      // If the nav page has an access permission for the authenticated user's client type and user role
      if (navPage['page_role_access_permissions'] != null && navPage['page_role_access_permissions'][clientType + '_' + userRole]) {
        let pageRoleAccessPermissions = navPage['page_role_access_permissions'][clientType + '_' + userRole]
        // If access is granted at the client level
        let compositePermissionOverrideKey =
          'desktop_page_access_' +
          clientType +
          '_' +
          pageRoleAccessPermissions['highlighted_nav_section'] +
          '_' +
          pageRoleAccessPermissions['highlighted_nav_page']
        if (
          pageRoleAccessPermissions['highlighted_nav_section'] != null &&
          pageRoleAccessPermissions['highlighted_nav_page'] != null &&
          rootClientPermissions[compositePermissionOverrideKey]
        ) {
          if (pageRoleAccessPermissions != null && pageRoleAccessPermissions['access']) {
            // Just show the permissions that the client AND user role have access to
            flatUserPermissions[compositePermissionOverrideKey] = {
              key: compositePermissionOverrideKey,
              access: pageRoleAccessPermissions['access'],
            }
          }
        }
      }
    }
    for (let emulatedSectionKey in emulatedNav) {
      let emulatedSection = emulatedNav[emulatedSectionKey]
      if (availableUserTypePermissionsObject[emulatedSectionKey] == null) {
        availableUserTypePermissionsObject[emulatedSectionKey] = {
          sectionKey: emulatedSectionKey,
          sectionName: emulatedSection.name,
          permissions: {},
        }
      }
      for (let emulatedRootNavLinkKey in emulatedSection['links']) {
        let emulatedRootNavLink = emulatedSection['links'][emulatedRootNavLinkKey]
        let compositePermissionKey = 'desktop_page_access_' + clientType + '_' + emulatedSectionKey + '_' + emulatedRootNavLinkKey
        if (flatUserPermissions[compositePermissionKey] != null) {
          availableUserTypePermissionsObject[emulatedSectionKey]['permissions'][compositePermissionKey] = {
            access: flatUserPermissions[compositePermissionKey]['access'],
            permissionKey: compositePermissionKey,
            pageName: emulatedRootNavLink.name,
          }
        }
      }
    }
  }
  return availableUserTypePermissionsObject
}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//
export const generateActiveUserApplicationPermissions = (
  rootClientUser: TsInterface_RootData_ClientUser,
  rootGlobalUser: TsInterface_RootData_GlobalUser,
  rootClientPermissions: TsInterface_RootData_ClientPermissions,
): Promise<TsInterface_GenerateActiveUserApplicationPermissionsResult> => {
  // permissions are of the form "desktop_page_access_$clientType_$sectionKey_$pageKey" where $clientType, $sectionKey and $pageKey values are all in camelCase
  return new Promise((resolve, reject) => {
    let permissions: TsInterface_PermissionObject = {
      HomePage: true,
    }
    let userRole = getProp(rootClientUser, 'user_role', null)
    if (userRole == null) {
      userRole = getProp(rootGlobalUser, 'user_role', null)
    }
    if (userRole != null && rootClientPermissions != null && rootClientPermissions['client_type'] != null) {
      let clientType = rootClientPermissions['client_type']
      // Loop through top level nav pages (which is what permissions are based on)
      for (let navPageKey in ApplicationNavPages) {
        let navPage = ApplicationNavPages[navPageKey]
        // If the nav page has an access permission for the authenticated user's client type and user role
        if (navPage['page_role_access_permissions'] != null && navPage['page_role_access_permissions'][clientType + '_' + userRole]) {
          let pageRoleAccessPermissions = navPage['page_role_access_permissions'][clientType + '_' + userRole]
          // If access is granted at the client level
          let compositePermissionOverrideKey =
            'desktop_page_access_' +
            clientType +
            '_' +
            pageRoleAccessPermissions['highlighted_nav_section'] +
            '_' +
            pageRoleAccessPermissions['highlighted_nav_page']
          if (
            pageRoleAccessPermissions['highlighted_nav_section'] != null &&
            pageRoleAccessPermissions['highlighted_nav_page'] != null &&
            rootClientPermissions[compositePermissionOverrideKey]
          ) {
            if (pageRoleAccessPermissions != null && pageRoleAccessPermissions['access']) {
              // Depending on user role and overrides, determine access boolean
              if (pageRoleAccessPermissions['access'] === 'always_yes') {
                permissions[navPage.key] = true
              } else if (pageRoleAccessPermissions['access'] === 'default_yes') {
                if (
                  rootClientUser != null &&
                  rootClientUser['permission_overrides'] != null &&
                  rootClientUser['permission_overrides'][compositePermissionOverrideKey] != null
                ) {
                  permissions[navPage.key] = rootClientUser['permission_overrides'][compositePermissionOverrideKey]
                } else {
                  permissions[navPage.key] = true
                }
              } else if (pageRoleAccessPermissions['access'] === 'default_no') {
                if (
                  rootClientUser != null &&
                  rootClientUser['permission_overrides'] != null &&
                  rootClientUser['permission_overrides'][compositePermissionOverrideKey] != null
                ) {
                  permissions[navPage.key] = rootClientUser['permission_overrides'][compositePermissionOverrideKey]
                } else {
                  permissions[navPage.key] = false
                }
              } else if (pageRoleAccessPermissions['access'] === 'always_no') {
                permissions[navPage.key] = false
              } else {
                permissions[navPage.key] = false
              }
            } else {
              permissions[navPage.key] = false
            }
          } else {
            permissions[navPage.key] = false
          }
        }
      }
      permissions['HomePage'] = true
      permissions['UserSettingsPage'] = true
      resolve({
        success: true,
        permissions: permissions,
        error: {},
      })
    } else {
      resolve({
        success: false,
        permissions: {},
        error: { message: '', details: '', code: 'ER-D-SAS-GAUAP-01' },
      })
    }
  })
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2'
import { formTypeOptions } from 'app/models/forms/form_types'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_ActiveClassCustomForms_Query,
  DatabaseRef_ActivePersistentClasses_Query,
  DatabaseRef_ClassCalculatedFields_Collection,
  DatabaseRef_ClassCalculatedFields_Document,
  DatabaseRef_ClassCustomForms_Collection,
  DatabaseRef_ClassCustomForms_Document,
  DatabaseRef_ClassFields_Collection,
  DatabaseRef_ClassFields_Document,
  DatabaseRef_Class_Document,
} from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import { DatabaseRef_ActiveCalculatedFields_Query } from 'rfbp_aux/services/database_endpoints/clients/data_management/calculated_fields'
import { DatabaseRef_ActiveFormulas_Query } from 'rfbp_aux/services/database_endpoints/clients/data_management/formulas'
import { DatabaseRef_ActiveLookupTables_Query } from 'rfbp_aux/services/database_endpoints/clients/data_management/lookup_tables'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_RootData_ClientKey,
  Context_RootData_GlobalUser,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseStagedBatchUpdate,
  DatabaseUpdateDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_GenericPromiseReject, TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { tableColumns_CalculatedFieldVariables, tableSettings_CalculatedFieldVariables } from '../architecture_data_buckets/tables/calculated_fields'
import { libraryFormulas } from '../refine_formulas/services/calculation_functions'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['ClassesViewPage']['key']

// Table
const otherDirectoryOptions: TsInterface_UnspecifiedObject = {
  no_other_option: {
    key: 'no_other_option',
    name: rLIB('No Other Option'),
  },
  top_of_list: {
    key: 'top_of_list',
    name: rLIB('Top of List'),
  },
  bottom_of_list: {
    key: 'bottom_of_list',
    name: rLIB('Bottom of List'),
  },
}

const dataFieldFilters: TsInterface_UnspecifiedObject = {
  all: {
    key: 'all',
    value: rLIB('All'),
  },
  active: {
    key: 'active',
    value: rLIB('Active'),
  },
  inactive: {
    key: 'inactive',
    value: rLIB('Inactive'),
  },
}

const tableSettings_FormFields: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'order',
  use_live_data: true,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'inactive',
        conditions: [],
      },
    },
  ],
  collapsible_columns: false,
  no_data_message: rLIB('No fields on form') as JSX.Element,
  searchable: false,
  hide_pagination: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

const tableColumns_FormFields: TsInterface_TableColumns = {
  unique_identifier: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.unique_identifier === true) {
          cellJSX = (
            <Box>
              <Icon
                icon="star"
                sx={{
                  color: themeVariables.warning_main,
                  fontSize: '18px',
                }}
              />
            </Box>
          )
        } else {
          cellJSX = (
            <Box>
              <Icon
                icon="star"
                type="light"
                className="tw-cursor-pointer"
                sx={{
                  'color': themeVariables.gray_500,
                  'fontSize': '18px',
                  '&:hover': {
                    color: themeVariables.warning_main,
                  },
                }}
                onClick={() => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                        {
                          type: 'setMerge',
                          ref: DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                          data: {
                            unique_identifier: true,
                          },
                        },
                      ]
                      for (let loopFieldIndex in tableAdditionalData.tableData) {
                        let loopField = tableAdditionalData.tableData[loopFieldIndex]
                        if (loopField.key !== rowData.key) {
                          updateArray.push({
                            type: 'setMerge',
                            ref: DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, loopField.key as string),
                            data: {
                              unique_identifier: false,
                            },
                          })
                        }
                      }
                      DatabaseBatchUpdate(updateArray)
                        .then((res_DBU) => {
                          tableHooks.ur_forceRerender()
                        })
                        .catch((rej_DBU) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                }}
              />
            </Box>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Unique Identifier')
      },
      header_sort_by: null,
    },
  },
  // name: TableCellBasic('name', rLIB('Field name'), 'name'),
  name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let cellEditIconJSX = (
          <>
            <Icon
              icon="pen-to-square"
              className="tw-ml-2 tw-opacity-20 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-green-600"
              tooltip={rLIB('Rename')}
              tooltipPlacement="right"
              onClick={() => {
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: rowData,
                      formInputs: {
                        name: {
                          data_type: 'string',
                          input_type: 'text_basic',
                          key: 'name',
                          label: <>{rLIB('Name')}</>,
                          required: true,
                        },
                      },
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: {},
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              renameClassField(res_GCK.clientKey, tableAdditionalData.itemKey as string, rowData.key as string, formSubmittedData.name)
                                .then((res_RCF) => {
                                  resolve({ close_dialog: true })
                                })
                                .catch((rej_RCF) => {
                                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_RCF.error })
                                  reject(rej_RCF.error)
                                })
                            })
                            .catch((rej_GCK) => {
                              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              reject(rej_GCK.error)
                            })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'success',
                      formDialogHeaderText: <>{rLIB('Create New Form')}</>,
                      formDialogIcon: (
                        <Icon
                          type="solid"
                          icon="pen-to-square"
                        />
                      ),
                    },
                  },
                })
              }}
            />
          </>
        )
        cellJSX = (
          <Box>
            {rowData.name}
            {cellEditIconJSX}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Field Name')
      },
      header_sort_by: 'name',
    },
  },
  form_type: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellSX: TsInterface_UnspecifiedObject = {}
        if (rowData.form_type == null) {
          cellSX['background'] = themeVariables.warning_light
        }
        let cellJSX = (
          <Box
            className="tw-rounded-md"
            sx={cellSX}
          >
            <FormControl
              className="bp_thin_select_input bp_thin_select_multiple_input"
              sx={{ minWidth: '130px', width: 'calc(100%)' }}
            >
              <InputLabel>{rLIB('Form Input Type')}</InputLabel>
              <Select
                autoWidth={true}
                label={rLIB('Form Input Type')}
                onChange={(event, value) => {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    form_type: event.target.value,
                  }
                  if (event.target.value === 'display') {
                    updateObject['required'] = false
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(
                        DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                        updateObject,
                      )
                        .then((res_DSMD) => {
                          // Nothing
                        })
                        .catch((rej_DSMD) => {
                          console.error(rej_DSMD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                }}
                value={rowData.form_type || ''}
              >
                {objectToArray(formTypeOptions).map((option: TsInterface_UnspecifiedObject) => (
                  <MenuItem
                    key={option['key']}
                    value={option['key']}
                    disabled={option['disabled']}
                  >
                    <Box
                      className="tw-inline-block"
                      sx={{ marginRight: '8px', color: themeVariables.error_main }}
                    >
                      {option['icon']}
                    </Box>
                    {option['value']}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Form Input Type')
      },
      header_sort_by: null,
    },
  },
  options: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          rowData != null &&
          rowData.form_type != null &&
          formTypeOptions != null &&
          formTypeOptions[rowData.form_type as string] != null &&
          formTypeOptions[rowData.form_type as string].has_options === true
        ) {
          let sortedOptions = objectToArray(getProp(rowData, 'options', {})).sort(dynamicSort('order', 'asc'))
          cellJSX = (
            <Box>
              <Box>
                {sortedOptions.map((option: TsInterface_UnspecifiedObject, index: number) => (
                  <Box key={index}>
                    <Box
                      className="tw-inline-block tw-mr-2"
                      sx={{ color: themeVariables.error_main }}
                    >
                      {formTypeOptions[rowData.form_type as string]['icon']}
                    </Box>
                    {option.value}
                    <Icon
                      icon="trash"
                      className="tw-cursor-pointer tw-opacity-10 tw-ml-2 hover:tw-opacity-100 tw-mr-2"
                      tooltip={rLIB('Delete')}
                      tooltipPlacement="right"
                      sx={{
                        '&:hover': {
                          color: themeVariables.error_main,
                        },
                      }}
                      onClick={() => {
                        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
                          display: true,
                          confirm: {
                            color: 'error',
                            header: rLIB('Delete Option'),
                            icon: (
                              <Icon
                                icon="trash"
                                type="solid"
                              />
                            ),
                            submit_text: rLIB('Delete'),
                            text: rLIB('Are you sure that you want to delete this option?'),
                            submit_callback: () => {
                              return new Promise((resolve, reject) => {
                                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                                  .then((res_GCK) => {
                                    let updateObject: TsInterface_UnspecifiedObject = {
                                      options: rowData.options,
                                    }
                                    delete updateObject.options[option.value]
                                    DatabaseUpdateDocument(
                                      DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                                      updateObject,
                                    )
                                      .then((res_DSMD) => {
                                        resolve({ close_dialog: true })
                                      })
                                      .catch((rej_DSMD) => {
                                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                      })
                                  })
                                  .catch((rej_GCK) => {
                                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                  })
                              })
                            },
                          },
                        })
                      }}
                    />
                    <Icon
                      icon="up"
                      sx={{
                        'fontSize': '18px',
                        '&:hover': {
                          color: themeVariables.info_main,
                        },
                      }}
                      tooltip={rLIB('Move Up')}
                      tooltipPlacement="left"
                      onClick={() => {
                        let proceedWithUpdate = false
                        let updateObject: TsInterface_UnspecifiedObject = {}
                        // Loop thrugh table data
                        for (let loopIndex = 0; loopIndex < sortedOptions.length; loopIndex++) {
                          let loopRowData = sortedOptions[loopIndex]
                          if (loopRowData.value === option.value) {
                            if (loopIndex > 0) {
                              proceedWithUpdate = true
                              let copyOfLoopRowData = { ...loopRowData }
                              let loopRowDataAbove = { ...sortedOptions[loopIndex - 1] }
                              updateObject['options'] = getProp(rowData, 'options', {})
                              updateObject['options'][copyOfLoopRowData.value as string]['order'] = loopRowDataAbove.order
                              updateObject['options'][loopRowDataAbove.value as string]['order'] = copyOfLoopRowData.order
                            }
                          }
                        }
                        // Update the database
                        if (proceedWithUpdate === true) {
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                                {
                                  type: 'setMerge',
                                  ref: DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                                  data: updateObject,
                                },
                              ]
                              DatabaseBatchUpdate(updateArray)
                                .then((res_DBU) => {
                                  tableHooks.ur_forceRerender()
                                })
                                .catch((rej_DBU) => {
                                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                                })
                            })
                            .catch((rej_GCK) => {
                              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            })
                        }
                      }}
                      className="tw-inline-block tw-mr-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
                    />
                    <Icon
                      icon="down"
                      sx={{
                        'fontSize': '18px',
                        '&:hover': {
                          color: themeVariables.info_main,
                        },
                      }}
                      tooltip={rLIB('Move Down')}
                      tooltipPlacement="right"
                      onClick={() => {
                        let proceedWithUpdate = false
                        let updateObject: TsInterface_UnspecifiedObject = {}
                        // Loop thrugh table data
                        for (let loopIndex = 0; loopIndex < sortedOptions.length; loopIndex++) {
                          let loopRowData = sortedOptions[loopIndex]
                          if (loopRowData.value === option.value) {
                            if (loopIndex < sortedOptions.length - 1) {
                              proceedWithUpdate = true
                              let copyOfLoopRowData = { ...loopRowData }
                              let loopRowDataBelow = { ...sortedOptions[loopIndex + 1] }
                              updateObject['options'] = getProp(rowData, 'options', {})
                              updateObject['options'][copyOfLoopRowData.value as string]['order'] = loopRowDataBelow.order
                              updateObject['options'][loopRowDataBelow.value as string]['order'] = copyOfLoopRowData.order
                            }
                          }
                        }
                        // Update the database
                        if (proceedWithUpdate === true) {
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                                {
                                  type: 'setMerge',
                                  ref: DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                                  data: updateObject,
                                },
                              ]
                              DatabaseBatchUpdate(updateArray)
                                .then((res_DBU) => {
                                  tableHooks.ur_forceRerender()
                                })
                                .catch((rej_DBU) => {
                                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                                })
                            })
                            .catch((rej_GCK) => {
                              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            })
                        }
                      }}
                      className="tw-inline-block tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
                    />
                  </Box>
                ))}
              </Box>
              <Box>
                <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Option')}</Box>
                <Icon
                  icon="circle-plus"
                  sx={{
                    '&:hover': {
                      color: themeVariables.success_main,
                    },
                  }}
                  className="tw-cursor-pointer tw-opacity-50 tw-ml-2 hover:tw-opacity-100"
                  onClick={() => {
                    tableHooks.uc_setUserInterface_PromptDialogDisplay({
                      display: true,
                      prompt: {
                        color: 'success',
                        confirm_text: rLIB('Save'),
                        default_value: '',
                        header: rLIB('Add Option'),
                        icon: (
                          <Icon
                            icon="circle-plus"
                            type="solid"
                          />
                        ),
                        input_label: rLIB('Option'),
                        input_type: 'text',
                        text: <>{rLIB('Create New Option')}</>,
                        submit_callback: (promptValue: string) => {
                          return new Promise((resolve, reject) => {
                            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                let updateObject = {
                                  options: {
                                    [promptValue]: { value: promptValue, order: new Date().getTime() },
                                  },
                                }
                                DatabaseSetMergeDocument(
                                  DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                                  updateObject,
                                )
                                  .then((res_DSMD) => {
                                    resolve({ close_dialog: true })
                                  })
                                  .catch((rej_DSMD) => {
                                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  })
                              })
                              .catch((rej_GCK) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              })
                          })
                        },
                      },
                    })
                  }}
                />
              </Box>
            </Box>
          )
        } else if (rowData != null && rowData.form_type != null && rowData.form_type === 'directory_multiple_choice') {
          cellJSX = (
            <Box>
              <FormControl
                className="bp_thin_select_input bp_thin_select_multiple_input"
                sx={{ minWidth: '130px', width: 'calc(100%)' }}
              >
                <InputLabel>{rLIB('Directory Item')}</InputLabel>
                <Select
                  autoWidth={true}
                  label={rLIB('Directory Item')}
                  onChange={(event, value) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      associated_directory_item_key: event.target.value,
                    }
                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(
                          DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                          updateObject,
                        )
                          .then((res_DSMD) => {
                            // Nothing
                          })
                          .catch((rej_DSMD) => {
                            console.error(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                      })
                  }}
                  value={rowData.associated_directory_item_key || ''}
                >
                  {objectToArray(tableAdditionalData.us_activePersistentClasses).map((option: TsInterface_UnspecifiedObject) => (
                    <MenuItem
                      key={option['key']}
                      value={option['key']}
                      disabled={option['disabled']}
                    >
                      {option['name']}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box>
                <FormControl
                  className="bp_thin_select_input bp_thin_select_multiple_input tw-mt-3"
                  sx={{ minWidth: '130px', width: 'calc(100%)' }}
                >
                  <InputLabel>{rLIB('Include "Other" Option')}</InputLabel>
                  <Select
                    autoWidth={true}
                    label={rLIB('Include "Other" Option')}
                    onChange={(event, value) => {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        include_other_option: event.target.value,
                      }
                      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(
                            DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                            updateObject,
                          )
                            .then((res_DSMD) => {
                              // Nothing
                            })
                            .catch((rej_DSMD) => {
                              console.error(rej_DSMD)
                            })
                        })
                        .catch((rej_GCK) => {
                          console.error(rej_GCK)
                        })
                    }}
                    value={rowData.include_other_option || ''}
                  >
                    {objectToArray(otherDirectoryOptions).map((option: TsInterface_UnspecifiedObject) => (
                      <MenuItem
                        key={option['key']}
                        value={option['key']}
                        disabled={option['disabled']}
                      >
                        {option['name']}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          )
        } else if (rowData != null && rowData.form_type != null && rowData.form_type === 'directory_field_verification') {
          const rJSX_EditDirectoryFieldVerificationField = (): JSX.Element => {
            let editIconJSX = (
              <Icon
                icon="pen-to-square"
                tooltip={rLIB('Edit Field')}
                tooltipPlacement="right"
                sx={{
                  '&:hover': {
                    color: themeVariables.success_main,
                  },
                }}
                className="tw-cursor-pointer tw-opacity-50 tw-ml-1 hover:tw-opacity-100"
                onClick={() => {
                  if (rowData.associated_directory_item_key != null) {
                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                      DatabaseGetCollection(DatabaseRef_ClassFields_Collection(res_GCK.clientKey, rowData.associated_directory_item_key as string))
                        .then((res_DGC) => {
                          let classFields = res_DGC.data
                          let optionsArray: TsInterface_UnspecifiedObject[] = []
                          for (let loopFieldKey in classFields) {
                            let loopFieldData = classFields[loopFieldKey]
                            if (loopFieldData.status != 'deleted') {
                              optionsArray.push({
                                key: loopFieldData.key,
                                value: loopFieldData.name,
                              })
                            }
                          }
                          tableHooks.uc_setUserInterface_FormDialogDisplay({
                            display: true,
                            form: {
                              form: {
                                formAdditionalData: {},
                                formData: rowData,
                                formInputs: {
                                  associated_directory_item_verification_field_key: {
                                    data_type: 'string',
                                    input_type: 'multiple_choice_select',
                                    key: 'associated_directory_item_verification_field_key',
                                    label: <>{rLIB('Field to Verify')}</>,
                                    required: true,
                                    options: optionsArray,
                                  },
                                },
                                formOnChange: (
                                  formAdditionalData: TsInterface_FormAdditionalData,
                                  formData: TsInterface_FormData,
                                  formInputs: TsInterface_FormInputs,
                                  formSettings: TsInterface_FormSettings,
                                ) => {},
                                formSettings: {},
                                formSubmission: (
                                  formSubmittedData: TsInterface_FormSubmittedData,
                                  formAdditionalData: TsInterface_FormAdditionalData,
                                  formHooks: TsInterface_FormHooksObject,
                                ) => {
                                  return new Promise((resolve, reject) => {
                                    let updateObject = formSubmittedData
                                    updateObject['associated_directory_item_verification_field_name'] = getProp(
                                      getProp(classFields, formSubmittedData.associated_directory_item_verification_field_key, {}),
                                      'name',
                                      null,
                                    )
                                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                                      .then((res_GCK) => {
                                        DatabaseSetMergeDocument(
                                          DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                                          updateObject,
                                        )
                                          .then((res_DSMD) => {
                                            resolve({ close_dialog: true })
                                          })
                                          .catch((rej_DSMD) => {
                                            console.error(rej_DSMD)
                                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                            reject(rej_DSMD)
                                          })
                                      })
                                      .catch((rej_GCK) => {
                                        console.error(rej_GCK)
                                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                        reject(rej_GCK)
                                      })
                                  })
                                },
                              },
                              dialog: {
                                formDialogHeaderColor: 'success',
                                formDialogHeaderText: <>{rLIB('Select Field to Verify')}</>,
                                formDialogIcon: (
                                  <Icon
                                    type="solid"
                                    icon="pen-to-square"
                                  />
                                ),
                              },
                            },
                          })
                        })
                        .catch((rej_DGC) => {
                          console.error(rej_DGC)
                        })
                    })
                    //
                  }
                }}
              />
            )
            let valueJSX = <Box className="tw-inline-block tw-italic tw-opacity-50">{rLIB('Missing Field to Verify')}</Box>
            if (
              rowData != null &&
              rowData.associated_directory_item_verification_field_key != null &&
              rowData.associated_directory_item_verification_field_name != null
            ) {
              valueJSX = <Box className="tw-inline-block">{rowData.associated_directory_item_verification_field_name}</Box>
            }
            return (
              <Box className="tw-mt-2">
                <Icon
                  icon="lock-keyhole"
                  className="tw-mr-2"
                  sx={{ color: themeVariables.warning_main, fontSize: '18px' }}
                />
                {valueJSX} {editIconJSX}
              </Box>
            )
          }
          cellJSX = (
            <Box>
              <Box>
                <FormControl
                  className="bp_thin_select_input bp_thin_select_multiple_input"
                  sx={{ minWidth: '130px', width: 'calc(100%)' }}
                >
                  <InputLabel>{rLIB('Directory Item')}</InputLabel>
                  <Select
                    autoWidth={true}
                    label={rLIB('Directory Item')}
                    onChange={(event, value) => {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        associated_directory_item_key: event.target.value,
                      }
                      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(
                            DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                            updateObject,
                          )
                            .then((res_DSMD) => {
                              // Nothing
                            })
                            .catch((rej_DSMD) => {
                              console.error(rej_DSMD)
                            })
                        })
                        .catch((rej_GCK) => {
                          console.error(rej_GCK)
                        })
                    }}
                    value={rowData.associated_directory_item_key || ''}
                  >
                    {objectToArray(tableAdditionalData.us_activePersistentClasses).map((option: TsInterface_UnspecifiedObject) => (
                      <MenuItem
                        key={option['key']}
                        value={option['key']}
                        disabled={option['disabled']}
                      >
                        {option['name']}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {rJSX_EditDirectoryFieldVerificationField()}
              </Box>
            </Box>
          )
        } else if (rowData != null && rowData.form_type != null && rowData.form_type === 'display') {
          cellJSX = (
            <Box>
              <Box
                className="tw-inline-block tw-align-top"
                sx={{
                  maxWidth: '150px',
                  overflowX: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {rowData.display_text}
              </Box>
              <Icon
                icon="pen-to-square"
                tooltip={rLIB('Edit Display Text')}
                tooltipPlacement="right"
                sx={{
                  '&:hover': {
                    color: themeVariables.success_main,
                  },
                }}
                className="tw-cursor-pointer tw-opacity-50 tw-ml-1 hover:tw-opacity-100"
                onClick={() => {
                  tableHooks.uc_setUserInterface_FormDialogDisplay({
                    display: true,
                    form: {
                      form: {
                        formAdditionalData: {},
                        formData: rowData,
                        formInputs: {
                          display_text: {
                            data_type: 'string',
                            input_type: 'text_multiline',
                            key: 'display_text',
                            label: rLIB('Display Text'),
                            required: true,
                          },
                        },
                        formOnChange: (
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formData: TsInterface_FormData,
                          formInputs: TsInterface_FormInputs,
                          formSettings: TsInterface_FormSettings,
                        ) => {},
                        formSettings: {},
                        formSubmission: (
                          formSubmittedData: TsInterface_FormSubmittedData,
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formHooks: TsInterface_FormHooksObject,
                        ) => {
                          return new Promise((resolve, reject) => {
                            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                let updateObject = formSubmittedData
                                DatabaseSetMergeDocument(
                                  DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                                  updateObject,
                                )
                                  .then((res_DSMD) => {
                                    resolve({ close_dialog: true })
                                  })
                                  .catch((rej_DSMD) => {
                                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  })
                              })
                              .catch((rej_GCK) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              })
                          })
                        },
                      },
                      dialog: {
                        formDialogHeaderColor: 'success',
                        formDialogHeaderText: (
                          <>
                            {rLIB('Edit Display Text')} - {rowData.name}
                          </>
                        ),
                        formDialogIcon: (
                          <Icon
                            type="solid"
                            icon="pen-to-square"
                          />
                        ),
                      },
                    },
                  })
                }}
              />
            </Box>
          )
        } else if (rowData != null && rowData.form_type != null && rowData.form_type === 'hyperlink') {
          // Prefix with https:// if not already
          let hyperlink = getProp(rowData, 'hyperlink', null)
          if (hyperlink != null && !hyperlink.startsWith('http')) {
            hyperlink = 'https://' + hyperlink
          }
          cellJSX = (
            <Box>
              <Box
                className="tw-inline-block tw-align-top"
                sx={{
                  maxWidth: '150px',
                  overflowX: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {rowData.hyperlink != null && rowData.hyperlink !== '' ? (
                  <a
                    href={hyperlink}
                    target="_blank"
                    rel="noreferrer"
                    className="tw-text-blue-500 tw-underline"
                  >
                    {getProp(rowData, 'display_text', '')}
                  </a>
                ) : (
                  <>{getProp(rowData, 'display_text', '')}</>
                )}
              </Box>
              <Icon
                icon="pen-to-square"
                tooltip={rLIB('Edit Display Text')}
                tooltipPlacement="right"
                sx={{
                  '&:hover': {
                    color: themeVariables.success_main,
                  },
                }}
                className="tw-cursor-pointer tw-opacity-50 tw-ml-1 hover:tw-opacity-100"
                onClick={() => {
                  tableHooks.uc_setUserInterface_FormDialogDisplay({
                    display: true,
                    form: {
                      form: {
                        formAdditionalData: {},
                        formData: rowData,
                        formInputs: {
                          display_text: {
                            data_type: 'string',
                            input_type: 'text_basic',
                            key: 'display_text',
                            label: rLIB('Display Text'),
                            required: true,
                          },
                          hyperlink: {
                            data_type: 'string',
                            input_type: 'text_basic',
                            key: 'hyperlink',
                            label: rLIB('Hyperlink'),
                            required: true,
                          },
                        },
                        formOnChange: (
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formData: TsInterface_FormData,
                          formInputs: TsInterface_FormInputs,
                          formSettings: TsInterface_FormSettings,
                        ) => {},
                        formSettings: {},
                        formSubmission: (
                          formSubmittedData: TsInterface_FormSubmittedData,
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formHooks: TsInterface_FormHooksObject,
                        ) => {
                          return new Promise((resolve, reject) => {
                            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                let updateObject = formSubmittedData
                                DatabaseSetMergeDocument(
                                  DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                                  updateObject,
                                )
                                  .then((res_DSMD) => {
                                    resolve({ close_dialog: true })
                                  })
                                  .catch((rej_DSMD) => {
                                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  })
                              })
                              .catch((rej_GCK) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              })
                          })
                        },
                      },
                      dialog: {
                        formDialogHeaderColor: 'success',
                        formDialogHeaderText: (
                          <>
                            {rLIB('Edit Hyperlink')} - {rowData.name}
                          </>
                        ),
                        formDialogIcon: (
                          <Icon
                            type="solid"
                            icon="pen-to-square"
                          />
                        ),
                      },
                    },
                  })
                }}
              />
            </Box>
          )
        } else if (rowData != null && rowData.form_type != null && rowData.form_type === 'number') {
          const rJSX_MinNumber = (): JSX.Element => {
            let numberJSX = <></>
            let editIconJSX = (
              <Icon
                icon="pen-to-square"
                tooltip={rLIB('Edit Min')}
                tooltipPlacement="right"
                sx={{
                  '&:hover': {
                    color: themeVariables.success_main,
                  },
                }}
                className="tw-cursor-pointer tw-opacity-50 tw-ml-1 hover:tw-opacity-100"
                onClick={() => {
                  tableHooks.uc_setUserInterface_FormDialogDisplay({
                    display: true,
                    form: {
                      form: {
                        formAdditionalData: {},
                        formData: rowData,
                        formInputs: {
                          min: {
                            data_type: 'number',
                            input_type: 'text_number',
                            key: 'min',
                            label: rLIB('Min'),
                            required: true,
                          },
                        },
                        formOnChange: (
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formData: TsInterface_FormData,
                          formInputs: TsInterface_FormInputs,
                          formSettings: TsInterface_FormSettings,
                        ) => {},
                        formSettings: {},
                        formSubmission: (
                          formSubmittedData: TsInterface_FormSubmittedData,
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formHooks: TsInterface_FormHooksObject,
                        ) => {
                          return new Promise((resolve, reject) => {
                            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                let updateObject = formSubmittedData
                                DatabaseSetMergeDocument(
                                  DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                                  updateObject,
                                )
                                  .then((res_DSMD) => {
                                    resolve({ close_dialog: true })
                                  })
                                  .catch((rej_DSMD) => {
                                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  })
                              })
                              .catch((rej_GCK) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              })
                          })
                        },
                      },
                      dialog: {
                        formDialogHeaderColor: 'success',
                        formDialogHeaderText: (
                          <>
                            {rLIB('Edit Min')} - {rowData.name}
                          </>
                        ),
                        formDialogIcon: (
                          <Icon
                            type="solid"
                            icon="pen-to-square"
                          />
                        ),
                      },
                    },
                  })
                }}
              />
            )
            let deleteIconJSX = (
              <Icon
                icon="trash"
                className="tw-cursor-pointer tw-opacity-10 tw-ml-2 hover:tw-opacity-100"
                tooltip={rLIB('Delete')}
                tooltipPlacement="right"
                sx={{
                  '&:hover': {
                    color: themeVariables.error_main,
                  },
                }}
                onClick={() => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = { min: null }
                      DatabaseSetMergeDocument(
                        DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                        updateObject,
                      )
                        .then((res_DSMD) => {
                          // Nothing
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                }}
              />
            )
            if (rowData != null && rowData.min != null && rowData.min !== '') {
              numberJSX = (
                <Box className="">
                  {rLIB('Min')}: {rowData.min}
                  {editIconJSX}
                  {deleteIconJSX}
                </Box>
              )
            } else {
              numberJSX = (
                <Box>
                  <Box className="tw-opacity-30 tw-inline-block">{rLIB('No min limit')}</Box>
                  {editIconJSX}
                </Box>
              )
            }
            return numberJSX
          }
          const rJSX_MaxNumber = (): JSX.Element => {
            let numberJSX = <></>
            let editIconJSX = (
              <Icon
                icon="pen-to-square"
                tooltip={rLIB('Edit Max')}
                tooltipPlacement="right"
                sx={{
                  '&:hover': {
                    color: themeVariables.success_main,
                  },
                }}
                className="tw-cursor-pointer tw-opacity-50 tw-ml-1 hover:tw-opacity-100"
                onClick={() => {
                  tableHooks.uc_setUserInterface_FormDialogDisplay({
                    display: true,
                    form: {
                      form: {
                        formAdditionalData: {},
                        formData: rowData,
                        formInputs: {
                          max: {
                            data_type: 'number',
                            input_type: 'text_number',
                            key: 'max',
                            label: rLIB('Max'),
                            required: true,
                          },
                        },
                        formOnChange: (
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formData: TsInterface_FormData,
                          formInputs: TsInterface_FormInputs,
                          formSettings: TsInterface_FormSettings,
                        ) => {},
                        formSettings: {},
                        formSubmission: (
                          formSubmittedData: TsInterface_FormSubmittedData,
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formHooks: TsInterface_FormHooksObject,
                        ) => {
                          return new Promise((resolve, reject) => {
                            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                let updateObject = formSubmittedData
                                DatabaseSetMergeDocument(
                                  DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                                  updateObject,
                                )
                                  .then((res_DSMD) => {
                                    resolve({ close_dialog: true })
                                  })
                                  .catch((rej_DSMD) => {
                                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  })
                              })
                              .catch((rej_GCK) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              })
                          })
                        },
                      },
                      dialog: {
                        formDialogHeaderColor: 'success',
                        formDialogHeaderText: (
                          <>
                            {rLIB('Edit Max')} - {rowData.name}
                          </>
                        ),
                        formDialogIcon: (
                          <Icon
                            type="solid"
                            icon="pen-to-square"
                          />
                        ),
                      },
                    },
                  })
                }}
              />
            )
            let deleteIconJSX = (
              <Icon
                icon="trash"
                className="tw-cursor-pointer tw-opacity-10 tw-ml-2 hover:tw-opacity-100"
                tooltip={rLIB('Delete')}
                tooltipPlacement="right"
                sx={{
                  '&:hover': {
                    color: themeVariables.error_main,
                  },
                }}
                onClick={() => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = { max: null }
                      DatabaseSetMergeDocument(
                        DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                        updateObject,
                      )
                        .then((res_DSMD) => {
                          // Nothing
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                }}
              />
            )
            if (rowData != null && rowData.max != null && rowData.max !== '') {
              numberJSX = (
                <Box className="">
                  {rLIB('Max')}: {rowData.max}
                  {editIconJSX}
                  {deleteIconJSX}
                </Box>
              )
            } else {
              numberJSX = (
                <Box>
                  <Box className="tw-opacity-30 tw-inline-block">{rLIB('No max limit')}</Box>
                  {editIconJSX}
                </Box>
              )
            }
            return numberJSX
          }
          cellJSX = (
            <Box>
              <Box className="tw-mb-1">{rJSX_MinNumber()}</Box>
              <Box>{rJSX_MaxNumber()}</Box>
            </Box>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Options')
      },
      header_sort_by: null,
    },
  },
  status: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let checked = false
        if (rowData.status === 'active') {
          checked = true
        }
        cellJSX = (
          <Box>
            <Switch
              color="info"
              checked={checked}
              onChange={(event, value) => {
                if (value === true) {
                  let updateObject = { status: 'active' }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(
                        DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                        updateObject,
                      )
                        .then((res_DSMD) => {
                          // Nothing
                        })
                        .catch((rej_DSMD) => {
                          console.error(rej_DSMD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                } else if (value === false) {
                  let updateObject = { status: 'inactive' }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(
                        DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                        updateObject,
                      )
                        .then((res_DSMD) => {
                          // Nothing
                        })
                        .catch((rej_DSMD) => {
                          console.error(rej_DSMD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                }
              }}
            />
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Active')
      },
      header_sort_by: null,
    },
  },
  required: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let checked = false
        if (rowData.required === true) {
          checked = true
        }
        cellJSX = (
          <Box>
            <Switch
              color="info"
              checked={checked}
              disabled={rowData.form_type === 'display'}
              onChange={(event, value) => {
                if (value === true) {
                  let updateObject = { required: true }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(
                        DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                        updateObject,
                      )
                        .then((res_DSMD) => {
                          // Nothing
                        })
                        .catch((rej_DSMD) => {
                          console.error(rej_DSMD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                } else if (value === false) {
                  let updateObject = { required: false }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(
                        DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, rowData.key as string),
                        updateObject,
                      )
                        .then((res_DSMD) => {
                          // Nothing
                        })
                        .catch((rej_DSMD) => {
                          console.error(rej_DSMD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                }
              }}
            />
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Required')
      },
      header_sort_by: null,
    },
  },
  TEMP_move: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = (
          <Box>
            <Icon
              icon="up"
              sx={{
                'fontSize': '18px',
                '&:hover': {
                  color: themeVariables.info_main,
                },
              }}
              tooltip={rLIB('Move Up')}
              tooltipPlacement="top"
              onClick={() => {
                let proceedWithUpdate = false
                let updateObjectKey1: string | null = null
                let updateObjectKey2: string | null = null
                let updateObject1: TsInterface_UnspecifiedObject = {}
                let updateObject2: TsInterface_UnspecifiedObject = {}
                // Loop thrugh table data
                for (let loopIndex = 0; loopIndex < tableAdditionalData.tableData.length; loopIndex++) {
                  let loopRowData = tableAdditionalData.tableData[loopIndex]
                  if (loopRowData.key === rowData.key) {
                    if (loopIndex > 0) {
                      proceedWithUpdate = true
                      let loopRowDataAbove = tableAdditionalData.tableData[loopIndex - 1]
                      updateObject1['order'] = loopRowDataAbove.order
                      updateObject2['order'] = loopRowData.order
                      updateObjectKey1 = loopRowData.key
                      updateObjectKey2 = loopRowDataAbove.key
                    }
                  }
                }
                // Update the database
                if (proceedWithUpdate === true) {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                        {
                          type: 'setMerge',
                          ref: DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, updateObjectKey1 as string),
                          data: updateObject1,
                        },
                        {
                          type: 'setMerge',
                          ref: DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, updateObjectKey2 as string),
                          data: updateObject2,
                        },
                      ]
                      DatabaseBatchUpdate(updateArray)
                        .then((res_DBU) => {
                          // Nothing
                        })
                        .catch((rej_DBU) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                }
              }}
              className="tw-inline-block tw-mr-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
            />
            <Icon
              icon="down"
              sx={{
                'fontSize': '18px',
                '&:hover': {
                  color: themeVariables.info_main,
                },
              }}
              tooltip={rLIB('Move Down')}
              tooltipPlacement="bottom"
              onClick={() => {
                let proceedWithUpdate = false
                let updateObjectKey1: string | null = null
                let updateObjectKey2: string | null = null
                let updateObject1: TsInterface_UnspecifiedObject = {}
                let updateObject2: TsInterface_UnspecifiedObject = {}
                // Loop thrugh table data
                for (let loopIndex = 0; loopIndex < tableAdditionalData.tableData.length; loopIndex++) {
                  let loopRowData = tableAdditionalData.tableData[loopIndex]
                  if (loopRowData.key === rowData.key) {
                    if (loopIndex < tableAdditionalData.tableData.length - 1) {
                      proceedWithUpdate = true
                      let loopRowDataBelow = tableAdditionalData.tableData[loopIndex + 1]
                      updateObject1['order'] = loopRowDataBelow.order
                      updateObject2['order'] = loopRowData.order
                      updateObjectKey1 = loopRowData.key
                      updateObjectKey2 = loopRowDataBelow.key
                    }
                  }
                }
                // Update the database
                if (proceedWithUpdate === true) {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                        {
                          type: 'setMerge',
                          ref: DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, updateObjectKey1 as string),
                          data: updateObject1,
                        },
                        {
                          type: 'setMerge',
                          ref: DatabaseRef_ClassFields_Document(res_GCK.clientKey, tableAdditionalData.itemKey, updateObjectKey2 as string),
                          data: updateObject2,
                        },
                      ]
                      DatabaseBatchUpdate(updateArray)
                        .then((res_DBU) => {
                          // Nothing
                        })
                        .catch((rej_DBU) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                }
              }}
              className="tw-inline-block tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
            />
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

const returnFormattedDatabaseKey = (str: string): string => {
  // Convert the input string to lowercase
  let lowerCaseStr = str.toLowerCase()
  // Remove any whitespace at the beginning and end of the string
  let trimmedStr = lowerCaseStr.trim()
  // Replace spaces with underscores
  let withUnderscores = trimmedStr.replace(/ /g, '_')
  // Remove non-alphanumeric characters except underscores
  let cleanedString = withUnderscores.replace(/[^a-z0-9_]/g, '')
  return cleanedString
}

const renameClassField = (clientKey: string, classKey: string, fieldKey: string, newName: string) => {
  return new Promise((resolve, reject) => {
    let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
      {
        type: 'setMerge',
        ref: DatabaseRef_ClassFields_Document(clientKey, classKey, fieldKey),
        data: {
          name: newName,
        },
      },
    ]
    DatabaseBatchUpdate(updateArray)
      .then((res_DBU) => {
        resolve(res_DBU)
      })
      .catch((rej_DBU) => {
        reject(rej_DBU)
      })
  })
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_selectedCustomFormKey, us_setSelectedCustomFormKey] = useState<string>('DEFAULT')
  const [us_customForms, us_setCustomForms] = useState<TsInterface_UnspecifiedObject>({})
  const [us_calculatedFieldsList, us_setCalculatedFieldsList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeLookupTables, us_setActiveLookupTables] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activePersistentClasses, us_setActivePersistentClasses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_class, us_setClass] = useState<TsInterface_UnspecifiedObject>({})
  const [us_classCalculatedFields, us_setClassCalculatedFields] = useState<TsInterface_UnspecifiedObject>({})
  const [us_classFields, us_setClassFields] = useState<TsInterface_UnspecifiedObject>({})
  const [us_loadedFormFields, us_setLoadedFormFields] = useState<boolean>(false)
  const [us_selectedDataFieldFilterType, us_setSelectedDataFieldFilterType] = useState<string>('active')
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  const [us_activeFormulas, us_setActiveFormulas] = useState<TsInterface_UnspecifiedObject>({})
  const [us_copyingClass, us_setCopyingClass] = useState<boolean>(false)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  let un_routerNavigation = useNavigate()
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    if (us_class != null && us_class.name != null) {
      document.title = (rLIB('Form', false) as string) + ' - ' + us_class.name
    } else {
      document.title = rLIB('Form', false) as string
    }
  }, [us_class])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setClass(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Class_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setCustomForms(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveClassCustomForms_Query(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setLoadedFormFields(true)
      us_setClassFields(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ClassFields_Collection(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setClassCalculatedFields(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ClassCalculatedFields_Collection(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setLoadedFormFields(true)
      us_setClassFields(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ClassFields_Collection(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActivePersistentClasses(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActivePersistentClasses_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setCalculatedFieldsList(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveCalculatedFields_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveFormulas(newData)
      // ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveFormulas_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveLookupTables(newData)
      // ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveLookupTables_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  // Functions
  const updateClassRootData = (newData: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_Class_Document(res_GCK.clientKey, itemKey), newData)
            .then((res_DSMD) => {
              resolve(res_DSMD)
            })
            .catch((rej_DSMD) => {
              reject(rej_DSMD)
            })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
        })
    })
  }

  const returnFilteredFormFields = (): TsInterface_UnspecifiedObject[] => {
    let filteredFormData: TsInterface_UnspecifiedObject[] = []
    if (us_selectedDataFieldFilterType === 'all') {
      filteredFormData = objectToArray(us_classFields)
    } else if (us_selectedDataFieldFilterType === 'active') {
      filteredFormData = objectToArray(us_classFields).filter((object) => object.status === 'active')
    } else if (us_selectedDataFieldFilterType === 'inactive') {
      filteredFormData = objectToArray(us_classFields).filter((object) => object.status === 'inactive')
    }
    return filteredFormData
  }

  const createFormField = (): void => {
    uc_setUserInterface_PromptDialogDisplay({
      display: true,
      prompt: {
        color: 'success',
        confirm_text: rLIB('Add Field') as JSX.Element,
        default_value: null,
        header: rLIB('Create field for form'),
        icon: (
          <Icon
            icon="pen-to-square"
            type="solid"
          />
        ),
        input_label: rLIB('Field name') as JSX.Element,
        input_type: 'text',
        text: rLIB('Enter a name for the new data field'),
        submit_callback: (promptValue: string) => {
          return new Promise((resolve, reject) => {
            let fieldKey = returnFormattedDatabaseKey(promptValue) + '_' + new Date().getTime()
            let updateObject: TsInterface_UnspecifiedObject = {
              status: 'active',
              name: promptValue,
              key: fieldKey,
              order: new Date().getTime(),
            }
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseSetMergeDocument(DatabaseRef_ClassFields_Document(res_GCK.clientKey, itemKey, fieldKey), updateObject)
                  .then((res_DSMD) => {
                    resolve(res_DSMD)
                  })
                  .catch((rej_DSMD) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    reject(rej_DSMD)
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
      },
    })
  }

  const addCalculatedField = () => {
    let calculatedFieldOptions = []
    for (let loopCalculatedFieldKey in us_calculatedFieldsList) {
      let loopCalculatedField = us_calculatedFieldsList[loopCalculatedFieldKey]
      if (loopCalculatedField != null && loopCalculatedField.status === 'active') {
        calculatedFieldOptions.push({
          key: loopCalculatedFieldKey,
          value: loopCalculatedField.name,
        })
      }
    }
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            // name: {
            //   data_type: 'string',
            //   input_type: 'text_basic',
            //   key: 'name',
            //   label: rLIB('Calculated Field Name'),
            //   required: true,
            // },
            associated_calculated_field_key: {
              data_type: 'string',
              input_type: 'multiple_choice_select',
              key: 'associated_calculated_field_key',
              label: rLIB('Calculated Field'),
              options: calculatedFieldOptions,
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let updateObject = { ...formSubmittedData }
              updateObject['status'] = 'active'
              updateObject['key'] = formSubmittedData['associated_calculated_field_key']
              updateObject['name'] = getProp(us_calculatedFieldsList, formSubmittedData['associated_calculated_field_key'] + '.name', '')
              updateObject['associated_calculated_field_name'] = getProp(
                us_calculatedFieldsList,
                formSubmittedData['associated_calculated_field_key'] + '.name',
                '',
              )
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_ClassCalculatedFields_Document(res_GCK.clientKey, itemKey, updateObject['key']), updateObject)
                    .then((res_DAD) => {
                      resolve(res_DAD)
                    })
                    .catch((rej_DAD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                      reject(rej_DAD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('New Calculated Field')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const editCalculatedFieldName = (calculatedField: TsInterface_UnspecifiedObject, calculatedFieldKey: string) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: calculatedField,
          formInputs: {
            name: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'name',
              label: rLIB('Calculated Field Name'),
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = { ...formSubmittedData }
                  DatabaseSetMergeDocument(DatabaseRef_ClassCalculatedFields_Document(res_GCK.clientKey, itemKey, calculatedFieldKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('New Calculated Field')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const editCalculatedFieldAssociatedFormula = (calculatedField: TsInterface_UnspecifiedObject, calculatedFieldKey: string) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: calculatedField,
          formInputs: {
            associated_formula_key: {
              data_type: 'string',
              input_type: 'multiple_choice_select',
              key: 'associated_formula_key',
              label: rLIB('Formula'),
              required: true,
              options: objectToArray(us_activeFormulas)
                .sort(dynamicSort('name', 'asc'))
                .map((loopFormula) => {
                  return {
                    key: loopFormula.key,
                    value: loopFormula.name,
                  }
                }),
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = { ...formSubmittedData }
                  updateObject['associated_formula_name'] = getProp(us_activeFormulas, formSubmittedData['associated_formula_key'] + '.name', '')
                  DatabaseSetMergeDocument(DatabaseRef_ClassCalculatedFields_Document(res_GCK.clientKey, itemKey, calculatedFieldKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('New Calculated Field')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const copyClassToOtherWorkspace = (): void => {
    us_setCopyingClass(true)
    cloudFunctionManageRequest('manageUser', {
      function: 'getAvailableClientsList',
      user_key: uc_RootData_GlobalUser.key,
    })
      .then((res_CFMUR: unknown) => {
        let clientOptions: TsInterface_UnspecifiedObject = {}
        for (let loopWorkspaceKey in getProp(res_CFMUR, 'clientToggleOptions', {})) {
          let loopWorkspace = getProp(res_CFMUR, 'clientToggleOptions', {})[loopWorkspaceKey]
          if (loopWorkspace != null && loopWorkspace.name != null && loopWorkspace.key != null) {
            clientOptions[loopWorkspaceKey] = {
              key: loopWorkspace.key,
              value: loopWorkspace.name,
            }
          }
        }
        us_setCopyingClass(false)
        uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: {
            form: {
              formAdditionalData: {},
              formData: {},
              formInputs: {
                client_key: {
                  data_type: 'string',
                  input_type: 'multiple_choice_radio',
                  key: 'client_key',
                  label: rLIB('Workspace to copy to'),
                  required: true,
                  options: objectToArray(clientOptions),
                },
              },
              formOnChange: (
                formAdditionalData: TsInterface_FormAdditionalData,
                formData: TsInterface_FormData,
                formInputs: TsInterface_FormInputs,
                formSettings: TsInterface_FormSettings,
              ) => {},
              formSettings: {},
              formSubmission: (
                formSubmittedData: TsInterface_FormSubmittedData,
                formAdditionalData: TsInterface_FormAdditionalData,
                formHooks: TsInterface_FormHooksObject,
              ) => {
                return new Promise((resolve, reject) => {
                  console.log(formSubmittedData)
                  // Copy Class and sub-collections to specified workspace
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                  updateArray.push({
                    type: 'setMerge',
                    ref: DatabaseRef_Class_Document(formSubmittedData.client_key, itemKey),
                    data: us_class,
                  })
                  for (let loopFieldKey in us_classFields) {
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_ClassFields_Document(formSubmittedData.client_key, itemKey, loopFieldKey),
                      data: us_classFields[loopFieldKey],
                    })
                  }
                  for (let loopCalculatedFieldKey in us_classCalculatedFields) {
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_ClassCalculatedFields_Document(formSubmittedData.client_key, itemKey, loopCalculatedFieldKey),
                      data: us_classCalculatedFields[loopCalculatedFieldKey],
                    })
                  }

                  console.log(updateArray)

                  DatabaseStagedBatchUpdate(updateArray)
                    .then((res_DSBU) => {
                      resolve(res_DSBU)
                    })
                    .catch((rej_DSBU) => {
                      reject(rej_DSBU)
                    })
                })
              },
            },
            dialog: {
              formDialogHeaderColor: 'success',
              formDialogHeaderText: <>{rLIB('Copy Class to Other Workspace')}</>,
              formDialogIcon: (
                <Icon
                  type="solid"
                  icon="pen-to-square"
                />
              ),
            },
          },
        })
      })
      .catch((rej_CFMUR: TsInterface_GenericPromiseReject) => {
        console.error(rej_CFMUR)
        us_setCopyingClass(false)
      })
  }

  const createCustomForm = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            name: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'name',
              label: rLIB('Custom Form Name'),
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let updateObject = { ...formSubmittedData }
              updateObject['status'] = 'active'
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseAddDocument(DatabaseRef_ClassCustomForms_Collection(res_GCK.clientKey, itemKey), updateObject, true)
                    .then((res_DAD) => {
                      us_setSelectedCustomFormKey(res_DAD.key)
                      resolve(res_DAD)
                    })
                    .catch((rej_DAD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                      reject(rej_DAD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('New Custom Form')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const deleteCustomForm = (customFormKey: string): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: rLIB('Delete Custom Form'),
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: rLIB('Delete'),
        text: rLIB('Are you sure that you want to delete this Custom Form?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let updateObject = {
                  status: 'deleted',
                }
                DatabaseSetMergeDocument(DatabaseRef_ClassCustomForms_Document(res_GCK.clientKey, itemKey, customFormKey), updateObject)
                  .then((res_DSMD) => {
                    resolve(res_DSMD)
                    us_setSelectedCustomFormKey('DEFAULT')
                  })
                  .catch((rej_DSMD) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    reject(rej_DSMD)
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
      },
    })
  }

  const addFormFieldToCustomForm = (field: TsInterface_UnspecifiedObject): void => {
    let updateObject = {
      fields: {
        [field.key]: new Date().getTime(),
      },
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseSetMergeDocument(DatabaseRef_ClassCustomForms_Document(res_GCK.clientKey, itemKey, us_selectedCustomFormKey), updateObject)
          .then((res_DSMD) => {
            console.log(res_DSMD)
          })
          .catch((rej_DSMD) => {
            console.error(rej_DSMD)
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const removeFormFieldFromCustomForm = (field: TsInterface_UnspecifiedObject): void => {
    let updateObject = {
      fields: {
        [field.key]: null,
      },
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseSetMergeDocument(DatabaseRef_ClassCustomForms_Document(res_GCK.clientKey, itemKey, us_selectedCustomFormKey), updateObject)
          .then((res_DSMD) => {
            console.log(res_DSMD)
          })
          .catch((rej_DSMD) => {
            console.error(rej_DSMD)
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const moveFormFieldUpInCustomForm = (field: TsInterface_UnspecifiedObject): void => {
    if (us_selectedCustomFormKey && us_customForms[us_selectedCustomFormKey]) {
      let fields = us_customForms[us_selectedCustomFormKey].fields
      let tempArray = []

      // Create a temporary array with key and order
      for (let key in fields) {
        tempArray.push({ key: key, order: fields[key] })
      }

      // Sort the array by order
      tempArray.sort((a, b) => a.order - b.order)

      // Find the index of the current field
      let currentIndex = -1
      for (let i = 0; i < tempArray.length; i++) {
        if (tempArray[i].key === field.key) {
          currentIndex = i
          break
        }
      }

      // If not at the top, swap with the field above
      if (currentIndex > 0) {
        let temp = tempArray[currentIndex].order
        tempArray[currentIndex].order = tempArray[currentIndex - 1].order
        tempArray[currentIndex - 1].order = temp

        // Create updated fields object
        let updatedFields: TsInterface_UnspecifiedObject = {}
        for (let item of tempArray) {
          updatedFields[item.key] = item.order
        }

        // Update the custom form
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseSetMergeDocument(DatabaseRef_ClassCustomForms_Document(res_GCK.clientKey, itemKey, us_selectedCustomFormKey), { fields: updatedFields })
              .then((res_DSMD) => {
                // Optionally handle success
              })
              .catch((rej_DSMD) => {
                console.error(rej_DSMD)
                // Handle error
              })
          })
          .catch((rej_GCK) => {
            console.error(rej_GCK)
            // Handle error
          })
      }
    }
  }

  const moveFormFieldDownInCustomForm = (field: TsInterface_UnspecifiedObject): void => {
    if (us_selectedCustomFormKey && us_customForms[us_selectedCustomFormKey]) {
      let fields = us_customForms[us_selectedCustomFormKey].fields
      let tempArray = []

      // Create a temporary array with key and order
      for (let key in fields) {
        tempArray.push({ key: key, order: fields[key] })
      }

      // Sort the array by order
      tempArray.sort((a, b) => a.order - b.order)

      // Find the index of the current field
      let currentIndex = -1
      for (let i = 0; i < tempArray.length; i++) {
        if (tempArray[i].key === field.key) {
          currentIndex = i
          break
        }
      }

      // If not at the bottom, swap with the field below
      if (currentIndex < tempArray.length - 1) {
        let temp = tempArray[currentIndex].order
        tempArray[currentIndex].order = tempArray[currentIndex + 1].order
        tempArray[currentIndex + 1].order = temp

        // Create updated fields object
        let updatedFields: TsInterface_UnspecifiedObject = {}
        for (let item of tempArray) {
          updatedFields[item.key] = item.order
        }

        // Update the custom form
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseSetMergeDocument(DatabaseRef_ClassCustomForms_Document(res_GCK.clientKey, itemKey, us_selectedCustomFormKey), { fields: updatedFields })
              .then((res_DSMD) => {
                // Optionally handle success
              })
              .catch((rej_DSMD) => {
                console.error(rej_DSMD)
                // Handle error
              })
          })
          .catch((rej_GCK) => {
            console.error(rej_GCK)
            // Handle error
          })
      }
    }
  }

  // JSX Generation
  const rJSX_BackButton = (shrinkButton: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrinkButton === true) {
      buttonJSX = (
        <Tooltip
          title={rLIB('Back to all Forms')}
          placement="top"
        >
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => {
              un_routerNavigation(ApplicationPages.ClassesListPage.url())
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button tw-mb-2"
          >
            <Icon icon="chevron-left" />
          </Button>
        </Tooltip>
      )
    } else {
      buttonJSX = (
        <Button
          color="inherit"
          variant="outlined"
          onClick={() => {
            un_routerNavigation(ApplicationPages.ClassesListPage.url())
          }}
          disableElevation
          startIcon={<Icon icon="chevron-left" />}
          className="tw-mr-2 tw-mb-2"
        >
          {rLIB('Back to all Forms')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_CopyButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (uc_RootData_GlobalUser != null && uc_RootData_GlobalUser.super === true) {
      if (shrink === true) {
        buttonJSX = (
          <Tooltip
            title={rLIB('Copy To Other Workspace')}
            placement="top"
          >
            <Button
              color="secondary"
              variant="outlined"
              disableElevation
              className="bp_icon_only_button tw-mb-2"
              disabled={us_copyingClass}
              onClick={() => {
                copyClassToOtherWorkspace()
              }}
            >
              {us_copyingClass ? (
                <Icon
                  icon="arrows-rotate"
                  className="bp_spin"
                />
              ) : (
                <Icon icon="copy" />
              )}
            </Button>
          </Tooltip>
        )
      } else {
        buttonJSX = (
          <Button
            color="secondary"
            variant="contained"
            disabled={us_copyingClass}
            startIcon={
              us_copyingClass ? (
                <Icon
                  icon="arrows-rotate"
                  className="bp_spin"
                />
              ) : (
                <Icon icon="copy" />
              )
            }
            onClick={() => {
              copyClassToOtherWorkspace()
            }}
            className="tw-mb-2 tw-ml-2"
          >
            {rLIB('Copy To Other Workspace')}
          </Button>
        )
      }
    }
    return buttonJSX
  }

  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <></>
    if (us_class != null && us_class.name != null) {
      pageHeader = (
        <Box>
          <Box className="tw-inline-block tw-mr-1">{rLIB('Form')}:</Box>
          <Box className="tw-inline-block tw-opacity-50">{us_class.name}</Box>
        </Box>
      )
    } else {
      pageHeader = rLIB('Form') as JSX.Element
    }
    return pageHeader
  }

  const rJSX_FieldsTabContent = (): JSX.Element => {
    let tableJSX = <></>
    if (us_loadedFormFields === true) {
      tableJSX = (
        <Card className="tw-mb-2">
          <TableBasic
            tableAdditionalData={{
              itemKey: itemKey,
              tableData: returnFilteredFormFields().sort(dynamicSort('order', 'asc')),
              us_activePersistentClasses: us_activePersistentClasses,
            }}
            tableColumns={tableColumns_FormFields}
            tableData={returnFilteredFormFields().sort(dynamicSort('order', 'asc'))}
            tableSettings={tableSettings_FormFields}
          />
        </Card>
      )
    } else {
      tableJSX = <LinearProgress color="error" />
    }
    return tableJSX
  }

  const rJSX_FilterFieldsDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="tw-ml-2 bp_thin_select_input bp_thin_select_multiple_input tw-mb-2"
        sx={{ minWidth: '130px' }}
      >
        <InputLabel>{rLIB('Filter')}</InputLabel>
        <Select
          autoWidth={true}
          label={rLIB('Filter')}
          onChange={(event, value) => {
            us_setSelectedDataFieldFilterType(event.target.value)
          }}
          value={us_selectedDataFieldFilterType}
        >
          {objectToArray(dataFieldFilters).map((option: TsInterface_UnspecifiedObject) => (
            <MenuItem
              key={option['key']}
              value={option['key']}
              disabled={option['disabled']}
            >
              {option['value']}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_AssociatedClasses = (): JSX.Element => {
    let associatedClassesJSX = <></>
    if (us_class != null && us_class.class_type === 'transactional_data') {
      associatedClassesJSX = (
        <Box className="tw-pt-2">
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {rLIB('Directory items that can be associated with this transactional data form')}
          </Typography>
          <Box>
            {objectToArray(us_activePersistentClasses)
              .sort(dynamicSort('name', null))
              .map((loopClass: TsInterface_UnspecifiedObject, index: number) => (
                <Box key={index}>
                  <Checkbox
                    checked={getProp(getProp(us_class, 'associated_related_class_keys', {}), loopClass.key, false)}
                    onChange={(event, value) => {
                      if (value != null) {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          associated_related_class_keys: {
                            [loopClass.key]: value,
                          },
                        }
                        updateClassRootData(updateObject)
                      }
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  {loopClass.name}
                </Box>
              ))}
          </Box>
        </Box>
      )
    }
    return associatedClassesJSX
  }

  const rJSX_ClassType = (): JSX.Element => {
    let classTypeJSX = <></>
    if (us_class.class_type === 'persistent_data') {
      classTypeJSX = (
        <Chip
          color="info"
          variant="outlined"
          label={
            <Box>
              <Icon
                icon="database"
                className="tw-mr-2"
              />
              {rLIB('Persistent Data')}
            </Box>
          }
        />
      )
    } else if (us_class.class_type === 'transactional_data') {
      classTypeJSX = (
        <Chip
          color="primary"
          variant="outlined"
          label={
            <Box>
              <Icon
                icon="database"
                className="tw-mr-2"
              />
              {rLIB('Transactional Data')}
            </Box>
          }
        />
      )
    } else {
      classTypeJSX = (
        <Chip
          color="warning"
          label={
            <Box>
              <Icon
                icon="database"
                className="tw-mr-2"
              />
              {us_class.class_type}
            </Box>
          }
        />
      )
    }
    return classTypeJSX
  }

  const rJSX_NewFieldButton = (shrinkButton: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrinkButton === true) {
      buttonJSX = (
        <Tooltip
          title={rLIB('Create Field')}
          placement="top"
        >
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              createFormField()
            }}
            disableElevation
            className="bp_icon_only_button tw-mb-2"
          >
            <Icon icon="circle-plus" />
          </Button>
        </Tooltip>
      )
    } else {
      buttonJSX = (
        <Button
          className="tw-mb-2"
          variant="contained"
          color="success"
          onClick={() => {
            createFormField()
          }}
          startIcon={<Icon icon="circle-plus" />}
        >
          {rLIB('Create Field')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_NewCalculatedFieldButton = (shrinkButton: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrinkButton === true) {
      buttonJSX = (
        <Tooltip
          title={rLIB('Add Calculated Field')}
          placement="top"
        >
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              addCalculatedField()
            }}
            disableElevation
            className="bp_icon_only_button tw-mb-2"
          >
            <Icon icon="circle-plus" />
          </Button>
        </Tooltip>
      )
    } else {
      buttonJSX = (
        <Button
          className="tw-mb-2"
          variant="contained"
          color="success"
          onClick={() => {
            addCalculatedField()
          }}
          startIcon={<Icon icon="circle-plus" />}
        >
          {rLIB('Add Calculated Field')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_SettingsTabContent = (): JSX.Element => {
    let contentJSX = (
      <Card className="tw-p-4">
        <Box>
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {rLIB('Type')}
          </Typography>
          <Box className="tw-pl-4">{rJSX_ClassType()}</Box>
        </Box>
        {rJSX_AssociatedClasses()}
      </Card>
    )
    return contentJSX
  }

  // Calculated Fields // TODO: Edit in data_bucket_view.tsx as well
  const rJSX_CalculatedFieldIcon = (calculatedField: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = <></>
    if (calculatedField != null && calculatedField['associated_formula_key'] == null) {
      iconJSX = (
        <Icon
          icon="triangle-exclamation"
          className="tw-mr-2"
          tooltip={rLIB('Calculated Field Missing Formula')}
          tooltipPlacement="right"
          sx={{ color: themeVariables.error_main }}
        />
      )
    } else {
      // TODO: Add other bad format cases
      iconJSX = (
        <Icon
          icon="badge-check"
          className="tw-mr-2"
          tooltip={rLIB('Calculated Field Setup Complete')}
          tooltipPlacement="right"
          sx={{ color: themeVariables.success_main }}
        />
      )
    }
    return iconJSX
  }

  const rJSX_EditCalculatedFieldButton = (calculatedField: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = (
      <Box
        className="tw-inline-block tw-opacity-30 tw-cursor-pointer tw-ml-2 hover:tw-opacity-100"
        sx={{
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        onClick={(event: any) => {
          event.stopPropagation()
          editCalculatedFieldName(calculatedField, calculatedField.key)
        }}
      >
        <Icon
          icon="pen-to-square"
          tooltip={rLIB('Edit Calculated Field')}
          tooltipPlacement="right"
        />
      </Box>
    )
    return iconJSX
  }

  const rJSX_EditCalculatedFieldFormulaIcon = (calculatedField: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = (
      <Box
        className="tw-inline-block tw-opacity-30 tw-cursor-pointer tw-ml-2 hover:tw-opacity-100"
        sx={{
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        onClick={(event: any) => {
          event.stopPropagation()
          editCalculatedFieldAssociatedFormula(calculatedField, calculatedField.key)
        }}
      >
        <Icon
          icon="pen-to-square"
          tooltip={rLIB('Edit Calculated Field Formula')}
          tooltipPlacement="right"
        />
      </Box>
    )
    return iconJSX
  }

  const rJSX_CalculationFormulaName = (calculatedField: TsInterface_UnspecifiedObject): string => {
    let formulaName = ''
    if (
      calculatedField != null &&
      calculatedField.associated_formula_key != null &&
      us_activeFormulas != null &&
      us_activeFormulas[calculatedField.associated_formula_key] != null &&
      us_activeFormulas[calculatedField.associated_formula_key].name != null
    ) {
      formulaName = us_activeFormulas[calculatedField.associated_formula_key].name
    } else if (calculatedField != null && calculatedField.associated_formula_name != null) {
      formulaName = calculatedField.associated_formula_name
    }
    return formulaName
  }

  const rJSX_CalculatedFieldCard = (calculatedField: TsInterface_UnspecifiedObject): JSX.Element => {
    let calculatedFieldJSX = <></>
    let variablesForCalculatedField: TsInterface_UnspecifiedObject[] = []
    if (
      calculatedField != null &&
      calculatedField.associated_formula_key != null &&
      us_activeFormulas != null &&
      us_activeFormulas[calculatedField.associated_formula_key] != null &&
      us_activeFormulas[calculatedField.associated_formula_key]['formula_type'] === 'library' &&
      us_activeFormulas[calculatedField.associated_formula_key]['associated_library_formula_key'] != null &&
      libraryFormulas != null &&
      libraryFormulas[us_activeFormulas[calculatedField.associated_formula_key]['associated_library_formula_key']] != null &&
      libraryFormulas[us_activeFormulas[calculatedField.associated_formula_key]['associated_library_formula_key']].variables != null
    ) {
      for (let loopVariableKey in libraryFormulas[us_activeFormulas[calculatedField.associated_formula_key]['associated_library_formula_key']].variables) {
        let loopVariable =
          libraryFormulas[us_activeFormulas[calculatedField.associated_formula_key]['associated_library_formula_key']].variables[loopVariableKey]
        variablesForCalculatedField.push({
          key: loopVariableKey,
          name: loopVariable.value,
          data_type: loopVariable.data_type,
        })
      }
    } else if (
      calculatedField != null &&
      calculatedField.associated_formula_key != null &&
      us_activeFormulas != null &&
      us_activeFormulas[calculatedField.associated_formula_key] != null &&
      us_activeFormulas[calculatedField.associated_formula_key].variables != null
    ) {
      for (let loopVariableKey in us_activeFormulas[calculatedField.associated_formula_key].variables) {
        let loopVariable = us_activeFormulas[calculatedField.associated_formula_key].variables[loopVariableKey]
        if (loopVariable != null && loopVariable.status === 'active') {
          variablesForCalculatedField.push(loopVariable)
        }
      }
    }
    calculatedFieldJSX = (
      <Accordion className="tw-mb-2">
        <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {rJSX_CalculatedFieldIcon(calculatedField)}
            {calculatedField.name}
            {rJSX_EditCalculatedFieldButton(calculatedField)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: '0px' }}>
          <Divider className="tw-mb-3" />
          <Typography variant="h6">
            <Box
              className="tw-inline-block tw-mr-1"
              sx={{ fontWeight: 900 }}
            >
              {rLIB('Formula')}:
            </Box>
            {calculatedField.associated_formula_key != null ? (
              <Box className="tw-inline-block tw-mr-1 tw-opacity-40">{rJSX_CalculationFormulaName(calculatedField)}</Box>
            ) : (
              <Box className="tw-inline-block tw-mr-1 tw-opacity-40 tw-italic">{rLIB('Formula Missing')}</Box>
            )}
            {rJSX_EditCalculatedFieldFormulaIcon(calculatedField)}
          </Typography>
          <Divider className="tw-my-3" />
          <Typography variant="h6">
            <Box
              className="tw-inline-block tw-mr-1 tw-mb-2"
              sx={{ fontWeight: 900 }}
            >
              {rLIB('Variables')}:
            </Box>
          </Typography>
          <Box
            className="tw-rounded-xl tw-ml-8"
            sx={{ border: '1px solid rgba(0,0,0,0.2)' }}
          >
            <TableBasic
              tableAdditionalData={{
                itemKey: itemKey,
                calculatedField: calculatedField,
                us_selectedClassFields: us_classFields,
                table_data_source: 'class',
                us_activeLookupTables: us_activeLookupTables,
              }}
              tableColumns={tableColumns_CalculatedFieldVariables}
              tableData={objectToArray(variablesForCalculatedField)}
              tableSettings={tableSettings_CalculatedFieldVariables}
            />
          </Box>
          <Box className="tw-mt-2">
            {/* <Json
              data={calculatedField}
              alphabetized={true}
            /> */}
          </Box>
        </AccordionDetails>
      </Accordion>
    )
    return calculatedFieldJSX
  }

  const rJSX_CalculatedFieldsTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    if (objectToArray(us_classCalculatedFields).length === 0) {
      tabContentJSX = (
        <Card>
          <Box className="tw-p-4">
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('No Calculated Fields')}
            </Typography>
          </Box>
        </Card>
      )
    } else {
      tabContentJSX = (
        <Card className="tw-px-4 tw-pt-4 tw-pb-2">
          {objectToArray(objectToArray(us_classCalculatedFields).sort(dynamicSort('name', 'asc')))
            .sort(dynamicSort('name', null))
            .map((calculatedField: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>{rJSX_CalculatedFieldCard(calculatedField)}</Box>
            ))}
        </Card>
      )
    }
    return tabContentJSX
  }

  // TODO: Custom Forms - shows all fields with ability to turn them on or off and change the order
  // Then on data buckets select which form to use - if none selected then use default form which includes all fields
  // How to handle on data item dialog screen? - maybe show which form is being used in dropdown but let user view all fields by changing dropdown
  const rJSX_CustomFormSelectionDropdown = (): JSX.Element => {
    let dropdownJSX = <></>
    if (objectToArray(us_customForms).length > 0) {
      dropdownJSX = (
        <FormControl>
          {/* <InputLabel>{rLIB('Custom Form')}</InputLabel> */}
          <Select
            className="bp_thin_select_input tw-ml-2"
            // label={rLIB('Custom Form')}
            onChange={(event: any) => {
              us_setSelectedCustomFormKey(event.target.value)
            }}
            value={us_selectedCustomFormKey}
          >
            <MenuItem value="DEFAULT">{rLIB('Default Form')}</MenuItem>
            {objectToArray(us_customForms).map((customForm: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={customForm.key}
              >
                {customForm.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }
    return dropdownJSX
  }

  const rJSX_FieldIcon = (field: TsInterface_UnspecifiedObject, color: string): JSX.Element => {
    let iconJSX = <></>
    if (
      field != null &&
      field.form_type != null &&
      formTypeOptions != null &&
      formTypeOptions[field.form_type] != null &&
      formTypeOptions[field.form_type]['icon'] != null
    ) {
      iconJSX = (
        <Box
          className="tw-inline-block"
          sx={{ marginRight: '8px', color: color }}
        >
          {formTypeOptions[field.form_type]['icon']}
        </Box>
      )
    } else {
      iconJSX = (
        <Box
          className="tw-inline-block"
          sx={{ marginRight: '8px', color: themeVariables.gray_300 }}
        >
          <Icon icon="question-circle" />
        </Box>
      )
    }
    return iconJSX
  }

  const rJSX_HiddenCustomFormField = (field: TsInterface_UnspecifiedObject): JSX.Element => {
    let fieldJSX = <></>
    let showField = true
    if (
      us_selectedCustomFormKey != null &&
      us_customForms != null &&
      us_customForms[us_selectedCustomFormKey] != null &&
      us_customForms[us_selectedCustomFormKey].fields != null &&
      us_customForms[us_selectedCustomFormKey].fields[field.key] != null
    ) {
      showField = false
    }
    if (showField === true) {
      fieldJSX = (
        <Box>
          {rJSX_FieldIcon(field, themeVariables.error_main)}
          {field.name}
          <Icon
            tooltip={rLIB('Add to Custom Form')}
            tooltipPlacement="top-start"
            icon="circle-plus"
            className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-green-600"
            onClick={() => {
              addFormFieldToCustomForm(field)
            }}
          />
        </Box>
      )
    }
    return fieldJSX
  }

  const rJSX_VisibleCustomFormField = (field: TsInterface_UnspecifiedObject): JSX.Element => {
    let fieldJSX = <></>
    let showField = false
    if (
      us_selectedCustomFormKey != null &&
      us_customForms != null &&
      us_customForms[us_selectedCustomFormKey] != null &&
      us_customForms[us_selectedCustomFormKey].fields != null &&
      us_customForms[us_selectedCustomFormKey].fields[field.key] != null
    ) {
      showField = true
    }
    if (showField === true) {
      fieldJSX = (
        <Box>
          {rJSX_FieldIcon(field, themeVariables.success_light)}
          {field.name}
          <Icon
            icon="up"
            tooltip={rLIB('Move Up')}
            tooltipPlacement="top-start"
            className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-yellow-500"
            onClick={() => {
              moveFormFieldUpInCustomForm(field)
            }}
          />
          <Icon
            icon="down"
            tooltip={rLIB('Move Down')}
            tooltipPlacement="top-start"
            className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-yellow-500"
            onClick={() => {
              moveFormFieldDownInCustomForm(field)
            }}
          />
          <Icon
            icon="circle-xmark"
            tooltip={rLIB('Remove from Custom Form')}
            tooltipPlacement="top-start"
            className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-red-500"
            onClick={() => {
              removeFormFieldFromCustomForm(field)
            }}
          />
        </Box>
      )
    }
    return fieldJSX
  }

  const rJSX_CustomFormsTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    if (us_selectedCustomFormKey === 'DEFAULT') {
      tabContentJSX = (
        <Card className="tw-p-2">
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {rLIB('Default Form')}
          </Typography>
          <Box className="tw-ml-4">
            {objectToArray(us_classFields)
              .sort(dynamicSort('order', 'asc'))
              .map((field: TsInterface_UnspecifiedObject, fieldIndex: number) => (
                <Box key={fieldIndex}>
                  {rJSX_FieldIcon(field, themeVariables.success_light)}
                  {field.name}
                </Box>
              ))}
          </Box>
        </Card>
      )
    } else if (us_selectedCustomFormKey != null && us_customForms != null && us_customForms[us_selectedCustomFormKey] != null) {
      let fieldsInCustomForm: TsInterface_UnspecifiedObject[] = []
      if (us_customForms != null && us_customForms[us_selectedCustomFormKey] != null && us_customForms[us_selectedCustomFormKey]['fields'] != null) {
        for (let loopFieldKey in us_customForms[us_selectedCustomFormKey]['fields']) {
          let loopFieldOrder = us_customForms[us_selectedCustomFormKey]['fields'][loopFieldKey]
          if (us_classFields[loopFieldKey] != null && us_classFields[loopFieldKey].key != null && loopFieldOrder != null) {
            let loopField = { ...us_classFields[loopFieldKey] }
            loopField['order'] = loopFieldOrder
            fieldsInCustomForm.push(loopField)
          }
        }
      }
      tabContentJSX = (
        <Card className="tw-p-2">
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {us_customForms[us_selectedCustomFormKey].name}
            <Icon
              icon="trash"
              className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-red-500"
              onClick={() => {
                deleteCustomForm(us_selectedCustomFormKey)
              }}
            />
          </Typography>
          <Divider className="tw-my-2" />
          <Box className="tw-ml-4">
            <Grid2 container>
              <Grid2
                xs={12}
                sm={6}
              >
                <Typography
                  variant="h6"
                  className="tw-font-bold"
                >
                  {rLIB('Fields not in custom form')}
                </Typography>
                {objectToArray(us_classFields)
                  .sort(dynamicSort('order', 'asc'))
                  .map((field: TsInterface_UnspecifiedObject, fieldIndex: number) => (
                    <Box key={fieldIndex}>{rJSX_HiddenCustomFormField(field)}</Box>
                  ))}
              </Grid2>
              <Grid2
                xs={12}
                sm={6}
              >
                <Typography
                  variant="h6"
                  className="tw-font-bold"
                >
                  {rLIB('Fields included in custom form')}
                </Typography>
                {objectToArray(fieldsInCustomForm)
                  .sort(dynamicSort('order', 'asc'))
                  .map((field: TsInterface_UnspecifiedObject, fieldIndex: number) => (
                    <Box key={fieldIndex}>{rJSX_VisibleCustomFormField(field)}</Box>
                  ))}
              </Grid2>
            </Grid2>
          </Box>
        </Card>
      )
    } else {
      tabContentJSX = <></>
    }
    return tabContentJSX
  }

  const rJSX_NewCustomFormButton = (shrinkButton: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrinkButton === true) {
      buttonJSX = (
        <Tooltip
          title={rLIB('New Form')}
          placement="top"
        >
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              createCustomForm()
            }}
            disableElevation
            className="bp_icon_only_button tw-mb-2"
          >
            <Icon icon="circle-plus" />
          </Button>
        </Tooltip>
      )
    } else {
      buttonJSX = (
        <Button
          className="tw-mb-2"
          variant="contained"
          color="success"
          onClick={() => {
            createCustomForm()
          }}
          startIcon={<Icon icon="circle-plus" />}
        >
          {rLIB('New Form')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box className="tw-m-auto">
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'All_Fields',
                  tabHeader: rLIB('All Fields'),
                  tabOnChange: () => {},
                  tabContent: rJSX_FieldsTabContent(),
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 455 },
                    { fullJSX: rJSX_NewFieldButton(false), minJSX: rJSX_NewFieldButton(true), sizeCutoff: 550 },
                    { fullJSX: rJSX_FilterFieldsDropdown(), minJSX: rJSX_FilterFieldsDropdown(), sizeCutoff: 0 },
                    { fullJSX: rJSX_CopyButton(false), minJSX: rJSX_CopyButton(true), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'Custom_Forms',
                  tabHeader: rLIB('Custom Forms'),
                  tabOnChange: () => {},
                  tabContent: rJSX_CustomFormsTabContent(),
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 310 },
                    { fullJSX: rJSX_NewCustomFormButton(false), minJSX: rJSX_NewCustomFormButton(true), sizeCutoff: 510 },
                    { fullJSX: rJSX_CustomFormSelectionDropdown(), minJSX: rJSX_CustomFormSelectionDropdown(), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'Calculated_Fields',
                  tabHeader: rLIB('Calculated Fields'),
                  tabOnChange: () => {},
                  tabContent: rJSX_CalculatedFieldsTabContent(),
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 310 },
                    { fullJSX: rJSX_NewCalculatedFieldButton(false), minJSX: rJSX_NewCalculatedFieldButton(true), sizeCutoff: 510 },
                  ],
                },
                {
                  tabUrlKey: 'Settings',
                  tabHeader: rLIB('Settings'),
                  tabOnChange: () => {},
                  tabContent: rJSX_SettingsTabContent(),
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.ClassesViewPage.url(itemKey),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Form', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

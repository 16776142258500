///////////////////////////////
// Description
///////////////////////////////

/*

		__          __     _____  _   _ _____ _   _  _____
		\ \        / /\   |  __ \| \ | |_   _| \ | |/ ____|
		\ \  /\  / /  \  | |__) |  \| | | | |  \| | |  __
		\ \/  \/ / /\ \ |  _  /| . ` | | | | . ` | | |_ |
		\  /\  / ____ \| | \ \| |\  |_| |_| |\  | |__| |
		\/  \/_/    \_\_|  \_\_| \_|_____|_| \_|\_____|


		COPIED FROM CLIENT - ANY UPDATE NEEDS TO BE IN BOTH PLACES

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { DatabaseRef_ClassCalculatedFields_Collection } from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import {
  DatabaseRef_DataBuckets_Document,
  DatabaseRef_DataBucket_CalculatedFields_Collection,
} from 'rfbp_aux/services/database_endpoints/clients/architecture/data_buckets'
import { DatabaseRef_Formula_Document } from 'rfbp_aux/services/database_endpoints/clients/data_management/formulas'
import {
  DatabaseRef_LookupTablesDateRangesData_Document,
  DatabaseRef_LookupTablesSpecificActiveDateRange_Query,
  DatabaseRef_LookupTable_Document,
} from 'rfbp_aux/services/database_endpoints/clients/data_management/lookup_tables'
import { DatabaseRef_Data_CalculationMetadata_Document, DatabaseRef_Data_Document } from 'rfbp_aux/services/database_endpoints/clients/transactional_data/data'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseBatchUpdate, DatabaseGetCollection, DatabaseGetDocument, TsInterface_DatabaseBatchUpdatesArray } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray, returnTimestampFromUnknownDateFormat } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import { calculateNetBarrels } from './library/net_bbls'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

export const libraryFormulas: TsInterface_UnspecifiedObject = {
  net_bbls_simple: {
    key: 'net_bbls_simple',
    value: rLIB('Net BBLs (Simple)'),
    icon: 'oil-well',
    variables: {
      observed_density: {
        key: 'observed_density',
        value: rLIB('Observed Gravity'),
        data_type: 'number',
        icon: 'calculator-simple',
      },
      observed_temperature: {
        key: 'observed_temperature',
        value: rLIB('Observed Temperature'),
        data_type: 'number',
        icon: 'calculator-simple',
      },
      observed_pressure: {
        key: 'observed_pressure',
        value: rLIB('Observed Pressure'),
        data_type: 'number',
        icon: 'calculator-simple',
      },
      bsw: {
        key: 'bsw',
        value: rLIB('BS&W'),
        data_type: 'number',
        icon: 'calculator-simple',
      },
      bbl: {
        key: 'bbl',
        value: rLIB('Gross BBLs'),
        data_type: 'number',
        icon: 'calculator-simple',
      },
      top_temp: {
        key: 'top_temp',
        value: rLIB('Top Temp'),
        data_type: 'number',
        icon: 'calculator-simple',
      },
      bottom_temp: {
        key: 'bottom_temp',
        value: rLIB('Bottom Temp'),
        data_type: 'number',
        icon: 'calculator-simple',
      },
    },
    calculation: (data: TsInterface_UnspecifiedObject) => {
      return new Promise((resolve, reject) => {
        if (
          data != null &&
          data['observed_density'] != null &&
          !isNaN(parseFloat(data['observed_density'])) &&
          data['observed_temperature'] != null &&
          !isNaN(parseFloat(data['observed_temperature'])) &&
          data['observed_pressure'] != null &&
          !isNaN(parseFloat(data['observed_pressure'])) &&
          data['bsw'] != null &&
          !isNaN(parseFloat(data['bsw'])) &&
          data['bbl'] != null &&
          !isNaN(parseFloat(data['bbl'])) &&
          data['top_temp'] != null &&
          !isNaN(parseFloat(data['top_temp'])) &&
          data['bottom_temp'] != null &&
          !isNaN(parseFloat(data['bottom_temp']))
        ) {
          let observedDensity = parseFloat(data['observed_density'])
          let observedTemperature = parseFloat(data['observed_temperature'])
          let observedPressure = parseFloat(data['observed_pressure'])
          let bsw = parseFloat(data['bsw'])
          let bbl = parseFloat(data['bbl'])
          let topTemp = parseFloat(data['top_temp'])
          let bottomTemp = parseFloat(data['bottom_temp'])
          calculateNetBarrels(observedDensity, observedTemperature, observedPressure, bsw, bbl, 1, topTemp, bottomTemp, 'crude oil', 'API')
            .then((res_CNB) => {
              resolve({ success: true, value: getProp(res_CNB, 'netBbls', null), error_path: null, pathDebug: null, error_type: null })
            })
            .catch((rej_CNB) => {
              reject({ success: false, value: null, error_path: null, pathDebug: null, error_type: 'Failed to Run Library Calculate' })
            })
        } else {
          reject({ success: false, error: 'Missing Data Fields Required for Calculation' })
        }
      })
    },
  },
  net_bbls_with_mf: {
    key: 'net_bbls_with_mf',
    value: rLIB('Net BBLs (with MF)'),
    icon: 'oil-well',
    variables: {
      observed_density: {
        key: 'observed_density',
        value: rLIB('Observed Gravity'),
        data_type: 'number',
        icon: 'calculator-simple',
      },
      observed_temperature: {
        key: 'observed_temperature',
        value: rLIB('Observed Temperature'),
        data_type: 'number',
        icon: 'calculator-simple',
      },
      observed_pressure: {
        key: 'observed_pressure',
        value: rLIB('Observed Pressure'),
        data_type: 'number',
        icon: 'calculator-simple',
      },
      bsw: {
        key: 'bsw',
        value: rLIB('BS&W'),
        data_type: 'number',
        icon: 'calculator-simple',
      },
      bbl: {
        key: 'bbl',
        value: rLIB('Gross BBLs'),
        data_type: 'number',
        icon: 'calculator-simple',
      },
      mf: {
        key: 'mf',
        value: rLIB('Meter Factor'),
        data_type: 'number',
        icon: 'calculator-simple',
      },
      top_temp: {
        key: 'top_temp',
        value: rLIB('Top Temp'),
        data_type: 'number',
        icon: 'calculator-simple',
      },
      bottom_temp: {
        key: 'bottom_temp',
        value: rLIB('Bottom Temp'),
        data_type: 'number',
        icon: 'calculator-simple',
      },
    },
    calculation: (data: TsInterface_UnspecifiedObject) => {
      return new Promise((resolve, reject) => {
        if (
          data != null &&
          data['observed_density'] != null &&
          !isNaN(parseFloat(data['observed_density'])) &&
          data['observed_temperature'] != null &&
          !isNaN(parseFloat(data['observed_temperature'])) &&
          data['observed_pressure'] != null &&
          !isNaN(parseFloat(data['observed_pressure'])) &&
          data['bsw'] != null &&
          !isNaN(parseFloat(data['bsw'])) &&
          data['bbl'] != null &&
          !isNaN(parseFloat(data['bbl'])) &&
          data['mf'] != null &&
          !isNaN(parseFloat(data['mf'])) &&
          data['top_temp'] != null &&
          !isNaN(parseFloat(data['top_temp'])) &&
          data['bottom_temp'] != null &&
          !isNaN(parseFloat(data['bottom_temp']))
        ) {
          let observedDensity = parseFloat(data['observed_density'])
          let observedTemperature = parseFloat(data['observed_temperature'])
          let observedPressure = parseFloat(data['observed_pressure'])
          let bsw = parseFloat(data['bsw'])
          let bbl = parseFloat(data['bbl'])
          let mf = parseFloat(data['mf'])
          let topTemp = parseFloat(data['top_temp'])
          let bottomTemp = parseFloat(data['bottom_temp'])
          calculateNetBarrels(observedDensity, observedTemperature, observedPressure, bsw, bbl, mf, topTemp, bottomTemp, 'crude oil', 'API')
            .then((res_CNB) => {
              resolve({ success: true, value: getProp(res_CNB, 'netBbls', null), error_path: null, pathDebug: null, error_type: null })
            })
            .catch((rej_CNB) => {
              reject({ success: false, value: null, error_path: null, pathDebug: null, error_type: 'Failed to Run Library Calculate' })
            })
        } else {
          reject({ success: false, error: 'Missing Data Fields Required for Calculation' })
        }
      })
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

function createTableData(data: TsInterface_UnspecifiedObject) {
  let rowVariables: any = new Set()
  let colVariables: any = new Set()
  // Populate rowVariables and colVariables with unique values
  for (let key in data) {
    const [rowVar, colVar] = key.split('~')
    rowVariables.add(rowVar)
    colVariables.add(colVar)
  }
  // Sort rowVariables and colVariables alphabetically
  rowVariables = Array.from(rowVariables).sort()
  colVariables = Array.from(colVariables).sort()
  // Create the table data
  const tableData = []
  // Add the header row (first row)
  tableData.push(['', ...colVariables])
  // Add the remaining rows
  for (const rowVar of rowVariables) {
    const row = [rowVar]
    for (const colVar of colVariables) {
      const key = `${rowVar}~${colVar}`
      const value = data[key] ? data[key] : ''
      row.push(value)
    }
    tableData.push(row)
  }
  return tableData
}

export const returnDataInRenderTableFormat = (lookupTableType: string, databaseDataObject: TsInterface_UnspecifiedObject) => {
  let outputArray: TsInterface_UnspecifiedObject[] = []
  switch (lookupTableType) {
    case 'identical_lookup':
      for (let loopDataRowKey in databaseDataObject) {
        outputArray.push({ lookup: loopDataRowKey, result: databaseDataObject[loopDataRowKey] })
      }
      outputArray.sort(dynamicSort('lookup', 'asc'))
      break
    case 'range_lookup':
      for (let loopDataRowKey in databaseDataObject) {
        let splitIndex = loopDataRowKey.indexOf('~')
        let lookupStart = parseFloat(loopDataRowKey.substring(0, splitIndex))
        let lookupEnd = parseFloat(loopDataRowKey.substring(splitIndex + 1))
        outputArray.push({ lookup_start: lookupStart, lookup_end: lookupEnd, result: databaseDataObject[loopDataRowKey] })
      }
      outputArray.sort(dynamicSort('lookup_start', 'asc'))
      break
    case 'matrix_lookup':
      outputArray = createTableData(databaseDataObject)
      break
  }
  return outputArray
}

export const returnSearchValueFromLookupTable = (
  clientKey: string,
  lookupTableKey: string,
  searchDate: Date,
  queryParam1: string | number,
  queryParam2: string | number | null,
) => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && lookupTableKey != null) {
      // Get Base Lookup Table
      DatabaseGetDocument(DatabaseRef_LookupTable_Document(clientKey, lookupTableKey))
        .then((getDocumentResult) => {
          let lookupTable = getDocumentResult.data
          let lookupTableType = getProp(lookupTable, 'table_type', null)
          // Query Lookup by provided date
          DatabaseGetCollection(DatabaseRef_LookupTablesSpecificActiveDateRange_Query(clientKey, lookupTableKey, searchDate))
            .then((getCollectionResult) => {
              if (getCollectionResult != null && getCollectionResult.data != null && objectToArray(getCollectionResult.data).length === 1) {
                let queriedDateRange = objectToArray(getCollectionResult.data)[0]
                DatabaseGetDocument(DatabaseRef_LookupTablesDateRangesData_Document(clientKey, lookupTableKey, queriedDateRange.key))
                  .then((getDocumentResult2) => {
                    let lookupData = getDocumentResult2.data
                    let foundLookupValue = false
                    let lookupValue: any = null
                    switch (lookupTableType) {
                      case 'identical_lookup':
                        if (lookupData != null && queryParam1 != null && lookupData[queryParam1] != null) {
                          foundLookupValue = true
                          lookupValue = lookupData[queryParam1]
                        }
                        break
                      case 'range_lookup':
                        // eslint-disable-next-line no-case-declarations
                        let lookupTableData = returnDataInRenderTableFormat(lookupTableType, lookupData)
                        for (let loopIndex in lookupTableData) {
                          let loopRow = lookupTableData[loopIndex]
                          if (
                            loopRow.lookup_start != null &&
                            loopRow.lookup_end != null &&
                            queryParam1 != null &&
                            // @ts-expect-error
                            !isNaN(parseFloat(queryParam1)) &&
                            // @ts-expect-error
                            loopRow.lookup_start <= parseFloat(queryParam1) &&
                            // @ts-expect-error
                            parseFloat(queryParam1) < loopRow.lookup_end
                          ) {
                            foundLookupValue = true
                            lookupValue = loopRow.result
                          }
                        }
                        break
                      case 'matrix_lookup':
                        if (
                          queryParam1 != null &&
                          queryParam2 != null &&
                          lookupData != null &&
                          lookupData[queryParam1.toString() + '~' + queryParam2.toString()] != null
                        ) {
                          foundLookupValue = true
                          lookupValue = lookupData[queryParam1.toString() + '~' + queryParam2.toString()]
                        }
                        break
                    }
                    if (foundLookupValue === true) {
                      resolve({ success: true, value: lookupValue })
                    } else {
                      reject({
                        success: false,
                        error: {
                          message: rLIB('Failed to Return Lookup Value'),
                          details: rLIB('Failed to Find Lookup Value on Table'),
                          code: 'ER-D-LTD-RSVFLT-01',
                        },
                      })
                    }
                  })
                  .catch((getDocumentReject2) => {
                    reject(getDocumentReject2)
                  })
              } else {
                reject({
                  success: false,
                  error: {
                    message: rLIB('Failed to Return Lookup Value'),
                    details: rLIB('No Lookup Table Data for Specified Date'),
                    code: 'ER-D-LTD-RSVFLT-02',
                  },
                })
              }
            })
            .catch((getCollectionReject) => {
              reject(getCollectionReject)
            })
        })
        .catch((getDocumentReject) => {
          reject(getDocumentReject)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to Return Lookup Value'),
          details: rLIB('Missing Required Parameters'),
          code: 'ER-D-LTD-RSVFLT-03',
        },
      })
    }
  })
}

// Main Function
export const calculateValuesForDataItemCalculatedFields = (
  clientKey: string,
  dataItemKey: string,
  dataItem: TsInterface_UnspecifiedObject | null,
  dataBucket: TsInterface_UnspecifiedObject | null,
  classCalculatedFields: TsInterface_UnspecifiedObject | null,
  dataBucketCalculatedFields: TsInterface_UnspecifiedObject | null,
  formulas: TsInterface_UnspecifiedObject | null,
  saveOrReturn: 'save' | 'return',
) => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && dataItemKey != null) {
      // Instantiate Variables
      let promiseArray1: TsType_UnknownPromise[] = []
      let promiseArray2: TsType_UnknownPromise[] = []
      let promiseArray3: TsType_UnknownPromise[] = []
      let promiseArray4: TsType_UnknownPromise[] = []
      let customCalculationsRun: TsInterface_UnspecifiedObject = {}
      let calculatedData: TsInterface_UnspecifiedObject = {}
      let calculatedFieldNames: TsInterface_UnspecifiedObject = {}
      let calculatedDataMetadata: TsInterface_UnspecifiedObject = {}
      let calculationsToRun: TsInterface_UnspecifiedObject = {}
      let formulasToLoad: TsInterface_UnspecifiedObject = {}
      let loadedFormulas: TsInterface_UnspecifiedObject = {}
      // If the Data Item is not provided, load it
      if (dataItem == null) {
        promiseArray1.push(
          DatabaseGetDocument(DatabaseRef_Data_Document(clientKey, dataItemKey))
            .then((res_DGD) => {
              dataItem = res_DGD.data
            })
            .catch((rej_DGD) => {
              console.error(rej_DGD)
            }),
        )
      }
      // Data Item Loaded
      Promise.all(promiseArray1).finally(() => {
        if (dataItem != null) {
          // Load Class and Data Bucket Calculated Fields if not provided
          if (classCalculatedFields == null && dataItem.associated_class_key != null) {
            promiseArray2.push(
              DatabaseGetCollection(DatabaseRef_ClassCalculatedFields_Collection(clientKey, dataItem.associated_class_key))
                .then((res_DGD) => {
                  classCalculatedFields = res_DGD.data
                })
                .catch((rej_DGD) => {
                  console.error(rej_DGD)
                }),
            )
          }
          if (dataBucketCalculatedFields == null && dataItem.associated_data_bucket_key != null) {
            promiseArray2.push(
              DatabaseGetCollection(DatabaseRef_DataBucket_CalculatedFields_Collection(clientKey, dataItem.associated_data_bucket_key))
                .then((res_DGD) => {
                  dataBucketCalculatedFields = res_DGD.data
                })
                .catch((rej_DGD) => {
                  console.error(rej_DGD)
                }),
            )
          }
          if (dataBucket == null && dataItem.associated_data_bucket_key != null) {
            promiseArray2.push(
              DatabaseGetDocument(DatabaseRef_DataBuckets_Document(clientKey, dataItem.associated_data_bucket_key))
                .then((res_DGD) => {
                  dataBucket = res_DGD.data
                })
                .catch((rej_DGD) => {
                  console.error(rej_DGD)
                }),
            )
          }
          // After Class and Data Bucket Calculated Fields Loaded
          Promise.all(promiseArray2).finally(() => {
            // Handle Reconciliation Tagging
            let reconciliationData: TsInterface_UnspecifiedObject = {}
            if (dataBucket != null && dataBucket['associated_reconciliation_active'] === true && dataBucket['associated_reconciliation_keys'] != null) {
              for (let loopReconciliationKey in dataBucket['associated_reconciliation_keys']) {
                if (reconciliationData['associated_reconciliation_keys'] == null) {
                  reconciliationData['associated_reconciliation_keys'] = {}
                }
                // TODO: support for other timestamps?
                reconciliationData['associated_reconciliation_keys'][loopReconciliationKey] = getProp(dataItem, 'timestamp_primary_query', null)
              }
            }
            // Combine Calculated Fields - Data Bucket Overwrites Class
            for (let loopCalculatedFieldKey in classCalculatedFields) {
              let loopCalculation = classCalculatedFields[loopCalculatedFieldKey]
              if (loopCalculation != null && loopCalculation.status === 'active') {
                calculationsToRun[loopCalculatedFieldKey] = classCalculatedFields[loopCalculatedFieldKey]
                if (calculatedDataMetadata[loopCalculatedFieldKey] == null) {
                  calculatedDataMetadata[loopCalculatedFieldKey] = {}
                }
                calculatedDataMetadata[loopCalculatedFieldKey]['source'] = 'class'
                calculatedDataMetadata[loopCalculatedFieldKey]['name'] = loopCalculation.associated_calculated_field_name
                calculatedFieldNames[loopCalculatedFieldKey] = loopCalculation.associated_calculated_field_name
              }
            }
            for (let loopCalculatedFieldKey in dataBucketCalculatedFields) {
              let loopCalculation = dataBucketCalculatedFields[loopCalculatedFieldKey]
              if (loopCalculation != null && loopCalculation.status === 'active') {
                calculationsToRun[loopCalculatedFieldKey] = dataBucketCalculatedFields[loopCalculatedFieldKey]
                if (calculatedDataMetadata[loopCalculatedFieldKey] == null) {
                  calculatedDataMetadata[loopCalculatedFieldKey] = {}
                }
                calculatedDataMetadata[loopCalculatedFieldKey]['source'] = 'data_bucket'
                calculatedDataMetadata[loopCalculatedFieldKey]['name'] = loopCalculation.associated_calculated_field_name
                calculatedFieldNames[loopCalculatedFieldKey] = loopCalculation.associated_calculated_field_name
              }
            }
            // Loop through and load all formulas needed to perform calculations
            for (let loopCalculatedFieldKey in calculationsToRun) {
              let loopCalculation = calculationsToRun[loopCalculatedFieldKey]
              if (
                loopCalculation != null &&
                loopCalculation.associated_formula_key != null &&
                (formulas == null || formulas[loopCalculation.associated_formula_key] == null)
              ) {
                formulasToLoad[loopCalculation.associated_formula_key] = loopCalculation.associated_formula_key
                calculatedDataMetadata[loopCalculatedFieldKey]['associated_formula_key'] = loopCalculation.associated_formula_key
              }
            }
            // Loop through and load formulas
            for (let loopFormulaKey in formulasToLoad) {
              if (formulas != null && formulas[loopFormulaKey] != null) {
                loadedFormulas[loopFormulaKey] = formulas[loopFormulaKey]
              } else {
                promiseArray3.push(
                  DatabaseGetDocument(DatabaseRef_Formula_Document(clientKey, loopFormulaKey))
                    .then((res_DGD) => {
                      loadedFormulas[loopFormulaKey] = res_DGD.data
                    })
                    .catch((rej_DGD) => {
                      console.error(rej_DGD)
                    }),
                )
              }
            }
            // Loop through and load lookup tables
            let lookupTableValues: TsInterface_UnspecifiedObject = {}
            for (let loopCalculatedFieldKey in calculationsToRun) {
              lookupTableValues[loopCalculatedFieldKey] = {}
              let loopCalculation = calculationsToRun[loopCalculatedFieldKey]
              if (loopCalculation != null && loopCalculation['variable_mapping'] != null) {
                for (let loopVariableMappingKey in loopCalculation['variable_mapping']) {
                  let loopVariableMapping = loopCalculation['variable_mapping'][loopVariableMappingKey]
                  if (
                    loopVariableMapping != null &&
                    loopVariableMapping['variable_mapping_type'] === 'lookup_table_value' &&
                    loopVariableMapping['associated_lookup_table_key'] != null &&
                    loopVariableMapping['associated_lookup_table_date_key'] != null &&
                    loopVariableMapping['associated_lookup_table_date_key'] != null &&
                    loopVariableMapping['associated_lookup_table_primary_input_key'] != null &&
                    // loopVariableMapping['associated_lookup_table_secondary_input_key'] != null &&
                    dataItem != null &&
                    dataItem['data'] != null &&
                    dataItem[loopVariableMapping['associated_lookup_table_date_key']] != null &&
                    dataItem[loopVariableMapping['associated_lookup_table_date_key']] != '' &&
                    dataItem['data'][loopVariableMapping['associated_lookup_table_primary_input_key']] != null &&
                    dataItem['data'][loopVariableMapping['associated_lookup_table_primary_input_key']] != ''
                  ) {
                    let lookupTableKey = loopVariableMapping['associated_lookup_table_key']
                    let searchDate = new Date(returnTimestampFromUnknownDateFormat(dataItem[loopVariableMapping['associated_lookup_table_date_key']]))
                    let primaryInputValue = dataItem['data'][loopVariableMapping['associated_lookup_table_primary_input_key']]
                    let secondaryInputValue = null
                    if (
                      loopVariableMapping['associated_lookup_table_secondary_input_key'] != null &&
                      dataItem['data'][loopVariableMapping['associated_lookup_table_secondary_input_key']] != null &&
                      dataItem['data'][loopVariableMapping['associated_lookup_table_secondary_input_key']] != ''
                    ) {
                      secondaryInputValue = dataItem['data'][loopVariableMapping['associated_lookup_table_secondary_input_key']]
                    }
                    // Get Lookup Table Data
                    promiseArray3.push(
                      returnSearchValueFromLookupTable(clientKey, lookupTableKey, searchDate, primaryInputValue, secondaryInputValue)
                        .then((res_LUT) => {
                          if (res_LUT != null && getProp(res_LUT, 'value', null) != null) {
                            lookupTableValues[loopCalculatedFieldKey][loopVariableMappingKey] = getProp(res_LUT, 'value', null)
                          }
                        })
                        .catch((rej_LUT) => {
                          console.error(rej_LUT)
                        }),
                    )
                  }
                }
              }
            }
            Promise.all(promiseArray3).finally(() => {
              // Loop through and run calculations for each calculated field
              for (let loopCalculatedFieldKey in calculationsToRun) {
                if (customCalculationsRun[loopCalculatedFieldKey] != true) {
                  let loopCalculatedField = calculationsToRun[loopCalculatedFieldKey]
                  // Check if the formula is loaded and the required data is available
                  if (
                    // Library Formulas
                    loopCalculatedField != null &&
                    loopCalculatedField['associated_formula_key'] != null &&
                    loadedFormulas != null &&
                    loadedFormulas[loopCalculatedField['associated_formula_key']] != null &&
                    loadedFormulas[loopCalculatedField['associated_formula_key']]['formula_type'] === 'library'
                  ) {
                    if (
                      loadedFormulas[loopCalculatedField['associated_formula_key']]['associated_library_formula_key'] != null &&
                      libraryFormulas != null &&
                      libraryFormulas[loadedFormulas[loopCalculatedField['associated_formula_key']]['associated_library_formula_key']] != null &&
                      libraryFormulas[loadedFormulas[loopCalculatedField['associated_formula_key']]['associated_library_formula_key']]['calculation'] != null
                    ) {
                      // Get The formula
                      let calculationFunction =
                        libraryFormulas[loadedFormulas[loopCalculatedField['associated_formula_key']]['associated_library_formula_key']]['calculation']
                      // Map Variables to values for the formula
                      let mappedVariables: TsInterface_UnspecifiedObject = {}
                      for (let loopVariableMappingKey in loopCalculatedField['variable_mapping']) {
                        let loopVariableMapping = loopCalculatedField['variable_mapping'][loopVariableMappingKey]
                        if (loopVariableMapping != null) {
                          if (loopVariableMapping['variable_mapping_type'] === 'hard_code') {
                            mappedVariables[loopVariableMappingKey] = getProp(loopVariableMapping, 'hardcoded_value', null)
                          } else if (loopVariableMapping['variable_mapping_type'] === 'user_entered_value') {
                            if (
                              loopVariableMapping['associated_user_entered_field_key'] != null &&
                              dataItem != null &&
                              dataItem['data'] != null &&
                              dataItem['data'][loopVariableMapping['associated_user_entered_field_key']] != null
                            ) {
                              mappedVariables[loopVariableMappingKey] = dataItem['data'][loopVariableMapping['associated_user_entered_field_key']]
                            } else {
                              // Handle Checkboxes
                              if (
                                loopVariableMapping['associated_user_entered_field_key'] != null &&
                                loopVariableMapping['associated_user_entered_field_key'].includes('.')
                              ) {
                                let firstPeriodIndex = loopVariableMapping['associated_user_entered_field_key'].indexOf('.')
                                let mappedFieldKeySubstring = loopVariableMapping['associated_user_entered_field_key'].substring(0, firstPeriodIndex)
                                let mappedFieldOptionSubstring = loopVariableMapping['associated_user_entered_field_key'].substring(firstPeriodIndex + 1)
                                if (
                                  dataItem != null &&
                                  dataItem['data'] != null &&
                                  dataItem['data'][mappedFieldKeySubstring] != null &&
                                  dataItem['data'][mappedFieldKeySubstring][mappedFieldOptionSubstring] != null
                                ) {
                                  mappedVariables[loopVariableMappingKey] = dataItem['data'][mappedFieldKeySubstring][mappedFieldOptionSubstring]
                                } else {
                                  mappedVariables[loopVariableMappingKey] = null
                                }
                              }
                            }
                          } else if (loopVariableMapping['variable_mapping_type'] === 'lookup_table_value') {
                            if (
                              lookupTableValues != null &&
                              lookupTableValues[loopCalculatedFieldKey] != null &&
                              lookupTableValues[loopCalculatedFieldKey][loopVariableMappingKey] != null
                            ) {
                              mappedVariables[loopVariableMappingKey] = lookupTableValues[loopCalculatedFieldKey][loopVariableMappingKey]
                            } else {
                              mappedVariables[loopVariableMappingKey] = null
                            }
                          }
                        }
                      }
                      //
                      try {
                        promiseArray4.push(
                          calculationFunction(mappedVariables)
                            .then((res_CCP: any) => {
                              calculatedData[loopCalculatedFieldKey] = res_CCP.value
                              calculatedDataMetadata[loopCalculatedFieldKey]['result'] = res_CCP
                              calculatedDataMetadata[loopCalculatedFieldKey]['timestamp_last_calculated'] = new Date()
                            })
                            .catch((rej_CCP: any) => {
                              calculatedData[loopCalculatedFieldKey] = null
                              calculatedDataMetadata[loopCalculatedFieldKey]['result'] = rej_CCP
                              calculatedDataMetadata[loopCalculatedFieldKey]['timestamp_last_calculated'] = new Date()
                            }),
                        )
                      } catch (err) {
                        calculatedData[loopCalculatedFieldKey] = null
                        calculatedDataMetadata[loopCalculatedFieldKey]['result'] = {
                          success: false,
                          value: null,
                          error_path: 'MD-3',
                          pathDebug: null,
                          error_type: 'Calculation Formula Failed to Run',
                        }
                        calculatedDataMetadata[loopCalculatedFieldKey]['timestamp_last_calculated'] = new Date()
                      }
                    } else {
                      calculatedData[loopCalculatedFieldKey] = null
                      calculatedDataMetadata[loopCalculatedFieldKey]['result'] = {
                        success: false,
                        value: null,
                        error_path: 'MD-2',
                        pathDebug: null,
                        error_type: 'Calculation Formula Missing or Not Found in Library',
                      }
                      calculatedDataMetadata[loopCalculatedFieldKey]['timestamp_last_calculated'] = new Date()
                    }
                  } else if (
                    // Custom Formulas
                    loopCalculatedField != null &&
                    loopCalculatedField['associated_formula_key'] != null &&
                    loopCalculatedField['variable_mapping'] != null &&
                    loadedFormulas[loopCalculatedField['associated_formula_key']] != null
                  ) {
                    // Get The formula
                    let formulaToRun = loadedFormulas[loopCalculatedField['associated_formula_key']]
                    // Map Variables to values for the formula
                    let mappedVariables: TsInterface_UnspecifiedObject = {}
                    for (let loopVariableMappingKey in loopCalculatedField['variable_mapping']) {
                      let loopVariableMapping = loopCalculatedField['variable_mapping'][loopVariableMappingKey]
                      if (loopVariableMapping != null) {
                        if (loopVariableMapping['variable_mapping_type'] === 'hard_code') {
                          mappedVariables[loopVariableMappingKey] = getProp(loopVariableMapping, 'hardcoded_value', null)
                        } else if (loopVariableMapping['variable_mapping_type'] === 'user_entered_value') {
                          if (
                            loopVariableMapping['associated_user_entered_field_key'] != null &&
                            dataItem != null &&
                            dataItem['data'] != null &&
                            dataItem['data'][loopVariableMapping['associated_user_entered_field_key']] != null
                          ) {
                            mappedVariables[loopVariableMappingKey] = dataItem['data'][loopVariableMapping['associated_user_entered_field_key']]
                          } else {
                            // Handle Checkboxes
                            if (
                              loopVariableMapping['associated_user_entered_field_key'] != null &&
                              loopVariableMapping['associated_user_entered_field_key'].includes('.')
                            ) {
                              let firstPeriodIndex = loopVariableMapping['associated_user_entered_field_key'].indexOf('.')
                              let mappedFieldKeySubstring = loopVariableMapping['associated_user_entered_field_key'].substring(0, firstPeriodIndex)
                              let mappedFieldOptionSubstring = loopVariableMapping['associated_user_entered_field_key'].substring(firstPeriodIndex + 1)
                              if (
                                dataItem != null &&
                                dataItem['data'] != null &&
                                dataItem['data'][mappedFieldKeySubstring] != null &&
                                dataItem['data'][mappedFieldKeySubstring][mappedFieldOptionSubstring] != null
                              ) {
                                mappedVariables[loopVariableMappingKey] = dataItem['data'][mappedFieldKeySubstring][mappedFieldOptionSubstring]
                              } else {
                                mappedVariables[loopVariableMappingKey] = null
                              }
                            }
                          }
                        } else if (loopVariableMapping['variable_mapping_type'] === 'lookup_table_value') {
                          if (
                            lookupTableValues != null &&
                            lookupTableValues[loopCalculatedFieldKey] != null &&
                            lookupTableValues[loopCalculatedFieldKey][loopVariableMappingKey] != null
                          ) {
                            mappedVariables[loopVariableMappingKey] = lookupTableValues[loopCalculatedFieldKey][loopVariableMappingKey]
                          } else {
                            mappedVariables[loopVariableMappingKey] = null
                          }
                        }
                      }
                    }
                    // Loop through and parse numbers if possible
                    for (let loopVariableKey in mappedVariables) {
                      let loopVariableValue = mappedVariables[loopVariableKey]
                      if (loopVariableValue != null && loopVariableValue !== '' && !isNaN(parseFloat(loopVariableValue))) {
                        mappedVariables[loopVariableKey] = parseFloat(loopVariableValue)
                      }
                    }
                    // Run the formula
                    let calculationResult = evaluateFormulaLogicPath(formulaToRun['logic'], mappedVariables, {})
                    if (calculationResult.success === true) {
                      calculatedData[loopCalculatedFieldKey] = calculationResult.value
                      calculatedDataMetadata[loopCalculatedFieldKey]['result'] = calculationResult // TODO: { success: true, value: calculationResult.value, error_path: null, pathDebug: null, error_type: null }
                      calculatedDataMetadata[loopCalculatedFieldKey]['timestamp_last_calculated'] = new Date()
                    } else {
                      calculatedData[loopCalculatedFieldKey] = null
                      calculatedDataMetadata[loopCalculatedFieldKey]['result'] = calculationResult // TODO: { success: true, value: calculationResult.value, error_path: null, pathDebug: null, error_type: null }
                      calculatedDataMetadata[loopCalculatedFieldKey]['timestamp_last_calculated'] = new Date()
                    }
                  } else {
                    calculatedData[loopCalculatedFieldKey] = null
                    calculatedDataMetadata[loopCalculatedFieldKey]['result'] = {
                      success: false,
                      value: null,
                      error_path: 'MD-1',
                      pathDebug: null,
                      error_type: 'Missing Data Required to Run Calculation',
                    }
                    calculatedDataMetadata[loopCalculatedFieldKey]['timestamp_last_calculated'] = new Date()
                  }
                }
              }
              // After Calculations Run
              Promise.all(promiseArray4).finally(() => {
                if (saveOrReturn === 'return') {
                  resolve({
                    success: true,
                    calculatedData: calculatedData,
                    calculatedFieldNames: calculatedFieldNames,
                    calculatedDataMetadata: calculatedDataMetadata,
                    reconciliationData: reconciliationData,
                  })
                } else if (saveOrReturn === 'save') {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    calculated_data: calculatedData,
                    calculated_data_names: calculatedFieldNames,
                  }
                  if (reconciliationData['associated_reconciliation_keys'] != null) {
                    updateObject['associated_reconciliation_keys'] = reconciliationData['associated_reconciliation_keys']
                  }
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_Data_Document(clientKey, dataItemKey),
                      data: updateObject,
                    },
                    {
                      type: 'setOverwrite',
                      ref: DatabaseRef_Data_CalculationMetadata_Document(clientKey, dataItemKey),
                      data: {
                        key: dataItemKey,
                        // TODO: - save parts of data item used to calculate this data (lookup table values, formulas, etc?)
                        timestamp_last_calculated: new Date(),
                        calculated_data: calculatedDataMetadata,
                      },
                    },
                  ]
                  DatabaseBatchUpdate(updateArray)
                    .then((res_DBU) => {
                      resolve(res_DBU)
                    })
                    .catch((rej_DBU) => {
                      reject(rej_DBU)
                    })
                } else {
                  reject({
                    success: false,
                    error: {
                      message: rLIB('Failed to run calculations'),
                      details: rLIB('Invalid Save or Return Parameter'),
                      code: 'ER-D-CF-DVFDICF-03',
                    },
                  })
                }
              })
            })
          })
        } else {
          reject({
            success: false,
            error: {
              message: rLIB('Failed to run calculations'),
              details: rLIB('Data Item Not Found'),
              code: 'ER-D-CF-DVFDICF-02',
            },
          })
        }
      })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to run calculations'),
          details: rLIB('Missing Required Parameters'),
          code: 'ER-D-CF-DVFDICF-01',
        },
      })
    }
  })
}

// Evaluation
export const evaluateFormulaLogicPath = (
  logicPath: TsInterface_UnspecifiedObject,
  variables: TsInterface_UnspecifiedObject,
  pathDebug: TsInterface_UnspecifiedObject,
): TsInterface_UnspecifiedObject => {
  if (logicPath['logic_step_type'] === 'condition_if_else') {
    if (logicPath['condition_ifs'] != null && logicPath['condition_ifs'].length > 0 && logicPath['condition_else'] != null) {
      // Instantiate Variables
      let foundValidIfPath: boolean = false
      let validIfPathIndex = null
      let errorWithLogicPath: boolean = false
      // Loop through If Conditions
      for (let loopIfConditionIndex in logicPath['condition_ifs']) {
        let loopIfCondition = logicPath['condition_ifs'][loopIfConditionIndex]
        if (foundValidIfPath === false) {
          if (
            loopIfCondition['logic_step_type'] != null &&
            loopIfCondition['condition_type'] != null &&
            loopIfCondition['conditions'] != null &&
            loopIfCondition['conditions'].length > 0
          ) {
            let foundFalseCondition = false
            let foundTrueCondition = false
            for (let loopIndividualConditionIndex in loopIfCondition['conditions']) {
              let loopIndividualCondition = loopIfCondition['conditions'][loopIndividualConditionIndex]
              if (loopIndividualCondition != null) {
                let comparator = getProp(loopIndividualCondition, 'comparator', null)
                let variableOneKey = getProp(loopIndividualCondition, 'variable_1', null)
                let variableTwoKey = getProp(loopIndividualCondition, 'variable_2', null)
                let variableOneValue = null
                let variableTwoValue = null
                if (variableOneKey != null) {
                  variableOneValue = getProp(variables, variableOneKey, null)
                  if (!isNaN(parseFloat(variableOneValue))) {
                    variableOneValue = parseFloat(variableOneValue)
                  } else if (variableOneValue != null && variableOneValue.toLowerCase() === 'true') {
                    variableOneValue = true
                  } else if (variableOneValue != null && variableOneValue.toLowerCase() === 'false') {
                    variableOneValue = false
                  }
                }
                if (variableTwoKey != null) {
                  variableTwoValue = getProp(variables, variableTwoKey, null)
                  if (!isNaN(parseFloat(variableTwoValue))) {
                    variableTwoValue = parseFloat(variableTwoValue)
                  } else if (variableTwoValue != null && variableTwoValue.toLowerCase() === 'true') {
                    variableTwoValue = true
                  } else if (variableTwoValue != null && variableTwoValue.toLowerCase() === 'false') {
                    variableTwoValue = false
                  }
                }
                switch (comparator) {
                  case '>':
                    if (variableOneValue != null && variableTwoValue != null && variableOneValue > variableTwoValue) {
                      foundTrueCondition = true
                    } else {
                      foundFalseCondition = true
                    }
                    break
                  case '>=':
                    if (variableOneValue != null && variableTwoValue != null && variableOneValue >= variableTwoValue) {
                      foundTrueCondition = true
                    } else {
                      foundFalseCondition = true
                    }
                    break
                  case '<':
                    if (variableOneValue != null && variableTwoValue != null && variableOneValue < variableTwoValue) {
                      foundTrueCondition = true
                    } else {
                      foundFalseCondition = true
                    }
                    break
                  case '<=':
                    if (variableOneValue != null && variableTwoValue != null && variableOneValue <= variableTwoValue) {
                      foundTrueCondition = true
                    } else {
                      foundFalseCondition = true
                    }
                    break
                  case '==':
                    if (variableOneValue != null && variableTwoValue != null && variableOneValue === variableTwoValue) {
                      foundTrueCondition = true
                    } else {
                      foundFalseCondition = true
                    }
                    break
                  case '!=':
                    if (variableOneValue != null && variableTwoValue != null && variableOneValue !== variableTwoValue) {
                      foundTrueCondition = true
                    } else {
                      foundFalseCondition = true
                    }
                    break
                  case '==null':
                    if (variableOneValue == null) {
                      foundTrueCondition = true
                    } else {
                      foundFalseCondition = true
                    }
                    break
                  case '!=null':
                    if (variableOneValue != null) {
                      foundTrueCondition = true
                    } else {
                      foundFalseCondition = true
                    }
                    break
                  case '==true':
                    if (variableOneValue === true) {
                      foundTrueCondition = true
                    } else {
                      foundFalseCondition = true
                    }
                    break
                  case '!=true':
                    if (variableOneValue !== true) {
                      foundTrueCondition = true
                    } else {
                      foundFalseCondition = true
                    }
                    break
                  case '==false':
                    if (variableOneValue === false) {
                      foundTrueCondition = true
                    } else {
                      foundFalseCondition = true
                    }
                    break
                  case '!=false':
                    if (variableOneValue !== false) {
                      foundTrueCondition = true
                    } else {
                      foundFalseCondition = true
                    }
                    break
                  default:
                    errorWithLogicPath = true
                }
              } else {
                errorWithLogicPath = true
              }
            }
            if (loopIfCondition['condition_type'] === 'and' && foundFalseCondition === false) {
              foundValidIfPath = true
              validIfPathIndex = loopIfConditionIndex
            } else if (loopIfCondition['condition_type'] === 'or' && foundTrueCondition === true) {
              foundValidIfPath = true
              validIfPathIndex = loopIfConditionIndex
            }
            // Trace
            if (foundValidIfPath === true) {
              pathDebug[loopIfCondition.logic_trace_key] = true
            } else {
              pathDebug[loopIfCondition.logic_trace_key] = false
            }
          } else {
            errorWithLogicPath = true
          }
        }
      }
      // Resolve Out
      if (errorWithLogicPath === true) {
        return { success: false, value: null, error_path: 'IE-1', pathDebug: pathDebug, error_type: 'Missing Required If/Else Condition Configurations' }
      } else if (foundValidIfPath === true && validIfPathIndex != null) {
        return evaluateFormulaLogicPath(logicPath['condition_ifs'][validIfPathIndex], variables, pathDebug)
      } else {
        // Trace
        pathDebug[logicPath['condition_else'].logic_trace_key] = true
        return evaluateFormulaLogicPath(logicPath['condition_else'], variables, pathDebug)
      }
    } else {
      return { success: false, value: null, error_path: 'IE-2', pathDebug: pathDebug, error_type: 'Missing Required If/Else Condition Configurations' }
    }
  } else if (logicPath['logic_step_type'] === 'condition_switch') {
    if (
      logicPath['condition_cases'] != null &&
      logicPath['condition_cases'].length > 0 &&
      logicPath['condition_default'] != null &&
      logicPath['switch_variable'] != null &&
      variables[logicPath['switch_variable']] != null
    ) {
      // Instantiate Variables
      let foundValidCasePath: boolean = false
      let validCasePathIndex = null
      let errorWithLogicPath: boolean = false
      // Loop through Case Conditions
      for (let loopCaseConditionIndex in logicPath['condition_cases']) {
        let loopCaseCondition = logicPath['condition_cases'][loopCaseConditionIndex]
        if (foundValidCasePath === false) {
          if (loopCaseCondition != null && loopCaseCondition['case_variable'] != null && loopCaseCondition['logic_step_type'] != null) {
            if (variables[logicPath['switch_variable']] === variables[loopCaseCondition['case_variable']]) {
              foundValidCasePath = true
              validCasePathIndex = loopCaseConditionIndex
            }
          } else {
            errorWithLogicPath = true
          }
          // Trace
          if (foundValidCasePath === true) {
            pathDebug[loopCaseCondition.logic_trace_key] = true
          } else {
            pathDebug[loopCaseCondition.logic_trace_key] = false
          }
        }
      }
      // Resolve Out
      if (errorWithLogicPath === true) {
        return { success: false, value: null, error_path: 'SC-1', pathDebug: pathDebug, error_type: 'Missing Required Switch Condition Configurations' }
      } else if (foundValidCasePath === true && validCasePathIndex != null) {
        return evaluateFormulaLogicPath(logicPath['condition_cases'][validCasePathIndex], variables, pathDebug)
      } else {
        // Trace
        pathDebug[logicPath['condition_default'].logic_trace_key] = true
        return evaluateFormulaLogicPath(logicPath['condition_default'], variables, pathDebug)
      }
    } else {
      return { success: false, value: null, error_path: 'SC-2', pathDebug: pathDebug, error_type: 'Missing Required Condition Switch Configurations' }
    }
  } else if (logicPath['logic_step_type'] === 'operation_formula') {
    if (logicPath['operation_steps'] != null && logicPath['operation_steps'].length > 0) {
      // Instantiate Variables
      let stepCalculationResults: TsInterface_UnspecifiedObject = {}
      let lastCalculationResult: string | number | boolean | Date | null = null
      let errorWithCalculation = false
      let logicInducedError = false
      // Loop through Steps
      for (let loopStepIndex in logicPath['operation_steps']) {
        let loopStep = logicPath['operation_steps'][loopStepIndex]
        let operator = getProp(loopStep, 'operation_type', null)
        let variableOneKey = getProp(loopStep, 'variable_1', null)
        let variableTwoKey = getProp(loopStep, 'variable_2', null)
        let variableOneValue = null
        let variableTwoValue = null
        if (variableOneKey != null && variableOneKey.startsWith('STEP_')) {
          variableOneValue = stepCalculationResults[variableOneKey]
        } else if (variableOneKey != null) {
          variableOneValue = getProp(variables, variableOneKey, null)
        }
        if (variableTwoKey != null && variableTwoKey.startsWith('STEP_')) {
          variableTwoValue = stepCalculationResults[variableTwoKey]
        } else if (variableTwoKey != null) {
          variableTwoValue = getProp(variables, variableTwoKey, null)
        }
        switch (operator) {
          case '+':
            if (variableOneValue != null && variableTwoValue != null && !isNaN(parseFloat(variableOneValue)) && !isNaN(parseFloat(variableTwoValue))) {
              let calculatedStepValue = parseFloat(variableOneValue) + parseFloat(variableTwoValue)
              stepCalculationResults['STEP_' + (parseInt(loopStepIndex) + 1).toString()] = calculatedStepValue
              lastCalculationResult = calculatedStepValue
            } else {
              errorWithCalculation = true
            }
            break
          case '-':
            if (variableOneValue != null && variableTwoValue != null && !isNaN(parseFloat(variableOneValue)) && !isNaN(parseFloat(variableTwoValue))) {
              let calculatedStepValue = parseFloat(variableOneValue) - parseFloat(variableTwoValue)
              stepCalculationResults['STEP_' + (parseInt(loopStepIndex) + 1).toString()] = calculatedStepValue
              lastCalculationResult = calculatedStepValue
            } else {
              errorWithCalculation = true
            }
            break
          case '*':
            if (variableOneValue != null && variableTwoValue != null && !isNaN(parseFloat(variableOneValue)) && !isNaN(parseFloat(variableTwoValue))) {
              let calculatedStepValue = parseFloat(variableOneValue) * parseFloat(variableTwoValue)
              stepCalculationResults['STEP_' + (parseInt(loopStepIndex) + 1).toString()] = calculatedStepValue
              lastCalculationResult = calculatedStepValue
            } else {
              errorWithCalculation = true
            }
            break
          case '/':
            if (variableOneValue != null && variableTwoValue != null && !isNaN(parseFloat(variableOneValue)) && !isNaN(parseFloat(variableTwoValue))) {
              let calculatedStepValue = parseFloat(variableOneValue) / parseFloat(variableTwoValue)
              stepCalculationResults['STEP_' + (parseInt(loopStepIndex) + 1).toString()] = calculatedStepValue
              lastCalculationResult = calculatedStepValue
            } else {
              errorWithCalculation = true
            }
            break
          case '%':
            if (variableOneValue != null && variableTwoValue != null && !isNaN(parseFloat(variableOneValue)) && !isNaN(parseFloat(variableTwoValue))) {
              let calculatedStepValue = parseFloat(variableOneValue) % parseFloat(variableTwoValue)
              stepCalculationResults['STEP_' + (parseInt(loopStepIndex) + 1).toString()] = calculatedStepValue
              lastCalculationResult = calculatedStepValue
            } else {
              errorWithCalculation = true
            }
            break
          case 'nothing':
            // eslint-disable-next-line no-case-declarations
            let calculatedStepValue = variableOneValue
            stepCalculationResults['STEP_' + (parseInt(loopStepIndex) + 1).toString()] = calculatedStepValue
            lastCalculationResult = calculatedStepValue
            break
          case 'error':
            logicInducedError = true
            break
          default:
            errorWithCalculation = true
        }
      }
      // Trace
      pathDebug[logicPath.logic_trace_key] = true
      // Resolve Out
      if (logicInducedError === true) {
        // Trace
        pathDebug[logicPath.logic_trace_key] = true
        return { success: false, value: null, error_path: 'OF-1', pathDebug: pathDebug, error_type: 'Logic Path Tagged with Error Flag' }
      } else if (errorWithCalculation === true) {
        // Trace
        pathDebug[logicPath.logic_trace_key] = false
        return {
          success: false,
          value: null,
          error_path: 'OF-2',
          pathDebug: pathDebug,
          error_type: 'Missing Required Formula Operation Configurations or Values',
        }
      } else {
        return { success: true, value: lastCalculationResult, pathDebug: pathDebug }
      }
    } else {
      return {
        success: false,
        value: null,
        error_path: 'OF-3',
        pathDebug: pathDebug,
        error_type: 'Missing Required Formula Operation Configurations or Values',
      }
    }
  } else {
    return { success: false, value: null, error_path: 'OF-4', pathDebug: pathDebug, error_type: 'Unsupported Logic Step Type' }
  }
}

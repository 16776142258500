//======================================//
//		  ooOOOO BOILERPLATE TEMPLATE   //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Generic unbranded login page

		TODO:
			[ ] Feature - Get Login email working

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, ButtonGroup } from '@mui/material/'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import { ConfirmationResult, getAuth, isSignInWithEmailLink, RecaptchaVerifier, signInWithEmailLink, signInWithPhoneNumber } from 'firebase/auth'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  returnLoginButtonColor,
  returnLoginHoverButtonColor,
  returnLoginLinkColor,
  rJSX_DynamicApplicationLogoSvg,
} from 'rfbp_aux/components/dynamic_app_branding'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Form, TsInterface_FormInputs, TsInterface_FormSettings, TsType_FormOnChange, TsType_FormSubmission } from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_UserInterface_AlertDialog, Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getPageLocalStorage, setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { authWithPassword, sendAuthLinkToEmail, sendPasswordResetToEmail } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { rJSX_UnauthenticatedPageFooter } from './components/footer'

///////////////////////////////
// Typescript
///////////////////////////////

declare global {
  interface Window {
    recaptchaVerifier: any
  }
}

///////////////////////////////
// Variables
///////////////////////////////

// Form Properties
const formInputs_PasswordLogin: TsInterface_FormInputs = {
  email: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email',
    label: rLIB('Your Email'),
    required: true,
    startAdornment: (
      <Icon
        icon="user"
        type="light"
      />
    ),
  },
  password: {
    data_type: 'string',
    input_type: 'text_password',
    key: 'password',
    label: rLIB('Password'),
    required: true,
    startAdornment: (
      <Icon
        icon="lock-keyhole"
        type="light"
      />
    ),
  },
}

const formInputs_LinkLogin: TsInterface_FormInputs = {
  email: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email',
    label: rLIB('Your Email'),
    required: true,
    startAdornment: (
      <Icon
        icon="user"
        type="light"
      />
    ),
  },
}

const formSettings_Login: TsInterface_FormSettings = {
  submit_button_theme: 'info',
  submit_button_alignment: 'center',
  submit_button_hide: true,
  submit_button_icon: <Icon icon="right-to-bracket" />,
  submit_button_saving_icon: true,
  submit_button_text: <>{rLIB('Log In')}</>,
  update_form_each_keystroke: true,
}

const formInputs_ForgotEmail: TsInterface_FormInputs = {
  email: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email',
    label: rLIB('Your Email'),
    required: true,
    startAdornment: (
      <Icon
        icon="user"
        type="light"
      />
    ),
  },
}

const formInputs_PhoneLogin: TsInterface_FormInputs = {
  phone: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'phone',
    label: rLIB('Your Phone Number'),
    required: true,
    startAdornment: (
      <Icon
        icon="phone"
        type="light"
      />
    ),
  },
}

const formInputs_VerifyCode: TsInterface_FormInputs = {
  access_code: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'access_code',
    label: rLIB('Access Code'),
    required: true,
    startAdornment: (
      <Icon
        icon="key"
        type="light"
      />
    ),
  },
}

const formSettings_ForgotEmail: TsInterface_FormSettings = {
  submit_button_theme: 'error',
  submit_button_alignment: 'center',
  submit_button_hide: true,
  submit_button_icon: <Icon icon="paper-plane" />,
  submit_button_saving_icon: true,
  submit_button_text: <>{rLIB('Send Password Reset Email')}</>,
  update_form_each_keystroke: true,
}

const formSettings_PhoneLogin: TsInterface_FormSettings = {
  submit_button_theme: 'info',
  submit_button_alignment: 'center',
  submit_button_hide: true,
  submit_button_icon: <Icon icon="message-sms" />,
  submit_button_saving_icon: true,
  submit_button_text: <>{rLIB('Text Access Code')}</>,
  update_form_each_keystroke: true,
}

const formSettings_VerifyCode: TsInterface_FormSettings = {
  submit_button_theme: 'info',
  submit_button_alignment: 'center',
  submit_button_hide: true,
  submit_button_icon: <Icon icon="key" />,
  submit_button_saving_icon: true,
  submit_button_text: <>{rLIB('Verify Access Code')}</>,
  update_form_each_keystroke: true,
}

// Passwordless Auth
const auth = getAuth()

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: getDomainWithProtocol(window.location.href) + '/login',
  // This must be true.
  handleCodeInApp: true,
  iOS: {
    bundleId: 'com.loadcall',
  },
  android: {
    packageName: 'com.loadcall',
    installApp: true,
    minimumVersion: '12',
  },
  dynamicLinkDomain: undefined,
  // dynamicLinkDomain: 'example.page.link'
}

///////////////////////////////
// Functions
///////////////////////////////

function getDomainWithProtocol(url: string) {
  const protocol = url.match(/^(https?:\/\/)/i)
  if (protocol) {
    return protocol[0] + url.substr(protocol[0].length).split('/')[0]
  }
  return url
}

export const returnStandardizedUSPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber != null) {
    // Remove all non-numeric characters from the input
    const numericOnly = phoneNumber.replace(/\D/g, '')
    // Check if the number is 10 digits long (without country code)
    // or 11 digits long (with country code)
    if (numericOnly.length === 10) {
      // If it's 10 digits, assume it's a US number without the country code
      return `+1${numericOnly}`
    } else if (numericOnly.length === 11 && numericOnly.startsWith('1')) {
      // If it's 11 digits and starts with '1', remove the '1' and assume it's a US number with the country code
      return `+1${numericOnly.substring(1)}`
    } else {
      // If the number does not conform to expected formats, return an error message
      return 'Invalid phone number'
    }
  } else {
    return 'Invalid phone number'
  }
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_loggingIn, us_setLoggingIn] = useState<boolean>(false)
  const [us_formData_ForgotEmail, us_setFormData_ForgotEmail] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formData_Login, us_setFormData_Login] = useState<TsInterface_UnspecifiedObject>({})
  const [us_pageType, us_setPageType] = useState('log_in_with_email_link')
  const un_routerNavigation = useNavigate()
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  // const ul_queryParams = new URLSearchParams(useLocation().search)
  const [us_formData_PhoneLogin, us_setFormData_PhoneLogin] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formData_VerifyCode, us_setFormData_VerifyCode] = useState<TsInterface_UnspecifiedObject>({})
  const [us_loginPhoneConfirmationResult, us_setLoginPhoneConfirmationResult] = useState<ConfirmationResult | null>(null)

  // { sort-end } - hooks

  // Hooks - useEffect
  // useEffect(() => {
  //   if (ul_queryParams != null && ul_queryParams.get('v') != null) {
  //     let viewType: string = ul_queryParams.get('v') as string
  //     if (viewType === 'pw') {
  //       us_setPageType('log_in_with_password')
  //       setPageLocalStorage('login', 'page_type', "log_in_with_password")
  //     }
  //   }
  //   return () => {}
  //   // eslint-disable-next-line
  // }, [])

  useEffect(() => {
    us_setFormData_Login({})
    us_setFormData_ForgotEmail({})
    return () => {}
  }, [])

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn')
      if (email != null) {
        us_setLoggingIn(true)
        signInWithEmailLink(auth, email, window.location.href)
          .then((res_SIWEL: any) => {
            window.localStorage.removeItem('emailForSignIn')
            const userKey = res_SIWEL.user.uid
            cloudFunctionUnauthenticatedRequests({
              function: 'email_link_user_account_creation',
              email: email,
              uid: userKey,
            })
              .then((res_CFUR) => {
                setTimeout(() => {
                  un_routerNavigation(ApplicationPages.HomePage.url())
                  us_setLoggingIn(false)
                }, 100)
              })
              .catch((rej_CFUR) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFUR })
                us_setLoggingIn(false)
              })
          })
          .catch((rej_SIWEL) => {
            console.error(rej_SIWEL)
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_SIWEL.error })
            us_setLoggingIn(false)
          })
      }
    }
  }, [uc_setUserInterface_ErrorDialogDisplay, un_routerNavigation])

  useEffect(() => {
    let initializeRecaptcha: any = null
    try {
      initializeRecaptcha = () => {
        if (!window.recaptchaVerifier) {
          try {
            window.recaptchaVerifier = new RecaptchaVerifier(
              'recaptcha-container',
              {
                'size': 'invisible',
                'callback': (response: any) => {
                  // reCAPTCHA solved - will proceed with submit
                },
                'expired-callback': () => {
                  // Response expired. Ask user to solve reCAPTCHA again.
                  window.recaptchaVerifier.reset()
                },
              },
              auth,
            )
          } catch (error) {
            console.error('Error initializing reCAPTCHA verifier:', error)
          }
        }
      }
      // Ensure the DOM is fully loaded before initializing reCAPTCHA
      if (document.readyState === 'complete') {
        initializeRecaptcha()
      } else {
        window.addEventListener('load', initializeRecaptcha)
      }
    } catch (err) {
      console.error(err)
    }
    // Remove the event listener when the component is unmounted
    return () => {
      if (initializeRecaptcha != null) {
        window.removeEventListener('load', initializeRecaptcha)
      }
    }
  }, [])

  useEffect(() => {
    // Set Region from Local Storage
    let pageLocalStorageData = getPageLocalStorage('login')
    if (getProp(pageLocalStorageData, 'page_type', null) != null) {
      us_setPageType(getProp(pageLocalStorageData, 'page_type', null))
    }
  }, [])

  // Functions
  const formOnChange_Login: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
    us_setFormData_Login(formData)
    ur_forceRerender()
  }

  const formSubmission_Login: TsType_FormSubmission = (formSubmittedData, formAdditionalData) => {
    return new Promise((resolve, reject) => {
      resolve({ success: true })
      // authWithPassword(formSubmittedData.email, formSubmittedData.password)
      //   .then((res_AWP) => {
      //     un_routerNavigation(ApplicationPages.HomePage.url())
      //     resolve(res_AWP)
      //   })
      //   .catch((rej_AWP) => {
      //     reject(rej_AWP)
      //   })
    })
  }

  const formOnChange_ForgotEmail: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
    us_setFormData_ForgotEmail(formData)
    ur_forceRerender()
  }

  const formOnChange_PhoneLogin: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
    us_setFormData_PhoneLogin(formData)
    ur_forceRerender()
  }

  const formOnChange_VerifyCode: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
    us_setFormData_VerifyCode(formData)
    ur_forceRerender()
  }

  const formSubmission_ForgotEmail: TsType_FormSubmission = (formSubmittedData, formAdditionalData) => {
    return new Promise((resolve, reject) => {
      resolve({ success: true })
      sendPasswordResetToEmail(formSubmittedData.email)
        .then((res_SPRTE) => {
          uc_setUserInterface_AlertDialogDisplay({
            display: true,
            alert: {
              color: 'info',
              header: rLIB('Email Sent Successfully') as JSX.Element,
              icon: <Icon icon="paper-plane" />,
              text: rLIB('Your password reset email should arrive momentarily') as JSX.Element,
            },
          })
          us_setPageType('log_in_with_password')
          setPageLocalStorage('login', 'page_type', 'log_in_with_password')
          resolve(res_SPRTE)
        })
        .catch((rej_SPRTE) => {
          reject(rej_SPRTE)
        })
    })
  }

  const formSubmission_PhoneLogin: TsType_FormSubmission = (formSubmittedData, formAdditionalData) => {
    return new Promise((resolve, reject) => {
      resolve({ success: true })
      sendSMSVerificationCode()
    })
  }

  const formSubmission_VerifyCode: TsType_FormSubmission = (formSubmittedData, formAdditionalData) => {
    return new Promise((resolve, reject) => {
      resolve({ success: true })
      verifyCode()
    })
  }

  const sendSMSVerificationCode = () => {
    us_setLoggingIn(true)
    let phoneNumber = returnStandardizedUSPhoneNumber(us_formData_PhoneLogin.phone)
    const appVerifier = window.recaptchaVerifier
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        us_setLoggingIn(false)
        us_setLoginPhoneConfirmationResult(confirmationResult)
        us_setPageType('verify_phone')
        // setPageLocalStorage('login', 'page_type', 'verify_phone')
        uc_setUserInterface_AlertDialogDisplay({
          display: true,
          alert: {
            color: 'info',
            header: rLIB('SMS Sent Successfully') as JSX.Element,
            icon: <Icon icon="paper-plane" />,
            text: rLIB('Check your phone for your access code') as JSX.Element,
          },
        })
      })
      .catch((error) => {
        us_setLoggingIn(false)
        console.error('Error during sign in:')
        console.error(error)
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
      })
  }

  const verifyCode = () => {
    if (us_loginPhoneConfirmationResult != null) {
      us_setLoggingIn(true)
      us_loginPhoneConfirmationResult
        .confirm(us_formData_VerifyCode.access_code)
        .then((res_C: any) => {
          let phoneNumber = returnStandardizedUSPhoneNumber(us_formData_PhoneLogin.phone)
          let userKey = res_C.user.uid
          console.log('<><><>')
          console.log(userKey)
          console.log(phoneNumber)

          cloudFunctionUnauthenticatedRequests({
            function: 'phone_otp_user_account_creation',
            phone: phoneNumber,
            uid: userKey,
          })
            .then((res_CFUR) => {
              setTimeout(() => {
                un_routerNavigation(ApplicationPages.HomePage.url())
                us_setLoggingIn(false)
              }, 100)
            })
            .catch((rej_CFUR) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFUR })
              us_setLoggingIn(false)
            })
        })
        .catch((rej_c: any) => {
          us_setLoggingIn(false)
          console.error('2<><><><><>')
          console.error('Error verifying code:')
          console.error(rej_c)
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: {
              code: 'auth/invalid-verification-code',
              message: rLIB('Invalid verification code'),
              details: rLIB('The verification code you entered is invalid. Please try again.'),
            },
          })
        })
    }
  }

  // JSX Generation
  const rJSX_ForgotPasswordButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="text"
        color={returnLoginLinkColor()}
        className="tw-mr-2"
        onClick={() => {
          us_setPageType('forgot_email')
          // setPageLocalStorage('login', 'page_type', "forgot_email")
        }}
      >
        {rLIB('Forgot Password')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_BackToLoginButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="text"
        color={returnLoginLinkColor()}
        onClick={() => {
          us_setPageType('log_in_with_password')
          setPageLocalStorage('login', 'page_type', 'log_in_with_password')
        }}
      >
        {rLIB('Back to Login')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_SignInWithPasswordButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_loggingIn === true) {
      buttonJSX = (
        <Box
          className="tw-mt-3.5 tw-p-3.5 tw-rounded-lg tw-cursor-default tw-text-center tw-text-white tw-text-lg tw-font-bold"
          sx={{
            background: themeVariables.gray_400,
          }}
        >
          <Icon
            icon="spinner-third"
            className="bp_spin_fast"
          />
        </Box>
      )
    } else if (us_formData_Login.email != null && us_formData_Login.email.length > 0) {
      buttonJSX = (
        <Box
          className="tw-mt-3.5 tw-p-3.5 tw-rounded-lg tw-cursor-pointer tw-text-center tw-text-white tw-text-lg tw-font-bold"
          sx={{
            'background': returnLoginButtonColor(),
            // 'fontWeight': 300,
            '&:hover': {
              background: returnLoginHoverButtonColor(),
            },
          }}
          onClick={() => {
            authWithPassword(us_formData_Login.email, us_formData_Login.password)
              .then((res_AWP) => {
                un_routerNavigation(ApplicationPages.HomePage.url())
              })
              .catch((rej_AWP) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_AWP.error })
              })
          }}
        >
          {rLIB('Log In')}
        </Box>
      )
    } else {
      buttonJSX = (
        <Box
          className="tw-mt-3.5 tw-p-3.5 tw-rounded-lg tw-cursor-default tw-text-center tw-text-white tw-text-lg tw-font-bold"
          sx={{
            background: themeVariables.gray_400,
          }}
        >
          {rLIB('Log In')}
        </Box>
      )
    }
    return buttonJSX
  }

  const rJSX_SignInWithLinkButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_loggingIn === true) {
      buttonJSX = (
        <Box
          className="tw-mt-3.5 tw-p-3.5 tw-rounded-lg tw-cursor-default tw-text-center tw-text-white tw-text-lg tw-font-bold"
          sx={{
            background: themeVariables.gray_400,
          }}
        >
          <Icon
            icon="spinner-third"
            className="bp_spin_fast"
          />
        </Box>
      )
    } else if (
      us_formData_Login.email != null &&
      us_formData_Login.email.length > 0
      // us_formData_Login.password != null &&
      // us_formData_Login.password.length > 0
    ) {
      buttonJSX = (
        <Box
          className="tw-mt-3.5 tw-p-3.5 tw-rounded-lg tw-cursor-pointer tw-text-center tw-text-white tw-text-lg tw-font-bold"
          sx={{
            'background': returnLoginButtonColor(),
            '&:hover': {
              background: returnLoginHoverButtonColor(),
            },
          }}
          onClick={() => {
            sendAuthLinkToEmail(us_formData_Login.email, actionCodeSettings)
              .then((res_SALTE) => {
                uc_setUserInterface_AlertDialogDisplay({
                  display: true,
                  alert: {
                    color: 'info',
                    header: rLIB('Email Sent Successfully') as JSX.Element,
                    icon: <Icon icon="paper-plane" />,
                    text: rLIB('Check your email for a link to sign in') as JSX.Element,
                  },
                })
              })
              .catch((rej_SALTE) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_SALTE.error })
              })
          }}
        >
          {rLIB('Send Login Email')}
        </Box>
      )
    } else {
      buttonJSX = (
        <Box
          className="tw-mt-3.5 tw-p-3.5 tw-rounded-lg tw-cursor-default tw-text-center tw-text-white tw-text-lg tw-font-bold"
          sx={{
            background: themeVariables.gray_400,
          }}
        >
          {rLIB('Send Login Email')}
        </Box>
      )
    }
    return buttonJSX
  }

  const rJSX_SignInWithPhoneButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_loggingIn === true) {
      buttonJSX = (
        <Box
          className="tw-mt-3.5 tw-p-3.5 tw-rounded-lg tw-cursor-default tw-text-center tw-text-white tw-text-lg tw-font-bold"
          sx={{
            background: themeVariables.gray_400,
          }}
        >
          <Icon
            icon="spinner-third"
            className="bp_spin_fast"
          />
        </Box>
      )
    } else if (
      us_formData_PhoneLogin.phone != null &&
      us_formData_PhoneLogin.phone.length > 0 &&
      returnStandardizedUSPhoneNumber(us_formData_PhoneLogin.phone) !== 'Invalid phone number'
    ) {
      buttonJSX = (
        <Box
          className="tw-mt-3.5 tw-p-3.5 tw-rounded-lg tw-cursor-pointer tw-text-center tw-text-white tw-text-lg tw-font-bold"
          sx={{
            'background': returnLoginButtonColor(),
            '&:hover': {
              background: returnLoginHoverButtonColor(),
            },
          }}
          onClick={() => {
            sendSMSVerificationCode()
          }}
        >
          {rLIB('Send SMS Access Code')}
        </Box>
      )
    } else {
      buttonJSX = (
        <Box
          className="tw-mt-3.5 tw-p-3.5 tw-rounded-lg tw-cursor-default tw-text-center tw-text-white tw-text-lg tw-font-bold"
          sx={{
            background: themeVariables.gray_400,
          }}
        >
          {rLIB('Send SMS Access Code')}
        </Box>
      )
    }
    return buttonJSX
  }

  const rJSX_ConfirmPhoneAccessTokenButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_loggingIn === true) {
      buttonJSX = (
        <Box
          className="tw-mt-3.5 tw-p-3.5 tw-rounded-lg tw-cursor-default tw-text-center tw-text-white tw-text-lg tw-font-bold"
          sx={{
            background: themeVariables.gray_400,
          }}
        >
          <Icon
            icon="spinner-third"
            className="bp_spin_fast"
          />
        </Box>
      )
    } else if (
      us_formData_VerifyCode.access_code != null &&
      us_formData_VerifyCode.access_code.length > 0 &&
      returnStandardizedUSPhoneNumber(us_formData_VerifyCode.phone) !== 'Invalid phone number'
    ) {
      buttonJSX = (
        <Box
          className="tw-mt-3.5 tw-p-3.5 tw-rounded-lg tw-cursor-pointer tw-text-center tw-text-white tw-text-lg tw-font-bold"
          sx={{
            'background': returnLoginButtonColor(),
            '&:hover': {
              background: returnLoginHoverButtonColor(),
            },
          }}
          onClick={() => {
            verifyCode()
          }}
        >
          {rLIB('Verify Access Code')}
        </Box>
      )
    } else {
      buttonJSX = (
        <Box
          className="tw-mt-3.5 tw-p-3.5 tw-rounded-lg tw-cursor-default tw-text-center tw-text-white tw-text-lg tw-font-bold"
          sx={{
            background: themeVariables.gray_400,
          }}
        >
          {rLIB('Verify Access Code')}
        </Box>
      )
    }
    return buttonJSX
  }

  const rJSX_ResetPasswordButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_formData_ForgotEmail.email != null && us_formData_ForgotEmail.email.length > 0) {
      buttonJSX = (
        <Box
          className="tw-mt-4 tw-p-4 tw-rounded-lg tw-cursor-pointer tw-text-center tw-text-white tw-text-lg tw-font-bold"
          sx={{
            'background': returnLoginButtonColor(),
            '&:hover': {
              background: returnLoginHoverButtonColor(),
            },
          }}
          onClick={() => {
            sendPasswordResetToEmail(us_formData_ForgotEmail.email)
              .then((res_SPRTE) => {
                uc_setUserInterface_AlertDialogDisplay({
                  display: true,
                  alert: {
                    color: 'info',
                    header: rLIB('Email Sent Successfully') as JSX.Element,
                    icon: <Icon icon="paper-plane" />,
                    text: rLIB('Your password reset email should arrive momentarily') as JSX.Element,
                  },
                })
                us_setPageType('log_in_with_password')
                setPageLocalStorage('login', 'page_type', 'log_in_with_password')
              })
              .catch((rej_SPRTE) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_SPRTE.error })
              })
          }}
        >
          {rLIB('Send Password Reset Email')}
        </Box>
      )
    } else {
      buttonJSX = (
        <Box
          className="tw-mt-4 tw-p-4 tw-rounded-lg tw-cursor-default tw-text-center tw-text-white tw-text-lg tw-font-bold"
          sx={{
            background: themeVariables.gray_400,
          }}
        >
          {rLIB('Send Password Reset Email')}
        </Box>
      )
    }
    return buttonJSX
  }

  const rJSX_PhoneEmailToggle = (): JSX.Element => {
    let buttonGroupJSX = <></>
    buttonGroupJSX = (
      <ButtonGroup className="tw-my-4">
        <Button
          color="info"
          variant={us_pageType.indexOf('email') > -1 ? 'contained' : 'outlined'}
          startIcon={<Icon icon="envelope" />}
          onClick={() => {
            us_setPageType('log_in_with_email_link')
            setPageLocalStorage('login', 'page_type', 'log_in_with_email_link')
          }}
        >
          {rLIB('Email Link')}
        </Button>
        <Button
          color="info"
          variant={us_pageType.indexOf('password') > -1 ? 'contained' : 'outlined'}
          startIcon={<Icon icon="envelope" />}
          onClick={() => {
            us_setPageType('log_in_with_password')
            setPageLocalStorage('login', 'page_type', 'log_in_with_password')
          }}
        >
          {rLIB('Password')}
        </Button>
        <Button
          color="info"
          variant={us_pageType.indexOf('phone') > -1 ? 'contained' : 'outlined'}
          startIcon={<Icon icon="phone" />}
          disabled={true}
          onClick={() => {
            us_setPageType('sign_in_with_phone')
            setPageLocalStorage('login', 'page_type', 'sign_in_with_phone')
          }}
        >
          {rLIB('SMS Code')}
        </Button>
      </ButtonGroup>
    )
    return buttonGroupJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let loginFormJSX: JSX.Element = <></>
    if (us_pageType === 'log_in_with_password') {
      // HIDDEN?
      loginFormJSX = (
        <Box>
          <Box className="tw-mx-auto tw-my-4 tw-max-w-lg">
            {rJSX_PhoneEmailToggle()}
            <Form
              formAdditionalData={{}}
              formData={us_formData_Login}
              formInputs={formInputs_PasswordLogin}
              formOnChange={formOnChange_Login}
              formSettings={formSettings_Login}
              formSubmission={formSubmission_Login}
            />
            <Box>{rJSX_SignInWithPasswordButton()}</Box>
          </Box>
          <Box className="tw-mb-16 tw-mt-4">{rJSX_ForgotPasswordButton()}</Box>
        </Box>
      )
    } else if (us_pageType === 'log_in_with_email_link') {
      formInputs_LinkLogin.email.disabled = us_loggingIn
      loginFormJSX = (
        <Box>
          <Box className="tw-mx-auto tw-my-4 tw-max-w-lg">
            {rJSX_PhoneEmailToggle()}
            <Form
              formAdditionalData={{}}
              formData={us_formData_Login}
              formInputs={formInputs_LinkLogin}
              formOnChange={formOnChange_Login}
              formSettings={formSettings_Login}
              formSubmission={formSubmission_Login}
            />
            <Box>{rJSX_SignInWithLinkButton()}</Box>
          </Box>
        </Box>
      )
    } else if (us_pageType === 'forgot_email') {
      // HIDDEN?
      loginFormJSX = (
        <Box>
          <Box className="tw-mx-auto tw-my-4 tw-max-w-lg">
            {rJSX_PhoneEmailToggle()}
            <Form
              formAdditionalData={{}}
              formData={us_formData_ForgotEmail}
              formInputs={formInputs_ForgotEmail}
              formOnChange={formOnChange_ForgotEmail}
              formSettings={formSettings_ForgotEmail}
              formSubmission={formSubmission_ForgotEmail}
            />
            <Box>{rJSX_ResetPasswordButton()}</Box>
          </Box>
          <Box className="tw-mb-16 tw-mt-4">{rJSX_BackToLoginButton()}</Box>
        </Box>
      )
    } else if (us_pageType === 'sign_in_with_phone') {
      loginFormJSX = (
        <Box>
          <Box className="tw-mx-auto tw-my-4 tw-max-w-lg">
            {rJSX_PhoneEmailToggle()}
            <Form
              formAdditionalData={{}}
              formData={us_formData_PhoneLogin}
              formInputs={formInputs_PhoneLogin}
              formOnChange={formOnChange_PhoneLogin}
              formSettings={formSettings_PhoneLogin}
              formSubmission={formSubmission_PhoneLogin}
            />
            <Box>{rJSX_SignInWithPhoneButton()}</Box>
          </Box>
        </Box>
      )
    } else if (us_pageType === 'verify_phone') {
      loginFormJSX = (
        <Box>
          <Box className="tw-mx-auto tw-my-4 tw-max-w-lg">
            {rJSX_PhoneEmailToggle()}
            <Form
              formAdditionalData={{}}
              formData={us_formData_VerifyCode}
              formInputs={formInputs_VerifyCode}
              formOnChange={formOnChange_VerifyCode}
              formSettings={formSettings_VerifyCode}
              formSubmission={formSubmission_VerifyCode}
            />
            <Box>{rJSX_ConfirmPhoneAccessTokenButton()}</Box>
          </Box>
        </Box>
      )
    } else {
      loginFormJSX = (
        <Box>
          <Box className="tw-mx-auto tw-my-4 tw-max-w-lg">{rJSX_PhoneEmailToggle()}</Box>
        </Box>
      )
    }
    let pageJSX = (
      <Box
        className="tw-text-center tw-w-full tw-pt-10"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '70vh',
        }}
      >
        <Box sx={{ marginTop: '10vh' }}>{rJSX_DynamicApplicationLogoSvg('80px', {})}</Box>
        {loginFormJSX}
        {rJSX_UnauthenticatedPageFooter(un_routerNavigation)}
      </Box>
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

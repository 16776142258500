//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

import { Box } from '@mui/material'
import { getProp } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  height: string
  color: string
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const LogoLoadCall = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let height: TsInterface_ComponentProps['height'] = getProp(props, 'height', '30px')
  let color: TsInterface_ComponentProps['color'] = getProp(props, 'color', null)

  // Hooks - useContext, useState, useReducer, other

  // Hooks - useEffect

  // Functions

  // JSX Generation
  const rJSX_Logo = () => {
    let logoJSX = <></>
    if (color != null) {
      logoJSX = (
        <Box>
          <svg
            height={height}
            viewBox="0 0 366.441 186.017"
          >
            <g>
              <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="183.2202"
                y1="-43.1855"
                x2="183.2202"
                y2="205.151"
              >
                <stop
                  offset="0.0171"
                  // @ts-ignore
                  style={{ stopColor: color }}
                />
                <stop
                  offset="0.6"
                  // @ts-ignore
                  style={{ stopColor: color }}
                />
                <stop
                  offset="1"
                  // @ts-ignore
                  style={{ stopColor: color }}
                />
              </linearGradient>
              <path
                fill="url(#SVGID_1_)"
                d="M75.447,59.861c1.807-1.065,3.515-2.022,5.377-2.63
            c7.172-2.342,14.306-2.594,21.232,0.782c6.039,2.946,9.309,8.026,10.577,14.507c0.112,0.568,0.184,1.146,0.285,1.776
            c0.279-0.218,0.316-0.524,0.41-0.805c1.163-3.479,2.329-6.957,3.492-10.436c1.43-4.274,4.681-6.643,9.195-6.654
            c4.952-0.017,9.911-0.021,14.866,0c5.7,0.021,9.663,4.176,9.414,9.849c-0.136,3.241-0.297,6.477-0.433,9.717
            c-0.03,0.724-0.008,1.454,0.105,2.199c0.459-2.355,0.922-4.713,1.373-7.072c0.459-2.425,0.881-4.859,1.362-7.283
            c0.847-4.22,4.398-7.328,8.703-7.376c6.596-0.079,13.203-0.271,19.784,0.092c5.271,0.292,10.008,2.165,13.756,6.056
            c0.644,0.672,0.869,0.798,1.268-0.22c5.73-14.58,14.445-27.061,26.523-37.077c12.668-10.503,26.876-18.062,42.942-21.96
            c6.728-1.633,13.545-2.643,20.461-3.089c6.486-0.418,12.966-0.26,19.43,0.29c8.252,0.702,16.36,2.135,24.032,5.422
            c3.367,1.443,6.709,2.963,10.088,4.387c0.847,0.355,0.455,0.83,0.312,1.294c-2.814,9.194-5.927,18.292-8.937,27.424
            c-1.746,5.296-3.541,10.575-5.283,15.875c-0.237,0.713-0.429,0.847-1.185,0.54c-6.404-2.59-12.91-4.933-19.735-6.048
            c-10.23-1.67-20.473-1.994-30.401,1.616c-11.428,4.148-19.642,11.99-25.09,22.756c-3.642,7.204-5.316,14.955-5.99,22.956
            c-0.346,4.11-0.53,8.218-0.177,12.328c0.572,6.72,1.979,13.16,6.303,18.616c0.267,0.33,0.481,0.719,1.005,0.933
            c1.057-3.142,2.114-6.259,3.156-9.38c6.284-18.766,12.563-37.534,18.84-56.298c1.271-3.787,4.308-6.393,8.074-6.479
            c5.716-0.131,11.446-0.143,17.158,0.028c4.439,0.134,8.188,4.228,8.213,8.673c0.008,2.003-0.139,4.005-0.207,6.007
            c-0.011,0.346-0.003,0.694,0.147,1.056c0.534-2.809,1.072-5.616,1.603-8.423c0.76-3.99,4.214-7.194,8.267-7.346
            c3.966-0.147,7.942-0.154,11.912-0.008c4.354,0.16,7.473,3.101,8.557,7.825c0.339-1.101,0.605-2.111,1.129-3.025
            c0.534-0.929,1.192-1.738,1.994-2.444c1.742-1.535,3.8-2.269,6.092-2.295c3.461-0.042,6.923-0.028,10.388-0.008
            c6.01,0.036,10.25,5.313,9.113,11.229c-2.638,13.736-5.233,27.477-7.841,41.214c-0.67,3.5-1.287,7.006-2.028,10.488
            c-0.191,0.899,0.098,0.92,0.816,0.954c1.938,0.092,3.812,0.506,5.584,1.326c3.672,1.7,5.561,5.491,4.79,9.685
            c-1.204,6.525-2.446,13.043-3.65,19.568c-1.61,8.709-3.209,17.422-4.797,26.135c-0.478,2.607-0.948,5.215-1.328,7.838
            c-0.132,0.908-0.527,1.074-1.354,1.07c-11.329-0.025-22.658-0.018-33.992-0.018c-102.196-0.006-204.385-0.008-306.581-0.012
            c-4.511,0-9.026-0.012-13.542,0.031c-0.858,0.008-1.148-0.186-0.982-1.084c1.806-9.709,3.589-19.422,5.376-29.137
            c1.769-9.646,3.537-19.295,5.313-28.941c1.651-9.004,3.311-18.008,4.963-27.012c1.776-9.649,3.537-19.296,5.312-28.942
            c1.727-9.397,3.466-18.792,5.188-28.189c1.852-10.114,3.691-20.228,5.539-30.342c0.365-2,0.764-3.994,1.091-6.001
            c0.12-0.783,0.406-1.063,1.249-1.059C43.954,5.343,53.831,5.33,63.708,5.33c6.924,0,13.851,0.019,20.773-0.019
            c0.975-0.008,1.144,0.26,0.971,1.18c-2.122,11.131-4.191,22.272-6.287,33.406c-1.193,6.317-2.42,12.631-3.623,18.947
            C75.489,59.122,75.489,59.41,75.447,59.861z M351.281,159.763c0.696-4.051,1.46-8.09,2.212-12.129
            c0.233-1.277,0.245-1.275-1.057-1.275c-27.265,0-54.524-0.027-81.788,0.027c-3.499,0.01-6.886-0.406-10.234-1.342
            c-7.57-2.109-14.091-5.848-18.858-12.256c-5.17-6.932-7.093-14.962-7.736-23.393c-0.553-7.269,0.012-14.483,1.299-21.642
            c1.968-10.895,5.989-20.888,13.218-29.403c10.448-12.304,23.806-18.706,39.91-19.36c4.338-0.177,8.673-0.042,12.996,0.416
            c6.072,0.644,11.983,1.912,17.537,4.542c0.523,0.252,0.708,0.224,0.892-0.346c1.317-4.041,2.66-8.074,4.008-12.106
            c0.173-0.512,0.797-2.333,1.004-3.002c1.092-3.428,2.25-6.835,3.379-10.25c0.734-2.237,0.745-2.269-1.358-3.258
            c-5.52-2.598-11.415-3.881-17.425-4.617c-7.604-0.932-15.238-1.251-22.902-0.736c-7.142,0.48-14.159,1.597-21.06,3.486
            c-10.667,2.92-20.408,7.748-29.51,14.014c-13.688,9.427-23.811,21.707-30.214,37.046c-5.494,13.175-7.706,27.016-8.263,41.176
            c-0.233,6.022,0.248,12.015,1.286,17.95c1.366,7.796,3.842,15.208,7.86,22.065c0.173,0.289,0.497,0.535,0.376,0.988
            c-0.368,0-0.733,0-1.095,0c-51.683,0-103.366,0-155.05,0c-0.146,0-0.289-0.02-0.437,0.002c-0.778,0.115-0.876-0.188-0.741-0.914
            c1.238-6.354,2.431-12.715,3.635-19.073c2.152-11.323,4.309-22.647,6.457-33.969c2.077-10.931,4.154-21.861,6.227-32.792
            c2.07-10.932,4.143-21.862,6.22-32.795c0.757-3.994,1.517-7.99,2.296-12.083c-5.482,0-10.874,0.004-16.263-0.007
            c-0.372-0.002-2.246,0.009-2.386,0.009c-4.699-0.004-9.402,0.015-14.105-0.022c-0.816-0.004-0.824,0.468-0.926,1.023
            c-1.257,6.904-2.521,13.809-3.793,20.711c-2.009,10.941-4.021,21.885-6.035,32.827c-1.934,10.519-3.871,21.029-5.802,31.544
            c-1.934,10.517-3.856,21.029-5.791,31.546c-2.005,10.905-4.011,21.813-6.017,32.722c-0.655,3.578-1.294,7.156-1.99,10.727
            c-0.135,0.701,0.094,0.842,0.692,0.785c0.218-0.02,0.44-0.002,0.658-0.002c86.198,0,172.392,0,258.593,0
            c25.111,0,50.224,0,75.339,0c0.29,0,0.583-0.035,0.869,0.004c0.609,0.09,0.832-0.217,0.934-0.766
            C349.139,171.366,351.167,160.421,351.281,159.763z M157.212,140.06c0-0.018,0-0.035,0-0.055c2.916,0,5.84,0.145,8.74-0.031
            c5.336-0.324,9.704-2.604,12.876-6.99c2.638-3.65,3.85-7.85,4.677-12.211c2.138-11.322,4.309-22.635,6.453-33.954
            c0.685-3.604,1.444-7.194,1.03-10.924c-0.699-6.248-4.549-10.039-10.832-10.21c-5.824-0.16-11.656-0.057-17.484-0.134
            c-0.96-0.013-1.303,0.292-1.46,1.2c-0.787,4.4-1.618,8.792-2.461,13.185c-3.752,19.707-7.511,39.412-11.292,59.115
            c-0.162,0.852,0.061,1.043,0.899,1.029C151.309,140.032,154.258,140.06,157.212,140.06z M76.131,140.919
            c6.623-0.045,11.891-2.67,15.585-8.246c2.19-3.297,3.349-6.994,4.087-10.846c2.155-11.352,4.33-22.705,6.471-34.061
            c0.686-3.608,1.577-7.194,1.393-10.912c-0.158-2.995-0.79-5.849-2.814-8.2c-2.37-2.762-5.554-3.72-9.045-3.893
            c-6.999-0.344-12.684,2.109-16.702,7.982c-2.276,3.328-3.474,7.092-4.222,11.015c-2.228,11.711-4.451,23.426-6.679,35.138
            c-0.749,3.93-1.622,7.848-1.001,11.898c0.646,4.213,2.622,7.439,6.72,9.082C71.911,140.673,73.991,140.96,76.131,140.919z
            M256.127,140.06c3.003,0,5.874-0.027,8.748,0.02c0.757,0.014,1.065-0.223,1.287-0.936c1.208-3.848,2.514-7.664,3.698-11.518
            c0.268-0.873,0.674-1.082,1.532-1.07c3.457,0.049,6.923,0.019,10.385,0.019c1.509,0,1.479,0,1.385,1.558
            c-0.223,3.671-0.399,7.348-0.629,11.018c-0.045,0.709,0.128,0.938,0.865,0.928c2.95-0.041,5.903-0.037,8.854,0
            c0.685,0.006,0.96-0.158,0.989-0.893c0.286-6.876,0.603-13.756,0.911-20.634c0.316-7.095,0.621-14.192,0.948-21.287
            c0.466-10.225,0.944-20.448,1.452-30.671c0.037-0.809-0.14-1.048-0.986-1.041c-4.405,0.045-8.815,0.064-13.225-0.011
            c-1.047-0.015-1.423,0.346-1.731,1.277c-7.183,21.53-14.396,43.052-21.601,64.574C258.058,134.241,257.114,137.097,256.127,140.06z
            M139.212,102.823c0.003,0,0.007,0,0.011,0c0.207-4.694,0.398-9.39,0.617-14.084c0.335-7.388,0.666-14.774,1.038-22.158
            c0.042-0.837-0.191-1.035-1.008-1.027c-4.485,0.042-8.963,0.042-13.448,0c-0.797-0.009-1.105,0.254-1.354,1.001
            c-3.289,9.894-6.615,19.774-9.923,29.657c-4.778,14.258-9.538,28.519-14.335,42.771c-0.294,0.861-0.211,1.121,0.764,1.096
            c2.589-0.055,5.174-0.045,7.758-0.002c0.666,0.01,0.941-0.215,1.141-0.834c1.215-3.844,2.502-7.664,3.698-11.516
            c0.271-0.873,0.636-1.193,1.581-1.176c3.499,0.064,7.002,0.07,10.493-0.002c1.046-0.022,1.366,0.219,1.287,1.302
            c-0.274,3.776-0.44,7.563-0.677,11.343c-0.042,0.635,0.078,0.893,0.805,0.885c3.025-0.045,6.047-0.033,9.072-0.006
            c0.59,0.004,0.819-0.146,0.846-0.787c0.275-6.697,0.572-13.393,0.877-20.09C138.704,113.738,138.959,108.28,139.212,102.823z
            M342.972,140.06c3.604,0,7.214-0.018,10.821,0.018c0.741,0.006,1.065-0.145,1.204-0.967c0.479-2.867,1.05-5.723,1.637-8.568
            c0.181-0.857,0-1.121-0.914-1.105c-3.608,0.051-7.221-0.012-10.825,0.041c-0.91,0.013-1.034-0.292-0.873-1.07
            c0.392-1.852,0.73-3.713,1.088-5.567c2.156-11.319,4.305-22.642,6.46-33.963c1.419-7.43,2.818-14.857,4.264-22.281
            c0.154-0.812,0.019-1.061-0.862-1.042c-2.766,0.058-5.538,0.086-8.304-0.011c-1.042-0.036-1.351,0.333-1.524,1.264
            c-1.952,10.396-3.939,20.787-5.914,31.178c-2.604,13.68-5.192,27.36-7.815,41.035c-0.146,0.797-0.063,1.072,0.843,1.061
            C335.828,140.032,339.398,140.06,342.972,140.06z M313.805,140.06c3.604,0,7.217-0.031,10.821,0.025
            c0.861,0.014,1.103-0.309,1.237-1.064c0.501-2.865,1.009-5.732,1.622-8.57c0.207-0.939-0.075-1.014-0.865-1.009
            c-3.608,0.032-7.217,0-10.825,0.024c-0.775,0.008-1.133-0.002-0.926-1.031c1.189-5.954,2.269-11.929,3.405-17.893
            c2.8-14.678,5.599-29.354,8.417-44.026c0.132-0.692,0.022-0.969-0.771-0.956c-2.95,0.041-5.903,0.041-8.854-0.002
            c-0.67-0.011-0.881,0.258-0.993,0.837c-1.027,5.501-2.073,11.004-3.119,16.505c-2.142,11.287-4.29,22.57-6.438,33.856
            c-1.414,7.429-2.825,14.858-4.263,22.28c-0.15,0.771-0.079,1.062,0.835,1.047C306.66,140.028,310.234,140.06,313.805,140.06z"
              />
              <linearGradient
                id="SVGID_2_"
                gradientUnits="userSpaceOnUse"
                x1="170.1465"
                y1="-23.5825"
                x2="170.1465"
                y2="224.754"
              >
                <stop
                  offset="0.0171"
                  // @ts-ignore
                  style={{ stopColor: color }}
                />
                <stop
                  offset="0.6"
                  // @ts-ignore
                  style={{ stopColor: color }}
                />
                <stop
                  offset="1"
                  // @ts-ignore
                  style={{ stopColor: color }}
                />
              </linearGradient>
              <path
                fill="url(#SVGID_2_)"
                d="M163.357,129.456c-0.688,0-1.385,0-2.077,0
            c-1.399,0-1.393,0.002-1.125-1.414c3.131-16.428,6.257-32.857,9.376-49.287c0.489-2.566,0.479-2.592,3.135-2.561
            c1.49,0.019,2.987-0.152,4.47,0.132c1.961,0.376,2.932,1.498,3.104,3.501c0.188,2.218-0.369,4.34-0.775,6.479
            c-2.216,11.754-4.459,23.5-6.697,35.251c-0.271,1.431-0.617,2.831-1.234,4.169c-1.121,2.43-2.995,3.671-5.659,3.723
            c-0.839,0.016-1.678,0.004-2.517,0.004V129.456z"
              />
              <linearGradient
                id="SVGID_3_"
                gradientUnits="userSpaceOnUse"
                x1="83.3398"
                y1="-23.5645"
                x2="83.3398"
                y2="224.7721"
              >
                <stop
                  offset="0.0171"
                  // @ts-ignore
                  style={{ stopColor: color }}
                />
                <stop
                  offset="0.6"
                  // @ts-ignore
                  style={{ stopColor: color }}
                />
                <stop
                  offset="1"
                  // @ts-ignore
                  style={{ stopColor: color }}
                />
              </linearGradient>
              <path
                fill="url(#SVGID_3_)"
                d="M73.735,125.152c0.207-2.507,0.873-5.231,1.389-7.982
            c2.137-11.357,4.312-22.708,6.482-34.061c0.426-2.265,0.994-4.502,2.766-6.156c1.754-1.637,3.834-1.958,6.084-1.283
            c1.358,0.408,2.021,1.445,2.318,2.783c0.478,2.22-0.146,4.348-0.553,6.479c-2.359,12.537-4.753,25.066-7.149,37.597
            c-0.429,2.265-1.001,4.496-2.766,6.158c-1.72,1.628-3.796,1.935-5.99,1.313C74.521,129.49,73.686,127.89,73.735,125.152z"
              />
              <linearGradient
                id="SVGID_4_"
                gradientUnits="userSpaceOnUse"
                x1="279.7534"
                y1="-33.8687"
                x2="279.7534"
                y2="214.4722"
              >
                <stop
                  offset="0.0171"
                  // @ts-ignore
                  style={{ stopColor: color }}
                />
                <stop
                  offset="0.6"
                  // @ts-ignore
                  style={{ stopColor: color }}
                />
                <stop
                  offset="1"
                  // @ts-ignore
                  style={{ stopColor: color }}
                />
              </linearGradient>
              <path
                fill="url(#SVGID_4_)"
                d="M285.845,78.939c-0.091,1.761-0.17,3.524-0.271,5.283
            c-0.496,8.722-0.997,17.444-1.509,26.167c-0.102,1.744-0.237,3.486-0.319,5.23c-0.03,0.562-0.147,0.827-0.806,0.818
            c-2.878-0.036-5.757-0.033-8.631-0.004c-0.723,0.009-0.738-0.205-0.538-0.816c3.92-12.088,7.811-24.181,11.713-36.272
            c0.049-0.154,0.132-0.297,0.195-0.45C285.736,78.912,285.792,78.927,285.845,78.939z"
              />
              <linearGradient
                id="SVGID_5_"
                gradientUnits="userSpaceOnUse"
                x1="124.0298"
                y1="-33.8179"
                x2="124.0298"
                y2="214.5183"
              >
                <stop
                  offset="0.0171"
                  // @ts-ignore
                  style={{ stopColor: color }}
                />
                <stop
                  offset="0.6"
                  // @ts-ignore
                  style={{ stopColor: color }}
                />
                <stop
                  offset="1"
                  // @ts-ignore
                  style={{ stopColor: color }}
                />
              </linearGradient>
              <path
                fill="url(#SVGID_5_)"
                d="M130.193,79.005c-0.26,4.688-0.516,9.38-0.786,14.068
            c-0.331,5.82-0.678,11.636-1.013,17.455c-0.102,1.709-0.188,3.417-0.274,5.127c-0.022,0.422-0.041,0.779-0.644,0.777
            c-3.16-0.021-6.328-0.01-9.609-0.01c4.052-12.562,8.074-25.016,12.097-37.47C130.042,78.97,130.114,78.989,130.193,79.005z"
              />
            </g>
          </svg>
        </Box>
      )
    } else {
      logoJSX = (
        <Box>
          <svg
            height={height}
            viewBox="0 0 366.441 186.017"
          >
            <g>
              <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="183.2202"
                y1="-43.1855"
                x2="183.2202"
                y2="205.151"
              >
                <stop
                  offset="0.0171"
                  // @ts-ignore
                  style={{ stopColor: '#F9C828' }}
                />
                <stop
                  offset="0.6"
                  // @ts-ignore
                  style={{ stopColor: '#F16C24' }}
                />
                <stop
                  offset="1"
                  // @ts-ignore
                  style={{ stopColor: '#EC197B' }}
                />
              </linearGradient>
              <path
                fill="url(#SVGID_1_)"
                d="M75.447,59.861c1.807-1.065,3.515-2.022,5.377-2.63
            c7.172-2.342,14.306-2.594,21.232,0.782c6.039,2.946,9.309,8.026,10.577,14.507c0.112,0.568,0.184,1.146,0.285,1.776
            c0.279-0.218,0.316-0.524,0.41-0.805c1.163-3.479,2.329-6.957,3.492-10.436c1.43-4.274,4.681-6.643,9.195-6.654
            c4.952-0.017,9.911-0.021,14.866,0c5.7,0.021,9.663,4.176,9.414,9.849c-0.136,3.241-0.297,6.477-0.433,9.717
            c-0.03,0.724-0.008,1.454,0.105,2.199c0.459-2.355,0.922-4.713,1.373-7.072c0.459-2.425,0.881-4.859,1.362-7.283
            c0.847-4.22,4.398-7.328,8.703-7.376c6.596-0.079,13.203-0.271,19.784,0.092c5.271,0.292,10.008,2.165,13.756,6.056
            c0.644,0.672,0.869,0.798,1.268-0.22c5.73-14.58,14.445-27.061,26.523-37.077c12.668-10.503,26.876-18.062,42.942-21.96
            c6.728-1.633,13.545-2.643,20.461-3.089c6.486-0.418,12.966-0.26,19.43,0.29c8.252,0.702,16.36,2.135,24.032,5.422
            c3.367,1.443,6.709,2.963,10.088,4.387c0.847,0.355,0.455,0.83,0.312,1.294c-2.814,9.194-5.927,18.292-8.937,27.424
            c-1.746,5.296-3.541,10.575-5.283,15.875c-0.237,0.713-0.429,0.847-1.185,0.54c-6.404-2.59-12.91-4.933-19.735-6.048
            c-10.23-1.67-20.473-1.994-30.401,1.616c-11.428,4.148-19.642,11.99-25.09,22.756c-3.642,7.204-5.316,14.955-5.99,22.956
            c-0.346,4.11-0.53,8.218-0.177,12.328c0.572,6.72,1.979,13.16,6.303,18.616c0.267,0.33,0.481,0.719,1.005,0.933
            c1.057-3.142,2.114-6.259,3.156-9.38c6.284-18.766,12.563-37.534,18.84-56.298c1.271-3.787,4.308-6.393,8.074-6.479
            c5.716-0.131,11.446-0.143,17.158,0.028c4.439,0.134,8.188,4.228,8.213,8.673c0.008,2.003-0.139,4.005-0.207,6.007
            c-0.011,0.346-0.003,0.694,0.147,1.056c0.534-2.809,1.072-5.616,1.603-8.423c0.76-3.99,4.214-7.194,8.267-7.346
            c3.966-0.147,7.942-0.154,11.912-0.008c4.354,0.16,7.473,3.101,8.557,7.825c0.339-1.101,0.605-2.111,1.129-3.025
            c0.534-0.929,1.192-1.738,1.994-2.444c1.742-1.535,3.8-2.269,6.092-2.295c3.461-0.042,6.923-0.028,10.388-0.008
            c6.01,0.036,10.25,5.313,9.113,11.229c-2.638,13.736-5.233,27.477-7.841,41.214c-0.67,3.5-1.287,7.006-2.028,10.488
            c-0.191,0.899,0.098,0.92,0.816,0.954c1.938,0.092,3.812,0.506,5.584,1.326c3.672,1.7,5.561,5.491,4.79,9.685
            c-1.204,6.525-2.446,13.043-3.65,19.568c-1.61,8.709-3.209,17.422-4.797,26.135c-0.478,2.607-0.948,5.215-1.328,7.838
            c-0.132,0.908-0.527,1.074-1.354,1.07c-11.329-0.025-22.658-0.018-33.992-0.018c-102.196-0.006-204.385-0.008-306.581-0.012
            c-4.511,0-9.026-0.012-13.542,0.031c-0.858,0.008-1.148-0.186-0.982-1.084c1.806-9.709,3.589-19.422,5.376-29.137
            c1.769-9.646,3.537-19.295,5.313-28.941c1.651-9.004,3.311-18.008,4.963-27.012c1.776-9.649,3.537-19.296,5.312-28.942
            c1.727-9.397,3.466-18.792,5.188-28.189c1.852-10.114,3.691-20.228,5.539-30.342c0.365-2,0.764-3.994,1.091-6.001
            c0.12-0.783,0.406-1.063,1.249-1.059C43.954,5.343,53.831,5.33,63.708,5.33c6.924,0,13.851,0.019,20.773-0.019
            c0.975-0.008,1.144,0.26,0.971,1.18c-2.122,11.131-4.191,22.272-6.287,33.406c-1.193,6.317-2.42,12.631-3.623,18.947
            C75.489,59.122,75.489,59.41,75.447,59.861z M351.281,159.763c0.696-4.051,1.46-8.09,2.212-12.129
            c0.233-1.277,0.245-1.275-1.057-1.275c-27.265,0-54.524-0.027-81.788,0.027c-3.499,0.01-6.886-0.406-10.234-1.342
            c-7.57-2.109-14.091-5.848-18.858-12.256c-5.17-6.932-7.093-14.962-7.736-23.393c-0.553-7.269,0.012-14.483,1.299-21.642
            c1.968-10.895,5.989-20.888,13.218-29.403c10.448-12.304,23.806-18.706,39.91-19.36c4.338-0.177,8.673-0.042,12.996,0.416
            c6.072,0.644,11.983,1.912,17.537,4.542c0.523,0.252,0.708,0.224,0.892-0.346c1.317-4.041,2.66-8.074,4.008-12.106
            c0.173-0.512,0.797-2.333,1.004-3.002c1.092-3.428,2.25-6.835,3.379-10.25c0.734-2.237,0.745-2.269-1.358-3.258
            c-5.52-2.598-11.415-3.881-17.425-4.617c-7.604-0.932-15.238-1.251-22.902-0.736c-7.142,0.48-14.159,1.597-21.06,3.486
            c-10.667,2.92-20.408,7.748-29.51,14.014c-13.688,9.427-23.811,21.707-30.214,37.046c-5.494,13.175-7.706,27.016-8.263,41.176
            c-0.233,6.022,0.248,12.015,1.286,17.95c1.366,7.796,3.842,15.208,7.86,22.065c0.173,0.289,0.497,0.535,0.376,0.988
            c-0.368,0-0.733,0-1.095,0c-51.683,0-103.366,0-155.05,0c-0.146,0-0.289-0.02-0.437,0.002c-0.778,0.115-0.876-0.188-0.741-0.914
            c1.238-6.354,2.431-12.715,3.635-19.073c2.152-11.323,4.309-22.647,6.457-33.969c2.077-10.931,4.154-21.861,6.227-32.792
            c2.07-10.932,4.143-21.862,6.22-32.795c0.757-3.994,1.517-7.99,2.296-12.083c-5.482,0-10.874,0.004-16.263-0.007
            c-0.372-0.002-2.246,0.009-2.386,0.009c-4.699-0.004-9.402,0.015-14.105-0.022c-0.816-0.004-0.824,0.468-0.926,1.023
            c-1.257,6.904-2.521,13.809-3.793,20.711c-2.009,10.941-4.021,21.885-6.035,32.827c-1.934,10.519-3.871,21.029-5.802,31.544
            c-1.934,10.517-3.856,21.029-5.791,31.546c-2.005,10.905-4.011,21.813-6.017,32.722c-0.655,3.578-1.294,7.156-1.99,10.727
            c-0.135,0.701,0.094,0.842,0.692,0.785c0.218-0.02,0.44-0.002,0.658-0.002c86.198,0,172.392,0,258.593,0
            c25.111,0,50.224,0,75.339,0c0.29,0,0.583-0.035,0.869,0.004c0.609,0.09,0.832-0.217,0.934-0.766
            C349.139,171.366,351.167,160.421,351.281,159.763z M157.212,140.06c0-0.018,0-0.035,0-0.055c2.916,0,5.84,0.145,8.74-0.031
            c5.336-0.324,9.704-2.604,12.876-6.99c2.638-3.65,3.85-7.85,4.677-12.211c2.138-11.322,4.309-22.635,6.453-33.954
            c0.685-3.604,1.444-7.194,1.03-10.924c-0.699-6.248-4.549-10.039-10.832-10.21c-5.824-0.16-11.656-0.057-17.484-0.134
            c-0.96-0.013-1.303,0.292-1.46,1.2c-0.787,4.4-1.618,8.792-2.461,13.185c-3.752,19.707-7.511,39.412-11.292,59.115
            c-0.162,0.852,0.061,1.043,0.899,1.029C151.309,140.032,154.258,140.06,157.212,140.06z M76.131,140.919
            c6.623-0.045,11.891-2.67,15.585-8.246c2.19-3.297,3.349-6.994,4.087-10.846c2.155-11.352,4.33-22.705,6.471-34.061
            c0.686-3.608,1.577-7.194,1.393-10.912c-0.158-2.995-0.79-5.849-2.814-8.2c-2.37-2.762-5.554-3.72-9.045-3.893
            c-6.999-0.344-12.684,2.109-16.702,7.982c-2.276,3.328-3.474,7.092-4.222,11.015c-2.228,11.711-4.451,23.426-6.679,35.138
            c-0.749,3.93-1.622,7.848-1.001,11.898c0.646,4.213,2.622,7.439,6.72,9.082C71.911,140.673,73.991,140.96,76.131,140.919z
            M256.127,140.06c3.003,0,5.874-0.027,8.748,0.02c0.757,0.014,1.065-0.223,1.287-0.936c1.208-3.848,2.514-7.664,3.698-11.518
            c0.268-0.873,0.674-1.082,1.532-1.07c3.457,0.049,6.923,0.019,10.385,0.019c1.509,0,1.479,0,1.385,1.558
            c-0.223,3.671-0.399,7.348-0.629,11.018c-0.045,0.709,0.128,0.938,0.865,0.928c2.95-0.041,5.903-0.037,8.854,0
            c0.685,0.006,0.96-0.158,0.989-0.893c0.286-6.876,0.603-13.756,0.911-20.634c0.316-7.095,0.621-14.192,0.948-21.287
            c0.466-10.225,0.944-20.448,1.452-30.671c0.037-0.809-0.14-1.048-0.986-1.041c-4.405,0.045-8.815,0.064-13.225-0.011
            c-1.047-0.015-1.423,0.346-1.731,1.277c-7.183,21.53-14.396,43.052-21.601,64.574C258.058,134.241,257.114,137.097,256.127,140.06z
            M139.212,102.823c0.003,0,0.007,0,0.011,0c0.207-4.694,0.398-9.39,0.617-14.084c0.335-7.388,0.666-14.774,1.038-22.158
            c0.042-0.837-0.191-1.035-1.008-1.027c-4.485,0.042-8.963,0.042-13.448,0c-0.797-0.009-1.105,0.254-1.354,1.001
            c-3.289,9.894-6.615,19.774-9.923,29.657c-4.778,14.258-9.538,28.519-14.335,42.771c-0.294,0.861-0.211,1.121,0.764,1.096
            c2.589-0.055,5.174-0.045,7.758-0.002c0.666,0.01,0.941-0.215,1.141-0.834c1.215-3.844,2.502-7.664,3.698-11.516
            c0.271-0.873,0.636-1.193,1.581-1.176c3.499,0.064,7.002,0.07,10.493-0.002c1.046-0.022,1.366,0.219,1.287,1.302
            c-0.274,3.776-0.44,7.563-0.677,11.343c-0.042,0.635,0.078,0.893,0.805,0.885c3.025-0.045,6.047-0.033,9.072-0.006
            c0.59,0.004,0.819-0.146,0.846-0.787c0.275-6.697,0.572-13.393,0.877-20.09C138.704,113.738,138.959,108.28,139.212,102.823z
            M342.972,140.06c3.604,0,7.214-0.018,10.821,0.018c0.741,0.006,1.065-0.145,1.204-0.967c0.479-2.867,1.05-5.723,1.637-8.568
            c0.181-0.857,0-1.121-0.914-1.105c-3.608,0.051-7.221-0.012-10.825,0.041c-0.91,0.013-1.034-0.292-0.873-1.07
            c0.392-1.852,0.73-3.713,1.088-5.567c2.156-11.319,4.305-22.642,6.46-33.963c1.419-7.43,2.818-14.857,4.264-22.281
            c0.154-0.812,0.019-1.061-0.862-1.042c-2.766,0.058-5.538,0.086-8.304-0.011c-1.042-0.036-1.351,0.333-1.524,1.264
            c-1.952,10.396-3.939,20.787-5.914,31.178c-2.604,13.68-5.192,27.36-7.815,41.035c-0.146,0.797-0.063,1.072,0.843,1.061
            C335.828,140.032,339.398,140.06,342.972,140.06z M313.805,140.06c3.604,0,7.217-0.031,10.821,0.025
            c0.861,0.014,1.103-0.309,1.237-1.064c0.501-2.865,1.009-5.732,1.622-8.57c0.207-0.939-0.075-1.014-0.865-1.009
            c-3.608,0.032-7.217,0-10.825,0.024c-0.775,0.008-1.133-0.002-0.926-1.031c1.189-5.954,2.269-11.929,3.405-17.893
            c2.8-14.678,5.599-29.354,8.417-44.026c0.132-0.692,0.022-0.969-0.771-0.956c-2.95,0.041-5.903,0.041-8.854-0.002
            c-0.67-0.011-0.881,0.258-0.993,0.837c-1.027,5.501-2.073,11.004-3.119,16.505c-2.142,11.287-4.29,22.57-6.438,33.856
            c-1.414,7.429-2.825,14.858-4.263,22.28c-0.15,0.771-0.079,1.062,0.835,1.047C306.66,140.028,310.234,140.06,313.805,140.06z"
              />
              <linearGradient
                id="SVGID_2_"
                gradientUnits="userSpaceOnUse"
                x1="170.1465"
                y1="-23.5825"
                x2="170.1465"
                y2="224.754"
              >
                <stop
                  offset="0.0171"
                  // @ts-ignore
                  style={{ stopColor: '#F9C828' }}
                />
                <stop
                  offset="0.6"
                  // @ts-ignore
                  style={{ stopColor: '#F16C24' }}
                />
                <stop
                  offset="1"
                  // @ts-ignore
                  style={{ stopColor: '#EC197B' }}
                />
              </linearGradient>
              <path
                fill="url(#SVGID_2_)"
                d="M163.357,129.456c-0.688,0-1.385,0-2.077,0
            c-1.399,0-1.393,0.002-1.125-1.414c3.131-16.428,6.257-32.857,9.376-49.287c0.489-2.566,0.479-2.592,3.135-2.561
            c1.49,0.019,2.987-0.152,4.47,0.132c1.961,0.376,2.932,1.498,3.104,3.501c0.188,2.218-0.369,4.34-0.775,6.479
            c-2.216,11.754-4.459,23.5-6.697,35.251c-0.271,1.431-0.617,2.831-1.234,4.169c-1.121,2.43-2.995,3.671-5.659,3.723
            c-0.839,0.016-1.678,0.004-2.517,0.004V129.456z"
              />
              <linearGradient
                id="SVGID_3_"
                gradientUnits="userSpaceOnUse"
                x1="83.3398"
                y1="-23.5645"
                x2="83.3398"
                y2="224.7721"
              >
                <stop
                  offset="0.0171"
                  // @ts-ignore
                  style={{ stopColor: '#F9C828' }}
                />
                <stop
                  offset="0.6"
                  // @ts-ignore
                  style={{ stopColor: '#F16C24' }}
                />
                <stop
                  offset="1"
                  // @ts-ignore
                  style={{ stopColor: '#EC197B' }}
                />
              </linearGradient>
              <path
                fill="url(#SVGID_3_)"
                d="M73.735,125.152c0.207-2.507,0.873-5.231,1.389-7.982
            c2.137-11.357,4.312-22.708,6.482-34.061c0.426-2.265,0.994-4.502,2.766-6.156c1.754-1.637,3.834-1.958,6.084-1.283
            c1.358,0.408,2.021,1.445,2.318,2.783c0.478,2.22-0.146,4.348-0.553,6.479c-2.359,12.537-4.753,25.066-7.149,37.597
            c-0.429,2.265-1.001,4.496-2.766,6.158c-1.72,1.628-3.796,1.935-5.99,1.313C74.521,129.49,73.686,127.89,73.735,125.152z"
              />
              <linearGradient
                id="SVGID_4_"
                gradientUnits="userSpaceOnUse"
                x1="279.7534"
                y1="-33.8687"
                x2="279.7534"
                y2="214.4722"
              >
                <stop
                  offset="0.0171"
                  // @ts-ignore
                  style={{ stopColor: '#F9C828' }}
                />
                <stop
                  offset="0.6"
                  // @ts-ignore
                  style={{ stopColor: '#F16C24' }}
                />
                <stop
                  offset="1"
                  // @ts-ignore
                  style={{ stopColor: '#EC197B' }}
                />
              </linearGradient>
              <path
                fill="url(#SVGID_4_)"
                d="M285.845,78.939c-0.091,1.761-0.17,3.524-0.271,5.283
            c-0.496,8.722-0.997,17.444-1.509,26.167c-0.102,1.744-0.237,3.486-0.319,5.23c-0.03,0.562-0.147,0.827-0.806,0.818
            c-2.878-0.036-5.757-0.033-8.631-0.004c-0.723,0.009-0.738-0.205-0.538-0.816c3.92-12.088,7.811-24.181,11.713-36.272
            c0.049-0.154,0.132-0.297,0.195-0.45C285.736,78.912,285.792,78.927,285.845,78.939z"
              />
              <linearGradient
                id="SVGID_5_"
                gradientUnits="userSpaceOnUse"
                x1="124.0298"
                y1="-33.8179"
                x2="124.0298"
                y2="214.5183"
              >
                <stop
                  offset="0.0171"
                  // @ts-ignore
                  style={{ stopColor: '#F9C828' }}
                />
                <stop
                  offset="0.6"
                  // @ts-ignore
                  style={{ stopColor: '#F16C24' }}
                />
                <stop
                  offset="1"
                  // @ts-ignore
                  style={{ stopColor: '#EC197B' }}
                />
              </linearGradient>
              <path
                fill="url(#SVGID_5_)"
                d="M130.193,79.005c-0.26,4.688-0.516,9.38-0.786,14.068
            c-0.331,5.82-0.678,11.636-1.013,17.455c-0.102,1.709-0.188,3.417-0.274,5.127c-0.022,0.422-0.041,0.779-0.644,0.777
            c-3.16-0.021-6.328-0.01-9.609-0.01c4.052-12.562,8.074-25.016,12.097-37.47C130.042,78.97,130.114,78.989,130.193,79.005z"
              />
            </g>
          </svg>
        </Box>
      )
    }
    return logoJSX
  }

  return rJSX_Logo()
}

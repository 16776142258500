///////////////////////////////
// Description
///////////////////////////////

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material'
import { DocumentData, DocumentReference } from 'firebase/firestore'
import { useContext, useEffect, useState } from 'react'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import { DatabaseSetMergeDocument, DatabaseUpdateDocument } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { generatePdfFromData, TsInterface_PdfTemplate } from './custom'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  pdfData: TsInterface_UnspecifiedObject
  pdfTemplate: TsInterface_PdfTemplate
  pdfFullTemplate: TsInterface_UnspecifiedObject
  pdfRoot: TsInterface_UnspecifiedObject
  pdfPossibleVariables: TsInterface_UnspecifiedObject
  mode: 'edit' | 'view'
  // onChange: (data: TsInterface_UnspecifiedObject) => Promise<any>
  databaseDocumentRef: DocumentReference<DocumentData>
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const CustomPdfEditor = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_pdfPossibleVariables: TsInterface_ComponentProps['pdfPossibleVariables'] = getProp(props, 'pdfPossibleVariables', {})
  let pr_pdfTemplate: TsInterface_ComponentProps['pdfTemplate'] = getProp(props, 'pdfTemplate', {})
  let pr_pdfFullTemplate: TsInterface_ComponentProps['pdfFullTemplate'] = getProp(props, 'pdfFullTemplate', {})
  let pr_pdfData: TsInterface_ComponentProps['pdfData'] = getProp(props, 'pdfData', {})
  let pr_pdfRoot: TsInterface_ComponentProps['pdfRoot'] = getProp(props, 'pdfRoot', {})
  let pr_mode: TsInterface_ComponentProps['mode'] = getProp(props, 'mode', 'edit')
  let pr_databaseDocumentRef: TsInterface_ComponentProps['databaseDocumentRef'] = getProp(props, 'databaseDocumentRef', null)
  // let pr_onChange: TsInterface_ComponentProps['onChange'] = getProp(props, 'onChange', (data: TsInterface_UnspecifiedObject) => {
  //   console.log('MISSING')
  // })

  // Hooks - useContext, useState, useReducer, other
  const [us_anchorPosition, us_setAnchorPosition] = useState<{ top: number; left: number } | null>(null)
  const [us_pdfFullTemplate] = useState<TsInterface_UnspecifiedObject>(pr_pdfFullTemplate)
  const [us_pdfData] = useState<TsInterface_UnspecifiedObject>(pr_pdfData)
  const [us_pdfRoot] = useState<TsInterface_UnspecifiedObject>(pr_pdfRoot)
  const [us_pdfPossibleVariables] = useState<TsInterface_UnspecifiedObject>(pr_pdfPossibleVariables)
  const [us_pdfTemplate, us_setPdfTemplate] = useState<TsInterface_PdfTemplate>(pr_pdfTemplate)
  const [us_selectedComponentKey, us_setSelectedComponentKey] = useState<string | null>(null)
  const [us_selectedComponentType, us_setSelectedComponentType] = useState<string | null>(null)
  const [us_updatingPdf, us_setUpdatingPdf] = useState<boolean>(false)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect
  useEffect(() => {
    us_setPdfTemplate(pr_pdfTemplate)
  }, [pr_pdfTemplate])

  // Functions

  let onChange = async (data: TsInterface_UnspecifiedObject) => {
    return new Promise((resolve, reject) => {
      if (pr_databaseDocumentRef != null) {
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            us_setUpdatingPdf(true)
            DatabaseSetMergeDocument(pr_databaseDocumentRef, { pdf_template: data })
              .then((res_DSMD) => {
                us_setUpdatingPdf(false)
                resolve(res_DSMD)
              })
              .catch((rej_DSMD) => {
                reject(rej_DSMD)
                us_setUpdatingPdf(false)
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
              })
          })
          .catch((rej_GCK) => {
            reject(rej_GCK)
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      } else {
        reject({ error: 'Database Document Reference is null' })
      }
    })
  }

  let onChangeWithDelete = async (data: TsInterface_UnspecifiedObject) => {
    return new Promise((resolve, reject) => {
      if (pr_databaseDocumentRef != null) {
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            us_setUpdatingPdf(true)
            DatabaseUpdateDocument(pr_databaseDocumentRef, { pdf_template: data })
              .then((res_DUD) => {
                us_setUpdatingPdf(false)
                resolve(res_DUD)
              })
              .catch((rej_DUD) => {
                reject(rej_DUD)
                us_setUpdatingPdf(false)
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
              })
          })
          .catch((rej_GCK) => {
            reject(rej_GCK)
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      } else {
        reject({ error: 'Database Document Reference is null' })
      }
    })
  }

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX: JSX.Element = <></>
    componentJSX = (
      <Box className="tw-w-full">
        {generatePdfFromData(us_pdfTemplate, us_pdfData, pr_mode, uc_setUserInterface_FormDialogDisplay, onChange, {
          onChangeWithDelete: onChangeWithDelete,
          uc_setUserInterface_ConfirmDialogDisplay: uc_setUserInterface_ConfirmDialogDisplay,
          uc_setUserInterface_CustomDialogDisplay: uc_setUserInterface_CustomDialogDisplay,
          uc_setUserInterface_FormDialogDisplay: uc_setUserInterface_FormDialogDisplay,
          us_anchorPosition: us_anchorPosition,
          us_pdfFullTemplate: us_pdfFullTemplate,
          us_pdfData: us_pdfData,
          us_pdfRoot: us_pdfRoot,
          us_updatingPdf: us_updatingPdf,
          us_pdfPossibleVariables: us_pdfPossibleVariables,
          us_pdfTemplate: us_pdfTemplate,
          us_selectedComponentKey: us_selectedComponentKey,
          us_selectedComponentType: us_selectedComponentType,
          us_setAnchorPosition: us_setAnchorPosition,
          us_setSelectedComponentKey: us_setSelectedComponentKey,
          us_setSelectedComponentType: us_setSelectedComponentType,
        })}
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}
